.gh-offcanvas-bar {
  width: 420px;
  box-sizing: border-box;
  height: calc(100% - 80px);
  background-color: #F0F2F6;
}

.pricing-page .gh-offcanvas-bar {
  width: 320px;
}

.gh-public-menu {
  height: 100%;
}

.gh-side-menu.second-step {
  height: calc(100% - 68px);
  overflow-y: scroll;
}

.gh-side-menu .header-desktop-menu-item:hover .link {
  border-bottom: 1px solid transparent;
}

.header-desktop-menu-item .mobile-subnavbtn {
  color: #ffffff;
  font-size: 15px;
  font-family: Rubik, sans-serif;
  font-weight: normal;
}

.header-desktop-menu-item > .link {
  color: #ffffff;
  font-size: 15px;
  font-family: Rubik, sans-serif;
  font-weight: normal;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: all 0.15s;
  white-space: nowrap;
}

.gh-action-menu-btn {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin-left: auto;
}

.gh-action-menu-btn img {
  padding: 2px;
  max-width: 32px;
}

.gh-action-menu-btn.back {
  margin-left: 0;
}

.gh-action-menu-btn.back img {
  padding: 4px;
}

.gh-public-menu .gh-side-menu {
  margin-top: 0;
}

.gh-side-menu.second-step > .header-desktop-menu-item > .link {
  min-height: 31px;
}

.gh-public-menu .gh-side-menu .link {
  color: var(--gh-font-black) !important;
  font-size: 15px;
  font-family: Rubik, sans-serif;
  font-weight: 500;
}

.gh-public-menu .header-desktop-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 47px;
  padding: 7px 0;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #bdc3cf;
}

.gh-public-menu .header-desktop-menu-item .gh-icon {
  transform: rotate(180deg);
  max-width: 32px;
  padding: 5px;
}

.header-desktop-menu-item.--subnav {
  display: flex;
}

.gh-subnav-menu.--separate {
  display: block;
  padding: 0;
}

.header-desktop-menu-item.--subnav .gh-subnav-menu {
  width: 100%;
}

.header-desktop-menu-item.--subnav .gh-subnav-menu .header-desktop-menu-item,
.header-desktop-menu-item.--subnav .gh-subnav-menu .header-desktop-menu-item .link {
  min-height: fit-content;
  height: fit-content;
}

.header-desktop-menu-item.--subnav .link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  align-items: center;
}

.header-desktop-menu-item.--subnav .link .gh-dropdown-arrow {
  transform: rotate(-90deg);
  margin-right: 11px;
}

.header-desktop-menu-item.--subnav.--open .link .gh-dropdown-arrow {
  transform: rotate(90deg);
}

  /*Second step*/
.gh-public-menu .gh-side-menu.second-step .header-desktop-menu-item {
  height: fit-content;
  min-height: fit-content;
  flex-direction: column;
  align-items: unset;
}

.gh-public-menu .gh-side-menu.second-step .header-desktop-menu-item .link {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.gh-public-menu .gh-side-menu.second-step .gh-dropdown-arrow {
  transform: rotate(-90deg);
  margin-right: 11px;
}

.gh-public-menu .gh-side-menu.second-step .header-desktop-menu-item.--redirect .gh-arrow-open-menu{
  margin-right: 7px;
}

.gh-subnav-menu {
  display: none;
}

.header-desktop-menu-item.--open .gh-subnav-menu {
  display: block;
}

.gh-subnav-menu .header-desktop-menu-item {
  border: none;
}

.gh-subnav-menu .header-desktop-menu-item .link {
  font-weight: 300;
}

.gh-subnav-menu .header-desktop-menu-item .link.title {
  font-weight: 400;
}

/*--------------- MAX WIDTH 959PX ---------------*/

@media (max-width: 959px) {
  .gh-subnav-menu .header-desktop-menu-item .link.title.first-row {
    margin-top: 0;
  }

  .gh-subnav-menu .header-desktop-menu-item .link.title {
    margin-top: 10px;
  }
}

/*--------------- MAX WIDTH 575PX ---------------*/

@media (max-width: 575.98px) {
  .gh-offcanvas-bar {
    width: 100%;
  }

  .pricing-page .gh-offcanvas-bar {
    width: 320px;
  }
}