.calculator__description {
  border: 1px solid #d5d5d5;
  background-color: #fff;
  transition-property: box-shadow;
  transition-duration: 0.2s;
  outline: none;
}
.calculator__description.clickable {
  cursor: pointer;
}
.calculator__description .calculator__description-item:not(:last-of-type) {
  border-bottom: 1px solid #d5d5d5;
}
.calculator__description-item {
  font-weight: 500;
  font-size: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #242f42;
  position: relative;
}
.calculator__description-item.uk-flex-between {
  justify-content: space-between;
}
.calculator__description-item:not(.placeholder) {
  background-color: #fff;
}
.calculator__description-item.text {
  padding: 0 10px;
}
.calculator__description-item.input {
  padding: 2px;
  border-radius: 0px;
}
.calculator__description-item.header {
  background-color: #e7eaef;
  text-transform: uppercase;
}
.calculator__description-item.separator {
  border-top: 1px solid #000;
}
.calculator__description-item.footer {
  background-color: #6d7481;
  color: #fff;
}
.calculator__description-item.footer input {
  background-color: #6d7481;
  color: #fff;
}
.calculator__description-item.footer input::placeholder {
  color: #e7eaef;
}
.calculator__description-item.info {
  background-color: #f4f7fb;
}
.description-prefix,
.description-suffix {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%;
}
.description-prefix {
  padding-left: 8px;
  margin-right: 6px;
}
.description-suffix {
  margin-left: 2px;
}
