.custom-tooltip-wrapper {
  position: relative;
}

.custom-tooltip-content {
  background-color: #242f42;
  color: #fff;
  font-size: 14px !important;
  line-height: 17px !important;
  text-transform: initial !important;
  text-align: start;
  font-weight: normal !important;
  padding: 10px;
  position: absolute;
  z-index: 999;
  min-width: 50px;
  max-width: 260px;
  pointer-events: none;
  opacity: 0;
  transition: 0.15s ease-out;
  white-space: initial;
}

.custom-tooltip-content::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #242f42;
}

div[class^="custom-tooltip-content top-"] {
  bottom: calc(100% + 12px);
  transform: scale(0.8) translateY(15px);
}

div[class^="custom-tooltip-content top-"]::after {
  top: calc(100% - 3px);
  border-bottom: 0;
  border-top: 12px solid #242f42;
}

div[class^="custom-tooltip-content bottom-"] {
  top: calc(100% + 12px);
  transform: scale(0.8) translateY(-15px);
}

div[class^="custom-tooltip-content bottom-"]::after {
  bottom: calc(100% - 3px);
}

.custom-tooltip-content.bottom-right,
.custom-tooltip-content.top-right {
  left: -6px;
}

.custom-tooltip-content.bottom-right::after,
.custom-tooltip-content.top-right::after {
  left: 6px;
}

.custom-tooltip-content.bottom-left,
.custom-tooltip-content.top-left {
  right: -6px;
}

.custom-tooltip-content.bottom-left::after,
.custom-tooltip-content.top-left::after {
  right: 6px;
}

.custom-tooltip-content.bottom-center,
.custom-tooltip-content.top-center {
  left: 50%;
  transform: translateX(-50%) scale(0.8) translateY(10px);
  /* transform: scale(0.8) translateY(10px); */
}

.custom-tooltip-content.bottom-center::after,
.custom-tooltip-content.top-center::after {
  left: 50%;
  transform: translateX(-50%);
}

.custom-tooltip-wrapper.show:hover div.custom-tooltip-content[class$="-center"] {
  /*transform: translateX(-50%) translateY(0) scale(1) !important;*/
  transform: translateY(0) scale(1) !important;
}

.custom-tooltip-wrapper.show:hover .custom-tooltip-content {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0) scale(1);
}

.custom-tooltip-wrapper:hover .custom-tooltip-content.top-center,
.custom-tooltip-wrapper:hover .custom-tooltip-content.bottom-center {
  transform: translateX(-50%) translateY(0) scale(1) !important;
}

.custom-tooltip-content.bottom-left {
  transform-origin: top right;
}

.enquire-tooltip-width {
  min-width: 280px;
}

.quote-details-tooltip {
  min-width: 120px;
}

.req-detail-width {
  max-width: 120px;
}

.tooltip-content-fixed-req {
  left: -35% !important;
}

.private-tooltip-content {
  max-width: 300px;
}
.identifier-tooltip-content {
  transform: translateX(0) !important;
}
@media (max-width: 576px) {
  .identifier-tooltip-content,
  .payload-tooltip-content {
    transform: translateX(-46%) !important;
  }
  .tooltip-content-fixed-req {
    left: 0 !important;
  }
  .tab-tooltip-content {
    right: -40px;
  }
  .tab-tooltip-price-content {
    right: 40px !important;
  }
}

.stripe-tooltip-content {
  background: #6976e3;
  right: 13px !important;
  max-width: 220px;
  box-shadow: -1px -1px 5px 1px #fff;
}

.stripe-tooltip-content:after {
  border-top-color: #6976e3 !important;
  border-bottom-color: #6976e3 !important;
}

@media (max-width: 450px) {
  .custom-tooltip-content {
    max-width: 220px;
  }
}
