.options {
  height: 100%;
  position: relative;
}
.options .accordion__content .options-btn {
  padding-left: 24px;
}
.options-trigger {
  color: #494e57;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 2px;
  background-color: transparent;
  width: 35px;
  height: 100%;
}
.options-trigger:hover {
  background-color: #c8cfdb;
}
.options-trigger svg {
  width: 20px;
  height: 20px;
}
.options-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.options-content {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  background-color: #fff;
  z-index: 12;
  display: flex;
  flex-direction: column;
  width: 160px;
  padding: 6px 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 2px;
}
.calculator .options-btn {
  width: 100%;
  border: none;
  padding: 6px 16px;
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  color: #005bff;
  background: transparent;
}
.calculator .options-btn.danger {
  color: #e42a2a;
}
.calculator .options-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
