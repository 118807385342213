.gh-button-copy-to-clipboard[disabled] {
    filter: grayscale(100%);
    opacity: 0.5;
    pointer-events: none;
    transition: background-color 0.2s ease-out;
}

.gh-button-copy-to-clipboard-wrapper.gh-wrapper-progress-grafic-btn.disabled:hover {
    opacity: initial;
    cursor: not-allowed;
}

.gh-wrapper-progress.disabled {
    opacity: 0.5;
}

.gh-button-copy-clippy {
    transition: all 300ms ease-in-out;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    stroke-dasharray: 56;
    stroke-dashoffset: 0;
}

.gh-button-copy-clippy-animation {
    stroke-dashoffset: 56;
}

.gh-button-copy-check {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    stroke-dasharray: 50;
    stroke-dashoffset: -50;
    transition: all 300ms ease-in-out
}

.gh-button-copy-check-animation {
    stroke-dashoffset: 0;
}

@media not all and (min-resolution: .001dpcm) {
    .gh-button-copy-check {
        display: none;
    }

    .gh-button-copy-clippy-animation {
        stroke-dashoffset: 0;
    }

    .gh-button-copy-to-clipboard:active {
        background: #0f9f81 !important;
    }
}
