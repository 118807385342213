.signup-progress-step_line {
  background-color: #fff;
  box-sizing: border-box;
  height: 10px;
  border: 1px solid transparent;
  width: calc(100% + 2px);
  position: relative;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.signup-progress-step_line.completed {
  border-color: #10b897;
  border-left-color: transparent;
  background-color: #e6f2f0;
  transition: 0.2s;
}

.signup-progress-step_line:first-of-type,
.signup-progress-step_line.with-border {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-left-color: #10b897;
}

.signup-progress-step_arrow {
  position: absolute;
  top: -1px;
  right: -2px;
  height: 9px;
  z-index: 2;
}

.signup-progress-step_arrow.completed path {
  stroke: #10b897;
  fill: #e6f2f0;
}

.signup-progress-step_line.completed.is-active {
  background-color: #10b897;
}

.signup-progress-step_arrow.completed.is-active path {
  fill: #10b897;
}
