.gh-text-no-wrap {
    white-space: nowrap;
}

/*font*/
.font-size-13 {
    font-size: 13px;
}

.text-gray-light {
    color: #9da3ae;
}

/*links*/
.gh-link-gray {
    color: #868e9e;
}

.gh-link-gray:hover {
    color: #43474f;
}

