@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.05);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.gh-radio-button-wrapper > *:after,
.gh-radio-button-wrapper > *:before,
.gh-radio-button-wrapper > * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.gh-radio-button-wrapper {
  margin: 0 0 10px;
  display: inline-block;
}

.gh-radio-button-wrapper input[type="radio"] {
  display: none;
}

.gh-radio-button-wrapper input[type="radio"]:checked + label.gh-radio-button-label:before {
  border-color: #9da3ae;
  animation: ripple 0.2s linear forwards;
}

.gh-radio-button-wrapper input[type="radio"]:checked + label.gh-radio-button-label:after {
  transform: scale(1);
}

.gh-radio-button-wrapper label.gh-radio-button-label {
  display: inline-block;
  min-height: 20px;
  position: relative;
  padding: 0 30px;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;
  color: #242f42;
}

.gh-radio-button-wrapper label.gh-radio-button-label:before,
.gh-radio-button-wrapper label.gh-radio-button-label:after {
  position: absolute;
  content: "";
  border-radius: 50%;
  transition: all 0.3s ease;
  transition-property: transform, border-color;
}

.gh-radio-button-wrapper label.gh-radio-button-label.gh-radio-label:before {
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #9da3ae;
  background: transparent;
}

.gh-radio-button-wrapper label.gh-radio-button-label.gh-radio-label:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  transform: scale(0);
  background: #10b897;
}

.gh-radio-button-wrapper label.gh-radio-button-label.gh-radio-purple:after {
  background: #635bff;
}

.gh-radio-button-wrapper label.gh-radio-button-label-disabled {
  cursor: default;
  user-select: none;
}

@media only screen and (max-width: 768px) {
  .gh-radio-button-wrapper {
    display: block;
  }
}
