.gh-download-svg-wrapper svg {
    width: 20px;
    height: 20px;
    fill: #fff;
    margin-right: 10px;
}

.gh-download-pdf-button {
    width: 80%;
    max-width: 300px;
    cursor: pointer;
}

#download-pdf-modal .uk-modal-dialog {
    min-width: 900px;
}

.gh-download-pdf-operator-margin__section {
    white-space: nowrap;
}

.gh-download-pdf-operator-margin__section .tax-input__part {
    max-height: 39px;
}

.gh-download-pdf-operator-margin__section .operator-margin__title {
    line-height: 39px;
}

.custom-tooltip-wrapper.gh-tooltip-download-pdf {
    display: inline-block;
}

.gh-radio-wrapper-pdf-modal {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 20px;
}

.tax-input-pdf-column {
    flex-direction: column;
    align-items: flex-start;
}

.tax-input-pdf-row {
    display: flex;
    font-weight: 500;
}

.gh-pdf-modal-vat-input {
    margin: 0 5px;
}

.gh-pdf-modal-margin-input .pc-input__suffix,
.gh-pdf-modal-vat-input .pc-input__suffix {
    width: 37px;
    box-sizing: border-box;
    min-width: 37px;
}

.gh-pdf-modal-vat-input.tax-input__part {
    position: relative;
    width: 100%;
    max-width: initial;
    height: inherit;
}

.gh-pdf-modal-margin-percent .pc-input {
    min-width: 55px;
    max-width: 62px;
    width: min-content;
}

.gh-pdf-modal-vat-input .pc-input {
    width: 70px;
}

.operator-margin__section-tax-inputs {
    min-width: 275px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
}

.gh-pdf-modal-input-error {
    position: absolute !important;
    transform: translateX(calc(-50px));
}

.gh-pdf-modal-margin-percent .pc-input__suffix {
    min-width: 12px;
}

.gh-download-pdf-calculator__operator-margin > *:not(:last-child) {
    margin-bottom: 15px;
}

.gh-download-pdf-calculator__operator-margin {
    flex-wrap: wrap;
    align-items: flex-start;
}

@media only screen and (max-width: 1024px) {
    #download-pdf-modal .uk-modal-dialog {
        min-width: unset !important;
        width: unset;
    }
}

@media only screen and (max-width: 768px) {
    .gh-download-pdf-calculator__operator-margin {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
    }

    .operator-margin-title-pdf {
        font-size: 18px;
        line-height: 20px;
    }

    .gh-download-pdf-operator-margin__subtitle {
        font-size: 14px;
    }

    .gh-download-pdf-operator-margin__section:not(:last-of-type) {
        margin-bottom: 5px;
    }

    .gh-download-pdf-operator-margin__subtitle {
        margin-bottom: 5px;
    }

    .gh-download-pdf-button {
        padding: 16px 30px !important;
    }

    .operator-margin__section.operator-margin__section-tax-inputs.gh-download-pdf-operator-margin__section {
        margin-bottom: 20px;
    }

    .operator-margin__section-tax-inputs {
        margin: 0;
    }

    .gh-download-pdf-calculator__operator-margin > *:not(:last-child) {
        margin-bottom: initial;
    }
}

@media only screen and (max-width: 420px) {
    #download-pdf-modal .uk-modal-dialog {
        padding: 24px 20px !important;
    }

    .gh-pdf-modal-margin-input .pc-input__suffix,
    .gh-pdf-modal-vat-input .pc-input__suffix {
        min-width: 12px;
    }

    .tax-input-pdf-row {
        flex-direction: column;
    }

    .gh-pdf-modal-margin-input .pc-input,
    .gh-pdf-modal-margin-percent .pc-input {
        width: 90px;
        max-width: initial;
    }

    .gh-pdf-modal-margin-input {
        flex: 1;
    }

    .gh-pdf-modal-margin-percent {
        flex: 1;
        margin-bottom: 5px;
    }

    .gh-pdf-modal-vat-input {
        margin: 0 6px 0 0;
    }
}
