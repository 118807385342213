.gh-pricing h1,
.gh-pricing h2,
.gh-pricing h3,
.gh-pricing h4,
.gh-pricing p {
  margin: 0;
}

.gh-pricing {
  min-width: 1500px;
  /*overflow-x: scroll;*/
  background: #f2f4f7;
  padding: 0 0 30px 0;
  color: #242e41;
}

.gh-pricing .stripe-image {
  margin-right: 10px;
  margin-bottom: 1px;
}

.grayed-info {
  color: #6d7480;
  font-size: 12px;
  font-weight: 300;
  margin-top: 3px;
}

.grayed-info .sup-text {
  display: inline-block;
  vertical-align: super;
  color: #242e41;
  font-size: 12px;
  font-weight: normal;
  transform: translateY(5px);
}

.section-title {
  font-size: 24px;
  font-weight: 500;
  border-top: 3px solid #1abc9c;
  background: #e7f2ef;
  padding: 10px 15px;
}

.gh-bundle {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gh-bundle-header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 13px 0;
}

.gh-bundle .bundle-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  text-transform: uppercase;
}

.gh-bundle .bundle-sub {
  font-weight: 400;
  font-size: 16px;
}

.gh-pricing-headers-column {
  margin-top: 90px;
  padding-bottom: 15px;
}

.gh-pricing-row {
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
}

.gh-pricing-item-title {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.pricing-head-message {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  padding: 40px 0;
}

.pricing-block {
  width: 100%;
  box-shadow: 0 12px 20px 0 #dfe2e8;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.recommended-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bordered {
  box-shadow: 10px 0 1px -1px #f2f4f7, inset 2px 0 1px -1px #f2f4f7;
  height: 100%;
}

.recommended {
  position: absolute;
  top: -9px;
  width: 100%;
  z-index: 9999;
}

.recommended-text {
  background: #1abc9c;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.gh-bundle-options .bundle-option {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gh-bundle-options .bundle-option .numbers {
  font-weight: 500;
}

.gh-bundle-options .bundle-addons {
  height: 100%;
  background: #e7f2ef;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding-left: 15px;
}

.gh-features-section {
  margin-top: 24px;
  border-top: 3px solid #1abc9c;
}
.gh-features-section .features-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.gh-features-section .section-title {
  font-size: 18px;
  border-top: 0;
}

.gh-features-section .gh-bundle-header {
  padding: 10px 0;
}

.gh-features-empty-row {
  background: #fff;
  height: 100%;
  width: 100%;
}

.gh-feature-card {
  margin-top: 15px;
}

.gh-feature-card:first-child {
  margin-top: 0;
}

.gh-feature-card-inner {
  background: #fff;
  padding: 20px 15px;
  border: 1px solid #d6d6d5;
  position: relative;
}

.gh-feature-card .card-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.gh-feature-card .option-title {
  font-size: 16px;
  font-weight: normal;
}

.gh-feature-card .option-value {
  text-align: center;
}

.gh-features-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.gh-pricing-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 25px 0 !important;
}

.gh-pricing-header-plan-text {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.gh-pricing-header-btn {
  display: block;
  /*position: absolute;*/
  font-size: 18px;
  font-weight: 400;
  transition: 0.4s;
  bottom: 27px;
  right: 0;
  color: #1abc9c;
}

.gh-grid-above {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
}

.gh-grid-above-item {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: relative;
}
.gh-grid-above-item:first-child:after {
  width: 95%;
}

.gh-grid-above-item:hover:after {
  opacity: 1;
}

.gh-grid-above-item:after {
  content: "";
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100% - 97px);
  box-shadow: 0 0 21px 9px #d5d5d5;
  z-index: 999;
}

.gh-grid-above-item:last-child:after {
  border-right: 0;
}

.gh-grid-above-item.active:after {
  opacity: 1;
  height: calc(100% - 97px);
  box-shadow: 0 0 11px 4px #d5d5d5;
}

.gh-pricing-header-currency {
  display: flex;
  align-items: center;
}

.gh-pricing-header-currency-key .sup-text {
  left: 4px;
}

.gh-pricing-header-currency .uk-select {
  height: 30px !important;
  line-height: 30px;
  padding-right: 30px !important;
  margin-left: 10px;
  border: 1px solid #6d7481 !important;
}

#pricing-page-modal .uk-modal-dialog {
  max-width: 615px !important;
}

#pricing-page-modal header {
  display: none;
}

.pricing-page-modal-title {
  text-align: center;
}

.pricing-page-modal-title h3 {
  margin-top: 0;
  font-weight: 500;
  font-size: 32px;
}

.pricing-page-modal-title h5 {
  font-size: 24px;
  margin-bottom: 8px;
}

.pricing-page-modal-content {
  text-align: center;
}

.pricing-page-modal-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding: 16px;
  border-radius: 4px;
  border: 1px dashed #d5d5d5;
  position: relative;
}

.pricing-page-modal-action .code {
  font-weight: 500;
  text-transform: uppercase;
  color: #242f42;
}

.pricing-page-modal-action .label {
  margin-right: 3px;
  text-transform: capitalize;
}

.pricing-page-modal-action .gh-main-button-component {
  padding: 14px 20px;
  min-width: unset;
}
