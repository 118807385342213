.request-details-later-action {
  position: relative;
}

.later-action__options button {
  outline: none;
  background: transparent;
  border: none;
  transition: 0.2s;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-family: Rubik, sans-serif;
}

.later-action__options button:not(.--selected):hover {
  background-color: #f2f4f7;
  cursor: pointer;
}

.later-action__options button:disabled {
  background-color: #eeeeee;
  color: #242E41;
  cursor: not-allowed !important;
}

.later-action__options button.--selected {
  background-color: #E7F2EF;
  color: #242E41;
  cursor: default;
}

/*Media */

@media (max-width: 768px) {
  .request-details-later-action {
    margin-left: 0;
  }
}
