/*.gh-quote-price-transfer-type-btn-wrapper-move-top {*/
/*    transform: translateY(-38px);*/
/*}*/
.gh-quote-details-buttons-wrapper {
  display: flex;
  flex: 1;
  margin: 7px 0 7px 100px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: transform 0.14s ease-out;
}

.gh-quote-details-card-payment-wrapper {
  border: 1px solid #d6d6d5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  padding: 12px;
  width: 202px;
  height: 115px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.gh-quote-details-card-payment-wrapper .gh-quote-details-text-normal {
  font-size: 14px;
}

.gh-quote-details-card-payment-selected-fee {
  border-color: #635bff;
}

.gh-quote-details-card-payment-selected-no-fee {
  border-color: #10b997;
}

.gh-quote-details-card-payment-img {
  display: none;
  width: 30px;
  height: 22px;
  position: absolute;
  right: 9px;
  top: 9px;
}

.gh-quote-details-card-payment-img.purple-check {
  content: "";
  width: 30px;
  height: 22px;
  background-color: #635bff;
  border-radius: 0 4px 0 4px;
}

.gh-quote-details-card-payment-selected-fee .gh-quote-details-card-payment-img,
.gh-quote-details-card-payment-selected-no-fee .gh-quote-details-card-payment-img {
  display: block;
}

.gh-quote-price-transfer-type-btn-wrapper {
  flex: 1;
}

.gh-quote-details-card-payment-wrapper label.gh-radio-button-label.gh-radio-purple {
  font-weight: normal;
  padding-right: 0;
}

.gh-quote-details-card-payment-wrapper .gh-radio-button-wrapper {
  margin-right: 10px;
}

.gh-quote-details-card-payment-wrapper .gh-select-fee-button:disabled {
  cursor: initial;
}

.gh-quote-details-card-payment-wrapper .gh-select-fee-button {
  max-width: initial;
  width: initial;
  line-height: 20px;
  text-align: left;
  /*padding: 10px 0;*/
  background: transparent;
}

/*===========Popover===========*/
.gh-quote-details-no-fee-popover-wrapper {
  position: relative;
}

.gh-quote-details-no-fee-popover {
  position: absolute;
  width: 286px;
  height: 83px;
  background: #ffa304;
  border-radius: 4px;
  padding: 15px;
  top: calc(-100% - 5px);
  left: 50%;
  transform: translateX(-158px);
  font-weight: 500;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.gh-quote-details-no-fee-popover-bigger {
  top: calc(-100% - 32px);
  height: 110px;
}

.gh-quote-details-no-fee-popover-wrapper:hover .gh-quote-details-no-fee-popover {
  visibility: visible;
  opacity: 1;
}

.gh-quote-details-row {
  display: flex;
  padding: 15px 0;
}

.gh-quote-details-col.--key {
  flex-basis: 70%;
  font-weight: 400;
  font-size: 16px;
  color: #242F42;
}

.gh-quote-details-col.--value {
  flex-basis: 30%;
  font-weight: 500;
  font-size: 16px;
  color: #242E41;
}

.gh-fees-card-disabled{
  filter: contrast(0.1);
}

@media only screen and (max-width: 768px) {
  .gh-quote-price-transfer-type-btn-wrapper {
    align-items: initial;
    flex-direction: column;
  }

  .gh-quote-detail-stripe-logo-space {
    display: none;
  }

  .gh-quote-details-buttons-wrapper {
    margin: 0;
    flex-direction: column;
  }
  .gh-quote-details-card-payment-wrapper .gh-select-fee-button {
    padding: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .gh-quote-details-buttons-wrapper {
    flex-direction: column;
  }

  .gh-quote-details-card-payment-wrapper {
    width: 100%;
    max-width: 425px;
  }

  .gh-quote-details-card-payment-wrapper:first-child {
    margin-bottom: 15px;
  }

  .gh-quote-details-no-fee-popover {
    top: calc(-100% + 5px);
    left: -2px;
    transform: none;
  }

  .gh-quote-details-no-fee-popover-bigger {
    top: calc(-100% - 17px);
  }
}
