.gh-request-feedback {

}

.gh-request-feedback .gh-form-textarea {
    min-height: 70px;
}

.gh-request-feedback-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #6D7481;
    text-align: center;
}

.gh-request-feedback-item {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 18px 50px 18px 20px;
    margin-bottom: 15px;
}

.gh-request-feedback-item__wrapper-btn {
    display: flex;
    padding-right: 25px;
}

.gh-request-feedback-item__wrapper-btn_arrow-btn {
    display: inline-block;
    margin-top: 10px;
    padding: 5px;
    width: 12px;
    height: 16px;
}

.gh-request-feedback-item__wrapper-btn_arrow-btn img {
    transition: all 0.25s;
    transform: rotate(270deg);
}

.gh-request-feedback-item__wrapper-btn_arrow-btn.--open img {
    transform: rotate(90deg);
}

.gh-request-feedback-item__wrapper {
    display: flex;
    flex: 1;
}

.gh-request-feedback-item__content {
    width: 100%;
    padding-right: 20px;
}

.gh-request-feedback-item__content .uk-form-label {
    white-space: nowrap;
}

.gh-request-feedback-item__content_title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #6D7481;
    margin-bottom: 8px;
}

.gh-request-feedback-item__content_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #242E41;
    cursor: pointer;
}

.gh-request-feedback-item__content_text.--cut-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.gh-request-feedback__form-wrapper {
    display: flex;
}

.gh-request-feedback-form {
    flex: 1;
}


.gh-request-feedback-item__controls {
    display: flex;
    align-items: center;
    border-left: 1px solid #EAEAEA;
    max-height: 58px;
    min-width: 242px;
}

.gh-request-feedback__form-controls {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-top: 10px;
}

.gh-request-feedback__form-controls_btn {
    border: 1px solid #d5d5d5;
    border-radius: 3px;
    height: 32px;
    width: 32px;
    transition: color 0.20s;
}

.gh-request-feedback__form-controls_btn:hover {
    color: #0f6ecd !important;
}

.gh-request-feedback__form-controls_btn:first-child {
    margin-bottom: 5px;
}

.gh-request-feedback-item__controls .gh-main-button-component {
    font-weight: 400;
    min-width: auto;
    white-space: nowrap;
    margin-left: 40px;
}

.gh-request-feedback-item__respond {
    display: flex;
    margin-top: 18px;
}

.gh-request-feedback-item__respond .gh-icon {
    display: flex;
    min-width: 17px;
    align-items: flex-start;
    padding-top: 3px;
}

.gh-request-feedback-item__respond .gh-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #242E41;
    padding-left: 15px;
}

.gh-feedback-suggestion-wrapper {
    display: flex;
    justify-content: end;
    position: relative;
    margin-bottom: 10px;
}

.gh-feedback-suggestion-wrapper .gh-suggest-message {
    position: absolute;
    right: 0;
    top: 0;
}

.gh-request-feedback-item-row {
    display: flex;
    align-items: baseline;
}



/*Media */

@media (max-width: 768px) {
    .gh-request-feedback-item {
        flex-direction: column;
    }

    .gh-request-feedback-item__controls {
        border: none;
        min-width: auto;
        border-top: 1px solid #EAEAEA;
        margin-top: 15px;
    }

    .gh-request-feedback-item {
        padding: 18px 18px 20px;
    }

    .gh-request-feedback-item__content {
        padding-right: 0;
    }

    .gh-request-feedback-item__wrapper-btn {
        padding-right: 15px;
    }

    .gh-feedback-suggestion-wrapper {
        margin-bottom: 0;
    }

    .gh-feedback-suggestion-wrapper .gh-suggest-message {
        position: relative;
        margin-bottom: 0;
    }
}

@media (max-width: 640px) {
    .gh-request-feedback-item-row {
        flex-direction: column;
    }

    .gh-request-feedback-item-row .gh-request-feedback-item__content_text {
        padding-left: 15px;
    }
}
