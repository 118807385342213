.legInfoWindow {
  min-width: 320px;
  font-family: "Rubik" !important;
}

.legInfoWindow .gh-link-icon {
  margin-left: 4px !important;
}

.legInfoWindow .legInfoWindow-date-range {
  margin-top: 11px;
  color: #6d7480;
  font-size: 14px;
}

.legInfoWindow.selected .top-head {
  margin-bottom: 0;
  line-height: 1;
  font-weight: 400;
}

.legInfoWindow.selected .bottom-head {
  line-height: 1;
  margin-top: 17px;
}

.legInfoWindow .top-subtitle {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 8px;
  color: #6d7481;
  letter-spacing: 1px;
}

.legInfoWindow .legInfoWindowActionLegs {
  margin-top: 24px;
  margin-bottom: 5px;
  line-height: 1;
}

.legInfoWindow.google .legInfoWindowActionLegs {
  margin-top: 20px;
}

.legInfoWindow.event .legInfoWindowActionLegs {
  margin-top: 14px;
}

.legInfoWindow .bottom-head.secondary {
  margin-top: 0;
  line-height: 1;
}

.legInfoWindow .item-icon.type .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
