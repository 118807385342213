.calculator__radio,
.calculator__radio-input {
  margin: 0;
}
.calculator__radio-group {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 10px;
}
.calculator__radio-group .calculator__radio:not(:last-of-type) {
  margin-right: 16px;
}
.calculator__radio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
}
.calculator__radio-input {
  appearance: none;
  left: unset !important;
  width: 24px;
  height: 24px;
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative !important;
  cursor: pointer;
  transition: 0.2s;
  margin-right: 8px;
}
.calculator__radio-input::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: 0.2s;
  background-color: #10b897;
}
.calculator__radio-input:checked::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.calculator__radio-group.pc-invalid {
  background-color: #fff5ef !important;
}

.calculator__radio-group.pc-invalid .calculator__radio {
  color: #e25950 !important;
}