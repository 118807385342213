
.gh-footer-row {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0;
}

.gh-footer-title {
    font-family: Rubik, sans-serif;
    font-size: 20px;
    color: #6D7480;
    font-weight: 400;
}

.gh-members-wrapper .gh-member img {
    max-width: 214px;
    width: 100%;
}

.gh-footer-side-menu {
    flex: 1 1 auto;
    display: flex;
    justify-content: end;
    height: fit-content;
}

.gh-footer-side-menu-subtitle {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
}

.gh-footer-side-menu-col {
    padding: 0 15px;
    border-right: 1px solid #bdc3cf;
    max-width: 300px;
}

.gh-footer-side-menu-col:last-child {
    padding-right: 0;
    border: none;
}

.gh-footer-side-menu-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.gh-footer-side-menu-item {
    margin-bottom: 10px;
    line-height: 15px;
}

.gh-footer-side-menu-item .link {
    color: #6D7480;
    font-size: 14px;
    text-decoration: none;
    font-weight: 300;
    transition: color 0.25s;
    cursor: pointer;
}

.gh-footer-side-menu-item .link:hover {
    color: #1abc9c;
}

.gh-footer {
    /*background-color: var(--bs-white);*/
    font-size: 14px;
    /*color: var(--bs-gray-dark);*/
    padding: 15px 0;
}

.gh-footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gh-footer-right-side {
    display: flex;
    align-items: center;
}

.gh-footer-social-icons {
    display: flex;
    align-items: center;
    width: 158px;
    justify-content: space-between;
    margin-right: 20px;
}

.gh-footer-social-icons-link {
    display: inline-block;
    width: 22px;
    transition: all 0.25s;
}

.gh-footer-social-icons-link:hover {
    opacity: 0.7;
}

.gh-footer-menu {
    display: flex;
}

.gh-footer-menu-item {
    border-left: 1px solid #e5e5e5;
    padding-left: 20px;
}

.gh-footer-menu-link {
    /*color: var(--bs-gray-dark);*/
    padding-right: 20px;
    transition: all 0.25s;
}

.gh-footer-menu-link:hover {
    opacity: 0.8;
    /*color: var(--bs-gray-dark);*/
}

.gh-footer-menu-item:last-child .gh-footer-menu-link {
    padding-right: 0;
}


/*--------------- MAX WIDTH 1024px ---------------*/

@media (max-width: 1024px) {
    .gh-footer-side-menu-col {
        padding: 0 10px;
    }

    .gh-footer-row {
      flex-direction: column;
    }

    .gh-member {
      margin-bottom: 20px;
      text-align: center;
      margin-top: 30px;
    }

    .gh-footer-side-menu {
      justify-content: space-between;
      width: 100%;
    }

    .gh-footer-title {
      text-align: center;
    }

    .gh-footer-side-menu-col {
      width: 50%;
    }
}

/*--------------- MAX WIDTH 768PX ---------------*/

@media (max-width: 767.98px) {
    .gh-footer-side-menu {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .gh-footer-side-menu-col {
      border: none;
      margin-bottom: 20px;
      width: 100%;
    }


}

/*--------------- MAX WIDTH 575PX ---------------*/

@media (max-width: 575.98px) {
    .gh-footer-side-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gh-footer-side-menu-col {
        padding: 0;
    }

    .gh-main-footer .gh-subnav {
        display: flex;
        flex-wrap: wrap;
        /*flex-direction: column;*/
    }

    .gh-main-footer .gh-subnav > li:first-child {
        width: 100%;
        text-align: center;
    }

    .FollowUsTooltipComponent-link-wrapper {
        margin: 0 auto 20px;
    }

    .gh-footer-title {
        text-align: center;
    }

    .gh-footer-side-menu {
        text-align: center;
    }

    .gh-footer-side-menu-subtitle {
        margin-bottom: 0;
    }

    .gh-footer-side-menu-col {
        margin-bottom: 15px;
    }

    .gh-footer-side-menu-col:last-child {
        margin-bottom: 0;
    }

}