.gh-quote-expired-wrapper {
    background-color: #FFF7E6;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #242E41;
    height: 30px;
    width: 100%;
}

.gh-quote-expired-wrapper span {
    font-weight: 500;
    padding-left: 4px;
}

.gh-quote-expired-wrapper.--expired {
    background-color: #D6D6D5;
}


/*=========== Media ===========*/

@media only screen and (max-width: 420px) {
    .gh-quote-expired-wrapper {
        font-size: 11px;
    }
}