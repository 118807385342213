.broker-submit-request-content {
    max-width: 800px;
    padding: 10px 20px;
}

.broker-submit-request-content:not(.--advanced-content) .broker-submit-request-confirm-text {
    line-height: 24px;
    text-align: left;
}

.broker-submit-request-content:not(.--advanced-content) {
    max-width: 600px !important;
    width: 100%;
}

.broker-submit-request-content.--advanced-content {
    min-height: 350px;
    max-height: 95vh;
    width: 80%;
}

.submit-request-tables-wrapper {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    overflow: hidden;
    max-height: 500px;
}

.preview-request-homebase-map {
}

.preview-request-skeleton {
    height: 538px;
}

.submit-request-table:first-child {
    margin-right: 15px;
}

.submit-request-table:last-child {
    margin-left: 15px;
}

.submit-request-table {
    display: flex;
    flex-direction: column;
    background: rgba(157, 163, 174, 0.22);
    padding: 10px;
    max-height: 430px;
    flex: 1;
    border-radius: 10px;
}

.submit-request-options-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.submit-request-table-title {
    font-size: 20px;
    margin-bottom: 0;
}

.submit-request-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.submit-request-table-header h3 {
    margin: 0;
    font-size: 19px;
}

.submit-request-table-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100px;
    font-size: 16px;
    color: #9a9ca1;
    transform: translateY(-40px);
    text-align: center;
    font-weight: 300;
}

.submit-request-options-list {
    overflow-y: auto;
    height: 100%;
    position: relative;
}

.submit-request-options-list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.submit-request-options-list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.submit-request-option {
    display: flex;
}

.submit-request-option .gh-wrapper-checkbox,
.submit-request-option .label {
    width: 100%;
}

.submit-request-option .label {
    display: flex;
    justify-content: space-between;
}

.submit-request-option-value:last-child {
    text-align: right;
    font-weight: 500;
}

.submit-request-checkbox-wrapper:hover {
}

.submit-request-checkbox-wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: .05s ease-out;
    margin: 10px;
}

.submit-request-checkbox-wrapper .label {
    font-size: 16px;

}

.broker-submit-request-confirm-text {
    color: #6d7481;
    font-size: 16px;
    margin-bottom: 25px;
    text-align: center;
}

/*Map*/
.broker-submit-request-content .gm-style-iw > div {
    overflow: scroll !important;
}

.broker-submit-request-content .available-countries-content {
    padding: 0;
}

.broker-submit-request-content .homebase-info__pagination {
    padding: 0;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .submit-request-tables-wrapper {
        flex-direction: column;
        max-height: initial;
        max-width: 380px;
        width: 95%;
        margin: 20px auto 0;
    }

    .submit-request-tables-wrapper .submit-request-table {
        margin: 0 0 15px;
    }

    .submit-request-options-header{
        flex-direction: column;
        align-items: flex-start;

        padding-bottom: 15px;
        padding-left: 10px;
        border-bottom: 2px solid #d5d5d5;
        margin-bottom: 10px;
    }

    .gh-review-request-modal-buttons .gh-main-button-component {
        min-width: 146px;
        font-size: 15px;
        padding: 8px 10px
    }

}
