.coverage-warning {
  color: orange;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  font-size: 13px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky-10 {
  position: sticky;
  top: 10px;
}
.autocomplete-dropdown-container {
  width: 630px;
  max-width: 100%;
}
