.gh-operator-quote-list-actions-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 55px;
}

.gh-min-width-140 {
    min-width: 140px;
}

.gh-action-view-btn {
    min-width: initial;
    padding: 2px 10px;
}

.gh-col-submitted-at{
    min-width: min-content;
}

@media only screen and (max-width: 959px) {
    .gh-operator-quote-list-actions-column {
        justify-content: center;
    }
    button.gh-marketplace-chase-button.gh-marketplace-chase-button{
        padding: 16px 10px;
        margin-left: 10px;
    }
}
