.gh-quote-expiration-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin: 20px 0 10px;
}

.gh-quote-expiration-col {
    position: relative;
}

.gh-quote-expiration-wrapper .rdtPicker {
    width: calc(100% - 11px);
}

.gh-quote-expiration-wrapper .rdtSwitch {
    text-align: center !important;
}

.gh-quote-expiration-wrapper .dow {
    text-align: center !important;
}

.gh-quote-expiration-col .uk-form-icon {
    top: auto;
}

.gh-quote-expiration-col .gh-enquire-calendar-icon {
    bottom: 0;
    right: 0;
    margin-bottom: 13px;
    margin-right: 12px;
}

.gh-quote-expiration-col .label {
    font-size: 14px;
    font-weight: 500;
    color: #242f42;
    text-transform: uppercase;
}



/*=================== MAX-WIDTH 768 ==============================*/

@media (max-width: 768px) {
    .gh-quote-expiration-wrapper {
        grid-template-columns: 1fr;
    }

    .gh-quote-expiration-col {
        margin-bottom: 20px;
    }

    .gh-quote-expiration-col:last-child {
        margin-bottom: 0;
    }
}