.gh-booking-details-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.gh-booking-details-buttons .gh-main-button-component {
  margin-right: 20px;
}

.gh-booking-details-buttons .gh-main-button-component:last-child {
  margin-right: 0;
}

/*Media */
@media (max-width: 560px) {
  .gh-booking-details-buttons {
    flex-direction: column;
  }

  .gh-booking-details-buttons .gh-main-button-component {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }
}
