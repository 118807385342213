.gh-aircraft-wrapper.alternative-aircrafts {
  margin-top: 25px;
}

.gh-aircraft-wrapper-title-line {
  display: flex;
  align-items: baseline;
}

.gh-aircraft-wrapper .title-item {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #242f42;
  margin-bottom: 13px;
  line-height: 20px;
}

.gh-aircraft-wrapper-content {
  display: flex;
  justify-content: space-between;
  margin: 0 -18px 20px;
}

.gh-aircraft-wrapper-content .title-item {
  display: none;
}

.gh-aircraft-wrapper-content-col {
  flex: 1;
  padding: 0 30px;
  flex-direction: row;
}

.gh-aircraft-wrapper-content-col.--owners-approval {
  border-left: 1px dashed #d5d5d5;
}

.gh-aircraft-wrapper-value {
  display: flex;
  height: 48px;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #242f42;
}

.gh-aircraft-wrapper-content.first-row .title-item {
  /*display: block;*/
  display: flex;
  padding-right: 0;
  margin-right: 0;
  min-height: 40px;
}

.gh-aircraft-wrapper-wrapper-btn {
  display: flex;
  align-items: center;
  height: 48px;
  margin-left: 10px;
  width: 24px;
  min-width: 24px;
}

.gh-aircraft-wrapper-wrapper-btn.mobile {
  display: none;
}

.gh-aircraft-wrapper-wrapper-btn .delete-btn {
  display: inline-block;
  cursor: pointer;
  width: 24px;
}

.gh-aircraft-wrapper-last-col {
  display: flex;
}

.gh-aircraft-wrapper-last-col .wrapper-select {
  max-width: 300px;
}

.gh-add-aircraft-btn {
  display: inline-block;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #10b897;
  cursor: pointer;
  padding: 3px 3px 3px 0;
}

.gh-aircraft-view-value {
  display: flex;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #242f42;
}

.gh-aircraft-wrapper.read-only .gh-aircraft-wrapper-content {
  margin: 0 -18px 18px;
}

.gh-aircraft-wrapper.read-only .gh-aircraft-wrapper-content:last-child {
  margin: 0 -18px;
}


.gh-aircraft-bottom-row {
  display: flex;
  justify-content: space-between;
}

.gh-aircraft-bottom-row.--flex-end {
  justify-content: flex-end;
}

.gh-crew-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 87px;
}

.gh-crew-buttons.read-only {
  width: auto;
}

.gh-crew-buttons.read-only .gh-aircraft-wrapper-wrapper-btn {
  display: none;
}

.gh-aircraft-select-tooltip-desk {
  right: -25px;
  position: absolute;
}
.gh-aircraft-select-tooltip-mob {
  display: none;
}

.gh-alternative-helicopters-tooltip {
  width: 20px;
  height: 20px;
}

/***************************************  Media  ****************************************************/

@media (max-width: 767px) {
  .gh-aircraft-wrapper-content {
    flex-direction: column;
    margin: 0 0 10px;
    position: relative;
    border-bottom: 1px solid #d6d6d5;
    padding-bottom: 5px;
  }

  .gh-aircraft-wrapper.read-only .gh-aircraft-wrapper-content {
    margin: 0 0 10px;
  }

  .gh-aircraft-wrapper.read-only .gh-aircraft-wrapper-content:last-child {
    margin: 0;
  }

  .gh-aircraft-wrapper-content.last-row {
    border: none;
    padding-bottom: 0;
    margin: 0 0 10px;
  }

  .gh-aircraft-wrapper-content .gh-aircraft-wrapper-last-col .wrapper-select {
    margin-bottom: 0;
  }

  .gh-aircraft-wrapper-content .wrapper-select {
    margin-bottom: 15px;
  }

  .gh-aircraft-wrapper-content-col {
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .gh-aircraft-wrapper-content-col.--owners-approval {
    border: none;
  }

  .gh-aircraft-wrapper-content .title-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .gh-aircraft-wrapper-wrapper-btn {
    display: none;
  }

  .gh-aircraft-wrapper-wrapper-btn.mobile {
    display: block;
    height: fit-content;
    margin-left: 20px;
  }

  .gh-aircraft-wrapper-last-col .wrapper-select {
    max-width: 100%;
  }

  .gh-aircraft-wrapper-last-col {
    max-width: 100%;
    width: 100%;
  }

  .gh-tooltip-wrapper {
    position: relative;
  }

  .gh-aircraft-select-tooltip-mob {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-17px);
    display: block;
  }

  .gh-aircraft-select-tooltip-desk {
    display: none;
  }
}
