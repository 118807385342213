.calculator-add-pilot-btn {
  color: #10b897;
  border: none;
  font-size: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
}
.calculator-add-pilot-btn:hover {
  background-color: transparent;
}
.calculator-add-pilot-btn:disabled {
  color: #ccc;
  cursor: auto;
}

.pilot.included .included-option > * {
  visibility: hidden;
  transition: unset !important;
  opacity: 0;
}
