.fleet-item {
  display: flex;
  flex-direction: column;
}

.fleet-items-list {
  padding: 0;
}

.fleet-items-list .fleet-item:not(:last-of-type) {
  margin-bottom: 10px;
}

.fleet-item {
  background-color: #fff;
  padding: 15px;
  transition: 0.15s ease-in;
  border-radius: 4px;
  border: 2px solid #dfe2e8;
}

.fleet-status-checkbox {
  border: unset !important;
  padding: 0 !important;
}

.fleet-status-checkbox:not(.gh-yellow-checkbox):checked {
  background-size: 20px;
}

.fleet-status-checkbox.gh-yellow-checkbox:checked {
  background-size: 16px;
}
/*.fleet-item:hover {*/
/*  border-color: #1abc9c;*/
/*}*/

.fleet-item .status {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.fleet-item .heli-img {
  width: 100%;
  height: 152px;
  margin-bottom: 15px;
}

.details-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.fleet-item_status {
  gap: 20px;
}

.fleet-item_status-checkbox,
.gh-verified-checkbox {
  width: 24px;
  height: 24px;
}

.details-btn button {
  cursor: pointer;
  background: #10b997;
  width: 150px;
  height: 35px;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik, sans-serif;
}

.info-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 30px;
}

.info-row h5,
.info-row span {
  line-height: 18px;
}

.info-row h5 {
  margin-bottom: 0;
  margin-top: 2px;
  font-size: 14px;
  color: #6d7480;
  font-weight: normal;
}

.info-row span {
  font-size: 18px;
  color: #242f42;
  font-weight: 500;
  text-align: right;
}

.info-row .info-row-description {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
}

.info-row .info-row-description-registration {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 88px;
  white-space: nowrap;
}

.status-bar {
  display: flex;
  align-items: center;
}
.status-bar img {
  width: 18px;
  margin-right: 8px;
}

.fleet-pagination .ant-pagination-item-active {
  background-color: #10b997 !important;
  border-color: #10b997 !important;
}

.fleet-pagination .ant-pagination-item-active > a {
  color: #fff !important;
}

.fleet-pagination .ant-pagination-item-active > a:hover {
  color: #fff !important;
  border-color: #10b997 !important;
}
.ant-pagination-item-active:hover a {
  color: #fff !important;
}

.list-aircraft-loader .gh-loading-component-spinner {
  margin-top: 5rem;
}

@media (max-width: 500px) {
  .fleet-item .gh-verified-checkbox {
    padding-left: 0;
  }

  .fleet-item {
    gap: 10px;
    padding: 15px;
  }
}

@media screen and (max-width: 650px) {
  .info-row .info-row-description {
    width: 70% !important;
  }

  .info-row .info-row-description-registration {
    width: 70% !important;
  }
}
