.gh-flag-request {
    display: inline-block;
}

.gh-flag-request.is-flagged path {
    stroke: #ffab00;
    fill: #ffab00;
}

.gh-flag-request .custom-tooltip-content.top-left {
    right: 0;
}