.gh-tabs-new-design {
    display: flex;
    border-bottom: 2px solid #242f42;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.gh-tabs-new-design-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    color: #242f42;
    min-width: 170px;
    height: 60px;
    /*background: #ffffff;*/
    border: 1px solid #d2d2d2;
    margin-right: 30px;
    box-sizing: border-box;
    position: relative;
    background: #E7EAEF;
}
.gh-tabs-new-design-tab.disabled {
    background: #f7f7f7;
    color: #6d7481;
}

.gh-tabs-new-design-tab.disabled:hover {
    cursor: not-allowed;
}

.gh-tabs-new-design-tab:hover {
    cursor: pointer;
}

.gh-tabs-new-design-tab.active {
    border: 2px solid #242f42;
    border-bottom: none;
    font-weight: 500;
    background: #E7F2EF;
}

.gh-tabs-new-design-tab.active:after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: #E7F2EF;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: -2px;
}

.gh-tooltip-disabled-tab .custom-tooltip-content {
    margin-left: -15px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.gh-tabs-new-design-counter {
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
    background-color: #E74C3C;
    border-radius: 4px;
    min-width: 24px;
    text-align: center;
    margin-left: 6px;
}

/*Media */

@media (max-width: 576px) {
    .gh-tabs-new-design-tab {
        margin-right: 20px;
        width: fit-content;
        height: 52px;
        font-size: 15px;
        padding: 0 15px;
        text-align: center;
        line-height: 18px;
        min-width: 140px !important;
    }
}

