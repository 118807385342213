.gh-filters-buttons {
    margin-top: 40px;
}

.gh-filters-buttons .gh-main-button-component {
    margin: 0 20px;
}


/*=================== MAX-WIDTH 576 ==============================*/

@media (max-width: 576px) {
    .gh-filters-buttons {
        margin-top: 20px;
        flex-direction: column;
    }

    .gh-filters-buttons .gh-main-button-component {
        width: 100%;
        margin: 15px 0 0;
    }
}
