/*
 this section should be completely refactored
 when will be created mobile version
 Html -> PricingBanner Component
 */
.section-title {
    border-top: none;
}

.gh-bundle-options .bundle-option .numbers,
.bundle-option-numbers {
    font-weight: 500;
}

.gh-features-section .features-header {
    top: 83px;
    z-index: 997;
}

.gh-pricing-header {
    top: 0;
    position: sticky;
    background: #f2f4f7;
    z-index: 997;
    border-bottom: 3px solid #1abc9c;
}

.gh-pricing-header {
    /*position: initial;*/
}

.gh-pricing-sticky-banner {
    margin: 0 !important;
}

.gh-pricing-banner-wrapper {
    padding: 20px 15px 20px 0;
    background: #fff;
    margin-bottom: -36px !important;
    border: 1px solid #d6d6d5;
    border-bottom: none;
    z-index: 997;
    position: -webkit-sticky;
    position: sticky;
    top: calc(83px + 60px);
}

.gh-pricing-banner-wrapper .option-value {
    text-align: center;
}

.gh-pricing-banner-handle-click {
    position: relative;
    /*border: 1px solid blue;*/
}

.gh-pricing-banner-handle-click:after {
    content: "";
    position: absolute;
    top: calc(-20px - 60px);
    left: 0;
    bottom: -20px;
    right: 0;
    /*border: .2px solid orange;*/
    z-index: 10011;
    transition: 0.3s;
}

.gh-pricing-banner-handle-click:hover:after,
.gh-pricing-banner-handle-click.gh-active-bundle:after {
    box-shadow: 0 11px 21px 9px #d5d5d5;
}

.gh-pricing-banner-handle-click:first-of-type:after {
    left: -10px;
    right: 5px;
}

.gh-pricing-banner-handle-click:nth-of-type(2):after {
    left: 0px;
    right: -4px;
}

.gh-pricing-banner-handle-click:nth-of-type(3):after {
    left: 5px;
    right: -8px;
}

.gh-pricing-banner-handle-click:nth-of-type(4):after {
    left: 8px;
    right: -12px;
}

.gh-pricing-banner-handle-click:nth-of-type(5):after {
    left: 13px;
    right: -17px;
}

.gh-pricing-banner-wrapper .gh-features-overlay {
    box-shadow: 0px 12px 24px -12px rgb(0 0 0 / 50%);
    -webkit-box-shadow: 0px 12px 24px -26px rgb(0 0 0 / 50%);
}

.gh-banner-not-is-pinned .gh-pricing-banner-handle-click.gh-active-bundle:after {
    box-shadow: none;
}

.gh-pricing-banner-wrapper {
    transition: transform .2s ease-out;
    visibility: visible;
    transform: scaleY(1);
    transform-origin: top;
}

/*banner if not pinned*/
.gh-pricing-banner-wrapper.gh-banner-not-is-pinned {
    visibility: hidden;
    padding: 0;
    transform: scaleY(0);
}

.gh-pricing-banner-wrapper.gh-banner-not-is-pinned + .gh-feature-card {
    margin-top: 0;
}

.recommended-for-banner{
    top: -16px;
}

/*---------------------*/
