.popup-content {
    min-width: 50px;
    max-width: 260px;
    background-color: #242f42;
    padding: 10px;
    color: #fff;
    font-size: 14px !important;
    line-height: 17px !important;
    transition: 0.15s ease-out;
    border: none;
    font-family: Rubik, sans-serif;
}

[role=tooltip].popup-content {
    width: initial;
}

.popup-arrow {
    color: #242f42;
}

/* animation */

@keyframes tooltipFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.popup-content {
    -webkit-animation: tooltipFadeIn 0.15s ease-out forwards;
}

[role='tooltip'].popup-content {
    border-radius: 1px;
    box-shadow: none;
}
