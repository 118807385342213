.react-tel-input .form-control {
  border: 2px solid #6d7481;
  color: #242f42;
  height: 48px;
  padding-right: 10px;
  padding-left: 57px;
  width: 100%;
  font-size: 16px;
}

.react-tel-input .flag-dropdown {
  border: 2px solid #6d7481;
  height: 48px;
}

.react-tel-input .selected-flag {
  width: 45px;
}

.react-tel-input .selected-flag .arrow {
  left: 33px;
}

.react-tel-input .form-control:disabled {
  background: transparent;
}

.react-tel-input .form-control:disabled {
  background-color: #f8f8f8;
  border-color: #e5e5e5;
}

.react-tel-input .form-control:disabled + .flag-dropdown {
  background-color: #f8f8f8;
  border-color: #e5e5e5;
}
