.calculator *,
.calculator *::after,
.calculator *::before {
  box-sizing: border-box !important;
}

.calculator {
  padding: 4px 0;
  display: flex;
  overflow-y: auto;
}
.calculator::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
.calculator::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}
.calculator::-webkit-scrollbar-thumb {
  background-color: #999;
  cursor: pointer;
}
.calculator::-webkit-scrollbar-thumb:hover {
  background-color: #737373;
}
.calculator-reset-btn {
  outline: none;
  border: none;
  font: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.calculator__labels {
  width: 264px;
  min-width: 264px;
  margin-right: 10px;
}
.calculator__labels-legs {
  margin-top: 26px;
}
.calculator__labels .accordion {
  margin-top: 10px;
}
.calculator__aircrafts .calculator__description {
  width: 320px;
  background-color: #f0f0f0;
}
.calculator-remove-btn {
  font-weight: 500;
  color: #242f42;
  border: 1px solid #242f42;
  padding: 0 6px;
  border-radius: 2px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  background: transparent;
}
.calculator-remove-btn:hover {
  background-color: rgba(185, 28, 28, 0.1);
  color: #b91c1c;
  border: 1px solid #b91c1c;
}
.calculator-add-btn {
  height: 50px;
  border: 1px solid #9da3ae;
  background-color: #eef0f5;
  font-family: inherit;
  font-weight: 600;
  padding: 0 10px;
  border-radius: 2px;
  margin: 0 16px;
  font-size: 14px;
  white-space: nowrap;
  width: 120px;
}
.calculator-add-btn:not([disabled]) {
  cursor: pointer;
}
.calculator-add-btn:hover:not([disabled]) {
  background-color: #9da3ae;
  color: #fff;
}
.accordion__content .calculator__description:first-of-type {
  border-top: transparent;
}
.pilots-list .accordion__content .calculator__description:not(:last-of-type) {
  margin-bottom: 4px;
}

.calculator-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 240px;
}

.calculator__operator-margin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border: 1px solid #10b997;
  background-color: #e7f2ef;
  margin-bottom: 24px;
  z-index: 10;
  transition: 0.2s;
}

.calculator__operator-margin.sticky {
  padding: 13px 12px 12px;
  background-color: #fff;
  border: transparent;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: sticky;
  top: 0;
}

.calculator__operator-margin.sticky .calculator-submit-btn {
  min-width: 179px;
}

.calculator__operator-margin.sticky .operator-margin__section:not(:last-of-type) {
  margin-right: 20px;
  padding-right: 20px;
  position: relative;
}

.calculator__operator-margin.sticky .operator-margin__section:not(:last-of-type)::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  right: 0;
  top: 0;
  background-color: #d5d5d5;
}

.operator-margin__title {
  margin: 0;
  font-weight: 500;
  font-size: 23px;
  line-height: 27.26px;
}

.operator-margin__subtitle {
  margin: 0 0 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.96px;
}

.operator-margin__section .pc-input {
  box-sizing: border-box;
  background-color: #fff;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d6d6d5;
  max-width: 140px;
}

.calculator__operator-margin.sticky .operator-margin__title {
  font-size: 18px;
  line-height: 21.33px;
}

.calculator__operator-margin.sticky .operator-margin__subtitle {
  margin-bottom: 2px;
  font-size: 15px;
  line-height: 16.59px;
}

.calculator__operator-margin.sticky .pc-input {
  padding: 4px 6px;
  max-width: 80px;
}

.calculator-submit-btn {
  font-weight: 500;
  color: #fff;
  background-color: #10b897;
  border-radius: 2px;
  padding: 16px;
  min-width: 215px;
}

.calculator-submit-btn:disabled {
  opacity: 0.7;
  pointer-events: none;
}

.uk-notification-message-calculator {
  background-color: #10b897;
  color: #fff;
}

.uk-notification-message-calculator > div {
  padding-right: 24px;
}

.uk-notification-message-calculator .uk-close {
  color: #fff !important;
}

.uk-notification-message-calculator .uk-notification-close {
  display: block !important;
  top: 23px;
}
.operator-margin__section-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.operator-margin__settings-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin-left: 8px;
  border-radius: 2px;
  background-color: transparent;
  transition-property: background-color;
  transition-duration: 0.2s;
}
.operator-margin__settings-btn img {
  width: 24px;
  height: 24px;
}
.operator-margin__settings-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.operator-margin__settings-btn:focus {
  outline: 2px solid #10b897;
}
.operator-margin__settings-btn:disabled {
  opacity: 0.5;
  background-color: #ccc;
  pointer-events: none;
}
