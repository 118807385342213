.FollowUsTooltipComponent {
  position: relative;
}
.FollowUsTooltipComponent-link-wrapper {
  list-style: none;
  width: 158px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin-bottom: 0;
}
.FollowUsTooltipComponent-link-wrapper li {
  display: inline-block;
}
.FollowUsTooltipComponent-link-wrapper .uk-icon {
  display: block;
}
.FollowUsTooltipComponent a.footer-svg.svg-fb svg {
  width: 20px;
}
.FollowUsTooltipComponent a.footer-svg.svg-in svg {
  width: 24px;
}
.FollowUsTooltipComponent a.footer-svg.inst svg {
  width: 22px;
}
.FollowUsTooltipComponent a.footer-svg.twit svg {
  width: 21px;
}
