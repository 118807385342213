.gh-main-button-component {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 16px 30px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  border: none;
  min-width: 170px;
  transition: opacity 0.26s;
  width: fit-content;
}

.gh-main-button-component:hover {
  opacity: 0.8;
}

.gh-main-button-component.disabled {
  cursor: not-allowed;
  border-color: #D6D6D5 !important;
  opacity: 0.8 !important;
  filter: grayscale(1);
}

/*===========Contained===========*/
.gh-main-button-component-success.gh-main-button-component-contained {
  background: #10b897;
}

.gh-main-button-component-gray.gh-main-button-component-contained {
  background: #e6eaee;
  color: #242f42;
}

.gh-main-button-component-transparentBlue.gh-main-button-component-contained {
  background: rgba(223, 226, 232, 1);
  color: #FFFFFF;
}

.gh-main-button-component-transparentBlue.gh-main-button-component-contained:hover {
  background: rgba(109, 116, 129, 0.8);
}


.gh-main-button-component-success.gh-main-button-component-contained.disabled {
  background-color: rgba(26, 188, 156, 0.3) !important;
  color: #ffffff;
}

.gh-main-button-component-danger.gh-main-button-component-contained {
  background: #e74c3c;
}

.gh-main-button-component-dangerBorder.gh-main-button-component-contained {
  color: #e74c3c;
  background: #ffffff;
  border: 1px solid #d5d5d5;
}

.gh-main-button-component-warning.gh-main-button-component-contained {
  background: #ffab00;
}

.gh-main-button-component-light.gh-main-button-component-contained {
  background: #ffffff;
  color: #242f42;
}

/*===========Text===========*/
.gh-main-button-component-text {
  font-weight: 500;
  padding: 16px 0;
  background: none;
  min-width: fit-content;
}

.gh-main-button-component-success.gh-main-button-component-text {
  color: #10b897;
}

.gh-main-button-component-primary.gh-main-button-component-text {
  color: #005BFF;
}

.gh-main-button-component-danger.gh-main-button-component-text {
  color: #e74c3c;
}

.gh-main-button-component-warning.gh-main-button-component-text {
  color: #ffab00;
}

.gh-main-button-component-dark.gh-main-button-component-text {
  color: #242f42;
}

.gh-main-button-component-light.gh-main-button-component-text {
  color: #242f42;
}

/*===========Outlined===========*/

.gh-main-button-component-outlined {
  font-weight: 500;
  border: 2px solid;
  background: #fff;
  padding: 14px 28px;
}

.gh-main-button-component-success.gh-main-button-component-outlined {
  color: #10b897;
  border-color: #10b897;
}

.gh-main-button-component-danger.gh-main-button-component-outlined {
  color: #e74c3c;
  border-color: #e74c3c;
}

.gh-main-button-component-warning.gh-main-button-component-outlined {
  color: #ffab00;
  border-color: #ffab00;
}

.gh-main-button-component-dark.gh-main-button-component-outlined {
  color: #242f42;
  border-color: #242f42;
}
.gh-main-button-component-primary.gh-main-button-component-outlined {
  color: #005bff;
  border-color: #005bff;
}

.gh-main-button-component-primaryBorder.gh-main-button-component-outlined {
  color: #242f42;
  border: 2px solid #f0f0f0;
}

.gh-main-button-component-light.gh-main-button-component-outlined {
  color: #242f42;
  border-color: #dfe2e8;
  font-weight: 400;
}

.gh-main-button-component-secondary.gh-main-button-component-outlined {
  color: #8d8e90;
  border-color: transparent;
  font-weight: 400;
}

.gh-main-button-component-gray.gh-main-button-component-outlined {
  color: #6d7480;
  border-color: #6d7480;
  font-weight: 400;
}
