.gh-hook-date-picker-wrapper {
    position: relative;
}

.gh-hook-date-picker-wrapper .rdtPicker {
    width: calc(100% - 10px);
}

.gh-hook-date-picker-wrapper .dow {
    text-align: center !important;
}

.gh-hook-date-picker-wrapper .rdtSwitch {
    text-align: center !important;
}

.gh-hook-calendar-icon {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 15px;
    transform: translateY(-50%);
}