.gh-loading-component {
    position: relative;
    transition: .3s ease-out;
    min-width: 40px;
}

.gh-loading-component-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.gh-animation-loading-component-enter {
    opacity: 0;
}

.gh-animation-loading-component-enter-active {
    opacity: 1;
    transition: opacity 300ms;
    transition-delay: 200ms;
}

.gh-animation-loading-component-exit {
    opacity: 1;
}

.gh-animation-loading-component-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}
