.uk-radio-button.uk-radio:checked,
.uk-radio-button.uk-radio:checked:focus {
  display: none;
}

.uk-radio-button[type="radio"]:checked,
.uk-radio-button[type="radio"]:not(:checked) {
  display: none;
}

.uk-radio-wrapper {
  display: flex;
  width: fit-content;
  margin-top: 5px;
}

.uk-radio-wrapper .uk-radio-item:not(:last-of-type) {
  margin-right: 12px;
}
