.aircraft-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2px 2px 2px 10px !important;
}
.calculator__aircraft {
  position: relative;
}
.calculator__aircraft::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
}
.calculator__aircraft.with-border-b::after {
  border-bottom: 2px solid #242f42;
}
.calculator__aircraft.active::after {
  border: 2px solid #242f42;
  border-bottom: none;
  width: 100%;
}

.calculator__aircraft.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 2px;
  width: calc(100% - 4px);
  height: 100%;
  border-bottom: 2px solid white;
  z-index: 2;
  pointer-events: none;
}

.aircrafts-container {
  display: flex;
  align-items: flex-start;
}
.aircrafts-list {
  position: relative;
  display: flex;
}
.aircrafts-list .calculator__aircraft:not(:last-of-type):not(.active)::after {
  width: calc(100% + 10px);
}
.aircrafts-list .calculator__aircraft:not(:last-of-type) {
  margin-right: 10px;
}

.aircrafts-container {
  position: relative;
}

.aircrafts-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  border-bottom: 2px solid #242f42;
  width: calc(100% - 2px - 152px);
}

.aircraft-modal-note {
  color: #6d7481;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  display: block;
}

.calculator-aircraft-input:hover .calculator-reset-btn {
  visibility: visible;
}

.aircraft-header__title {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.aircraft-header__price {
    display: block;
    width: 100%;
    font-size: 12px;
}
