.external-enquiry-form_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.external-enquiry-form {
  padding: 10px;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  max-width: 450px;
  border-radius: 2px;
  margin: 0;
}

.external-enquiry-form .form-content {
  transition: 0.3s ease;
}

.external-enquiry-form .form-content.disabled {
  opacity: 0.65;
  pointer-events: none;
}

.external-enquiry-form .form-title {
  color: #242f42;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.external-enquiry-form .uk-form-label {
  font-size: 13px;
  font-weight: 500;
  color: #6d7481;
  margin: 5px 0 10px 0;
  display: flex;
}

.external-enquiry-form .pax-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.external-enquiry-form .pax-wrapper .validation-errors {
  width: 100%;
  text-align: center;
}

.external-enquiry-form .gh-number-label {
  justify-content: center;
}

.external-enquiry-form .input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.external-enquiry-form .gh-calendar-input {
  border: 0;
  padding: 0;
  text-align: center;
  color: #6d7481 !important;
  font-weight: 500;
}

.external-enquiry-form .gh-time-input {
  transition: 0.3s ease;
}

.external-enquiry-form .gh-time-input:disabled {
  opacity: 0.6;
}

.external-enquiry-form .uk-input,
.external-enquiry-form .uk-textarea,
.external-enquiry-form .react-tel-input .form-control {
  border-color: #9da3ae;
}

.external-enquiry-form .uk-textarea {
  height: 64px;
}

.external-enquiry-form .input-wrapper {
  position: relative;
}

.external-enquiry-form .validation-errors {
  position: absolute !important;
  top: 100%;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.external-enquiry-status {
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.external-enquiry-status .content {
  margin-top: 24px;
}

.external-enquiry-status .content__title {
  margin-bottom: 6px;
  font-size: 20px;
}

.external-enquiry-status .content__descrp {
  margin: 0;
  max-width: 280px;
}

.external-enquiry-form_wrapper .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  position: unset !important;
}

.external-enquiry-form_wrapper .checkmark-enq {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.external-enquiry-form_wrapper .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.gh-steps-container .gh-step-item {
  display: none;
}

.gh-steps-container .gh-step-item.active {
  display: block;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
