.calculator__select {
  appearance: none;
  border: 2px solid transparent;
  outline: none;
  background-color: transparent;
  padding: 0 8px;
  padding-right: 31px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-family: inherit;
  border-radius: 2px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23494e57' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: calc(100% - 12px);
  background-size: 16px;
}
.calculator__select [readonly] {
  pointer-events: none;
}
.calculator__select.pc-invalid {
  background-color: #fff5ef !important;
}
.calculator__select.pc-invalid::placeholder {
  color: #e25950;
}
.calculator__select:hover:not([readonly]) {
  background-color: #e7eaef;
}
.calculator__select:focus:not([readonly]) {
  z-index: 1;
  border-color: #9da3ae;
  background-color: #e7eaef;
}
