.tooltip-wrapper {
    position: absolute;
    top: 30px;
    left: calc(40px + 117px + 10px);
    width: 20px;
    height: 20px;
    transform: translateY(2.5px);
}

.new-request-next-button-wrapper{
    display: flex;
}
.new-request-next-button-description{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #6d7481;
    margin-left: 10px;
    font-weight: 400;
}

@media only screen and (max-width: 639px) {
    .tooltip-wrapper {
        position: absolute;
        top: 20px;
        left: calc(20px + 117px + 15px);
    }

    .tooltip-wrapper .custom-tooltip-wrapper:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        top: -5px;
        left: -5px;
    }
    .new-request-next-button-description{
        font-size: 14px;
    }
    }
