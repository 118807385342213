#createEmptyLegFromThisBooking .uk-modal-dialog{
  width: 590px;
  padding: 40px;
}

#createEmptyLegFromThisBooking h3 {
  margin-bottom: 5px !important;
}

#createEmptyLegFromThisBooking input {
  border-color: #9DA3AE;
}

#createEmptyLegFromThisBooking .gh-site-title {
  font-size: 18px;
}

.gh-booking-empty-leg-form .rdtPicker {
  border: 2px solid #6d7481;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0 0 0 0.3);
  border-radius: 3px;
}

.gh-booking-empty-leg-form-row {
  /*display: flex;*/
  margin-bottom: 25px;
}

.gh-booking-empty-leg-form-row.time-row {
  display: flex;
  margin: 0 -10px 30px;
}

.gh-booking-empty-leg-form-row-col {
  flex: 1;
  padding: 0 10px;
}

.gh-booking-empty-leg-form-key, .gh-booking-empty-leg-form-row-col label {
  /*flex: 1;*/
  font-size: 12px;
  font-weight: 500;
  color: #6D7481;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.gh-booking-empty-leg-form-value {
  /*flex: 2;*/
  font-size: 16px;
  font-weight: 400;
  color: #242F42;
}

.empty-leg-close {
  top: 20px;
  right: 20px;
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.empty-leg-close img {
  width: 20px;
  height: 20px;
}

.gh-booking-empty-leg-form-value .gh-wrapper-autocomplete-input {
  max-width: 100% !important;
}

/*-------Media-------*/

@media (max-width: 576px) {
  .gh-booking-empty-leg-form {
    padding: 0;
  }

  .gh-booking-empty-leg-form-row {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .gh-booking-empty-leg-form-row-col {
    margin-bottom: 15px;
  }

  .gh-booking-empty-leg-form-row-col:last-child {
    margin-bottom: 0;
  }

  .gh-booking-empty-leg-form-row.time-row {
    margin: 0 -10px 30px;
  }

  #createEmptyLegFromThisBooking .uk-modal-dialog{
    width: 360px;
    padding: 20px;
  }

  .empty-leg-close {
    top: 10px;
    right: 10px;
  }
}
