.gh-icon-btn {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;
}

.gh-icon-btn:hover {
  background-color: rgb(16 184 151 / 15%);
}

.gh-icon-btn:focus {
  box-shadow: 0 0 0 0.2rem rgb(16 184 151 / 25%);
}

.gh-icon-btn svg {
  width: 20px;
  height: 20px;
  color: #10b897;
}
