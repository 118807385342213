@media (min-width: 320px) {
  .gh-quote-slider-item {
    min-height: 283px;
  }
  .gh-review-grid-section .row .count {
    width: 24px;
    transform: translateX(9px);
  }

  .gh-flight-duration {
    flex-direction: column;
    align-items: flex-start;
  }

  .gh-align-items {
    align-items: flex-start;
  }

  .gh-flight-duration .gh-estimated-time {
    margin-left: 0 !important;
    margin-top: 10px;
  }

  .gh-private-checkbox {
    justify-content: flex-start;
  }

  .validation-errors li {
    position: relative;
  }

  .gh-booking-layout-title {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 8px;
  }

  .gh-sign-by-invitation {
    padding: 10px;
  }

  .gh-terms-invitation-btn {
    min-width: 325px !important;
  }

  .gh-filter-tabs {
    margin-top: 15px;
    display: block;
  }

  .gh-filter-tabs li {
    display: inline-block;
    margin-bottom: 10px;
  }

  .gh-flex-group {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  #preview-file-account h3 {
    font-size: 16px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gh-quote-slider-text {
    width: calc(100% - 29px);
  }

  .gh-full-quote-actions {
    display: flex;
    flex-direction: column;
  }

  .gh-image-box-btn {
    width: 100%;
  }

  .gh-full-quote-actions .gh-short-nav-actions {
    margin: 20px 0;
  }

  .gh-aircraft-btn {
    min-width: 125px !important;
    width: 125px !important;
    padding: 0 !important;
  }

  .gh-discussion-container .gh-message-body .gh-message-header .gh-message-header-posted {
    margin-left: 10px;
    font-size: 10px;
  }

  .gh-discussion-container .gh-message-body {
    min-height: 64px;
  }

  .gh-discussion-container .second-role-body .gh-message-header .gh-message-header-posted {
    margin-left: 0;
    font-size: 10px;
  }

  .gh-uz-file-content a,
  .gh-uz-file-close a {
    padding: 0 !important;
  }

  .gh-uz-title h4,
  .gh-vm-more h4 {
    margin-bottom: 13px;
  }

  .gh-file-container {
    padding: 0;
  }

  .gh-file-padding {
    padding-right: 0;
  }

  .gh-future-dates-inside {
    margin: 10px 0 0 0;
  }

  .gh-image-event {
    width: 100%;
  }

  .gh-event-dates-inside {
    flex-direction: column;
  }

  .gh-event-card-content {
    padding: 0 19px 29px 19px;
  }

  .gh-padding-event-card {
    padding: 0;
  }

  .gh-heli-card-box {
    height: 180px;
  }

  .gh-button-terms-bank {
    width: 100% !important;
    padding: 10px 30px;
    height: auto !important;
  }

  .gh-button-terms,
  .gh-button-terms:hover {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    line-height: inherit;
  }

  .gh-btn-margin-register {
    margin-right: 0;
  }

  .gh-terms-btn-bank {
    width: 100%;
    margin-bottom: 15px;
  }

  .gh-terms-btns-bank {
    display: block;
  }

  .gh-terms-btn-bank-right {
    margin-left: 0;
    margin-bottom: 0;
  }

  .gh-event-card-header {
    flex-direction: column;
    margin-bottom: 18px;
  }

  .gh-pe-btn {
    width: 100%;
    margin-top: 15px;
  }

  .gh-icon-label,
  .gh-calendar-label {
    display: none;
  }

  .gh-request-info-section {
    border-right: none;
    padding: 0;
  }

  .gh-map-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .gh-map-header .gh-request-label {
    margin-bottom: 10px !important;
  }

  .gh-field-margin {
    margin-top: 20px !important;
  }

  .gh-review-grid-section {
    border-right: none;
    padding: 0;
  }

  .gh-simple-section {
    padding: 15px 10px;
  }

  .request-review h4,
  .gh-datetime-fields h5 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .empty-leg-detail .gh-info-text,
  .empty-leg-detail .gh-site-wrapper .gh-site-title {
    font-size: 14px;
  }

  .gh-info-text {
    font-size: 16px;
  }

  .gh-field-label {
    font-size: 13px;
  }

  .gh-review-grid-section h6 {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .gh-review-grid-section .row span {
    font-size: 16px;
  }

  .gh-datetime-fields span {
    font-size: 13px;
  }

  .review-icon {
    width: 30px;
    height: 30px;
  }

  .gh-location-marker {
    width: 25px;
    height: 34px;
  }

  /*.gh-location-fields {*/
  /*    flex-direction: column;*/
  /*}*/
  .gh-additional-info {
    margin-left: 0;
  }

  .gh-page-title {
    font-size: 18px;
  }

  .gh-general-info-section .gh-field-label {
    margin-bottom: 8px !important;
  }

  .gh-new-request-nav-inner {
    height: 30px;
  }

  .gh-accordion .uk-accordion-title,
  .gh-accordion .uk-accordion-content,
  .gh-accordion-like-title,
  .uk-accordion-like-content {
    padding: 20px 10px;
  }

  .gh-general-info {
    padding: 15px 10px;
  }

  .gh-flex-box-reverse {
    flex-direction: row;
  }

  .gh-flex-box {
    flex-direction: column;
  }

  .gh-radio-label {
    font-size: 13px;
  }

  .new-request .gh-section {
    padding: 15px 10px;
  }

  .gh-checkbox-label {
    justify-content: flex-start;
  }

  .new-request .gh-tooltip-box {
    width: 170px;
  }

  .new-request .gh-datepicker-field .rdtOpen .rdtPicker .wrapper {
    flex-direction: column;
  }

  .new-request .rdtPicker .rdtMonths,
  .new-request .rdtPicker .rdtDays,
  .new-request .rdtPicker .rdtYears {
    width: 100%;
  }

  .gh-picker-box {
    width: 95%;
    margin: 15px 0 0 10px;
  }

  .gh-location-row {
    flex-direction: column;
  }

  .gh-location-list-item .gh-pin {
    margin-top: 3px;
  }

  .gh-location-list-item {
    align-items: flex-start;
  }
}

@media (max-width: 360px) {
  #full-quote-modal {
    padding: 0;
  }

  .gh-quote-detail-modal {
    padding: 15px;
  }

  .gh-quote-detail-modal header h3 {
    font-size: 18px;
    padding: 8px 40px 0 0;
  }

  .gh-quote-detail-modal .gh-quote-slider-item-image {
    min-width: 100%;
    height: calc(100% - 53px);
  }

  .gh-quote-detail-modal .uk-modal-close-default {
    top: 10px;
    right: 10px;
  }

  .gh-quote-detail-modal .gh-slider-dots {
    bottom: 84px;
  }

  .gh-quote-detail-modal .gh-full-quote-btn {
    min-width: 100%;
  }

  .gh-margin-small-top-mobile {
    margin-top: 10px;
  }

  .wrapper-media-message .wrapper-bg {
    width: 210px !important;
    height: 115px !important;
  }
}

@media (max-width: 374px) {
  .gh-verified-checkbox {
    padding-left: 30px !important;
  }

  .heli-item-label,
  .heli-item-text {
    font-size: 12px;
  }

  .gh-edit-button,
  .gh-button-withdraw {
    text-align: center;
  }
}

@media (min-width: 450px) {
  .gh-discussion-container .gh-message-body .gh-message-header .gh-message-header-posted {
    font-size: 12px;
    margin-left: 10px;
  }

  .gh-discussion-container .gh-message-body {
    min-height: 67px;
  }

  .gh-sign-by-invitation {
    padding: 30px;
  }

  .gh-terms-invitation-btn {
    min-width: 340px !important;
  }
}

@media (max-width: 400px) {
  .wrapper-modal-buttons .modal-item-bg {
    flex-direction: column;
    margin: 0 !important;
  }

  .wrapper-modal-buttons .modal-item-bg .uk-button {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 450px) {
  .gh-bubble-message-container .gh-message-text {
    font-size: 12px !important;
  }

  .gh-enquire-actions-wide button {
    min-width: 45% !important;
  }

  .gh-margin-top-mobile {
    margin-top: 20px;
  }

  .gh-mobile-btn-normal {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gh-dashboard-modal-nav {
    padding: 10px 0 !important;
  }

  .gh-dashboard-modal-nav .gh-dm-link {
    width: 100% !important;
    min-width: 100% !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gh-discussion-container .gh-message-body .gh-message-header .gh-message-header-user {
    font-size: 13px;
  }

  .gh-discussion-container .gh-message-body .gh-message-header {
    align-items: center;
  }

  .gh-discussion-container .gh-message-body .gh-message-text {
    font-size: 14px;
  }

  .gh-full-width-btn {
    width: 100%;
    padding: 2px;
  }

  .gh-enquire .uk-form-label {
    font-size: 12px;
  }

  .gh-margin-left-medium {
    margin-top: 10px;
    margin-left: 0;
  }

  .gh-with-btn-full {
    width: 100%;
  }

  .gh-padding-none {
    padding: 0;
  }

  .gh-verified-checkbox {
    padding-left: 70px;
  }

  .gh-padding-large-top {
    padding-top: 10px;
  }

  .gh-margin-remove-top {
    margin-top: 0 !important;
  }

  .gh-margin-remove-bottom {
    margin-bottom: 0;
  }

  .gh-tax-line-height {
    line-height: 1.5;
  }

  .gh-padding-none {
    padding: 0;
  }

  .gh-full-width {
    width: 100%;
    display: inline-block;
  }

  .gh-payment-continue {
    width: 100% !important;
  }

  .gh-request-changes-btn {
    padding: 0;
    width: 100%;
    font-size: 13px;
  }

  .gh-full-width-btn {
    width: 100%;
    display: inline-block;
  }

  .gh-estimated-time {
    margin-top: 10px;
    margin-left: 0 !important;
  }

  .gh-direction-column {
    flex-direction: column;
  }

  .gh-payment-continue {
    width: 100% !important;
  }

  .gh-request-changes-btn {
    padding: 0;
    width: 100%;
    font-size: 13px;
  }

  .gh-full-width-btn {
    width: 100%;
  }

  .gh-social-icon {
    width: 25px;
  }

  .gh-long-button-block {
    margin-right: 0 !important;
  }

  .gh-long-button {
    font-size: 14px;
  }

  .gh-message-box {
    min-width: 240px;
  }

  .wrapper-prev-file .prev-file {
    width: 200px;
    height: 122px;
  }

  .wrapper-media-message .wrapper-bg {
    width: 230px !important;
    height: 115px !important;
  }

  #discuss-modal h3,
  #discussion-booking-modal h3 {
    margin-bottom: 5px;
  }

  .chat-modal-subtitle {
    line-height: 1;
  }

  .gh-simple-section_grid .gh-gi-card {
    min-width: 280px !important;
  }
}

@media (max-width: 640px) {
  .gh-outline-line {
    display: block;
  }

  .gh-outline-line div:first-of-type {
    margin-bottom: 15px !important;
  }

  .gh-event-card {
    margin-top: 10px !important;
  }

  .gh-event-card-content_wrapper {
    margin-top: 12px !important;
  }

  .gh-event-card-content {
    padding-bottom: 20px !important;
  }

  .gh-enquire-actions {
    flex-direction: column;
  }

  .gh-enquire-actions .gh-main-button-component {
    padding: 16px 30px;
    margin-bottom: 15px;
  }

  .gh-dashboard-create-empty-leg-row {
    flex-direction: column;
  }

  .gh-enquire-actions .gh-main-button-component {
    margin: 0 0 10px;
  }

}

@media (max-width: 575px) {
    .gh-enquire-content {
        padding: 0;
    }

    .gh-enquire {
        padding: 0 !important;
    }

    .gh-enquire-calendar .rdtOpen .rdtPicker {
        bottom: 0;
        margin-top: 0;
        margin-bottom: 53px;
    }

    .gh-time-enquire-form .rdtPicker {
        bottom: 0;
        margin-top: 0;
        margin-bottom: 53px;
    }

  .gh-display-block-mob {
    display: block;
  }

  .gh-elr-row .gh-label {
    margin-bottom: 8px;
  }

  .enquire-tooltip .gh-tooltip-box {
    top: 35px;
    right: -6px;
    transform: none !important;
  }
  .gh-document-type {
    margin-bottom: 10px;
  }

  .gh-margin-one {
    margin-top: 1px !important;
  }

  .gh-vetting {
    padding: 20px 0;
    border: 0;
    background: #ffffff;
  }

  .gh-broker-pad-map .gh-pad-title {
    align-items: flex-start;
  }

  .gh-broker-pad-map .gh-quote-pad-marker {
    margin: 5px 10px 0 0;
  }

  .gh-envelope-icon-simple {
    width: 20px;
  }

  .gh-time-format {
    display: none;
  }

  .gh-filters-tab {
    padding: 0 !important;
  }

  .gh-event-title {
    font-size: 24px;
    margin: 20px 0 10px 0 !important;
  }

  .gh-subtitle-text,
  .gh-subtitle-text p {
    font-size: 14px !important;
    line-height: 1.86;
  }

  .info-block .uk-container {
    padding: 20px;
  }

  .info-block .back-section {
    padding: 15px 0 0 0 !important;
  }

  .info-block h1 {
    font-size: 24px;
    margin-bottom: 0 !important;
  }

  .gh-title-events {
    font-size: 16px;
  }

  .info-block .button-return {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    padding: 0;
  }

  .button-return-label {
    font-size: 18px;
  }

  .gh-margin-btn-next {
    margin-bottom: 15px;
  }

  .gh-register-next {
    margin-bottom: 15px;
  }

  .gh-register-text {
    text-align: center;
  }

  .gh-margin-medium-bottom {
    margin-bottom: 20px;
  }

  .gh-margin-base-bottom {
    margin-bottom: 30px;
  }

  .gh-margin-extra-small-bottom {
    margin-bottom: 10px;
  }

  .gh-aoc-check {
    width: 100%;
    padding-left: 0;
  }

  #homepage-title,
  #gh-our-vision-title,
  #gh-find-out-title,
  .gh-feature-list-title {
    font-size: 1.3rem;
  }

  .gh-margin-left-normal {
    margin-left: 0;
  }

  .gh-margin-small,
  .gh-margin-right-normal {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .gh-register-buttons {
    flex-direction: column;
  }

  .gh-register-section {
    flex-direction: column;
  }

  .gh-register-select-button {
    width: 100%;
    margin: 15px 0 0 0;
    min-height: 48px;
  }

  .gh-request-btn-margin {
    margin-bottom: 10px;
  }

  .gh-margin-small-left {
    margin-left: 0;
  }

  .gh-text-break {
    word-break: break-all;
  }

  .gh-margin-small-top-bank {
    margin-top: 10px !important;
  }

  .gh-margin-small-top {
    margin-top: 10px !important;
  }

  .gh-padding-button-mobile {
    padding: 2px 0;
  }

  .review-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .review-btns .review-btn {
    margin-bottom: 15px;
  }

  .gh-request-btn-margin {
    margin-bottom: 10px;
  }

  .gh-margin-small-left {
    margin-left: 0;
  }

  .gh-margin-top-medium {
    margin-top: 25px;
  }

  .gh-margin-remove {
    margin: 0 !important;
  }

  .gh-text-break {
    word-break: break-all;
  }

  .gh-padding-button-mobile {
    padding: 2px 0;
  }

  .gh-broker-request-filters .gh-mob-radio-row {
    width: 100%;
  }

  .gh-broker-request-filters .gh-right-radio {
    padding-left: 15px;
  }

  /*.gh-broker-request-filters .gh-mob-radio {*/
  /*    width: 50%;*/
  /*}*/
  .gh-grid-title-progress .bottom-left .gh-tooltip-box {
    transform: translate(-140px, 14px);
  }

  .broker-booking-layout .uk-tab:before {
    display: none;
  }

  .gh-filters-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    width: 100%;
    height: 44px;
    font-size: 15px;
    padding: 0;
  }

  .gh-mob-radio-row {
    margin-top: 15px !important;
  }

  .broker-booking-layout .uk-tab {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .gh-broker-list-filters {
    margin-top: 20px;
  }

  .gh-tooltip-box {
    width: 170px;
  }

  .gh-padding-medium-left-s {
    padding-left: 30px;
  }

  .gh-margin-left-medium {
    margin-top: 10px;
    margin-left: 0;
    display: block;
  }

  .gh-number-field {
    padding-left: 0 !important;
  }

  .gh-create-empty-leg {
    font-size: 14px;
    padding: 2px;
  }

  .gh-input-errors {
    position: relative !important;
    display: block;
  }

  .gh-number-label {
    font-size: 10px !important;
  }

  .gh-button-responsive {
    font-size: 0.9rem !important;
    line-height: 3.3 !important;
  }

  .gh-display-block {
    display: block !important;
  }

  .gh-padding-remove {
    padding: 0;
  }

  .gh-margin-small-bottom {
    margin-bottom: 20px;
  }

  .gh-text-center {
    text-align: center !important;
  }

  .gh-map-type {
    padding: 6px;
    font-size: 10px;
    top: 10px;
    left: 10px;
    min-width: 85px;
  }

  .gh-private-text {
    font-size: 13px;
  }

  #gh-enquire-title {
    font-size: 1.4rem;
  }

  .hc-subtitle-box h2 {
    line-height: 1.2 !important;
    font-size: 0.8rem !important;
  }

  .hc-subtitle-box h3 {
    line-height: 1.2;
    font-size: 1.3rem;
  }

  #homepage-title {
    line-height: 1.2;
    letter-spacing: 1.2px;
    padding: 5px 10px;
  }

  #homepage-subtitle {
    line-height: 1.1;
    font-size: 30px;
    padding: 5px 10px;
  }

  #homepage-description {
    line-height: 1.2;
    padding: 0px 10px;
  }

  #gh-info-block-box {
    padding: 0;
  }

  .gh-btn-padding-xs {
    padding: 0 20px;
  }

  .gh-our-vision-title-box {
    height: 16%;
  }

  .gh-our-vision-description-box {
    height: auto;
  }

  .gh-our-vision-description {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.4;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
  }

  .gh-our-vision-btn-box {
    height: 22%;
    display: flex;
    align-items: center;
  }

  #gh-our-vision-find-out-box h2 {
    margin: 0 !important;
    line-height: 1.3;
  }

  .gh-our-vision-find-out-box:first-child {
    margin: 0 !important;
  }

  .gh-our-vision-find-out-box {
    height: 11%;
  }

  .gh-feature-list-item-description:first-child {
    font-size: 0.7rem !important;
    line-height: 1.3;
  }

  .gh-our-vision-find-out-box h2 {
    margin-top: 20px;
    line-height: 1.3;
  }

  #subscribe {
    flex-direction: column;
  }

  #subscribe-button {
    width: 100%;
    margin-left: 0 !important;
  }

  #subscribe-input-box {
    margin-bottom: 0;
  }

  #gh-role-title {
    font-size: 1.6rem;
  }

  .gh-home-marketplace-btn {
    margin: 0 0 45px 0;
  }

  .gh-feature-list-item-container {
    padding-top: 20px;
  }

  .gh-booking-summary .gh-text-big {
    font-size: 13px;
  }

  .gh-booking-summary small {
    font-size: 11px;
  }

  .gh-mob-center {
    text-align: center;
  }

  .gh-home-bg-1 {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/bg_m.jpg);
  }

  .gh-home-bg-2 {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/twoheli_mob.jpg);
    background-position-x: 20%;
    background-position-y: 20%;
  }

  .gh-contact-bg {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/contact_portrait_m.jpg);
    background-position-x: 83%;
    height: 25vh;
    background-position-y: 30%;
  }

  .gh-notification .subject-col {
    min-width: inherit;
  }

  .gh-notifications li > div > div.uk-grid > .uk-grid-margin,
  .gh-notification .gh-notification-location .location {
    margin-top: 0 !important;
  }

  .gh-radio-wrapper-mob {
    display: inherit;
  }

  .gh-hc-icon {
    width: 106px;
    margin: 0 auto;
  }

  .gh-remove-margin-top {
    margin-top: 0 !important;
  }

  .info-block:nth-child(2) .uk-section {
    padding-bottom: 35px;
  }

  .gh-remove-padding-bottom-small {
    padding-bottom: 1px !important;
  }

  .gh-contact-title {
    margin: 40px 25px 0 25px !important;
  }

  .gh-page-title {
    margin: 40px 25px 0 25px !important;
  }

  .feedback-form .submit-msg {
    width: 100% !important;
  }

  .gh-footer-side-menu-col {
    border-right: none !important;
  }
}

@media (max-width: 576px) {
  .gh-second-buttons {
    margin-top: 15px !important;
  }

  .gh-shortcut-box {
    display: none;
  }

  .gh-second-buttons-short {
    margin-top: 0 !important;
  }

  .gh-quote-button-icon {
    position: relative;
    width: 15px;
    margin: 0 7px 5px 0;
    top: 0;
    left: 0;
  }

  .gh-main-page-video {
    margin-bottom: 50px;
  }

  .selected-card {
    background: #ffffff;
    border: 1px solid #d8d8d8;
  }

  .wallet-row-default .wallet-card {
    flex-direction: column;
    justify-content: center;
  }
  .wallet-prices {
    font-size: 14px;
  }
  .gh-section-title-medium {
    margin-top: 15px;
  }
  .gh-section-title-large {
    margin-top: 0;
  }
  .wallet-row-default:not(.selected-card) {
    border: 0;
  }
}

@media (min-width: 576px) {
  .gh-review-grid-section .row .count {
    width: 33px;
    transform: translateX(15px);
  }

  .gh-align-items {
    align-items: center;
  }

  .gh-padding-field-left {
    padding-left: 15px;
  }

  .gh-padding-field-right {
    padding-right: 15px;
  }

  .gh-filter-tabs {
    margin-top: 0;
    display: flex;
  }

  .gh-filter-tabs li {
    margin-bottom: 0;
  }

  .gh-flex-group {
    width: 470px;
    flex-direction: row;
  }

  #preview-file-account h3 {
    font-size: 24px;
    width: 100%;
    white-space: normal;
    overflow: auto;
    text-overflow: initial;
  }

  .gh-quote-slider-item-image {
    height: calc(100% - 36px);
  }

  .gh-quote-slider-text {
    width: calc(100% - 29px);
  }

  .gh-image-box-btn {
    width: fit-content;
  }

  .gh-full-quote-actions {
    flex-direction: row;
    justify-content: space-between;
  }

  .gh-full-quote-actions .gh-short-nav-actions {
    margin: 0 0 0 20px;
  }

  .gh-event-list-label {
    margin-bottom: 5px;
  }

  .gh-add-member-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    height: 85px;
  }

  .gh-add-member-btn {
    width: 135px;
    margin-top: 0;
    padding: 2px 15px;
  }

  .gh-member-mail {
    width: 327px;
    margin-right: 15px;
  }

  .gh-el-card-item {
    padding-bottom: 15px;
  }

  .gh-title {
    margin-bottom: 20px;
  }

  .gh-button-terms-bank {
    width: 437px !important;
    height: 48px !important;
  }

  .gh-upload-zone {
    justify-content: flex-start;
  }

  .gh-uz-file-close,
  .gh-lc-close {
    top: 0;
    right: 0;
  }

  .gh-uz-file,
  .gh-list-card-item {
    padding: 13px 13px 0 0;
  }

  .gh-heli-card-box {
    height: 280px;
  }

  .gh-btn-margin-register {
    margin-right: 40px;
  }

  .gh-aoc-check {
    margin-top: 0 !important;
  }

  .gh-single-field {
    width: 345px;
  }

  .gh-initial-display {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  #homepage-title,
  #gh-our-vision-title,
  #gh-find-out-title,
  .gh-feature-list-title {
    font-size: 1.7rem;
  }

  .gh-broker-request-filters .gh-mob-radio-margin {
    margin-left: 40px !important;
  }

  .gh-our-vision-bg {
    background-size: 70vh !important;
  }

  .hc-subtitle-box h2 {
    line-height: 1;
    font-size: 0.9rem;
  }

  .hc-subtitle-box h3 {
    line-height: 1;
    font-size: 1.5rem;
  }

  .gh-our-vision-description,
  .gh-radio-wrapper-with-label label {
    font-size: 1rem;
  }

  #gh-role-title {
    font-size: 2.4rem;
  }

  .gh-home-bg-1 {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/heli_1024.jpg);
  }

  .gh-home-bg-2 {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/twoheli.jpg);
    background-position: 100% 42%;
  }

  .gh-contact-bg {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/contact_heli_1024.jpg);
    background-position-x: 83%;
  }

  .gh-mob-center {
    text-align: center;
  }

  .gh-helicopter-wrapper {
    background-image: url("../../img/h_front_m.png");
  }

  .gh-helicopter-wrapper.back {
    background-image: url("../../img/h_back_m.png");
  }
}

@media (min-width: 639px) {
  .gh-file-container,
  .gh-li-container {
    padding: 13px 13px 0 0;
  }

  .gh-uz-title h4,
  .gh-vm-more h4 {
    margin-bottom: 0;
  }

  .gh-file-padding:nth-child(4n) {
    padding-right: 0;
  }

  .gh-file-padding {
    padding-right: 10px;
  }

  .gh-aircraft-btn {
    min-width: 145px;
    padding: 5px 20px;
  }

  .gh-label-last {
    margin-bottom: 2px !important;
  }

  .gh-event-card-content {
    padding: 0;
  }

  .gh-padding-event-card {
    padding: 15px;
  }

  .gh-image-event {
    width: 300px;
    height: 193px;
    overflow: hidden;
  }

  .gh-padding-additional-top {
    padding-top: 4px;
  }

  .gh-terms-btn-bank {
    width: initial;
    margin-bottom: 0;
  }

  .gh-terms-btn-bank-left {
    margin-right: 20px;
  }

  .gh-terms-btns-bank {
    display: flex;
    justify-content: center;
  }

  .gh-terms-btn-bank {
    width: initial;
    margin-bottom: 0;
  }

  .gh-terms-btns-bank {
    display: flex;
    justify-content: center;
  }

  .gh-quote-detail-modal .gh-quote-slider-item {
    height: 350px;
  }

  .gh-quote-slider-item {
    min-height: 500px;
  }

  .gh-top-row {
    height: 35px;
  }

  .gh-icon-label {
    display: block;
  }

  .gh-request-info-section {
    border-right: 1px solid #d5d5d5;
    padding: 0 30px;
  }

  .gh-map-header {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .gh-map-header .gh-request-label {
    margin-bottom: 0 !important;
  }

  .gh-field-margin {
    margin: 0 !important;
  }

  .gh-broker-nav-request-modal .gh-review-grid-section {
    padding: 0 20px;
  }

  .gh-review-grid-section {
    padding: 0 50px;
    border-right: 1px solid #d5d5d5;
  }

  .gh-simple-section {
    padding: 25px;
  }

  .request-review h4,
  .gh-datetime-fields h5 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .empty-leg-detail .gh-info-text,
  .empty-leg-detail .gh-site-wrapper .gh-site-title {
    font-size: 16px;
  }

  .gh-info-text {
    font-size: 18px;
  }

  .gh-field-label {
    font-size: 14px;
  }

  .gh-review-grid-section h6 {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .gh-review-grid-section .row span {
    font-size: 18px;
  }

  .gh-datetime-fields span {
    font-size: 14px;
  }

  .review-icon {
    width: 40px;
    height: 40px;
  }

  .gh-location-marker {
    width: 35px;
    height: 44px;
  }

  .gh-additional-info {
    margin-left: 45px;
  }

  .gh-page-title {
    font-size: 24px;
  }

  .gh-general-info-section .gh-field-label {
    margin-bottom: 15px !important;
  }

  .gh-icon-label,
  .gh-calendar-label {
    display: block;
  }

  .gh-new-request-nav-inner {
    height: 50px;
  }

  .gh-accordion .uk-accordion-title,
  .gh-accordion .uk-accordion-content,
  .gh-accordion-like-title,
  .uk-accordion-like-content {
    padding: 30px 40px;
  }

  .gh-general-info {
    padding: 25px;
  }

  .gh-flex-box-reverse {
    flex-direction: column;
  }

  .gh-flex-box {
    flex-direction: row;
  }

  .gh-radio-label {
    font-size: 14px;
  }

  .new-request .gh-section {
    padding: 20px;
  }

  .gh-checkbox-label {
    justify-content: flex-end;
  }

  .new-request .gh-tooltip-box {
    width: 276px;
  }

  .new-request .gh-datepicker-field .rdtOpen .rdtPicker .wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .new-request .rdtPicker .rdtMonths,
  .new-request .rdtPicker .rdtDays,
  .new-request .rdtPicker .rdtYears {
    width: 320px;
  }

  .gh-picker-box {
    width: 280px;
    margin: 0 0 0 15px;
  }

  .new-request .rdtPicker .rdtMonths,
  .new-request .rdtPicker .rdtDays,
  .new-request .rdtPicker .rdtYears {
    width: 280px;
    margin: 0 0 10px 10px;
  }

  .new-request .gh-datepicker-field .rdtOpen .rdtPicker .wrapper .rdtTime {
    width: 50%;
    padding-top: 10%;
  }

  .new-request .gh-datepicker-field .rdtOpen .rdtPicker .wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .gh-location-row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .gh-location-list-item {
    align-items: center;
  }

  .gh-location-list-item .gh-pin {
    margin-top: 0;
  }

  .gh-event-card-header {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 38px;
  }

  .gh-pe-btn {
    margin-top: 0;
    width: auto;
  }
}

@media (max-width: 639px) {
  .gh-full-quote-btn {
    width: 100% !important;
  }

  .gh-initial-display {
    display: initial;
  }

  .gh-uploaded-zone li {
    padding-left: 0 !important;
  }

  .gh-flex-form {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-direction: column !important;
  }

  .gh-decline-full {
    width: 100% !important;
  }

  .gh-grid-small-text-margin {
    margin-top: 5px !important;
    margin-bottom: 20px !important;
  }

  .gh-margin-last {
    margin-top: 5px !important;
    margin-bottom: 0 !important;
  }

  .gh-first-element-margin {
    margin: 0 !important;
  }

  .gh-full-request-btn {
    width: 100%;
    margin: 15px 0;
  }

  .gh-margin-short-top {
    margin-top: 0 !important;
  }

  .gh-quote-buttons {
    width: 92% !important;
    padding: 0 20px 20px 20px;
  }

  .gh-quote-button {
    width: 100%;
  }

  .gh-bok-dec-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .gh-book-btn,
  .gh-decline-btn,
  .gh-book-btn-disabled {
    width: 48%;
    min-width: 48%;
  }

  .gh-quote-view-full-quote-btn {
    width: 100%;
  }

  .gh-with-full {
    display: inline-block;
    width: 100%;
  }

  .gh-padding-remove {
    padding: 0;
  }

  .gh-notification-location {
    display: block;
  }

  .gh-margin-bottom-10 {
    display: block;
    margin-bottom: 10px !important;
  }

  .gh-margin-top-10 {
    margin-top: 10px !important;
  }

  .gh-notification .gh-notification-location .location {
    width: 100%;
    margin-top: 30px;
  }

  .gh-text-center {
    text-align: center !important;
  }

  .gh-notification .medium-col .r-arrow {
    display: none;
  }

  .gh-range-hypen-filter::after {
    content: "" !important;
  }

  .gh-simple-section_grid {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 768px) {
  .enquiry-passed-btn,
  .enquiry-passed-info {
    font-size: 16px;
  }

  #profile-tab {
    flex-direction: column;
  }

  #profile-tab li {
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
  }

  .gh-register-btns button {
    padding: 0;
    display: flex;
    justify-content: center;
    width: fit-content;
    min-width: 130px;
    height: 50px;
  }

  .gh-rt-number.passed {
    display: flex;
    align-items: center;
    background: none;
    width: 28px;
    font-weight: 500;
  }

  .gh-rt-number.passed img {
    margin-left: 3px;
  }

  .gh-request-detail-btn {
    margin-bottom: 15px;
    width: 100%;
    height: 54px;
  }

  .gh-button-skip-active {
    border-color: #f0f0f0;
    background: #ffffff !important;
    color: #6d7481;
  }

  .gh-modal-flex-direction {
    flex-direction: column-reverse;
  }

  .gh-request-changes {
    margin-bottom: 0 !important;
  }

  .gh-margin-top-button-next {
    margin-top: 0 !important;
  }

  .gh-button-skip-active:hover {
    color: #6d7481;
  }

  .gh-button-skip-active:hover.gh-button-skip-active:before,
  .gh-button-skip-active:focus.gh-button-skip-active:before {
    display: none !important;
  }

  .gh-buttons-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .gh-term-arrow {
    line-height: 20px;
  }

  .gh-button-next {
    padding: 5px 76.3px !important;
  }

  .gh-term-condition-txt {
    padding: 0;
    line-height: normal;
  }

  .gh-our-vision-description {
    font-size: 1rem;
  }

  .gh-feature-list-item-title {
    font-size: 1.2rem;
    justify-content: center;
  }

  .gh-grid-notification {
    display: grid;
  }

  .gh-margin-bottom-notification {
    margin-bottom: 5px;
  }

  .gh-h2 {
    font-size: 1rem;
  }

  .gh-modal-grid {
    display: grid;
  }

  .gh-width-200 {
    width: 200px;
  }

  #gh-role-item {
    font-size: 1.2rem;
  }

  .gh-modal-promo-font {
    font-size: 13px;
  }

  .gh-conduct-container {
    max-height: 40vh;
  }

  .react-modal-content button,
  .react-modal-content a {
    min-width: 145px;
    font-size: 14px;
    line-height: inherit;
    padding: 5px 20px;
  }

  #full-request-modal .react-modal-content button {
    line-height: 42px;
  }

  .mini-tab .mini-tab a.uk-button-default,
  .register-page button.uk-button-default,
  .react-modal-content button.uk-button-default,
  .react-modal-content a.uk-button-default {
    padding: 3px 20px;
  }

  .gh-range-hypen::after {
    content: "";
  }

  .gh-range-hypen-filter::after {
    content: "-";
  }

  .gh-home-radio-group .gh-radio-wrapper-with-label label,
  .gh-radio-wrapper-with-label label {
    font-size: 1rem;
  }

  .gh-discussion-btn_envelope {
    width: 23px;
  }

  .gh-quote-buttons .gh-discussion-btn_envelope,
  .gh-full-quote .gh-discussion-btn_envelope {
    margin-top: 0;
  }

  .wrapper-modal-buttons .uk-button {
    flex: 1;
    padding: 5px 20px !important;
    height: initial;
    margin: 0 5px !important;
  }

  .gh-seen-message {
    font-size: 10px;
  }

  .gh-grid-title-progress {
    padding: 15px 0 0;
  }

  .gh-wrapper-progress {
    min-height: initial;

  }

  .gh-grid-title-progress .gh-tooltip .gh-tooltip-icon {
    transform: translate(4px, 0px);
  }

  .gh-wallet-price-value.deposit-row {
    flex-direction: column;
    align-items: start;
  }

  .gh-wallet-price-value.deposit-row .gh-wrapper-custom-checkbox {
    margin-left: 0;
    margin-top: 8px;
  }

}

@media (min-width: 768px) {
  .gh-register-landing {
    height: 176px;
  }

  .gh-booking-layout-title {
    font-size: 24px;
    margin-top: 0;
  }

  #homepage-title,
  #gh-our-vision-title,
  #gh-find-out-title,
  .gh-feature-list-title {
    font-size: 1.7rem;
  }

  .gh-uz-title h4,
  .gh-vm-more h4 {
    font-size: 16px;
  }

  .gh-register-title {
    margin: 50px auto;
  }

  .gh-register-title h3 {
    font-size: 24px;
  }

  .gh-uz-title {
    align-items: center;
  }

  .gh-uz-title span img {
    min-width: 25px;
    max-width: 25px;
  }

  .gh-rt-title {
    font-size: 16px;
  }

  .gh-rt-icon {
    margin-bottom: 5px;
    justify-content: center;
  }

  .gh-register-tab {
    justify-content: flex-end;
  }

  .gh-rt-number {
    margin-right: 10px;
    background: #fff;
  }

  .gh-rt-content {
    margin-right: 10px;
  }

  .gh-margin-left-cancel {
    margin-left: 25px;
  }

  .gh-our-vision-bg {
    background-size: 85vh !important;
  }

  .hc-subtitle-box h2 {
    line-height: 1.5;
    font-size: 1rem;
  }

  .hc-subtitle-box h3 {
    line-height: 1.2;
    font-size: 1.7rem;
  }

  .gh-child-full-page-height {
    height: 100%;
  }

  .info-block {
    width: auto;
    margin: 0;
  }

  .gh-modal-footer button,
  .gh-modal-footer a {
    margin-right: 15px;
  }

  .gh-helicopter-wrapper {
    width: 100%;
  }

  .gh-margin-medium-bottom-50 {
    margin-bottom: 50px;
  }

  .gh-home-marketplace-btn {
    margin: 25px 0 50px 0;
  }

  .gh-conduct-container {
    max-height: 40vh;
  }
}

@media (min-width: 960px) {
  .gh-flight-duration {
    flex-direction: row;
    align-items: center;
  }

  .gh-flight-duration .gh-estimated-time {
    margin-left: 10px !important;
    margin-top: 0;
  }

  .gh-private-checkbox {
    justify-content: flex-end;
  }

  .validation-errors li {
    position: absolute;
  }

  .gh-future-dates-inside {
    margin: 0 0 0 15px;
  }

  .gh-event-dates-inside {
    flex-direction: row;
  }

  .gh-register-form {
    padding: 42px 118px;
    background: #fff;
  }

  .gh-title {
    margin-bottom: 38px;
  }

  .gh-event-list-label {
    margin-bottom: 10px;
  }

  .gh-el-card-item {
    padding-bottom: 0;
  }
}

@media (max-width: 960px) {
  .gh-broker-request-filters {
    margin-top: 20px;
    padding: 20px;
  }

  .gh-table-actions {
    border-top: 1px solid #e7e7e7;
  }

  .gh-dashboard-item {
    margin-top: 30px !important;
  }
}

@media (max-width: 992px) {
  .gh-quote-buttons-box {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .gh-request-titles {
    font-weight: bold;
  }
}

@media (max-width: 959px) {
  .gh-mobile-action {
    padding-bottom: 15px;
    padding-left: 15px;
  }

  .gh-wrapper-progress-grafic-icon {
    margin: 0;
  }

  .gh-quote-num-mob {
    justify-content: flex-start;
  }

  .gh-marketplace-table-action {
    display: flex;
    align-items: center;
  }

  .gh-marketplace-table-action_item {
    flex: 1;
  }

  .gh-marketplace-envelope {
    margin-right: 0;
  }

  .gh-marketplace-envelope img {
    width: 22px;
  }

  .gh-marketplace-table-action_item .gh-flag-request {
    padding-top: 23px;
  }

  .gh-marketplace-table-action_item.gh-min-width-small {
    min-width: auto;
  }

  .header-desktop-submenu__group-wrapper {
    margin-bottom: 0;
  }

  .gh-offcanvas-bar ul.gh-side-menu li {
    padding: 2px 0 !important;
  }

  .gh-public-menu .gh-side-menu .link {
    line-height: 15px;
    min-height: 22px;
    display: flex;
    align-items: center;
  }

  .gh-subnav-menu > .link {
    margin-top: 10px;
  }

  .gh-subnav-menu > .link:first-child {
    margin-top: 0;
  }

  .gh-offcanvas-bar .gh-side-menu.second-step > li {
    padding: 7px 0 !important;
  }

  .header-desktop-menu-item .link.title {
    margin-top: 10px;
  }

  .header-desktop-menu-item .link.title:first-child {
    margin-top: 0;
  }

}

@media (min-width: 992px) {
  #homepage-title,
  #gh-our-vision-title,
  #gh-find-out-title,
  .gh-feature-list-title {
    font-size: 2.5rem;
  }

  .gh-aoc-check {
    padding-left: 23px !important;
  }

  .gh-initial-display {
    justify-content: flex-end;
  }

  .gh-request-titles {
    font-weight: bold;
  }

  .gh-width-small {
    width: 360px;
  }

  .gh-our-vision-bg {
    background-size: 95vh !important;
  }

  .gh-feature-list-item-container {
    min-width: 350px !important;
  }

  .gh-our-vision-description,
  .gh-home-radio-group .gh-radio-wrapper-with-label label {
    font-size: 1.3rem;
  }

  .hc-subtitle-box h2 {
    line-height: 2.55;
    font-size: 1.2rem;
  }

  .hc-subtitle-box h3 {
    line-height: 1.56;
    font-size: 2.1rem;
  }

  .gh-home-marketplace-btn {
    margin: 30px 0 80px 0;
  }

  .gh-conduct-container {
    max-height: 55vh;
  }

  .gh-notification .small-col {
    width: 12%;
  }
}

@media (max-width: 992px) {
  .gh-notification .medium-col {
    width: 100%;
  }

  .gh-mob-grid li {
    background-color: #ffffff;
    padding: 15px;
  }

  .gh-range-hypen-operator::after {
    content: "";
  }
}

@media (min-width: 1024px) {
  .gh-notification .medium-col {
    width: 39%;
  }

  .gh-notification .medium-col .location {
    width: 40%;
    text-align: center;
  }

  .gh-card-label {
    width: 150px;
  }

  .gh-home-bg-2 {
    height: 235px;
  }
}

@media (max-width: 1024px) {
  .gh-notifications li > div > div > div,
  .gh-notifications .gh-notification .small-col {
    width: 100%;
    text-align: left !important;
  }

  .gh-modal-promo-code {
    flex-direction: column;
  }

  .gh-modal-promo-small {
    margin-right: auto;
    margin-left: auto;
  }

  .gh-modal-promo-code-margin {
    margin-top: 10px;
  }

  .gh-notifications li label.uk-hidden\@m,
  .gh-notifications li span.uk-hidden\@m {
    display: inline-block !important;
  }

  .gh-notifications li span.uk-visible\@m {
    display: none !important;
  }

  .gh-notification .medium-col .location {
    text-align: left;
  }

  /*.gh-notification-location {*/
  /*    min-width: 500px;*/
  /*}*/
  .gh-child-full-height {
    padding-left: 20px;
  }

  .gh-modal-footer > div {
    display: block;
  }

  .gh-modal-footer {
    padding: 10px;
  }

  .gh-modal-footer-request a,
  .gh-modal-footer-request button {
    line-height: 40px;
  }
}

@media (max-width: 1200px) {
  .gh-offcanvas-bar {
    width: 270px;
    padding: 20px;
  }

  .gh-quote-detail-modal {
    width: 100%;
  }

  .gh-margin-bottom-quote {
    margin-bottom: 30px;
  }

  .gh-margin-top-60 {
    margin-top: 60px !important;
  }

  .gh-modal-footer-request {
    margin: 0;
    padding: 0;
  }

  .gh-modal-footer-request a,
  .gh-modal-footer-request button {
    width: 85%;
    max-width: 200px;
    padding: 0 !important;
  }

  .gh-outline-line {
    transform: translateX(26px);
  }

  .wrapper-modal-buttons {
    flex-direction: column;
  }

  .wrapper-modal-buttons .modal-item-bg {
    display: flex;
    margin-bottom: 10px;
  }

  .wrapper-modal-buttons .modal-item-sm {
    display: flex;
  }

  .wrapper-modal-buttons .uk-button {
    flex: 1;
    margin: 0 5px !important;
  }

  .gh-simple-section_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .subnav .subnavbtn {
    font-size: 14px;
  }

  .gh-dashboard-nav a {
    font-size: 14px;
  }

  .header-desktop-menu li {
    padding-right: 15px;
  }

  .header-desktop-menu > li.header-desktop-menu-item {
    padding: 0 10px;
  }

  .header-desktop-menu.--right-side > li.header-desktop-menu-item {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .gh-quote-slider-text {
    width: calc(100% - 29px);
  }

  .gh-quote-slider-item-image {
    height: calc(100% - 54px);
  }

  .gh-quote-slider-item {
    min-height: auto;
  }

  #homepage-title,
  #gh-our-vision-title,
  #gh-find-out-title {
    font-size: 3rem;
  }

  .gh-heli-card-box {
    height: 180px;
  }

  .gh-quote-detail-modal {
    width: 1200px !important;
  }

  .gh-register-buttons-first .uk-button {
    min-width: 159px;
  }

  .gh-quote-detail-modal {
    width: 1200px !important;
  }

  .gh-feature-list-item-container {
    min-width: 400px !important;
  }

  .gh-our-vision-description,
  .gh-home-radio-group .gh-radio-wrapper-with-label label {
    font-size: 1.5rem;
  }

  .gh-hc-icon h2 {
    line-height: 3;
    font-size: 1.4rem;
  }

  .hc-subtitle-box-contact h3 {
    line-height: 3;
    font-size: 2.5rem;
  }

  .uk-modal-dialog {
    width: 900px;
  }

  .gh-modal-footer > div {
    display: inline-block;
  }

  .gh-modal-footer > div:last-child a,
  .gh-modal-footer-request a.gh-request-active {
    margin-right: 0 !important;
  }

  .gh-home-bg-1 {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/heli4.jpg);
  }

  .gh-home-bg-2 {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/twoheli.jpg);
    background-position: 100% 58%;
    height: 400px;
  }

  .gh-contact-bg {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/contact_heli.jpg);
  }

  .gh-notification .medium-col {
    width: 42%;
  }

  .gh-notification .medium-col .location {
    width: 40%;
    text-align: center;
  }

  .gh-notification .created-col {
    width: 9%;
  }

  .hc-subtitle-box h3 {
    margin: 0;
  }

  .gh-modal-footer-request a,
  .gh-modal-footer-request button {
    min-width: 252px;
  }
}

@media (max-height: 415px) and (max-width: 825px) {
  .gh-width-50 {
    width: 47%;
  }

  .gh-grid-l {
    text-align: left !important;
    margin-left: 6%;
  }

  .gh-grid-r {
    text-align: right !important;
  }

  .gh-contact-bg {
    background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
      url(../../img/bg/contact_heli_m.jpg);
    height: 35vh;
  }

  .gh-contact-title {
    margin-top: 45px !important;
  }

  .gh-page-title {
    margin-top: 45px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .gh-request-btn {
    font-size: 14px;
  }

  /*.gh-uz-file-name {*/
  /*  width: 90px;*/
  /*}*/
}

@media (min-width: 1200px) and (max-width: 1350px) {
  .gh-event-dates-inside {
    flex-direction: column;
  }

  .gh-future-dates-inside {
    margin: 10px 0 0 0;
  }
}

@media (min-width: 1600px) {
  .gh-slider-arrow-right,
  .gh-slider-arrow-left {
    top: 120px;
  }

  /*.gh-quote-detail-modal .gh-slider-arrow-right, .gh-slider-arrow-left {*/
  /*    top: 120px;*/
  /*}*/
}

@media (min-width: 1624px) {
  .gh-home-bg-2 {
    height: 500px;
  }

  .gh-our-vision-bg {
    background-size: auto !important;
  }
}

@media (min-width: 2070px) {
  .gh-home-bg-2 {
    height: 600px;
  }

  .gh-our-vision-bg {
    background-size: auto !important;
  }
}

@media (max-height: 425px) and (max-device-width: 2500px) {
  .gh-contact-bg {
    height: 45vh;
  }
}

@media (max-height: 414px) and (max-width: 767px) {
  .gh-width-50 {
    width: 47%;
  }

  .gh-grid-l {
    text-align: left !important;
    margin-left: 6%;
  }

  .gh-grid-r {
    text-align: right !important;
  }

  .gh-grid-mob {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .gh-grid-mob * {
    padding: 0;
  }
}

@media (max-height: 320px) and (max-width: 568px) {
  #homepage-title {
    padding: 0;
  }
}

@media only screen and (max-device-width: 740px) and (max-device-height: 415px) and (-webkit-min-device-pixel-ratio: 2) {
  .gh-overlay-popup.default .tac-body {
    margin-bottom: 45px;
    min-height: 185px;
  }
}

@media only screen and (max-device-height: 740px) and (max-device-width: 415px) and (-webkit-min-device-pixel-ratio: 2) {
  .gh-overlay-popup.default .tac-body {
    height: calc(100vh - 525px);
    margin-bottom: 45px;
  }
}

@media only screen and (max-device-width: 568px) and (max-device-height: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .gh-overlay-popup.default .tac-body {
    margin-bottom: 45px;
    min-height: 150px;
  }
}

@media (min-width: 1440px) and (max-width: 1620px) {
  .gh-feature-list-item-container .gh-child-full-height {
    padding-left: 10px;
  }
}
