.gh-provide-payment-broker-info {
  padding-left: 30px;
  margin: 20px 0;
}

.gh-provide-payment-broker-info-row {
  display: flex;
}

.gh-provide-payment-broker-info-key {
  white-space: nowrap;
  margin-right: 10px;
  /*min-width: 150px;*/
  font-size: 16px;
}

.gh-provide-payment-form {
  margin: 20px 0;
}

.gh-provide-payment-warning {
  color: #e74c3c;
  font-size: 14px;
}

.gh-provide-payment-form-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.gh-provide-payment-form-row:first-child {
  margin-top: 10px;
}

.gh-provide-payment-form-row.hide {
  display: none;
}

.gh-provide-payment-form-row:last-child {
  margin-bottom: 0;
}

.gh-provide-payment-form-row .uk-form-label {
  flex: 1;
}

.gh-provide-payment-form-row .gh-provide-payment-input {
  flex: 1;
}

.gh-provide-payment-row-label {
  font-size: 14px;
  text-transform: capitalize;
}

.gh-provide-payment-alternative-text {
  color: #e74c3c;
  font-size: 14px;
}

.gh-provide-payment-alternative-text span {
  padding-right: 4px;
  font-weight: 600;
  text-transform: uppercase;
}

.gh-provide-payment .gh-uz-file-content {
  background-color: #e7eaef;
}

.gh-provide-payment .gh-uz-file-format {
  background-color: #fff;
}

/*Media */
@media (max-width: 767px) {
  .gh-provide-payment-broker-info {
    padding-left: 0;
  }

  .gh-provide-payment-broker-info-row {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .gh-provide-payment-broker-info-row:last-child {
    margin-bottom: 0;
  }

  .gh-provide-payment-broker-info-key {
    margin-right: 0;
    margin-bottom: 3px;
  }

  .gh-provide-payment-form-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .gh-provide-payment-input {
    min-height: 48px;
  }
}
