.gh-additional-info-wrapper {
  padding: 25px;
  color: #242f42;
  /*box-shadow: 1px 0 61px 0 rgba(0,0,0,0.75);*/
}

.gh-additional-info-title {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #242f42;
  margin-top: 20px;
}

.gh-additional-info-content {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #242f42;
  background-color: #e6f2f0;
  padding: 20px;
  margin-top: 10px;
  border-radius: 4px;
}

.gh-additional-info-content .gh-text-area {
  font-family: Rubik, sans-serif;
  color: #242f42;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  line-height: 19px;
  border: 2px solid #6d7481;
  border-radius: 4px;
  font-weight: 400;
  vertical-align: top;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  outline: none;
  resize: vertical;
  height: auto;
  min-height: 80px;
  font-size: 16px;
}

.gh-additional-info-content .gh-text-area::placeholder {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #9a9ca1;
}

@media (max-width: 500px) {
  .gh-additional-info-wrapper {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .gh-additional-info-content {
    padding: 15px;
  }

  .gh-additional-info-content .gh-text-area {
    font-size: 14px;
    padding: 10px;
    height: 140px;
  }
}
