.clients-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.gh-clients-remove-modal-btn {
    min-width: 150px;
}

#client-remove-modal {
    text-align: center;
}

@media only screen and (min-width: 1024px){
    .gh-client-remove-modal {
        max-width: 550px !important;
    }
}

@media only screen and (max-width: 425px) {
    .gh-clients-remove-modal-btn.gh-clients-remove-modal-btn {
        max-width: 45%;
        min-width: 100px;
    }
}
