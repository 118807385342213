.calculator__legs {
  margin-top: 26px;
  display: flex;
  position: relative;
}
.calculator__legs::after {
  content: "";
  position: absolute;
  top: -26px;
  left: 0;
  height: calc(100% + 25px);
  width: 100%;
  border-left: 2px solid #242f42;
  pointer-events: none;
}
.legs-list {
  display: flex;
}
.legs-list.disabled {
  pointer-events: none;
}
.legs-list.disabled *,
.legs-list.disabled *::placeholder {
  color: #ccc !important;
}
.legs-list .leg:not(:last-of-type) {
  margin-right: 10px;
}
.leg.hidden > .accordion {
  visibility: hidden;
}
.leg.hidden .calculator__description:last-of-type {
  visibility: hidden;
}
.leg.hidden .calculator__description:first-of-type {
  border-bottom: transparent;
  border-right: transparent;
  border-left: transparent;
}
.leg.hidden .calculator__description:first-of-type .calculator__description-item:first-of-type {
  border-right: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
}
.leg.hidden
  .calculator__description:first-of-type
  .calculator__description-item:not(:first-of-type) {
  visibility: hidden;
}
.label-without-border {
  border-bottom: transparent !important;
}
.notes-description .calculator__description-item {
  height: 140px;
  align-items: flex-start;
}
.notes-description .notes-description-label {
  padding-top: 17px;
}
.leg {
  position: relative;
  background-color: #f0f0f0;
}
.leg.is-dragging {
  z-index: 10;
}
.leg.is-dragging > * {
  opacity: 0;
}
.leg.is-dragging::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed #d5d5d5;
}
.leg-move-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7eaef;
  padding: 2px !important;
  position: absolute;
  bottom: 100%;
}
.leg-move-btn:disabled {
  visibility: hidden;
}
.leg-move-btn svg {
  width: 12px;
  height: 12px;
}

.calculator-location-input {
  font-weight: unset;
  position: relative;
}

.calculator-location-input .calculator-reset-btn {
  margin-left: 2px;
  position: absolute;
  top: 2px;
  right: 2px;
  height: calc(100% - 4px);
}

.calculator-location-input .calculator-reset-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: -2px;
  width: 2px;
  height: 100%;
  background-color: #fff;
}

.calculator-location-input:hover .calculator-reset-btn {
  visibility: visible;
}

.calculator-location-input > div {
  width: 100%;
  height: 100%;
}

.calculator-location-input .default-input-style {
  height: 100%;
  padding: 0 10px;
  border-radius: 2px;
  border-color: transparent;
  font-size: 15px;
  transition: unset;
}

.calculator-location-input.edited .default-input-style:focus {
  width: calc(100% - 73px);
}

.calculator-location-input .default-input-style:hover {
  background-color: #e7eaef;
}

.calculator-location-input .default-input-style:focus {
  border-color: #9da3ae;
  background-color: #e7eaef;
}

.calculator-location-input .gh-wrapper-autocomplete-input,
.calculator-location-input .uk-position-relative {
  height: 100%;
  width: 100%;
}

.leg-suffix-padding .description-suffix {
  padding-right: 10px;
  font-weight: 400;
  font: 14px;
}
