#available-countries-modal .uk-modal-dialog {
  max-height: calc(100% - 20px) !important;
  width: 100%;
  max-width: 1200px !important;
  padding: 20px 0 0;
}

#available-countries-modal .uk-modal-dialog header {
  padding: 0 20px;
}

.available-countries-title {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
}

.available-countries-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(134px, 234px));
  row-gap: 24px;
}

.available-countries-item {
  color: #242f42;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.available-countries-item .title {
  margin-left: 14px;
}

.available-countries-content {
  padding: 0 20px;
}

.available-countries-item.skeleton {
  width: 134px;
  height: 21px;
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

#homebase-info,
#homebase-info * {
  box-sizing: border-box;
  zoom: 1;
}

.homebase-info__container {
  width: 100%;
  min-width: 234px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 12px;
  font-weight: 500;
  color: #242f42;
  font-size: 14px;
  position: relative;
}

.homebase-info__container [data-uk-close] {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: unset;
  padding: 0;
}

.homebase-info__container.warning {
  min-width: unset;
  padding-right: 32px;
}

.homebase-info__container.warning [data-uk-close] {
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
}

.homebase-info__group,
.homebase-info__content {
  width: 100%;
}

.homebase-info__group .homebase-info__content {
  display: none;
}

.homebase-info__group .homebase-info__content[data-homebase-visible="true"] {
  display: block;
}

.homebase-info__content .subtitle {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 8px;
  color: #6d7481;
  letter-spacing: 1px;
}

.homebase-info__content .title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.homebase-info__content .homebase-info__line {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.homebase-info__content .homebase-info__line:not(:last-of-type) {
  margin-bottom: 12px;
}

.homebase-info__pagination {
  padding: 10px 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.homebase-info__pagination button {
  font-weight: 600;
  margin: 0 4px;
  color: #1abc9c;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  transition: 0.2s;
  padding-right: 0;
  min-width: unset !important;
}

.homebase-info__pagination-meta {
  position: absolute;
  bottom: 10px;
  left: 14px;
  font-size: 12px;
}

.homebase-info__pagination button:hover {
  color: #242f42;
}

.homebase-info__pagination button:disabled {
  color: #6d7481;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.9;
}

.gm-style div[role="button"],
.gm-style div[role="dialog"] {
  outline: none !important;
}

/* Media Queries */
@media (max-width: 768px) {
  .homebase-info__content {
    min-width: unset;
  }
}

.map-marker-label {
  transform: translateY(-4.5px);
  line-height: 1;
  font-weight: 600;
  font-size: 12px !important;
}

.map-marker-label-gray{
  color: #C1C1C1 !important;
}
