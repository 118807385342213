.upload,
.upload * {
  box-sizing: border-box;
}

.upload__select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 85px;
  padding: 0 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed #6d7481;
  position: relative;
  transition: 0.2s ease;
}

.upload__select.is-disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.upload__select.is-dragover {
  border-color: transparent;
  background-color: rgb(109, 116, 129);
}

.upload__description {
  font-size: 14px;
  color: #6d7481;
  margin-top: 4px;
  padding-left: 4px;
  display: block;
}

.upload__select-text {
  color: #242f42;
  font-weight: 500;
  font-size: 16px;
}

.upload__select-text .upload__select-text-delimiter {
  color: #6d7481;
  margin: 0 8px;
  display: inline-block;
}

.upload__select-btn {
  outline: none;
  border: none;
  background-color: rgba(16, 184, 151, 0.1);
  width: 135px;
  height: 45px;
  color: #10b897;
  font-weight: 600;
  transition: 0.2s ease;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.upload__select-btn:hover {
  background-color: rgba(16, 184, 151, 0.18);
}

.upload__select-file-input {
  display: none !important;
  visibility: hidden !important;
  width: 0px !important;
  height: 0px !important;
  opacity: 0 !important;
}

.upload__files {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 15px;
}

.upload-file {
  display: flex;
  align-items: center;
  background-color: #e7eaef;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  padding: 13px 11px;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid transparent;
  transition: 0.2s ease;
}

.upload-file__preview {
  width: 64px;
  height: 50px;
  min-width: 64px;
  min-height: 50px;
  background-color: #fff;
  border-radius: 4px;
  color: #242f42;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  cursor: auto;
  user-select: none;
  position: relative;
}

.upload-file__preview-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-file__loading svg circle {
  stroke: #fff !important;
}

.upload-file__caption {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
}

.upload-file__title {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  width: 100%;
}

.upload-file__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.upload-file__size {
  font-size: 12px;
  color: #6d7481;
  font-weight: 500;
}

.upload-file__fullsize {
  font-weight: 500;
  color: #10b897;
  transition: 0.2s ease;
}

.upload-file__fullsize.is-disabled {
  color: #242f42;
  pointer-events: none;
  opacity: 0.8;
}

.upload-file__remove {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 0.2s;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-file__remove img {
  width: 26px;
  height: 26px;
}

.upload-file__remove:hover {
  opacity: 0.87;
}

.upload-file.is-error {
  border-color: #ffab00;
  font-style: italic;
  opacity: 0.85;
}

.upload-file.is-disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.upload-file__warn {
  font-size: 14px;
  line-height: 1.15;
  margin-top: 5px;
}

#upload-file-view-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1015;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

#upload-file-view-modal .upload-file-view {
  max-height: calc(100vh - 220px);
  max-width: 900px;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: #fff;
  box-sizing: border-box;
  padding: 24px;
  position: relative;
}

#upload-file-view-modal .upload-file-view .title {
  font-size: 20px;
  font-weight: 600;
  color: #242f42;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

#upload-file-view-modal .upload-file-view [data-uk-close] {
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 0;
  margin: 0;
}

#upload-file-view-modal .upload-file-view img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-sizing: border-box;
}

.upload__errors {
  color: #ffab00;
  white-space: pre-wrap;
  font-size: 12px;
  margin-top: 8px;
  padding-left: 4px;
}
