.calculator__input,
.tax-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.calculator__input .calculator__input-btn,
.tax-input .calculator__input-btn {
  margin-left: 2px;
}
.calculator__input-actions {
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.calculator__input-actions.visible {
  display: flex;
}
.calculator .calculator__input-btn {
  background-color: #e7eaef;
  border-radius: 2px;
  width: 46px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #494e57;
}
.calculator .calculator__input-btn:hover {
  background-color: #d8dde5;
}
.calculator .calculator__input-btn svg {
  width: 20px;
  height: 20px;
}
.calculator .calculator__input-btn.reset {
  width: unset;
  padding: 0 6px;
  color: #005bff;
  font-weight: 500;
  visibility: hidden;
}
.calculator .calculator__input:hover .calculator__input-btn.reset {
  visibility: visible;
}
.calculator .calculator__input-btn.reset:hover {
  background-color: #d8dde5;
}
.calculator .calculator__input-btn.reset svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 4px;
}
.tax-input .calculator__input-actions {
  display: flex;
  visibility: hidden;
}
.tax-input .calculator__input-actions.visible {
  visibility: visible;
}
.tax-input__part {
  display: flex;
  height: 100%;
  align-items: center;
}
.tax-input__part:first-of-type {
  margin-right: 2px;
}
.pc-input {
  border: 2px solid transparent;
  outline: none;
  background-color: transparent;
  padding: 0 8px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-family: inherit;
  border-radius: 2px;
  position: relative;
}

.pc-input:not([readonly])::placeholder {
  color: #005bff;
}

.pc-input[readonly] {
  pointer-events: none;
}
.pc-input.pc-invalid {
  background-color: #fff5ef !important;
}
.pc-input.pc-invalid::placeholder {
  color: #e25950;
}
.pc-input:hover:not([readonly]) {
  background-color: #e7eaef;
}
.pc-input:focus:not([readonly]),
.pc-input.focus:not([readonly]) {
  z-index: 1;
  border-color: #9da3ae;
  background-color: #e7eaef;
}
.pc-input__container {
  height: inherit;
  width: inherit;
  display: flex;
  align-items: center;
}
.pc-input__prefix,
.pc-input__suffix {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px 0 0 2px;
  padding: 0 6px;
  font-size: 12px;
  color: #242f42;
  font-weight: 600;
}
.pc-input__prefix svg,
.pc-input__suffix svg {
  width: 12px;
  height: 12px;
}
.pc-input__suffix {
  border-radius: 0 2px 2px 0;
}
.pc-textarea {
  padding: 12px 10px;
  resize: none;
}
.pc-textarea::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
.pc-textarea::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}
.pc-textarea::-webkit-scrollbar-thumb {
  background-color: #999;
  cursor: pointer;
}
.pc-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #737373;
}

/*.tax-input .pc-input:not(:focus) {*/
/*  border: 1px solid #d5d5d5;*/
/*}*/
