/*===========Reset Theme css styles===========*/
/*.gh-booking-payment-details p {*/
/*    margin: 0;*/
/*}*/

/*===========Styles===========*/
.gh-booking-payment-details-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.gh-booking-payment-details-label {
  width: 30%;
}

.gh-booking-payment-details-value {
  color: #000;
}


.gh-booking-payment-details-modal-wrapper .hide {
  display: none !important;
}

.gh-booking-payment-details-status {
  display: flex;
  align-items: baseline;
}

.gh-booking-payment-details-status .gh-main-button-component-text {
  padding: 0;
}


/*===========Button===========*/
.gh-booking-payment-details-confirm-payment-btn {
  padding: 0 15px;
  line-height: 30px;
}

@media only screen and (max-width: 425px) {
  .gh-booking-payment-details-row {
    flex-direction: column;
    align-items: initial;
  }

  .gh-booking-payment-details-label {
    width: auto;
  }
}

/*===========Select===========*/
.gh-booking-payment-details .gh-custom-select {
  min-width: 160px;
  height: 34px !important;
  line-height: 34px !important;
}

.gh-booking-payment-details .gh-custom-select.paid {
  color: #1abc9c !important;
}

.gh-booking-payment-details .gh-custom-select.unpaid {
  color: #e74c3c !important;
}

.gh-booking-payment-details .gh-custom-select .gh-paid {
  color: #1abc9c !important;
}

.gh-booking-payment-details .gh-custom-select .gh-unpaid {
  color: #e74c3c !important;
}

.gh-booking-payment-details .gh-paid-label {
  color: #e74c3c;
  margin-right: 20px;
  font-weight: 600;
}
.gh-booking-payment-details .gh-paid-label-green {
  color: #1abc9c;
}
