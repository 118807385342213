.dropdown {
  position: relative;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.dropdown-overlay {
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 4;
  padding: 8px 0;
  border-radius: 4px;
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition-property: transform, visibility, opacity;
  transition-duration: 0.2s;
  font-size: 16px;
  -webkit-touch-callout: none;
  min-width: 200px;
}

.dropdown-close-btn {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.dropdown-overlay.enter-done {
  visibility: visible;
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.dropdown-overlay.bottomRight {
  left: 0;
  top: calc(100% + 4px);
  transform-origin: top left;
}

.dropdown-overlay.bottomLeft {
  right: 0;
  top: calc(100% + 4px);
  transform-origin: top right;
}

.dropdown-overlay.topLeft {
  right: 0;
  bottom: calc(100% + 4px);
  transform-origin: bottom right;
}

.dropdown-overlay.topCenter {
  left: 50%;
  bottom: calc(100% + 4px);
  transform-origin: bottom center;
  transform: translateX(-50%) scale3d(0.8, 0.8, 1);
}

.dropdown-overlay.topCenter.enter-done {
  transform: translateX(-50%) scale3d(1, 1, 1);
}
