.gh-spinner > * {
    animation: uk-spinner-rotate 1.4s linear infinite;
}

@keyframes uk-spinner-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(270deg);
    }
}

.gh-spinner > * > * {
    stroke-dasharray: 88px;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: uk-spinner-dash 1.4s ease-in-out infinite;
    stroke-width: 1;
    stroke-linecap: round;
}

@keyframes uk-spinner-dash {
    0% {
        stroke-dashoffset: 88px;
    }
    50% {
        stroke-dashoffset: 22px;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 88px;
        transform: rotate(450deg);
    }
}

.gh-animation-spinner-enter {
    opacity: 0;
}

.gh-animation-spinner-enter-active {
    opacity: 1;
    transition: opacity 250ms;
}

.gh-animation-spinner-exit {
    opacity: 1;
}

.gh-animation-spinner-exit-active {
    opacity: 0;
    transition: opacity 250ms;
}
