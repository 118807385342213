.gh-popup-type-modal-content {
    max-width: 900px;
    padding: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    animation: 0.3s modalAnimation ease-in-out;
    animation-duration: 300ms;
    position: relative;
}

.gh-popup-type-modal-overlay {
    background: rgba(0, 0, 0, 0.6);
}

.gh-modal-title {
    margin-top: 0;
    margin-bottom: 20px;
}

.gh-modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 5px;
    z-index: 1010;
    display: flex;
    color: #666
}

.gh-popup-type-modal-content {
    background: #fff;
    color: var(--gh-font-black);
    border-radius: initial;
    overflow-y: auto;
    max-height: 85vh;
}

@media only screen and (max-width: 768px) {
    .gh-popup-type-modal-content {
        width: 85%;
        max-width: 85vw;
        padding: 15px;
    }

    .gh-popup-type-modal-content .gh-modal-title {
        font-size: 18px;
    }
}


@keyframes modalAnimation {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

