.pipeline-view-tab .uk-form-label {
  font-weight: 500;
}

.pipeline-view-input-group > * {
  width: 100%;
}

.pipline-view-modal.uk-modal-body {
  padding: 14px 0 20px;
}

.pipeline-view-tab {
  padding: 26px 24px 0;
}

.pipeline-view-tab-action {
  margin-top: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: flex-end;
}

.pipline-view-tabs.gh-quote-tabs {
  margin-bottom: 0;
}

.pipline-view-tabs .gh-quote-tabs-tab {
  width: 142px;
  height: 54px;
}

.pipline-view-tabs .gh-quote-tabs-tab:first-of-type {
  margin-left: 24px;
}

.pipline-view-tabs .gh-quote-tabs-tab:not(:last-of-type) {
  margin-right: 4px;
}

.pipline-view-tabs .gh-quote-tabs-tab.active {
  background-color: #e7f2ef;
}

.pipeline-view-tab .pipeline-view-box:not(:last-of-type) {
  margin-bottom: 12px;
}

.pipeline-view-box .gh-request-area-box .uk-textarea {
  min-height: 82px;
}

.pipeline-view-select {
  background-color: var(--status-background-color) !important;
  color: var(--status-text-color) !important;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 5px;
}

.pipeline-view-select option {
  text-transform: uppercase;
  color: var(--option-text) !important;
  background-color: var(--options-background);
}

.pipelines-table .gh-marketplace-wrapper-tooltip {
  min-width: unset !important;
}

.pipeline-flag-button {
  min-width: unset !important;
  width: 33px;
}

.pipeline-view-item.--extra-margin-bottom{
    padding-top: 21px;
}

@media only screen and (min-width: 768px){
  .pipline-view-modal {
    max-width: 663px !important;
  }
}

@media only screen and (max-width: 768px){
    button.gh-pipeline-button-save{
      min-width: 145px;
      font-size: 16px;
      line-height: normal;
      padding: 16px 30px;
    }

    .pipeline-item{
        margin: 0 !important;
    }

    .pipeline-view-wrapper{
      margin-top: 0!important;
      margin-bottom: 0!important;
    }

    .pipeline-view-item{
        margin-bottom: 15px!important;
        margin-top: 0!important;
    }
    .pipeline-view-item.--extra-margin-bottom{
        padding-top: 0;
    }
}

@media only screen and (max-width: 375px){
  .pipline-view-tabs .gh-quote-tabs-tab {
    padding: 0 8px;
  }
  .pipline-view-tabs .gh-quote-tabs-tab:first-of-type {
    margin-left: 12px;
  }
}
