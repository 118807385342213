.gh-external-settings-sub-title {
    font-family: "rubik", Arial, Helvetica, sans-serif;
    font-weight: 500;
    color: #242f42;
    margin: 0;
    line-height: 1.4;
}


.gh-settings-checkbox-item {
    display: flex;
    align-items: center;
}

.gh-add-rule-btn {
    font-family: Rubik, sans-serif;
    text-transform: uppercase;
    color: #10b897;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
}

.gh-widget-settings-select{
    z-index: 10;
}
