.gh-group-operator {
    margin-bottom: 30px;
}

.gh-group-operator:last-child {
    margin-bottom: 0;
}

.gh-group-quotes {
    border-right: 1px solid #242e41;
    border-left: 1px solid #242e41;
    border-bottom: 1px solid #242e41;
}

.gh-group-quotes .gh-quote-item {
    margin-bottom: 30px;
}

.gh-group-quotes .gh-quote-item:last-child {
    margin-bottom:0;
}