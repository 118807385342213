.gh-quote-form {
  font-family: Rubik, sans-serif;
  overflow-x: hidden;
}

.title-item {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #242f42;
  margin: 0;
}

.gh-quote-form-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 27px 0 90px;
}

.gh-terms-conditions-wrapper .gh-uz-file-content {
  border: 1px solid #eeeeee;
}

.gh-request-gen-btns:last-of-type {
  padding: 0 15px;
}

/*Media */

@media (max-width: 576px) {
  .gh-request-gen-btns {
    width: 100%;
  }
  .gh-request-gen-btns:last-of-type {
    margin-top: 10px;
  }

  .gh-request-review-actions{
    flex-direction: column;
  }
}

.uk-accordion-content[aria-hidden="true"] {
  display: none;
}
