.gh-passengers {
  width: 100%;
}

.gh-passengers-info {
  display: flex;
  width: 100%;
}

.gh-passengers-info-item {
  flex: 1;
  padding: 0 30px;
  border-right: 1px dashed #d5d5d5;
}

.gh-passengers-info-item:first-child {
  padding-left: 0;
}

.gh-passengers-info-item:last-child {
  padding: 0 0 0 30px;
  border-right: none;
}

.gh-passengers-info-item .title-item {
  display: flex;
  min-height: 26px;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #242f42;
  /*margin-bottom: 8px;*/
  margin-bottom: 13px;
}

.gh-passengers-bottom-line {
  margin-top: 20px;
  display: flex;
}
.gh-left-auto {
  margin-left: auto;
}

.gh-passengers-same-as{
  display: flex;
  justify-content: flex-end;
}

/*Media */

@media (max-width: 767px) {
  .gh-passengers-info {
    flex-wrap: wrap;
  }

  .gh-passengers-info-item .title-item {
    justify-content: space-between;
  }

  .gh-passengers-info-item {
    flex: 1 100%;
    padding: 20px 0 10px;
    border-right: none;
    border-bottom: 1px solid #d5d5d5;
  }

  .gh-passengers-info-item:last-child {
    padding: 20px 0 0;
    border-bottom: none;
  }
}
