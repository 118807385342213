.gh-booking-private-notes {
  margin-top: 20px;
}

.gh-booking-private-notes-input {
  font-family: Rubik, san-serif;
  height: 49px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  color: #242f42;
  padding: 11px 16px;
  margin-top: 10px;
  height: 76px;
  resize: none;
  border-radius: 3px;
}

.gh-booking-private-notes-wrapper {
  display: flex;
}

.gh-booking-private-notes-buttons {
  display: none;
  margin-top: 10px;
}

.gh-booking-private-notes-buttons.visible {
  display: block;
}

.gh-booking-private-notes-buttons .gh-booking-info-bar-identifier-btn:first-child {
  margin-bottom: 5px;
}

.gh-booking-private-notes-buttons .gh-booking-info-bar-identifier-btn {
  width: 35px;
  height: 35px;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
}
