.gh-company-information-wrapper-chat {
  max-width: 180px;
  /*margin-bottom: 20px;*/
}

.gh-company-information-wrapper-chat .gh-quote-button {
  margin-bottom: 0;
}

.gh-company-information-wrapper-chat .gh-discussion-btn {
  justify-content: flex-start;
  font-weight: 400;
}

.gh-company-information-wrapper-chat .gh-discussion-btn_envelope {
  margin: 0 30px 0 14px;
}

.gh-company-information-content {
  display: flex;
  flex-wrap: wrap;
}

.gh-company-information-content-col {
  flex: 48%;
  margin-bottom: 20px;
}

.gh-company-information-content-col:nth-child(3),
.gh-company-information-content-col:nth-child(4) {
  margin-bottom: 0;
}

.gh-company-information-content-key {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #6d7481;
  margin-bottom: 10px;
}

.gh-company-information-content-key.link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #10b897;
  text-transform: initial;
}

.gh-company-information-content-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #242f42;
}

.gh-company-information-content-value .uk-company-link {
  color: #242f42;
}

.gh-company-information-content-value .uk-company-link:hover {
  color: #10b897;
}

/****************  Media  ******************/

@media (max-width: 768px) {
  .gh-company-information-content-key.link {
    font-size: 14px;
    letter-spacing: 0;
  }

  .gh-company-information-content-value {
    font-size: 14px;
  }
}
