/*===========Mobile===========*/
/* row and column for mobile is reversed*/

.gh-axis-row {
    display: flex;
    flex-direction: column;
    background: #F2F4F7;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
}

.gh-axis-row-highlighted {
    border: 1px solid #10B997;
    background: #E7F2EF;
}


.gh-axis-col {
    display: flex;
    margin-bottom: 8px;
}

.gh-axis-title {
    font-size: 12px;
    line-height: 24px;
    min-width: 80px;
    margin-right: 20px;
}

.gh-axis-value {
    line-height: 24px;
    color: #242E41;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
}

/*===========Axis line===========*/
.gh-axis-line-container {
    display: flex;
    align-items: center;
    height: 24px;
}

.gh-axis-line-label {
    color: #212121;
    font-size: 9px;
    font-weight: 600;
    line-height: 12px;
}

.gh-axis-line {
    display: flex;
    height: 6px;
    background: linear-gradient(270deg, #242F42 -0.84%, #10B998 100%);
    border-radius: 2px;
    position: relative;
    margin: 0 10px;
    width: 100%;
    max-width: 300px;
}

.gh-axis-line-disabled {
    background: #D8D8D8;
    margin: 0;
    max-width: calc(300px + 18px + 22px + 20px);
}

.gh-axis-line-point {
    position: absolute;
    left: 50%;
    top: -7px;
    width: 14px;
    height: 14px;
    background: #005BFF;
    border: 3px solid #fff;
    border-radius: 50%;
}

.gh-axis-line-disabled .gh-axis-line-point {
    display: none;
}

/*===========Axis Tag===========*/
.gh-axis-tag {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    border-radius: 4px;
    color: #fff;
    background: #10B897;
    padding: 4px 8px;
    width: max-content;
}

.gh-axis-tag-yellow {
    background: #FFC107;
    color: #000;
}

.gh-axis-tag-red {
    background: #E74C3C;
}

/*===========Axis helicopters===========*/
.gh-axis-helicopters {
    display: flex;
    flex-direction: column;
    line-height: 18px;
}

@media only screen and (min-width: 870px) {
    /*fix modal width*/
    .axis-modal-content {
        max-width: 1150px;
        width: 80%;
    }

    .gh-axis-row {
        flex-direction: row;
        justify-content: space-between;
    }

    .gh-axis-col {
        flex-direction: column;
        margin-bottom: 0;
        min-width: 100px;
    }

    .gh-axis-col-response-time{
        min-width: 85px;
        width: 9.09%;
    }

    .gh-axis-col-helicopter{
        width: 22%;
    }

    .gh-axis-col-line {
        max-width: 300px;
        width: 30%;
    }

    .gh-axis-col-operator,
    .gh-axis-col-status{
        width: 16%;
    }

    .gh-axis-col:not(:last-child) {
        margin-right: 15px;
    }

    .gh-axis-title {
        margin-right: 0;
    }

}
