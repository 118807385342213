.tooltip-container {
  line-height: 1;
}

.tooltip-container_content {
  background-color: #242f42;
  color: #fff;
  position: fixed;
  z-index: 999;
  font-family: "rubik";
  padding: 9px 11px 14px;
  font-weight: normal;
  max-width: 260px;
  opacity: 0;
  transition: 0.2s ease-out;
  transition-property: transform, opacity;
  pointer-events: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tooltip-container_content::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.tooltip-container_content[data-position="top-right"]::after,
.tooltip-container_content[data-position="top-left"]::after {
  right: 11px;
  bottom: -9px;
  border-style: solid;
  border-width: 15px 17px 0 16px;
  border-color: #242f42 transparent transparent transparent;
}

.tooltip-container_content[data-position="top-left"]::after {
  left: 11px;
}

.tooltip-container_content[data-position="top-right"]::after {
  right: 11px;
}

.tooltip-container_content[data-position="bottom-right"]::after,
.tooltip-container_content[data-position="bottom-left"]::after {
  right: 11px;
  top: -9px;
  border-style: solid;
  border-width: 0 15px 17px 16px;
  border-color: transparent transparent #242f42 transparent;
}

.tooltip-container_content[data-position="bottom-left"]::after {
  left: 11px;
}

.tooltip-container_content[data-position="bottom-right"]::after {
  right: 11px;
}

.tooltip-container:hover .tooltip-container_content {
  opacity: 1;
  pointer-events: all;
}

.tooltip-container_content[data-position="top-right"],
.tooltip-container_content[data-position="top-left"] {
  transform: translateY(-5px);
}

.tooltip-container_content[data-position="bottom-right"],
.tooltip-container_content[data-position="bottom-left"] {
  transform: translateY(5px);
}

.tooltip-container:hover .tooltip-container_content[data-position="top-right"],
.tooltip-container:hover .tooltip-container_content[data-position="top-left"],
.tooltip-container:hover .tooltip-container_content[data-position="bottom-left"],
.tooltip-container:hover .tooltip-container_content[data-position="bottom-right"] {
  transform: translateY(0);
}
