.gh-view-datetime {
    display: flex;
    flex: 1;
    padding: 20px 0;
}

.gh-view-datetime-icon {
    width: 30px;
    margin-right: 10px;
    padding-top: 4px;
}

.gh-view-datetime-icon img {
    width: 30px;
}

.gh-view-datetime-content {
    display: flex;
    flex-direction: column;
}

.gh-view-datetime-label {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #242f42;
}

.gh-view-datetime-value {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #242f42;
}

.gh-view-datetime-secondary-text {
    font-size: 14px;
    color: #6d7481;
    margin-left: 10px;
}

.gh-view-datetime-utc {
    margin-left: 10px;
}