.gh-back-link-nav {
  margin-bottom: 30px;
}

.gh-short-nav-title {
  color: #242f42;
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  margin: 30px 0 0;
}

a.gh-back-link-nav-title.uk-link-text {
  color: #242f42;
  transition: 0.15s ease-in;
  max-width: max-content;
}

a.gh-back-link-nav-title.uk-link-text:hover {
  color: #242f42b0;
}

@media (max-width: 768px) {
  .gh-short-nav-title {
    line-height: 35px;
  }
}
