.gh-marketplace-chase-button {
    font-size: 14px;
    font-weight: 400;
    min-width: initial;
    position: relative;
    text-align: left;
    white-space: nowrap;
}

.gh-marketplace-chase-button.mobile {
    display: none;
}

button.gh-marketplace-chase-button.gh-marketplace-chase-button {
    padding-right: 0;
}

.gh-marketplace-chase-button .uk-spinner {
    position: absolute;
    top: calc(50% - 8px);
    right: -21px;
    width: 16px;
    height: 16px;
    background: #fff;
    animation: fadein .1s ease-out;

}

.gh-chase-button-tooltip-icon {
    display: none;
}

@media only screen and (max-width: 959px) {
    .gh-marketplace-chase-button.desktop {
        display: none;
    }

    .gh-marketplace-chase-button.mobile {
        display: flex;
    }

    .gh-chase-button-tooltip-icon {
        display: block;
        padding: 10px;
    }

    .gh-chase-button-tooltip-trigger-wrapper {
        display: flex;
    }

    button.mobile.gh-marketplace-chase-button.gh-marketplace-chase-button {
        padding-right: 0;
    }

    .gh-mobile-hide {
        display: none;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

