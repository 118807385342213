.calculator__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 978;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  transition: 0.2s;
  opacity: 0;
}

.calculator__modal.visible {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.calculator__modal.visible .calculator__modal-backdrop {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.calculator__modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 979;
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  transition-property: opacity, visibility;
  transition-duration: 0.2s;
}

.calculator__modal-content {
  max-width: 600px;
  max-height: clamp(800px, 100vh, 90vh);
  width: 100%;
  background-color: #fff;
  z-index: 980;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 24px 30px;
}

.calculator__modal-title {
  color: #242f42;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.calculator__modal-title .close-btn {
  background-color: transparent;
}

.calculator__modal-title svg {
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.calculator__modal-title h3 {
  margin: 0 16px 0 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calculator__modal-bottom-radio-buttons {
  display: flex;
  justify-content: space-between;
}

.calculator__modal .calculator-submit-btn {
  width: 100%;
  margin-top: 15px;
}