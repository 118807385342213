.pricing-plan-form {
  margin-top: 28px;
}

.pricing-plan-form .uk-form-label {
  padding-left: 2px;
  font-weight: 500;
}

.pricing-plan-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 30px;
}

.pricing-plan-alert {
  padding: 30px 20px;
  border: 1px solid #242f42;
  color: #242f42;
  font-size: 14px;
  min-width: 280px;
  border-radius: 2px;
  top: calc(100% + 24px);
  z-index: 1021;
}

.pricing-plan-tooltip {
  width: 17px;
  transform: translate(6px, -2px);
}

.pricing-plan-divider {
  margin: 28px 0 24px;
  border-bottom: 1px solid #d5d5d5;
}

@media (max-width: 689px) {
  .pricing-addos .pricing-addos-title {
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: 16px;
    display: block;
  }
  .pricing-addos .pricing-addos-subtitle {
    display: none;
  }
  .pricing-plan-group {
    display: block;
  }
}
