.gh-operator-quote-details-wrapper {
}

.gh-operator-quote-details-main-details {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.gh-operator-quote-details-item-notes .gh-comment-section {
  padding: 10px;
}

.gh-operator-quote-details-item-notes {
  padding: 0 10px;
}

.gh-quote-divider {
  margin-top: 0px;
  margin-bottom: 30px;
  border-top: 1px solid #d5d5d5;
}

.gh-operator-quote-details-visuals-section {
  margin-bottom: 10px;
}

.gh-operator-quote-details-other-quotes {
  padding-bottom: 56px;
  max-width: 100%;
  overflow: hidden;
}

.gh-operator-quote-details-item-notes .gh-flight-item-note-form-textarea {
  height: 45px;
  padding-top: 8px;
}

.gh-operator-quote-details-item-notes .gh-request-block-title {
  color: #242f42;
  font-size: 14px;
  font-weight: 500;
}

.gh-operator-quote-details-item-notes {
  background: #fff;
  border-radius: 4px;
}

.gh-flight-notes-submit-button {
  margin: 0 10px 10px;
}

/*===========Typography===========*/
.gh-operator-quote-details-title {
  color: #242f42;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 30px;
  margin-top: 0;
}

.operator-private-notes {
  margin: 0 0 20px 10px;
}

.operator-private-notes h4 {
  font-size: 14px;
  margin-bottom: 0;
}

.operator-private-notes .inline-edit_wrapper {
  padding: 20px 15px;
  background: #f2f4f7;
}

@media only screen and (max-width: 425px) {
  .gh-quote-divider {
    display: none;
  }
}

@media print {
  .gh-quote-details-body .gh-alert-message  {
    display: none !important;
  }

  .gh-quote-details-body .gh-terms-conditions-text-view {
    display: none !important;
  }

  .gh-quote-details-body .gh-quote-details-buttons-wrapper {
    display: none !important;
  }

  .gh-quote-details-body .gh-quote-details-operator-comments {
    display: none !important;
  }
}