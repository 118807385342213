.gh-wrapper-custom-checkbox {
  display: flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  /*height: 24px;*/
}

.gh-wrapper-custom-checkbox .label {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  color: #242f42;
  padding-left: 10px;
  cursor: pointer;
}

.gh-wrapper-custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.gh-wrapper-custom-checkbox .gh-checkmark {
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background: #ffffff;
  border: 2px solid #6d7481;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 3px;
}

.gh-wrapper-custom-checkbox .gh-checkmark .check {
  display: none;
}

.gh-wrapper-custom-checkbox input:checked ~ .gh-checkmark .check {
  display: block;
}

.gh-wrapper-custom-checkbox input:checked ~ .gh-checkmark {
  background-color: #10b897;
  border-color: #10b897;
}

.gh-wrapper-custom-checkbox input:checked ~ .gh-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
