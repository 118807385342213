.gh-quote-tabs {
  display: flex;
  border-bottom: 2px solid #242f42;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.gh-quote-tabs-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #242f42;
  width: 172px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  margin-right: 30px;
  box-sizing: border-box;
  position: relative;
}

.gh-quote-tabs-tab .custom-tooltip-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.gh-quote-tabs-tab.disabled {
  background: #f7f7f7;
  color: #6d7481;
}

.gh-quote-tabs-tab.disabled:hover {
  cursor: not-allowed;
}

.gh-quote-tabs-tab:not(.disabled):hover {
  cursor: pointer;
  text-decoration: unset;
  opacity: 1;
  color: #242f42;
}

.gh-quote-tabs-tab.active {
  border: 2px solid #242f42;
  border-bottom: none;
  font-weight: 500;
}

.gh-quote-tabs-tab.active:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: -2px;
}

.gh-tooltip-disabled-tab .custom-tooltip-content {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.gh-quote-tabs-tab__tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 10;
}

/*Media */

@media (max-width: 576px) {
  .gh-quote-tabs-tab {
    margin-right: 20px;
    width: fit-content;
    height: 52px;
    font-size: 15px;
    padding: 0 15px;
    text-align: center;
    line-height: 18px;
  }
}
