.gh-quote-general-info-component {
  display: flex;
  align-items: center;
  padding: 0 20px 10px 20px;
  background: #ffffff;
  border-radius: 4px;
}

.gh-quote-general-info-component .gh-live-chat-wrapper .gh-quote-button {
  margin-bottom: 0;
}

.gh-quote-general-info-component .gh-live-chat-wrapper {
  width: 180px;
  margin-right: 25px;
  padding-top: 20px;
}

@media  (max-width: 1024px) {
  .gh-quote-general-info-component {
    flex-wrap: wrap;
  }
}

