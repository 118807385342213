.gh-wrapper-select {
  width: 100%;
}

.gh-custom-select,
.gh-custom-select:active,
.gh-custom-select:focus,
.gh-custom-select:disabled {
  cursor: pointer;
  background: #e7eaef;
  border-radius: 3px;
}

.gh-custom-select:disabled {
  cursor: not-allowed;
}

.gh-wrapper-select .gh-custom-select.uk-select {
  padding-right: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
