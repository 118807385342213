.mobile .mini-tab a,
.mobile .register-page button,
.mobile .gh-overlay-popup.full .overlay-footer button {
  min-width: inherit;
  font-size: 14px;
  line-height: inherit;
  padding: 5px 30px;
}

.mobile button.mob-btn {
  min-width: inherit;
  font-size: 14px;
  line-height: inherit;
  padding: 5px 15px;
  border: none;
}

.mobile .booking-layout .mini-tab a {
  padding: 5px 15px;
}

.mobile .gh-section-statistics .gh-count,
.mobile h1 {
  font-size: 1.5rem;
}

.mobile h3,
.mobile h2,
.mobile .tac-body h2 {
  font-size: 20px;
}

.mobile input,
.mobile .tac-body,
.mobile .booking-layout button {
  font-size: 14px;
}

.mobile .gh-section-statistics,
.mobile .gh-section-statistics a,
.mobile .gh-quote-detail span,
.mobile .small-font,
.mobile #payment-form button {
  font-size: 13px;
}

.mobile .header-title .uk-align-right {
  margin-top: -30px;
  margin-bottom: 20px;
  float: left;
  margin-left: 0;
}

.mobile .header-title .gh-align-left {
  margin-bottom: 0;
}

.mobile .uk-grid + .uk-grid,
.mobile .uk-grid-margin {
  margin-top: 15px;
}

.mobile .gh-status-account-summary li {
  line-height: inherit;
}

.mobile .gh-accordion .uk-accordion-content,
.mobile .gh-accordion-section .uk-accordion-title,
.mobile .gh-accordion-like-title {
  padding: 15px;
}

.mobile .uk-tab li:first-child,
.gh-mob .uk-tab li:first-child {
  padding-left: 22px;
}

.mobile .uk-tab li {
  padding-left: 18px;
}

.gh-mob .uk-tab li {
  padding-left: 36px;
}

.gh-mob-padding {
  padding: 15px;
}

.gh-mob-clear-padding {
  padding: 0;
}

.mobile .uk-tab li a,
.mobile .gh-notification {
  font-size: 13px;
}

.mobile .gh-quote-detail span {
  width: inherit;
}

.mobile label {
  font-size: 0.7rem;
}

.mobile .gh-text-bigger,
.gh-mob .gh-text-bigger {
  font-size: 18px;
}

.mobile .gh-notification label {
  font-size: 15px;
}

.mobile .quote-request-detail-wrapper label,
.gh-mob .quote-request-detail-wrapper label {
  min-width: 170px;
  display: inline-block;
}

.mobile .quote-request-detail-wrapper span,
.gh-mob .quote-request-detail-wrapper span {
  display: inline-block;
  margin-left: 15px;
}

.mobile .gh-mob-map-tab > div {
  width: 100%;
}

.mobile .gh-account-nav > li.uk-active > a:after {
  background-image: none;
}

.mobile .gh-mob-margin-remove-top {
  margin-top: 0;
}

.mobile .gh-mob-grid {
  font-size: 14px;
}

.mobile .gh-mob-grid li {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.mobile .gh-mob-grid li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
