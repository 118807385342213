.gh-quote-details-deposit-row {
    display: flex;
    /*padding: 10px 0;*/
    padding: 7px 0;
}

.gh-quote-details-deposit-value {
    /*flex-basis: 58%;*/
    margin-right: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #242F42;
}

.gh-quote-details-deposit-value.--no-deposit {
    display: flex;
    align-items: center;
}

.gh-quote-details-deposit-key {
    /*flex-basis: 42%;*/
    white-space: nowrap;
    font-weight: 500;
    font-size: 16px;
    color: #242E41;
}