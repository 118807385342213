/* Chrome, Safari, Edge, Opera */
.gh-price-input-wrapper input::-webkit-outer-spin-button,
.gh-price-input-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.gh-price-input-wrapper input[type="number"] {
  -moz-appearance: textfield;
}

.gh-price-input-wrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: 3px;
  width: 164px;
  height: 48px;
  margin-right: 12px;
}

.gh-price-input-wrapper.total {
  background: #e7eaef;
  padding-right: 5px;
}

.gh-price-input-wrapper input {
  background: #ffffff;
  border: 1px solid #6d7481;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100px;
  height: 35px;
}

.gh-price-input-wrapper .gh-input-number input:hover {
  cursor: text;
}


.gh-price-input-wrapper label,
.gh-price-input-wrapper input,
.gh-price-input-wrapper input::placeholder {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #242f42;
  /*margin-right: 14px;*/
}

.gh-price-input-wrapper.total input::placeholder {
  margin-right: 0;
  font-size: 16px;
}

.gh-price-input-wrapper label {
  margin-right: 19px;
  min-width: 32.86px;
}

.gh-price-input-wrapper.total label {
  font-size: 16px;
  margin-right: 14px;
}

.gh-price-input-wrapper input {
  text-align: end;
  padding-right: 8px;
}

.gh-price-input-wrapper.total input {
  font-size: 16px;
  background-color: #e7eaef;
  border-color: #e7eaef;
  transition: all 0.1s;
}

.gh-price-input-wrapper.total input:hover {
  border-color: #6d7481;
  cursor: text;
}

.gh-price-input-wrapper:hover {
  cursor: text;
}

.gh-price-input-wrapper.total input:focus,
.gh-price-input-wrapper input:focus,
.gh-price-input-wrapper.total input:focus-visible,
.gh-price-input-wrapper input:focus-visible {
  border: 1px solid #6d7481;
  outline: none;
  background-color: #fff;
}

.gh-price-input-wrapper input:hover {
  cursor: default;
}
