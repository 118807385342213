.gh-event-detail h2.gh-title-events {
  color: #1abc9c;
}

.gh-event-detail h3,
.gh-event-detail h3 > * {
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .gh-event-detail .gh-event-info-content img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
    margin-bottom: 10px;
  }
}

.gh-event-info-content {
  position: relative;
}

.gh-event-info-content.show-more {
  max-height: 1039px;
  overflow: hidden;
  position: relative;
}

.gh-event-info-content.gh-event-without-form {
  max-width: 1168px;
  margin-left: auto;
  margin-right: auto;
}

.back-section.gh-event-without-form {
  max-width: 1208px;
  margin-left: auto;
  margin-right: auto;
}

.gh-event-info-content.show-more::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 240px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.85) 60%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 1) 100%
  );
}

.gh-event-show-more-btn {
  position: absolute;
  z-index: 12;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  font-size: 18px;
  text-decoration: underline;
}
