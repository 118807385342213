.default-input-style {
  font-family: Rubik, sans-serif;
  background: #ffffff;
  border: 2px solid #6d7481;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  padding: 0 15px;
}

.default-input-style::placeholder {
  font-family: Rubik, sans-serif;
  color: #9a9ca1;
  font-size: 16px;
}

.gh-wrapper-autocomplete-input {
  position: relative;
  max-width: 480px;
}

.gh-wrapper-autocomplete-input .gh-leg-sub-title-location {
  position: absolute;
  right: 0;
  font-size: 13px;
  top: 50%;
  margin-top: -9px;
  margin-right: 10px;
}

.input-autocomplete-loader {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 13px;
  margin-right: 10px;
}

.gh-message-error {
  font-family: Rubik, sans-serif;
  font-size: 12px;
  color: #e74c3c;
}

.leg-form-label {
  display: flex;
  align-items: flex-end;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #242f42;
  margin: 5px 0 10px 0;
  text-transform: uppercase;
  min-height: 26px;
}
