html {
  background: transparent;
}

em {
  color: #ffffff;
}

a:hover {
  text-decoration: none;
  opacity: 0.9;
}

.uk-button-link:focus,
.uk-button-link:hover {
  text-decoration: none;
}

.uk-form-success:focus {
  color: #1abc9c;
  border-color: #1abc9c;
}

.uk-alert-success {
  background: rgba(26, 188, 156, 1);
  color: rgb(26, 188, 156);
}

.uk-label-success {
  background-color: #1abc9c;
}

.uk-alert-warning {
  color: #ffab00;
}

.uk-notification-message-success {
  color: #1abc9c;
}

.uk-text-success {
  color: #1abc9c !important;
}

.uk-input,
.uk-textarea {
  border: 2px solid #6d7481;
}

.w-400 {
  font-weight: 400 !important;
}

.w-500 {
  font-weight: 500 !important;
}

.w-600 {
  font-weight: 600 !important;
}

.w-700 {
  font-weight: 700 !important;
}

html,
body {
  color: #6d7481;
  font-family: "rubik", sans-serif;
}

section {
  position: relative;
}

span.reset-span {
  width: auto !important;
  box-sizing: inherit !important;
  flex: inherit !important;
}

b,
strong {
  font-weight: bold;
}

.uk-button {
  font-family: "rubik", sans-serif;
}

.gh-font-size {
  font-size: 16px;
}

.gh-font-size-big {
  font-size: 18px;
}

.disabled-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.gh-register-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.gh-register-btn {
  height: 54px;
}

.gh-register-buttons-first .uk-button {
  min-width: 155px;
}

.gh-register-buttons-second .uk-button {
  min-width: 152px;
}

.gh-single-field {
  width: 100%;
}

.gh-aoc-box {
  display: flex;
  justify-content: space-between;
}

.gh-operator-terms-btn {
  font-size: 14px;
}

.gh-terms-operator-wrapper {
  width: 223px !important;
  transform: translateY(-18px);
}

.gh-initial-display {
  display: initial;
  height: 100%;
  justify-content: flex-end;
}

.gh-margin-right-normal {
  margin-right: 20px;
}

.gh-margin-small {
  margin-right: 10px;
}

.gh-register-next {
  min-height: 48px;
}

.gh-register-next,
.gh-register-back {
  /*font-weight: 500;*/
}

.gh-register-back {
  border: 0;
  background: #dfe2ea;
}

.gh-register-back:hover {
  border: 0;
  background: #cdcfdb;
}

.gh-add-card-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  margin-top: 2px;
}

.gh-cords-component-add-new-btn {
  font-size: 14px;
  font-weight: 500;
}

.gh-margin-left-normal {
  margin-left: 20px;
}

.gh-register-text {
  color: #fff;
  border: 2px solid #1abc9c;
  border-radius: 4px;
  padding: 7px 15px;
  margin-bottom: 0;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.5);
  font-weight: 300;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: inherit;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #242f42;
}

.uk-input,
.uk-select,
.uk-textarea {
  color: #242f42 !important;
  font-family: "rubik", sans-serif !important;
}

.validation-errors {
  color: #e74c3c;
  font-size: 0.75em;
  position: relative !important;
}

.validation-errors .uk-alert {
  font-size: 0.9375em;
}

.validation-errors li {
  line-height: normal;
}

.validation-errors.hide {
  display: none;
}

.gh-register-tooltip svg {
  margin-bottom: 10px;
}

.validation-wrapper {
  position: relative;
  height: 88px;
}

.pointer {
  cursor: pointer;
}

.close,
.click {
  cursor: pointer;
  text-decoration: none;
}

.close:hover {
  text-decoration: underline;
}

.required {
  position: relative;
}

.gh-margin-small-left {
  margin-left: 10px;
}

.required::after {
  position: absolute;
  color: #e74c3c;
  content: "*";
  font-size: 20px;
}

.required-contact::after {
  position: relative;
  color: #e74c3c;
  content: "*";
  font-size: 20px;
  margin-left: 5px;
  top: 5px;
}

.gh-padding-remove-left {
  padding-left: 0;
}

.gh-extra-padding,
th.gh-extra-padding {
  padding-left: 30px;
  padding-right: 30px;
}

.gh-label-min {
  min-width: 120px;
}

.gh-position-center {
  margin-left: auto;
  margin-right: auto;
}

.gh-position-margin-left {
  margin-left: 20px;
  margin-right: auto;
}

.gh-decline-modal-content {
  width: 600px !important;
}

.gh-position-margin-right {
  margin-left: auto;
  margin-right: 20px;
}

.gh-position-absolute-right {
  right: 0;
}

.gh-quote-card {
  position: relative;
}

.gh-quote-card_amend-alert {
  padding: 3px 0;
  background-color: #ffebc1;
  color: #242f42;
  font-weight: 600;
  text-align: center;
  font-size: 13px;
  line-height: 27px;
}

.gh-quote-card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #5a657a;
  opacity: 0.9;
  z-index: 2;
  top: 0;
  left: 0;
}

.gh-quote-withdrawn {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.gh-quote-withdrawn .info {
  max-width: 242px;
  padding-left: 38px;
  z-index: 2;
  color: #ffffff;
}

.gh-quote-withdrawn .info .line {
  border-top: 2px solid #ffebc1;
}

.gh-quote-withdrawn .info .title {
  font-size: 20px;
  font-weight: 500;
}

.gh-quote-withdrawn .info .reason-label {
  text-transform: uppercase;
  font-size: 14px;
}

.gh-quote-withdrawn .info .reason-text {
  margin: 0;
  line-height: 1.5;
  font-size: 16px;
}

.gh-quote-withdrawn .badge {
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  transform: translate(23px, -23px);
  width: 125px;
}

.gh-quote-withdrawn .badge.--expired {
  width: 100px;
  transform: translate(4px, 0px);
}

.gh-condition-notification {
  line-height: 1.2;
  margin-bottom: 4px;
}

.gh-add-member-container {
  display: block;
  width: 100%;
  border: 1px dashed #6d7481;
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.gh-fleet-container {
  display: flex;
  justify-content: center;
  border: 1px dashed #6d7481;
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.gh-add-member-btn,
.gh-fleet-btn {
  color: #1abc9c;
  padding: 2px 0;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 45px;
  margin-top: 20px;
  border-radius: 4px;
  background: rgba(16, 184, 151, 0.1);
  transition: 0.5s;
}

.gh-fleet-btn,
.gh-fleet-btn:hover {
  margin-top: 0;
  text-decoration: none;
  max-width: 170px;
}

.gh-add-member-btn:hover {
  outline: none;
  text-decoration: none;
  color: #16ac8e;
}

.gh-clear-input {
  position: absolute;
  top: 16px;
  right: 22px;
  width: 17px;
  cursor: pointer;
}

.gh-member-mail {
  width: 100%;
  margin-right: 0;
  border: 2px solid #9da3ae !important;
  padding-right: 30px;
}

.gh-li-container {
  width: 100%;
}

.gh-list-card-item {
  padding: 0;
  position: relative;
}

.gh-lc-content {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 13px 11px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.gh-lc-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  width: 36px;
  height: 43px;
  background: #e2e5eb;
  color: #242f42;
  font-size: 12px;
  margin-right: 12px;
  border-radius: 4px;
}

.gh-lc-title {
  color: #242f42;
  font-size: 14px;
  width: 320px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gh-lc-close {
  position: absolute;
  top: 22px;
  right: 10px;
  width: 26px;
  height: 26px;
}

.gh-quote-withdrawn .badge .line .label {
  color: #242f42;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
}

.gh-quote-withdrawn .badge .line {
  background: #ffebc1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  transform: rotate(45deg);
}

.gh-complex-table-td {
  vertical-align: middle !important;
  color: #212121;
  font-weight: 400;
  font-size: 14px;
}

.uploaded-zone li,
.uploaded-zone li div,
.helli-list li div {
  position: relative;
}

.gh-verification-doc-upload {
  height: 100px;
  position: relative;
}

.reset-btn {
  background: none;
  border: none;
  z-index: 10;
}

.gh-account-nav li {
  position: relative;
  min-width: 150px;
  max-width: 300px;
}

.gh-account-nav > li.uk-active > a:after {
  right: 0;
}

.uk-nav-default > li > a {
  color: #6d7481;
}

.clear-paddings {
  padding: 0 0;
}

.gh-padding-micro-top {
  padding-top: 2px;
}

.gh-padding-plus-top {
  padding-top: 22px;
}

.gh-small-padding-top {
  padding-top: 20px;
}

.gh-medium-padding-left {
  padding-left: 30px;
}

.gh-count-pending {
  color: #ffb200;
}

.gh-margin-right-15 {
  margin-right: 0;
}

.gh-margin-bottom-0 {
  margin-bottom: 0;
}

.gh-margin-bottom-quote {
  padding-top: 13px;
}

.gh-margin-right-50 {
  margin-right: 50px;
}

.gh-margin-right-55 {
  margin-right: 55px;
}

/*ANIMATIONS*/

.gh-overlay-popup {
  position: fixed;
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.1);
  z-index: 100000;
  transition: all 0.5s ease;
}

.gh-overlay-popup.full.shown {
  height: 100vh;
}

.gh-overlay-popup.default.shown {
  position: relative;
  background: rgba(255, 255, 255, 1);
  height: 100%;
}

.gh-overlay-popup.default .nav {
  border: none;
}

.gh-overlay-popup.default .tac-body {
  overflow-y: auto;
  height: calc(100vh - 595px);
  min-height: 186px;
  position: relative;
  top: 1px;
  margin-bottom: 85px;
}

.gh-overlay-popup.full .tac-body {
  height: calc(100vh - 200px);
  overflow: auto;
  background: rgba(255, 255, 255, 1);
}

.overlay-footer .uk-button {
  margin: 10px 0;
}

.gh-btn-default-transparent {
  background-color: transparent;
  color: #fff;
}

.gh-filters-container {
  align-items: flex-end;
  flex-wrap: wrap;
}

.gh-filters-control {
  min-width: 160px;
  max-width: 300px;
}

.feedback-form .uk-button-primary {
  height: 54px;
}

.gh-filters-control.gh-appropriate-results-control {
  min-width: 380px;
  max-width: 460px;
  align-self: center;
}

.uk-select {
  background-color: #f0f0f0;
}

.uk-select:not([multiple]):not([size]) option {
  color: #6d7481;
}

/*ALERTS*/

.app-alert {
  z-index: 10001;
  position: absolute;
  width: 70%;
  padding: 20px;
  left: 15%;
  top: 3%;
  background-color: #e74c3c;
  color: #ffffff;
  border: 2px solid #000000;
  border-radius: 9px;
}

.app-alert header {
  text-align: right;
  display: block;
}

.uk-alert-default {
  background: transparent;
}

/*FileDrop*/

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

input.gh-padding-left-20 {
  padding-left: 20%;
}

.gh-feature-list-item-container {
  min-width: 300px !important;
  padding-top: 0;
}

.gh-feature-list-item-container .gh-child-full-height {
  height: inherit;
  height: -webkit-fill-available;
  height: -moz-fill-available;
  height: -fill-available;
}

.file-drop.file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  /* --------------- */
  /* a little transition sizzle */
  transition: all 150ms linear;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  opacity: 0;
  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  text-align: center;
  /* --------------- */
}

.file-drop.file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1;
  /* --------------- */
  /* typography */
  color: white;
  /* --------------- */
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #e74c3c;
  box-shadow: 0 0 13px 3px #e74c3c;
}

/* Ajax Spinner */

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100001;
  background-color: #6d7481;
  opacity: 0.5;
}

.loader {
  border: 5px solid #dfe2e8;
  border-top: 5px solid #6d7481;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader.default {
  width: 32px;
  height: 32px;
  top: 100px;
  right: 2%;
  position: absolute;
}

.loader.default.center {
  left: 50%;
  top: auto;
  margin-left: -16px;
}

.loader.default.center.middle {
  top: 50%;
  margin-top: -16px;
}

.loader.local {
  top: 50%;
  right: 50%;
  margin-right: -125px;
  margin-top: -14px;
  height: 20px;
  width: 20px;
  position: absolute;
}

.loader.large {
  border: 15px solid #dfe2e8;
  border-top: 15px solid #6d7481;
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -70px;
  margin-left: -70px;
}

.gh-autocomplete-wrapper {
  position: relative;
}

.gh-autocomplete-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  max-width: 45%;
}

.gh-autocomplete-info .info-item {
  font-size: 13px;
  color: #6d7481 !important;
}

.gh-autocomplete-info .info-item:not(:last-of-type)::after {
  content: ", ";
}

.gh-autocomplete-wrapper.show:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 5%;
  margin-left: auto;
  border: 2px solid #6d7481;
  border-top: 2px solid #333333;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  margin-top: -10px;
}

.gh-button-simple,
.gh-button-simple:hover {
  text-decoration: none;
}
.gh-button-simple:before {
  display: none;
}

.gh-button-filter {
  padding-right: 0;
  text-align: right;
}

.gh-button-filter:before {
  border-bottom: 0;
}

.gh-button-loader {
  position: relative;
}

.gh-button-loader.show {
  text-align: center;
  text-indent: -100000em;
}

.gh-button-loader.show::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -7px;
  border: 2px solid #6d7481;
  border-top: 2px solid #333333;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  margin-top: -10px;
}

.gh-button-loader.show.danger::after {
  border: 2px solid #ffffff;
  border-top: 2px solid #f0f0f0;
}

a.uk-active,
a.uk-active:focus,
.gh-account-nav > li a.uk-active {
  color: #1abc9c;
}

.uk-button {
  padding-bottom: 2px;
  padding-top: 2px;
}

.uk-button-default {
  padding-bottom: 0;
  padding-top: 0;
}

.uk-button-primary:disabled,
.uk-button-primary:disabled:hover {
  background-color: rgba(26, 188, 156, 0.25);
  color: #ffffff;
}

.uk-button-secondary {
  background-color: #ffffff;
  color: #6d7481;
  border-color: #6d7481;
  border-width: 2px;
  height: 54px;
}

.uk-button-secondary:hover,
.uk-button-secondary:focus,
.uk-button-secondary:active {
  border-color: #f0f0f0;
  background-color: #ffffff;
  color: #6d7481;
}

.gh-button-skip-active {
  padding: 0 30px;
}

.gh-buttons-summary {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.uk-button-secondary:disabled,
.uk-button-secondary:disabled:hover {
  background-color: #ffffff;
  color: #6d7481;
  border-color: #6d7481;
}

a.uk-remove,
.uk-text-danger {
  color: #e74c3c !important;
}

.mini-tab a.quote-quotes,
.mini-tab a.uk-active,
.mini-tab a.request-request-details {
  background-color: #1abc9c;
  color: #ffffff;
}

/* statuses */

.status-open {
  color: #6d7481;
}

.status-pending {
  color: #6fd3e6;
}

.uk-radio:checked,
.uk-radio:checked:focus {
  background-color: #1abc9c;
}

.status-unsucessful {
  color: #ffffff;
}

.status-close {
  color: #e74c3c;
}

.gh-text-medium {
  font-size: 18px;
}

.gh-text-normal {
  font-size: 16px;
}

/* HOME COMPONENT */

.gh-text-slim {
  font-weight: 400;
}

.gh-single-title {
  color: #242f42;
}

.gh-text-slimmer {
  font-weight: 300;
}

.gh-our-vision-box {
  position: relative;
  padding: 10px;
  z-index: 11;
  text-align: center;
}

@media (min-width: 786px) {
  .gh-our-vision-box {
    padding: 10px 40px;
  }
}

.gh-our-vision-bg {
  padding-top: 30px;
  background-color: #ffffff;
}

.gh-our-vision-bg .gh-vision-block {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gh-our-vision-bg .gh-vision-block-1 {
  margin-bottom: 44px;
}

.gh-our-vision-bg .gh-vision-block h1 {
  margin: 0;
  padding: 0;
}

.gh-home-marketplace-btn {
  margin: 30px 0 80px 0;
}

.gh-home-marketplace {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  text-align: center;
}

#gh-feature-list-grid {
  display: flex;
  flex-grow: 3;
}

.arrow {
  position: absolute;
  bottom: 9.5vh;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  cursor: pointer;
  height: 20px;
  /*background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);*/
  /*background-size: contain;*/
  /*background-repeat: no-repeat;*/
}

.hc-main-page {
  position: relative;
}

.gh-banner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #242f42;
  opacity: 0.2;
}

.gh-banner-inner,
.gh-home-static-content {
  z-index: 9999;
}

#homepage-title {
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.gh-hc-icon {
  height: 200px;
  width: 100%;
  margin-bottom: 25px;
}

.hc-subtitle-box-contact h3 {
  font-weight: 400;
  line-height: 1.56;
  font-size: 2.5vw;
}

.hc-subtitle-box h3 {
  color: #ffffff;
  margin: 0;
}

#homepage-subtitle {
  line-height: 1.56;
}

#homepage-description {
  line-height: 2.55;
}

.intro-bl {
  z-index: 20;
}

.gh-our-vision-padding {
  padding: 0 10% 0 8%;
}

.gh-our-vision-top {
  position: relative;
  top: 0;
  width: 100%;
  height: 7vh;
  z-index: 10;
  background-image: linear-gradient(rgba(0, 0, 0, 0.16), rgba(255, 255, 255, 0));
}

.gh-our-vision-title-box {
  height: 20%;
  display: flex;
  align-items: center;
}

.gh-our-vision-description-box {
  height: 20%;
  display: flex;
  align-items: center;
}

.gh-modal-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-modal-flex-direction {
  display: flex;
  flex-direction: row-reverse;
}

.gh-text-modal-promo {
  cursor: pointer;
  color: #6d7481;
}

.gh-account-settings-margin {
  margin-left: 105px;
  margin-right: auto;
}

.gh-account-not-width {
  margin-top: 20px;
  min-width: 200px;
  max-width: 850px;
}

.gh-page-line {
  border-top: 2px solid #dfe2e8;
}

.gh-divider {
  margin: 27px 0;
  border-top-color: #cfd4de;
}

.gh-text-modal-promo:hover {
  text-decoration: underline;
  color: #6d7481;
}

.gh-image-event img {
  width: 100%;
  height: auto;
}

.gh-margin-auto-left {
  margin-left: auto;
  margin-right: 185px;
}

.gh-outdated-event {
  opacity: 0.3;
}

.gh-internal-area {
  background: #fff;
  padding: 10px;
  transform: translateY(-5px);
  border-radius: 0 0 4px 4px;
}

.gh-our-vision-description {
  font-weight: 400;
  color: #6d7481;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.2px;
  margin-right: 30%;
  margin-top: 20px;
}

.gh-feature-list-title {
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #242f42;
}

.gh-no-messages {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.gh-message-body .gh-bubble-message-container {
  border-radius: 15px 15px 15px 0;
}

.gh-message-body.second-role-body .gh-bubble-message-container {
  border-radius: 15px 15px 0 15px;
}

.gh-bubble-message-container {
  min-width: 240px;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fae0b8;
  padding: 15px;
  font-weight: 600;
  position: relative;
}

.second-role-body .gh-bubble-message-container {
  float: right;
}

.gh-first-message {
  justify-content: flex-start !important;
  border-radius: 15px 15px 15px 0;
  font-size: 14px;
}

.gh-bubble-message .gh-system-role {
  margin-bottom: 5px;
}

.gh-amended-time {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #a9a9a9;
  direction: ltr;
}

#confirm-booking-modal a {
  padding: 0 !important;
}

.gh-margin-responsive-top {
  margin-top: 10%;
}

.gh-margin-left-10 {
  margin-left: 10px;
}

.gh-margin-left-medium {
  margin-left: 25px;
}

.gh-margin-left-20 {
  margin-left: 20px;
}

.gh-margin-top-20 {
  margin-top: 20px;
}

.gh-margin-top-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.gh-padding-medium-left-s {
  padding-left: 0;
}

.gh-padding-medium-left {
  padding-left: 30px;
}

.gh-padding-medium {
  padding: 35px;
}

.gh-discussion-container-empty {
  display: none;
}

.gh-envelope-icon-simple {
  width: 100%;
}

.gh-envelope-span {
  width: 25px;
  margin-left: 27px;
}

.gh-action {
  display: flex;
  justify-content: center;
}

.gh-envelope-icon {
  width: 23px;
  margin-right: 10px;
  transform: translateY(-3px);
  animation: envelopeAnimation infinite;
  animation-duration: 2s;
}

.gh-envelope-icon-broker {
  width: 20px;
  margin-right: 10px;
  position: relative;
}

.gh-discussion-btn_envelope {
  line-height: 50px;
  position: relative;
  margin-right: 7px;
}

.gh-discussion-btn_envelope .gh-envelope-icon-broker {
  width: unset;
  margin: 0;
}

.gh-discussion-btn_envelope .messages-count {
  position: absolute;
  right: -4px;
  top: 10px;
  border-radius: 2px;
  background-color: #e74c3c;
  font-size: 10px;
  font-weight: 600;
  width: fit-content;
  padding: 1px 2px;
  height: 11px;
  line-height: 1;
  color: #fff;
  min-width: 9px;
}

.gh-link-icon {
  margin-left: 10px;
  width: 14px;
  transform: translateY(-1px);
}

@keyframes envelopeAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.gh-discussion-container {
  min-height: 265px;
  max-height: 225px;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 10px;
  overflow-y: scroll;
  margin-top: 10px;
}

.gh-discussion-container .gh-message-body {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 31px;
  min-height: 67px;
}

.gh-discussion-container .gh-message-body::after {
  content: "";
  clear: both;
  display: table;
}

.second-role-body .gh-message-header {
  color: #ffffff;
}

.second-role-body .gh-message-box.doc .gh-message-header {
  color: #6d7481;
}

.second-role-body .gh-message-header .gh-message-header-posted {
  direction: ltr;
  transform: translateY(1px);
}

.second-role-body .gh-message-header-posted {
  margin-left: 0;
}

.second-role-body:after {
  content: "";
  clear: both;
  display: table;
}

.second-role-body .gh-message-box {
  background: #4278d8 !important;
  color: #ffffff !important;
  float: right;
  border-radius: 8px 8px 0 8px;
}

.second-role-body .gh-message-box.doc {
  background-color: #ffffff !important;
  border: 1px solid #e5e5e5;
  color: #6d7481 !important;
}

.gh-message-box {
  background: rgba(26, 188, 156, 0.3);
  padding: 10px;
  border-radius: 8px 8px 8px 0;
  float: left;
  position: relative;
  min-width: 260px;
  width: fit-content;
}

.gh-message-box.doc {
  background-color: #ffffff !important;
  border: 1px solid #e5e5e5;
  color: #6d7481 !important;
}

.gh-discussion-container .gh-message-body .gh-message-header {
  display: flex;
  margin-bottom: 5px;
}

.gh-discussion-container .gh-message-body .gh-message-header .gh-message-header-user,
.gh-system-role {
  font-family: "rubik", sans-serif;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.gh-discussion-container .gh-message-body .gh-message-header .gh-message-header-posted {
  line-height: 16px;
}

.gh-discussion-container .gh-message-body .gh-message-text {
  font-family: "rubik", sans-serif;
  font-size: 14px;
}

.gh-discussion-container .gh-message-body .gh-message-text.doc {
  cursor: pointer;
}

.gh-hr-notifications {
  border-top: 2px solid #dfe2e8;
}

.gh-margin-medium-top {
  margin-top: 20px !important;
}

.gh-margin-medium-y {
  margin: 20px 0 !important;
}

.gh-margin-medium-bottom {
  margin-bottom: 20px !important;
}

.gh-border-radius-4 {
  border-radius: 4px;
}

.gh-container {
  margin: auto auto 0 auto;
  max-width: 1440px;
}

.gh-modal-footer {
  border-top: none;
}

.gh-feature-list-wrapper {
  margin: auto auto 0 auto;
  max-width: 1440px;
}

#gh-role-title {
  font-size: 2.5rem;
}

#gh-role-item {
  font-size: 1.8rem;
}

.info-block {
  margin: auto !important;
}

.info-block .uk-section {
  padding: 15px 35px;
}

.info-block:nth-child(2) .uk-section {
  padding-bottom: 15px;
}

.info-block .gh-info-block-box a.el-link {
  display: block;
}

.gh-title {
  color: #10b897;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  line-height: 1.2;
  font-size: 20px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
}

.gh-event-card-header {
  display: flex;
}

.gh-pe-btn {
  padding: 10px 20px;
  margin-right: 7.4%;
  min-width: 226px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.gh-event-card-header .gh-title {
  margin-bottom: 0;
}

.button-return-label {
  font-size: 24px;
}

/*/ EVENTS/*/

.gh-city-event-title {
  font-size: 18px;
}

.gh-city-event-title,
.gh-title-events {
  color: #242f42;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  line-height: inherit;
}

.gh-event-title {
  display: block;
  font-size: 36px;
  color: #242f42;
  font-weight: 400;
  margin: 40px 0 20px 0 !important;
}

.gh-subtitle-text {
  font-size: 16px;
  color: #6d7481;
  line-height: 2;
}

.gh-event-list-label {
  display: block;
  color: #6d7481;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}

.gh-event-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.gh-event-card-meta {
  color: #1abc9c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  gap: 5px;
  margin-bottom: 8px;
}

.gh-event-card-meta .gh-event-icon {
  line-height: 1;
}

.gh-event-card-meta img {
  width: 18px;
}

.gh-future-dates {
  font-weight: 500;
  color: #242f42;
  line-height: 1.1;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 10px;
  background: #e1fff9;
  padding: 7px;
  border-radius: 4px;
  border: 1px solid #10b897;
  text-align: center;
  text-transform: uppercase;
  max-width: 210px;
}

.gh-event-dates-inside {
  display: flex;
}

.gh-event-card .uk-card-default {
  box-shadow: none !important;
  border-radius: 1px;
  border: 1px solid transparent;
  transition: 0.15s ease-in;
}

.gh-event-card .uk-card-default {
  border-radius: 2px;
}

.gh-event-card .uk-card-default.with-border {
  border-color: #1abc9c;
}

.info-block .back-section {
  padding: 15px 35px 0 35px !important;
}

.gh-event-detail .uk-section {
  padding: 15px 0 !important;
}

.gh-event-big-text {
  color: #6d7480;
  font-size: 18px;
  font-weight: 500;
}

.gh-event-small-text {
  color: #6d7481;
  font-size: 14px;
  font-family: "rubik", sans-serif;
  font-weight: 400;
}

.gh-open-enquire-btn {
  height: 50px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.gh-grid-margin-small-top {
  margin-top: 10px !important;
}

.gh-grid-margin-small-bottom {
  margin-bottom: 10px !important;
}

.gh-grid-margin-normal-top {
  margin-top: 30px;
}

.gh-info-description {
  font-weight: 400;
  color: #242f42;
  line-height: 1.1;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.gh-button-link,
.gh-button-link:visited {
  display: block;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  padding: 0 10px;
  width: fit-content;
  color: #1abc9c;
  border-color: #1abc9c;
}

.gh-button-link:hover,
.gh-button-link:active {
  background: #1abc9c !important;
  border-color: #1abc9c !important;
  text-decoration: none;
  color: #ffffff;
}

.gh-gray-warning {
  padding: 15px;
  background: #f3f4f7;
  color: #242f42;
}

.helicopters-list .gh-gray-warning {
  margin: 0 10px;
}

.gh-angle {
  width: 6px;
  margin-right: 10px;
}

.gh-enquire-actions {
  display: flex;
  padding: 20px 0 10px;
  margin: 0 -5px;
}

.gh-enquire-before-actions {
  margin: 20px 15px 20px 10px;
}

.gh-enquire-actions .gh-main-button-component {
  margin: 0 5px;
  width: 100%;
}

.gh-enquire-actions-wide {
  align-items: center;
  padding: 20px 0 !important;
}

.gh-enquire-actions .gh-button-default {
  color: #242f42;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #e6eaee;
  border-color: #e6eaee;
  height: 50px;
}

.info-block hr {
  height: 1px;
  background: #e6e6e6;
  margin-top: 25px;
  border: 0;
}

.gh-steps {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gh-steps:before {
  content: "";
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 90%;
  background: #979797;
  z-index: 2;
  margin: 0 0 18px 3px;
}

.gh-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gh-step-num-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #ffffff;
  z-index: 2;
}

.gh-step-num {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f4f7;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.gh-step-title,
.gh-step-num {
  font-size: 14px;
  color: #242f42;
  font-weight: 400;
  font-family: "rubik", sans-serif;
}

.gh-step-current {
  background: #10b897;
  color: #ffffff;
}

.gh-step-passed {
  background: #ffffff;
}

.gh-enquire-title {
  font-size: 18px;
  color: #242f42;
  font-weight: 500;
}

.gh-enquire-content {
  padding: 5px;
}

.gh-enquire .uk-input,
.gh-enquire .uk-textarea {
  border-color: #9da3ae;
}

.gh-enquire .uk-form-label {
  font-size: 13px;
  font-weight: 500;
  color: #242E41;
  margin: 5px 0 10px 0;
  display: block;
  font-family: "rubik", sans-serif;
}

.gh-enquire .required-contact::after {
  font-size: 15px;
  top: 1px;
}

.enquire-time-icon .uk-form-icon {
  display: none;
}

.enquire-form-content,
.enquire-additional-content {
  margin-top: 20px;
}

.enquire-form-content .gh-number-field {
  padding-left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.enquire-select-pax .uk-input {
  border: 0;
  background: #e7eaef;
  color: #6d7481 !important;
  font-family: "rubik", sans-serif;
  padding-left: 17px;
}

.enquire-select-pax .gh-number-field {
  position: relative;
}

.enquire-select-pax .gh-number-field:before,
.event-time .gh-time-select:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  right: 14px;
  top: 17px;
  border-bottom: 2px solid #6d7481;
  border-left: 2px solid #6d7481;
  z-index: 4;
}

.gh-time-format,
.event-time select {
  font-family: "rubik", sans-serif;
  font-size: 16px;
}

.gh-time-format {
  position: absolute;
  color: #6d7481;
  left: 70px;
  top: 12px;
  z-index: 4;
}

.event-time .gh-time-select {
  position: relative;
  width: 100%;
  height: 100%;
}

.event-time select {
  width: 100%;
  height: 48px;
  border: 0;
  background: #e7eaef;
  padding-left: 17px;
  color: #6d7481;
  border-radius: 4px;
  -webkit-appearance: none;
}

.event-time select:focus,
.event-time select:active {
  border: 0;
  outline: none;
}

.gh-one-way .uk-form-label,
.gh-one-way select,
.gh-one-way .gh-time-input,
.gh-one-way .gh-time-format,
.event-time .gh-one-way .gh-time-select:before {
  opacity: 0.3;
  transition: 0.4s;
}

.enquire-form-content .gh-counter {
  padding: 0;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #9da3ae !important;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 500;
    color: #242f42;
}

.enquire-form-content .gh-spinner-increase svg rect:last-child {
  height: 2px;
}

.enquire-form-content .gh-spinner-increase svg rect:first-child {
  width: 2px;
}

.enquire-form-content .gh-spinner-decrease svg rect {
  height: 2px;
}

.gh-enquire-calendar .rdtOpen .rdtPicker {
  width: 97%;
  margin-top: 5px;
}

.gh-enquire-calendar {
  margin-top: 25px;
}

.gh-enquire-calendar.--enquire-form {
    margin-top: 0;
}

.gh-enquire-calendar.--enquire-form .rdt {
    border: 2px solid #9da3ae;
    border-radius: 4px;
}

.gh-enquire-calendar.--enquire-form .rdt input.uk-input.gh-calendar-input {
    font-size: 14px;
}

.gh-enquire-calendar.--enquire-form .rdt.rdtOpen input.uk-input.gh-calendar-input {
    -webkit-text-fill-color: #FFFFFF;
}

.gh-time-enquire-form .rdt {
    border: 2px solid #9da3ae;
    border-radius: 4px;
}

.enquire-form-content .gh-time-input {
    border: none !important;
    font-size: 14px;
}

.gh-time-enquire-form .rdtPicker {
    width: 92px;
    right: 0;
    margin-right: -4px;
}

.gh-enquire-calendar .rdtPicker {
  background: #6d7481;
  color: #ffffff !important;
  border: solid 1px #6d7481;
  border-radius: 3px;
}

.gh-enquire-calendar .rdtPicker th {
  color: #ffffff !important;
  border-bottom: 0;
}

.gh-enquire-calendar .rdtPicker th:hover,
.gh-enquire-calendar .rdtPicker td:hover {
  color: #242f42 !important;
}

.gh-enquire-calendar .rdtPicker .rdtPrev,
.gh-enquire-calendar .rdtPicker .rdtNext {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.gh-enquire-calendar .rdtPicker td.rdtActive {
  background: #ffffff;
  color: #242f42;
}

.gh-enquire-calendar .rdtPicker td.rdtActive:hover {
  background: #242f42;
  color: #ffffff !important;
}

.gh-switcher {
  display: flex;
  border-radius: 3px;
  border: 2px solid #9da3ae;
  padding: 4px;
}

.gh-switcher-checked {
  margin-right: 2px;
  width: 19px;
}

.gh-switcher .uk-button-primary {
  background: #6d7481 !important;
}

.enquire-form .gh-switcher .uk-button-primary {
    background: #9da3ae !important;
}

.enquire-form .autocomplete-dropdown-container {
    min-width: auto !important;
}

.gh-switcher button {
  height: 42px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 0 !important;
  width: calc(100% - 10px);
  min-width: 0 !important;
}

.gh-switcher .uk-button-default {
  border: 0;
  color: #242f42;
}

.gh-switcher .uk-button-default:active,
.gh-switcher .uk-button-default:focus {
  border: 0;
}

.enquire-form-content button {
  font-weight: 500;
  height: 50px;
}

.gh-tooltip {
  position: relative;
  transform: translateY(-3px);
  z-index: 99;
}

.disabled .gh-tooltip-box {
  display: none !important;
}

.gh-tooltip:hover .gh-tooltip-box {
  display: block;
}

.top-left .gh-tooltip-box:before {
  content: "";
  position: absolute;
  bottom: -9px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #242f42;
  transform: rotate(180deg);
}

.bottom-left .gh-tooltip-box:before {
  content: "";
  position: absolute;
  top: -9px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #242f42;
}

.bottom-left .gh-tooltip-box {
  top: 25px;
  left: 0;
}

.enquire-tooltip .gh-tooltip-box {
  top: 35px;
  right: -6px !important;
  left: initial;
}

.bottom-center .gh-tooltip-box {
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 12px);
}

.bottom-center .gh-tooltip-box::before {
  content: "";
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #242f42;
}

.bottom-center .gh-tooltip-box {
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 12px);
}

.bottom-center .gh-tooltip-box::before {
  content: "";
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #242f42;
}

.top-right .gh-tooltip-box {
  transform: translate(-6px, -88px);
}

.top-right .gh-tooltip-box:before {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 6px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #242f42;
  transform: rotate(180deg);
}

.bottom-right .gh-tooltip-box {
  transform: translate(-6px, 14px);
}

.bottom-right .gh-tooltip-box:before {
  content: "";
  position: absolute;
  top: -9px;
  left: 6px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #242f42;
}
.gh-tooltip-box {
  display: none;
  text-transform: initial !important;
  position: absolute;
  width: 276px;
  padding: 10px;
  color: #ffffff !important;
  background: #242f42;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: "rubik", sans-serif;
  z-index: 4;
  text-align: left;
}

.gh-tooltip-box p,
.gh-tooltip-box strong,
.gh-tooltip-box span {
  color: #ffffff !important;
}

.gh-grid-title-progress .bottom-left .gh-tooltip-box {
  transform: translate(-265px, 14px);
}

.gh-notes-tooltip {
  transform: translateY(-5px);
}
.enquire-form-content .gh-time-input {
  border-radius: 3px;
  border: 2px solid #9da3ae;
  text-align: center;
  font-weight: 500;
}

.enquire-form-content .gh-enquire-calendar-icon {
  left: 17px;
  top: 15px;
}
.gh-enquire-calendar-icon {
  width: 22px;
  height: 22px;
  z-index: 2;
  position: absolute;
}

.enquire-form-content .gh-calendar-input {
  border: 0;
  padding: 0;
  text-align: center;
  color: #6d7481 !important;
  font-weight: 500;
}

.gh-enquire-calendar .rdt {
  border: solid 2px #9da3ae;
  border-radius: 3px;
}

.gh-enquire-calendar .rdtOpen {
  border: 2px solid #242f42;
}

.gh-enquire-calendar .rdtOpen .gh-calendar-input {
  background: #242f42;
  border-radius: 0;
  color: #ffffff !important;
}

.gh-enquire-calendar .rdtOpen .gh-calendar-input::placeholder {
  color: #ffffff;
}

.helicopters-list-content {
  overflow-y: scroll;
}

.helicopters-list-content::-webkit-scrollbar {
  width: 6px;
}

.helicopters-list-content::-webkit-scrollbar-thumb {
  width: 2px;
  background: #d8d8d8;
  border: solid 1px #979797;
}

.helicopters-list-content::-webkit-scrollbar-track {
  background: transparent;
}

.gh-heli-card-img {
  width: 100%;
  height: 100%;
}

.gh-animated-label {
  animation: labelInfoAnim 3s infinite;
}

@keyframes labelInfoAnim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.gh-heli-card-content {
  padding: 20px;
  background: #f2f4f7;
}

.gh-heli-card-content .gh-main-button-component {
  width: 100%;
  margin-top: 15px;
  height: 50px;
}

.heli-item-btn {
  width: 100%;
  height: 40px;
  font-weight: 500;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heli-item-label {
  color: #6d7481;
}

.heli-item-text {
  color: #242f42;
}

.heli-item-time {
  color: #242f42;
  display: inline-flex;
}

.gh-animated-description {
  animation: animatedDescription 0.5s ease-in;
  display: block;
  width: 100%;
}

.gh-animated-description span.solid {
  font-weight: 500;
  opacity: 0.95;
}

.gh-animated-description p {
  margin: 0;
}
@keyframes animatedDescription {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.heli-item-label,
.heli-item-text {
  font-size: 13px;
  font-family: "rubik", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.enquire-additional .uk-textarea {
  height: 191px;
}

.gh-enquire-submit {
  height: 50px;
  font-weight: 500;
  margin-left: 20px;
}

.enquiry-passed {
  margin: 30px 0;
}

.enquiry-passed-info {
  text-align: center;
  color: #242f42;
  display: flex;
  justify-content: center;
}

.ep-mid {
  text-align: center;
  padding: 20px 0;
}

.enquiry-passed-btn,
.enquiry-passed-info {
  font-size: 18px;
  font-weight: 500;
}

.enquiry-passed-btn {
  color: #10b897;
  margin-right: 3px;
  padding: 0;
}

.enquiry-passed-btn:hover {
  text-decoration: none;
}

.gh-enquire-content .disabled {
  user-focus: ignore;
  user-input: disabled;
  user-select: none;
  user-modify: read-only;
  opacity: 0.5;
}

#enquire-form .uk-modal-body {
  padding: 10px;
}

.enquire-form input {
  color: #242f42 !important;
}

.uk-input {
  color: #242f42;
  -webkit-text-fill-color: #242f42;
  opacity: 1;
}

.uk-input::placeholder {
  opacity: 0.7;
}

/*/ EVENTS-INFO/*/

.button-gradient {
  color: #ffffff;
  border: 0;
  border-radius: 8px;
  letter-spacing: 1px;
  padding: 0 20px;
  max-width: 120px;
  background: linear-gradient(
    180deg,
    rgba(0, 112, 79, 1) 0%,
    rgba(50, 214, 169, 1) 22%,
    rgba(0, 150, 107, 1) 49%,
    rgba(43, 189, 149, 1) 77%,
    rgba(0, 112, 79, 1) 100%
  );
}

.black-color {
  color: #171717;
}

.max_height_img {
  width: 100%;
  height: 100%;
  max-height: 490px;
  object-fit: cover;
}

.gh-image-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gh-image-box-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
  border: 1px solid #d5d5d5;
  color: #242f42;
}

.gh-image-box-icon {
  width: 20px;
  margin-right: 10px;
  transform: translateY(-4px);
}

.gh-padding-normal {
  padding: 20px;
}

.button-return {
  padding: 0 15px;
  min-width: 0;
  background: #f0f0f0;
  border: 2px solid #a9b1c1;
}

.button-return:hover,
.button-return:focus {
  border: 2px solid #e1e3e8;
}

.button-return:active {
  border: 1px solid #646464 !important;
}

.info-block .gh-content {
  line-height: 1.67;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.gh-text-color-link {
  color: #00b8d9;
}

.gh-text-color {
  color: #6d7481;
  /*font-family: Rubik;*/
}

.gh-text-white {
  color: #ffffff;
}

#subscribe {
  flex-direction: row;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 3s infinite;
  animation-iteration-count: 3;
}

.gh-side-menu-inner .uk-nav .gh-auth-btn {
  background: transparent;
  color: #1abc9c !important;
  border-color: #1abc9c !important;
}

.uk-navbar-right .gh-auth-btn {
  background: transparent;
  color: #1abc9c;
  border-color: #1abc9c !important;
  text-transform: uppercase;
  font-size: 15px;
}

.uk-navbar-right .gh-auth-btn:hover {
  color: #ffffff;
  background-color: #1abc9c !important;
}

.gh-btn-sign-up {
  border: 2px solid #1abc9c;
}

.gh-btn-sign-in {
  border: 2px solid #ffffff;
  width: 170px;
}

.gh-btn-sign-in:hover {
  color: #242f42;
}

.gh-btn-normal {
  background-color: transparent;
  border: 2px solid #ccc;
}

.gh-btn-black {
  background-color: #242f42;
  color: white;
}

.gh-btn-normal:hover {
  border: 2px solid #6d7481;
}

.gh-btn-black:hover {
  background-color: #242f42;
}

.gh-navlink-title {
  font-size: 16px !important;
  color: white !important;
  text-decoration: none !important;
  padding: 0 15px;
}

.gh-nav .gh-list-item.gh-navlink-title ul li ul li a {
  color: #242f42;
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gh-accordion .gh-accordion-title,
.gh-accordion .gh-accordion-content,
.gh-padding {
  padding: 15px;
}

.gh-padding-small-top {
  padding-top: 15px;
}

.gh-padding-small {
  padding: 10px;
}

.gh-padding-ex-small-top {
  padding-top: 10px !important;
}

.gh-margin-normal-bottom {
  margin-bottom: 30px;
}

.gh-margin-small-bottom {
  margin-bottom: 20px;
}

.gh-padding-small-bottom {
  padding-bottom: 7px;
}

.gh-padding-small-right {
  padding-right: 15px;
}

.gh-padding-small-left {
  padding-left: 15px;
}

.gh-padding-smaller-left {
  padding-left: 10px;
}

.gh-padding-bottom-2 {
  padding-bottom: 2px;
}

.gh-padding-top-3 {
  padding-top: 3px;
}

.gh-padding-large-top {
  padding-top: 40px;
}

.gh-padding-bottom-50 {
  padding-bottom: 50px;
}

.gh-accordion .gh-accordion-section .gh-accordion-title::after {
  content: "";
  background-image: none;
}

.gh-radio-wrapper input {
  position: absolute !important;
}

.gh-radio-wrapper-with-label input {
  display: none;
}

.gh-radio-wrapper-with-label label {
  line-height: 25px !important;
}

.gh-home-radio-group .gh-radio-wrapper-with-label label {
  padding-left: 35px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  font-size: 1.5rem;
}

.gh-radio-wrapper-with-label label b {
  color: #1abc9c;
}

.gh-radio-wrapper label::before {
  top: -21px !important;
  left: -18px !important;
}

.gh-radio-wrapper label::after {
  top: -16px !important;
  left: -13px !important;
}

.gh-term-condition-button-wrapper {
  margin: 15px auto;
  width: 100% !important;
  display: block;
}

.gh-invitation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gh-term-condition-txt {
  line-height: 36px;
}

.gh-term-arrow {
  line-height: 34px;
}

.gh-req-quotes {
  color: #ffab00 !important;
}

.gh-req-button {
  background-color: rgba(255, 171, 0, 1);
  color: #fff;
  max-width: 200px;
}

.gh-btn-max-width-200 {
  max-width: 200px;
}

.gh-req-button:hover {
  background-color: rgba(255, 171, 0, 0.8);
  color: #fff;
}

.gh-req-button:disabled {
  background-color: #f0f0f0;
  color: #dfe2e8;
}

.gh-welcome-message {
  position: absolute;
  top: 0;
}

.gh-counter {
  display: inline-block;
  padding: 2px 7px;
  background-color: #ffffff;
  border: 1px solid #6d7481;
  border-radius: 5px;
  margin: 0 5px;
  width: 20px;
  text-align: center;
}

#wallet {
  font-size: 0.875em;
  color: #6d7481;
  border: solid 1px #dfe2e8;
  padding: 20px;
  position: relative;
}

.wallet-row-default {
  display: block;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background: #ffffff;
}

.wallet-row-default_manual {
  display: flex;
  min-height: 120px;
}

.wallet-row-default:last-child {
  margin-bottom: 0;
}

.wallet-card {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.selected-card {
  background: #f2f4f7;
}

.wallet-default-button {
  color: #10b897 !important;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.wallet-row-default .wallet-row-text {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  color: #242f42;
  font-weight: normal;
}

.wallet-row-default .wallet-row-label {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  color: #242f42;
  font-weight: 500;
  min-width: 130px;
  display: inline-block;
}

.wallet-row-default .wallet-radio input {
  position: relative !important;
}

.wallet-row-expired {
  background: #cccfd3;
}

.wallet-row-expired-text {
  color: red;
  font-weight: bold;
}

.wallet-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
}

.card-loader {
  position: relative;
  top: -10px;
}

.upload-zone {
  position: relative;
  height: 240px;
  background-color: #ffffff;
  text-align: center;
}

.gh-provide-title {
  color: #242f42;
  font-size: 16px;
  font-weight: 500;
  margin: 40px 0 24px 0;
}

.gh-upload-container {
  margin-bottom: 42px;
}

.gh-upload-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #6d7481;
  padding: 0 20px;
  height: 85px;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.gh-upload-zone .file-drop {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.gh-upload-zone .gh-uz-wrapper {
  cursor: pointer;
}

.gh-uploaded-zone {
  z-index: 2;
  margin-bottom: 20px;
  display: flex;
}

.gh-uploaded-zone li:nth-child(3n) {
}

.gh-uz-text,
.gh-uz-prefix {
  font-size: 16px;
  font-weight: 500;
}

.gh-uz-text {
  color: #242f42;
}

.gh-uz-prefix {
  color: #6d7481;
  padding: 0 10px 0 8px;
}

.gh-uz-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 135px;
  height: 45px;
  border-radius: 4px;
  background: rgba(16, 184, 151, 0.1);
  min-width: unset;
  padding: 0px;
}

.gh-uz-wrapper input {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  display: none;
}

.gh-uz-wrapper label {
  color: #10b897;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.gh-uz-files-format {
  font-size: 14px;
  color: #6d7481;
  margin-top: 4px;
  padding-left: 4px;
}

.gh-uz-title {
  display: flex;
  align-items: flex-start;
}

.gh-vm-more {
  margin-bottom: 35px;
}

.gh-vm-more-head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.gh-uz-title span {
  margin-right: 10px;
}

.gh-uz-title span img {
  min-width: 22px;
  max-width: 22px;
}

.gh-vm-more span {
  margin-right: 15px;
}

.gh-uz-title h4,
.gh-vm-more h4 {
  color: #242f42;
  font-size: 16px;
  font-weight: normal;
  font-family: "rubik";
  margin: 0;
  line-height: 18px;
}

.gh-vm-more h4 {
  color: #10b897;
  display: flex;
  align-items: flex-end;
}

.gh-uz-title h4 b,
.gh-vm-more h4 b {
  font-weight: 500;
}

.gh-vm-more p {
  font-size: 16px;
  color: #242f42;
  padding: 0 0 0 37px;
  margin: 0;
  font-weight: 500;
}

.gh-uz-file {
  padding: 0;
  position: relative;
}

.gh-uz-file-close {
  position: absolute;
  top: 22px;
  right: 10px;
  width: 26px;
  height: 26px;
}

.gh-uz-file-content {
  background: #fff;
  align-items: center;
  padding: 13px 11px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.gh-uz-file-content::after {
  content: "";
  clear: both;
  display: table;
}

.gh-file-container {
  width: 100%;
}

.gh-register-back-button {
  color: #6d7481;
  background: #e7eaef;
}

.gh-uz-file-format {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  width: 36px;
  min-width: 36px;
  height: 43px;
  background: #e2e5eb;
  color: #242f42;
  font-size: 12px;
  margin-right: 12px;
  border-radius: 4px;
  float: left;
}

.gh-uz-file-format span {
  margin-top: -5px;
}

.gh-uz-file-format.error {
  background: #ffbfb9;
}

.gh-uz-file-name {
  color: #242f42;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gh-company-form .gh-provide-title {
  margin: 20px 0;
}

.gh-company-form .uk-input:disabled {
  border-color: #6d7481 !important;
  background-color: transparent !important;
}

.gh-aircraft-form .gh-uz-title {
  align-items: flex-end;
}

.gh-aircraft-form .uk-form-label {
  color: #242f42;
  font-weight: 500;
  font-size: 14px;
}

.gh-aircraft-form .gh-uz-file-format {
  background: #fff;
}

.gh-aircraft-form .gh-uz-file-content {
  background: #e7eaef;
}

.gh-cancel-btn {
  background: #e7eaef;
  color: #6d7481;
}

.gh-aircraft-form .uk-form-controls {
  margin-top: 10px;
}

.gh-check-card {
  padding: 9px 20px;
  border: 2px solid #10b897;
  background: #eff9f7;
  border-radius: 4px;
}

.gh-check-card .gh-checkbox {
  margin-top: 1px;
}

.gh-check-card label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #242f42;
  font-weight: 500;
  font-size: 16px;
}

.gh-check-card label span {
  padding-right: 50px;
}

.gh-aircraft-form .gh-uz-title span {
  display: none;
}

.gh-aircraft-form .gh-uz-title h4 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.gh-uz-file-status {
  font-size: 14px;
}

.gh-uz-file-status .gh-file-ready {
  color: #10b897;
  cursor: pointer;
}

.gh-uz-file-status .gh-file-uploading {
  color: #6d7481;
}

.gh-uz-file-status .gh-file-error {
  color: #e74c3c;
}

.gh-file-view {
  color: #10b897;
  text-decoration: none;
  font-weight: 500;
}

.gh-file-view:hover,
.gh-file-view:focus {
  text-decoration: none;
  color: #0b7762;
}

.gh-file-loader {
  animation: spin 3s reverse infinite;
}

.gh-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.gh-upload-btn-wrapper.gh-upload-btn-relative {
  position: relative;
  bottom: auto;
  left: auto;
  margin-left: 0;
  text-align: center;
  display: block;
}

.gh-upload-btn-wrapper button {
  border: 2px solid #dfe2e8;
  color: #6d7481;
  background-color: #ffffff;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 0.9375em;
  font-weight: bold;
}

.gh-upload-btn-wrapper.gh-upload-btn-relative button {
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
}

.gh-upload-btn-wrapper input[type="file"] {
  font-size: 16.25em;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.gh-upload-btn-wrapper input[type="file"]:hover + .gh-upload-btn-wrapper button {
  border-color: #f0f0f0;
}

.gh-helicopter-wrapper {
  min-height: 70px;
  max-height: 150px;
  max-width: 320px;
  margin: 15px auto;
  position: relative;
  background-image: url("../../img/h_front.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

#withdraw-request-modal h3 {
  margin-bottom: 0;
}

.gh-helicopter-wrapper.back {
  background-image: url("../../img/h_back.png");
}

.gh-req-quotes-details .gh-path {
  left: 0;
  top: 0;
}

.gh-req-quotes-details .gh-helicopter {
  left: 122px;
  top: 0;
}

.gh-req-quotes-details .gh-helicopter-line-divider-1 {
  left: 15px;
  top: 72px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-2 {
  left: 30px;
  top: 65px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-3 {
  left: 45px;
  top: 59px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-4 {
  left: 60px;
  top: 54px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-5 {
  left: 75px;
  top: 50px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-6 {
  left: 90px;
  top: 47px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-7 {
  left: 105px;
  top: 46px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-8 {
  left: 180px;
  top: 46px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-9 {
  left: 195px;
  top: 49px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-10 {
  left: 210px;
  top: 52px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-11 {
  top: 57px;
  left: 225px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-12 {
  left: 240px;
  top: 62px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-13 {
  left: 253px;
  top: 67px;
}

.gh-req-quotes-details .gh-helicopter-line-divider-14 {
  left: 266px;
  top: 73px;
}

.gh-req-quotes-details .gh-helicopter-line-start {
  top: 80px;
  left: 4px;
}

.gh-req-quotes-details .gh-helicopter-line-end {
  top: 72px;
  left: 272px;
}

.gh-nav .gh-list-item {
  padding-left: 15px;
}

.gh-sign-by-invitation {
  background-color: #ffffff;
  /*border-top: 3px solid #1abc9c;*/
}

.Rectangle-6 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 29px;
  background: linear-gradient(to bottom, #f0f0f0 0, #fff 100%);
}

.uk-button-small {
  padding: 0 15px;
  line-height: 28px;
  font-size: 0.875rem;
  min-width: 120px;
}

.gh-alert {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}

.gh-alert .uk-alert-success {
  color: #ffffff;
}

.gh-alert-confirm {
  color: #6d7481;
  background: none;
  padding: 0;
}

.nav-dropdown .gh-user-initials,
.nav-dropdown .gh-user-name,
.nav-dropdown .gh-dropdown-arrow {
  color: #ffffff;
}

.gh-dropdown-mobile .uk-navbar-dropdown {
  background: none;
  padding: 0;
  width: 85%;
  margin-top: 0;
}

.gh-add-icon {
  background: none;
  border: none;
  height: 50px;
  cursor: pointer;
}

.gh-add-icon:focus {
  border: none;
  outline: none;
}

.gh-email-invitation-input {
  display: block;
  margin-right: 4px;
  padding: 1px 4px;
  margin-bottom: 10px;
}

.gh-margin-small-bottom svg {
  margin-bottom: 10px;
}

.uk-tooltip {
  line-height: normal;
  padding: 10px;
}

.gh-tooltip-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.gh-account-restricted {
  font-size: 1.125em;
  width: 75%;
  text-align: center;
  margin-bottom: 0;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
}

.gh-dark-blue {
  color: #242f42;
}

.gh-dark-blue {
  color: #242f42;
}

.gh-status-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 15px;
}

.gh-status-field {
  display: block;
  height: 44px;
  border-radius: 4px;
}

.gh-status-field .uk-checkbox:read-only {
  cursor: default;
}

.pastel {
  background: #eff9f7;
  border: 2px solid #ffab00;
}

.green {
  border: 2px solid #10b897;
  background: #eff9f7;
}

.gh-status-field.green {
  border: unset;
}

.gh-item-count {
  margin: 2px 0 0 10px;
  width: 20px;
  height: 18px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #e74c3c;
  background: #f49c93;
  color: #242f42;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gh-item-count.active {
  color: #ffffff;
  background: #e74c3c;
  border: 1px solid #ffffff;
}

.gh-mini-tab-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-dark-blue-bg {
  background-color: #242e41;
}

.gh-checkbox:not(:disabled) {
  cursor: inherit;
}

.gh-checkbox {
  border-color: #dfe2e8;
}

.rdtPicker td.rdtOld {
  color: #6d7481;
}

.rdtPicker td.rdtDay.rdtOld:hover {
  background: none;
  cursor: inherit;
}

.gh-disabled-link {
  opacity: 0.4;
}

.read,
.read:hover {
  color: #6d7481;
  text-decoration: none;
}

.read li > div {
  border: 1px solid transparent;
}

.read li:hover > div {
  border: 1px solid #1abc9c;
}

.gh-calendar-icon,
.gh-close-icon {
  z-index: 999;
  height: 46px;
}

.gh-close-icon {
  left: auto;
  right: 0;
  cursor: pointer;
  pointer-events: auto !important;
}

.gh-calendar-input {
  padding-left: 40px;
}

.uk-block > div {
  margin-top: 10px;
}

.uk-button-primary:hover,
.uk-button-primary:focus {
  background: #1abc9c;
}

.gh-comment-author {
  font-size: 0.8125em;
}

.gh-middle-line {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  margin-top: -1px;
  background: #ccc;
  z-index: 0;
}

.gh-alert-without-bg {
  background: none;
  padding-left: 0;
}

.gh-sea-green {
  color: #1abc9c;
}

.gh-cloud-blue-btn {
  background-color: rgba(223, 226, 232, 1);
  color: #ffffff;
}

.gh-cloud-blue-btn:hover {
  background-color: rgba(109, 116, 129, 0.8);
}

.gh-home-bg-1 {
  background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
    url(../../img/bg/heli4.jpg);
  display: flex;
  min-height: 70vh;
  padding: 30px 0;
  flex-direction: column;
  justify-content: center;
}

.gh-home-bg-2 {
  background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
    url(../../img/bg/twoheli.jpg);
  background-position: 100% 86%;
  background-size: contain, cover;
}

.gh-contact-bg {
  height: 42vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(rgba(36, 47, 66, 0.6), rgba(36, 47, 66, 0.6)),
    url(../../img/bg/contact_heli.jpg);
  background-position-y: bottom;
}

.uk-button-default:active {
  border: 2px solid #6d7480;
}

.gh-button-terms,
.gh-button-terms:hover,
.gh-button-terms-register {
  border: 2px solid #6d7481;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gh-button-terms .gh-button-terms-text {
  border: none;
  display: flex;
  justify-content: center;
}

.gh-button-terms .uk-icon-button {
  width: 25px;
  height: 25px;
}

.gh-button-terms .gh-term-arrow {
  width: 35px;
}

.gh-button-terms-bank .gh-term-arrow {
  margin-left: 7px;
}

.gh-pad-selection {
  position: relative;
}

.gh-register-select-button {
  line-height: 48px;
  margin-left: 10px;
}

.gh-utc {
  position: absolute;
  left: 85px;
  top: 13px;
  z-index: 4;
}

.gh-register-landing {
  background: transparent;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-sign-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
}

.register-page {
  background: #f0f0f0;
  height: 100%;
  padding: 20px 3px;
}

.gh-role-view {
  padding: 0 !important;
}

.gh-white-bg {
  background: #fff;
}

.register-select-role {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.gh-register-tabs {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  border-bottom: solid 2px #cfd4de;
}

.gh-register-tabs::before,
.gh-register-tabs::after {
  content: none;
}

.gh-register-tab {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 15px;
  margin-bottom: -2px !important;
}

.gh-rt-icon {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 10px;
  margin-bottom: 10px;
}

.gh-rt-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  padding-right: 5px;
}

.gh-register-tab.active {
  box-shadow: 0 -2px #1abc9c inset;
}

.gh-rt-number {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: #e2e5eb;
  color: #10b897;
  font-size: 14px;
  margin-right: 5px;
}

.gh-rt-title {
  font-size: 14px;
}

.gh-rt-title,
.gh-rt-number {
  color: #242f42;
}

.gh-rt-title.active {
  color: #1abc9c;
}

.gh-rt-number.active {
  color: #fff;
  background: #10b897;
}

.gh-rt-title.passed {
  color: #6d7481;
}

.gh-rt-number.passed {
  color: #10b897;
}

.gh-button-terms-register {
  padding: 4px 0 !important;
}

.gh-container-affiliate {
  background: transparent;
}

.gh-register-title,
.gh-container-affiliate,
.gh-register-tabs {
  max-width: 970px;
  padding: 0 !important;
}

.gh-register-title {
  margin: 0 auto 20px;
}

.gh-register-title h3 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.gh-padding-terms-top {
  padding-top: 5px;
}

.gh-register-form {
  padding: 22px 15px;
  background: #fff;
}

.gh-register-section {
  display: flex;
  flex-direction: row;
}

.gh-register-ex-padding {
  padding: 10px !important;
}

.gh-map-type {
  position: absolute;
  top: 15px;
  left: 15px;
  background: #fff;
  z-index: 999;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  border: 0;
  text-transform: uppercase;
}

.gh-map-type:hover,
.gh-map-type:active,
.gh-map-type:focus {
  border: 0;
}

.gh-recover-pass {
  padding: 40px;
  background: #fff;
  width: 45%;
  margin: 40px auto;
  min-width: 240px;
  max-width: 340px;
}

.gh-dashboard-logout-header-nav {
  background: #242f42;
  border-bottom: 6px solid #1abc9c;
  min-height: 80px;
}

.gh-dashboard-logout-container-nav {
  max-width: 1440px;
  margin: 0 auto;
  border-bottom: 0px !important;
}

.gh-dashboard-logout-header-nav .gh-dashboard-logout-container-nav {
    display: flex;
    justify-content: space-between;
}

.gh-dashboard-nav {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.gh-dashboard-nav .gh-navbar-item-in-page {
  min-height: inherit;
  height: 40px;
  font-size: 1em;
  border-radius: 2px;
}

.gh-filters-tab.failed *,
.gh-filters-tab.failed:hover *,
.gh-filters-tab.failed a.uk-link-text:not(:hover) {
  color: #e74c3c !important;
}

.autocomplete-dropdown-container {
  border: 2px solid #6d7481;
  position: absolute;
  background-color: white;
  z-index: 1000;
  width: 100%;
  border-radius: 4px;
  max-height: 500px;
  overflow-y: scroll;
}

.autocomplete-dropdown-container.hide {
  border: none;
}

.autocomplete-dropdown-container li {
  padding: 5px;
  border-bottom: 1px solid #6d7481;
  cursor: pointer;
}

.autocomplete-dropdown-container li:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.gh-autocomplete {
  max-height: 250px;
  overflow-y: scroll;
}

.gh-autocomplete.autocomplete-dropdown-container li {
  padding: 0 5px;
  cursor: pointer;
  margin-bottom: 0;
}

.gh-vat-padding-10 {
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  line-height: 45px;
}

.gh-padding-left-10 {
  padding-left: 10px;
  display: inline-block;
}

.opacity-8 {
  opacity: 0.8;
}

.gh-radio-inline .gh-radio-wrapper-with-label {
  margin-left: 5px;
}

.gh-h2 {
  font-size: 1.5rem;
}

.gh-font-weight-remove {
  font-weight: normal;
}

.gh-select-role {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  color: #1abc9c;
}

.role-select-prefix {
  color: #242f42;
  margin-right: 5px;
}

.gh-request-filter-checkbox {
  margin-right: 2px;
}

.gh-notification .created-col {
  text-align: right;
}

.gh-notifications-label {
  background-color: #e74c3c !important;
  border: 2px solid #242f42 !important;
  border-radius: 4px;
  padding: 2px 4px 2px 4px !important;
  color: #ffffff !important;
  position: absolute;
  top: -50%;
  right: -50%;
  font-size: 10px;
  line-height: 1;
}

@media (max-width: 1200px) {
  .gh-notifications-label {
    padding: 2px 4px 3px 4px !important;
  }
}

.gh-notifications-label.no-border {
  border: none !important;
}

.gh-notifications-label.rightTop {
  position: absolute;
  top: -10px;
  right: -10px;
}

.gh-notifications-label.bigger {
  font-size: 13px;
  padding: 3px 8px 4px 8px !important;
  top: -7px;
  right: -10px;
}

.company-summary li b.suspended {
  text-decoration: line-through;
}

.gh-audit-author {
  color: #1abc9c !important;
}

.small-font {
  font-size: 0.75em;
}

.gh-favourites li {
  background-color: #ffffff;
  border-radius: 5px;
}

.gh-star polygon {
  fill: rgba(255, 171, 0, 0.85);
  stroke: #ffab00 !important;
}

.gh-powered-stripe img {
  width: 100px;
}

.gh-file-preview {
  background: #f2f4f7;
  height: inherit;
}

.overlay {
  background: #f2f4f7;
  overflow: hidden;
}

.gh-file-preview iframe {
  height: 90vh;
  width: 100%;
}

.gh-file-preview .title {
  color: #fff;
}

.gh-file-preview .footer {
  padding: 5px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #242f42;
}

.gh-file-preview .bodyContainer {
  height: 90%;
  overflow-y: auto;
}

.gh-preview-img {
  padding-top: 20px;
}

.gh-preview-img img {
  max-width: 100%;
  height: auto;
}

.gh-checkbox-aoc {
  margin: 7px 0 0 0;
}

.gh-location-lat::placeholder {
  font-size: 13px;
}

.gh-yellow-tooltip svg {
  color: #ffab00;
  cursor: pointer;
}

.gh-red-tooltip svg {
  color: #e74c3c;
  cursor: pointer;
}

.gh-yellow-checkbox {
  border-color: #ffab00 !important;
}

.gh-yellow-checkbox:checked {
  background-image: url("../../../assets/img/svg/tabCheckYellow.svg") !important;
  background-size: 12px;
}

.pipeline-view-tab .uk-select {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='10' viewBox='0 0 18 10'%3e%3cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3e%3cg stroke='%23242f42' stroke-width='2'%3e%3cg%3e%3cpath d='M570 0L578 8 570 16' transform='translate(-1247 -1043) translate(682 1040) rotate(90 574 8)'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
}

.gh-table-tooltip svg {
  color: #ffab00;
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: -5px 0 0 5px;
}

.gh-table-tooltip-mobile svg {
  color: #ffab00;
  cursor: pointer;
  width: 17px;
  height: 17px;
  margin: 0 0 0 5px;
}

.gh-tooltip-icon-small {
  width: 17px;
}

.gh-tooltip-icon-small svg {
  margin-bottom: 3px;
}

.uk-checkbox:disabled,
.uk-radio:disabled {
  background-color: #f0f0f0;
  border-color: #dfe2e8;
}

.gh-helicopter-label .disabled {
  color: #dfe2e8;
}

.gh-green,
.uk-button-link {
  color: #1abc9c;
}

.gh-red {
  color: #e74c3c;
}

.gh-yellow {
  color: rgb(255, 171, 0);
}

.gh-button-default {
  background-color: #f0f0f0;
}

.gh-button-hover:hover {
  opacity: 0.7;
}

.gh-warn {
  color: #6d7481;
}

.gh-button-disabled,
.gh-button-disabled:hover {
  background-color: rgba(240, 240, 240, 0.5);
}

.gh-conduct-container {
  max-height: 55vh;
  overflow-y: scroll;
  color: #242f42;
}

.gh-conduct-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.gh-conduct-container::-webkit-scrollbar:vertical {
  width: 11px;
}

.gh-conduct-container::-webkit-scrollbar:horizontal {
  height: 11px;
}

.gh-conduct-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.uk-label-success {
  background-color: #1abc9c;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #00b8d9;
}

.gh-alert-warning {
  color: #ffab00;
}

.uk-card-default,
.gh-main-footer .gh-subnav a,
.uk-input,
.uk-text-meta {
  color: #6d7481;
}

.uk-input {
  border-color: #6d7481;
}

.gh-address {
  line-height: 1.5;
  border-color: #6d7481;
}

.gh-reduce-paragraph-pad .gh-address p {
  margin: 0;
}

.uk-input,
.uk-textarea .uk-input:focus,
.uk-textarea:focus {
  border-color: #6d7481;
}

.uk-alert-danger,
.uk-text-danger {
  color: #e74c3c !important;
}

.uk-button-danger:focus {
  background-color: #e74c3c;
}

/*override .validation-errors font size*/

.gh-upload-errors ul.validation-errors {
  font-size: 0.875em;
}

.gh-print-icon {
  background-image: url(../../img/print_279443.png);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  height: 50px;
  width: 50px;
}

.gh-aircraft-label {
  display: block;
  width: fit-content;
  margin: 3px 0;
  white-space: inherit;
  height: auto !important;
  background: #00b8d9;
}

.gh-vat-position-error {
  width: 215px;
}

.gh-pound-position {
  position: absolute;
  display: inline-block;
  left: 26px;
  top: 11px;
}

.gh-pound {
  position: relative;
  width: 100%;
}

/*.gh-pound::before {*/
/*	content: '\00a3';*/
/*	position: absolute;*/
/*	left: -12px;*/
/*	top: 11px;*/
/*}*/

.gh-modal-close {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px;
  color: #6d7481;
}

.gh-feature-list-item-title {
  color: #242f42;
  line-height: 1.2;
  height: 45px;
  font-size: 1.4rem;
}

.gh-feature-list-item-description {
  color: #6d7481;
  font-weight: 300;
}

.gh-feature-list-item-description ul {
  padding: 0;
}

.gh-feature-list-item-description ul li {
  font-size: 15px !important;
  font-family: Rubik, sans-serif !important;
  font-weight: 300;
  color: #6d7480;
  list-style-type: none;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}

.gh-feature-list-item-description ul li:before {
  content: "";
  display: block;
  background: url("../../../assets/img/svg/checkFeature.svg") no-repeat;
  width: 20px;
  height: 14px;
  margin-right: 16px;
  margin-top: 5px;
}

th.gh-team-remove-icon {
  padding: 30px;
}

.gh-border-bottom {
  border-bottom: 2px solid #dfe2e8;
}

.gh-uk-tab {
  margin-left: -20px;
}

.gh-uk-tab-25 {
  margin-left: -25px;
}

.gh-uk-width {
  width: 226px;
}

.gh-min-width-small {
  min-width: 120px;
}

.gh-fleet-item label {
  min-width: 147px;
}

.gh-identifier-value {
  width: 65%;
}

.gh-aircraft-btn {
  margin-bottom: 0;
  height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gh-identifier-form {
  margin-bottom: 5px;
  transform: translateY(1px);
}

.gh-identifier-buttons {
  width: 35%;
  text-align: center;
  display: flex;
  align-items: center;
}

.gh-identifier-buttons button {
  border: none;
  margin: 0 0 0 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uk-button-primary:disabled,
.uk-button-primary:disabled:hover {
  background-color: rgba(26, 188, 156, 0.3);
}

.uk-button-primary:disabled .gh-dark-blue,
.uk-button-primary:disabled:hover .gh-dark-blue {
  color: #6d7481;
}

.gh-fleet-item {
  background-color: #ffffff;
  padding: 15px;
  margin-left: 0;
}

.gh-section-statistics {
  padding-bottom: 30px;
}

.gh-section-statistics .gh-count {
  line-height: normal;
}

.gh-dashboard-bl {
  min-height: 90px;
  min-width: 250px;
  background-color: #ffffff;
}

.gh-dashboard-bl > div {
  width: 100%;
}

/*.uk-accordion-title::before {*/
/*	background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20fill%3D%22%23fff%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%3Crect%20fill%3D%22%23fff%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%3C%2Fsvg%3E');*/
/*}*/

/*.uk-open > .uk-accordion-title:before {*/
/*	background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20fill%3D%22%23fff%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%3C%2Fsvg%3E');*/
/*}*/

.gh-aoc-tooltip span.uk-icon {
  position: absolute;
  top: 0;
  left: 15px;
}

.gh-overlay-none {
  overflow-y: hidden;
}

.gh-overlay-auto {
  overflow-y: auto;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(26, 188, 156, 0.2);
  }
}

@keyframes shadow-pulse-exit {
  100% {
    box-shadow: 0 0 0 20px rgba(26, 188, 156, 0.2);
  }
  0% {
    box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5);
  }
}

.pulse {
}

.pulse-enter {
  box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5);
}

.pulse-enter-active {
  animation-name: shadow-pulse;
  animation-duration: 2s;
}

.pulse-exit {
  box-shadow: 0 0 0 20px rgba(26, 188, 156, 0.2);
}

.pulse-exit-active {
  animation-name: shadow-pulse-exit;
  animation-duration: 2s;
}

.gh-row-highlight {
  border: 2px solid #00b8d9;
  border-radius: 4px;
  padding-left: 5px;
}

table tr.gh-row-highlight td {
  border-top: 2px solid #00b8d9;
  border-bottom: 2px solid #00b8d9;
}

table tr.gh-row-highlight td:first-child {
  border-left: 2px solid #00b8d9;
}

table tr.gh-row-highlight td:last-child {
  border-right: 2px solid #00b8d9;
}

.gh-pax-error {
  margin-top: 5px;
}

.gh-checkbox.checked {
  border: 2px solid #1abc9c;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  fill: #1abc9c;
  color: #1abc9c;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%231ABC9C%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.gh-inline-block {
  display: inline-block;
}

.gh-notification .medium-col .r-arrow {
  width: 20%;
  text-align: center;
  min-width: 45px;
}

.broker-booking-layout .uk-tab,
.booking-layout .uk-tab {
  /*padding-bottom: 20px;*/
  font-weight: 500;
  margin-top: 0;
}

.broker-booking-layout .uk-tab:before,
.booking-layout .uk-tab:before {
  border-bottom: none;
}

.gh-leg-link {
  color: #1abc9c;
}

.gh-progress-text {
  color: #212121;
  width: 100%;
}

.gh-progress-text p {
  margin: 0;
}

.gh-request-progress {
  padding-right: 10px;
  margin-top: 8px;
}

.gh-request-progress .uk-progress {
  margin-bottom: 0;
  height: 4px;
}

.gh-request-progress .gh-request-progress-content {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 10px;
  background-color: #1abc9c;
}

.gh-request-progress progress {
  border-radius: 10px;
  width: 100%;
  display: block;
  margin-bottom: 0;
  height: 4px;
}

.gh-request-progress progress::-webkit-progress-bar {
  background-color: #e6e6e6;
  border-radius: 10px;
}

.gh-request-progress progress::-webkit-progress-value {
  background-color: #10b897;
  border-radius: 10px;
}

.gh-request-progress progress[value] {
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: #e6e6e6;
}

.gh-label-quote {
  background: #bebebe;
  color: black;
  margin-bottom: 10px;
  padding: 0 7px;
}

.gh-leg-link:hover {
  color: #1abc9c;
  text-decoration: none;
}

.gh-fleet-row {
  box-shadow: inset 0 -5px 0 0 #f0f0f0;
  background: #fff;
}

.gh-fleet-row td {
  padding: 16px 12px 20px 12px;
}

.gh-fleet-delete {
  width: 100%;
  height: 100%;
}
.gh-fleet-delete .close {
  position: absolute;
  top: -10px;
  right: -10px;
}

.gh-table-id {
  color: #10b897;
  cursor: pointer;
}

.broker-booking-layout .gh-req-button {
  font-weight: 500;
}

.gh-quote-label-btn:hover {
  text-decoration: none;
}

.gh-google-map ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.gh-section-statistics .uk-card {
  max-width: 450px;
}

.gh-mobile-action {
  color: #212121;
}

.gh-grid-title-progress {
  display: flex;
  justify-content: space-between;
}

.gh-grid-title-progress .gh-tooltip .gh-tooltip-icon {
  transform: translate(4px, -3px);
}

.uk-offcanvas {
  z-index: 100000;
}

.gh-pin {
  width: 17px;
  margin-right: 15px;
}

.gh-info-title a,
.gh-info-title a:hover {
  color: #242f42;
  font-size: 1.1em;
}

.gh-info-btn {
  padding: 0 15px;
  line-height: 28px;
  font-size: 0.875rem;
}

.gh-info-window {
  padding: 15px;
}

.gm-style-iw {
  padding: 0 !important;
}

.gm-style-iw > div {
  overflow: hidden !important;
}

.range-error-position {
  margin-left: 15px;
}

.gh-height-75 {
  height: 75px;
}

.gh-vertical-middle {
  vertical-align: middle;
}

.gh-danger-opostrov {
  height: 20px;
  width: 20px;
  min-width: 20px;
}

.gh-text-bottom {
  vertical-align: text-bottom;
}

.gh-full-width {
  width: 100%;
}

.gh-width-240 {
  width: 247px;
}

.gh-empty-row p {
  margin: 10px 10px 15px 10px;
}

.gh-empty-row-mob {
  padding-bottom: 15px !important;
}

.gh-empty-row-mob p {
  margin: 10px 10px 15px 10px;
}

.gh-min-grid-col {
  min-width: 120px;
  max-width: 180px;
}

.gh-small-grid-col {
  min-width: 100px;
  max-width: 120px;
}

.gh-mob-request-header {
  text-transform: uppercase;
  color: #6d7481;
  font-size: 11px;
  font-family: "rubik", sans-serif;
  margin-bottom: 1px;
  letter-spacing: 0.9px;
}

.gh-filters-btn {
  font-weight: 500;
}

.gh-broker-request-filters label {
  font-size: 14px;
}

.gh-broker-request-filters .gh-radio-wrapper-with-label label {
  font-size: 14px;
  line-height: 23px !important;
  transform: translateY(-2px);
}

.gh-broker-request-filters .gh-radio-wrapper-with-label label:before {
  border: 2px solid #10b897;
}

.gh-broker-request-filters .gh-enquire-calendar-icon {
  width: 20px;
  height: 20px;
  left: 15px;
  top: 13px;
}

.gh-broker-request-filters .gh-calendar-input {
  padding-left: 45px;
}

.gh-broker-request-filters .uk-form-label {
  margin-bottom: 14px;
  font-size: 13px;
  font-family: "rubik", sans-serif;
  font-weight: 500;
  color: #6d7481;
  display: block;
}

.gh-broker-request-filters .uk-select:not([multiple]):not([size]) option {
  font-family: "rubik", sans-serif;
  font-size: 14px;
  color: #242f42;
}

.gh-broker-request-filters .uk-select {
  font-weight: 500;
  font-size: 14px;
  color: #242f42;
}

.gh-broker-request-filters .uk-input::placeholder {
  font-family: "rubik", sans-serif;
  font-size: 14px;
  color: #6d7481;
}

.gh-mob-request-header .gh-grid-title-progress {
  justify-content: flex-start;
}

.gh-mob-request-header .gh-grid-title-progress .gh-tooltip {
  margin-left: 7px;
}

.gh-mob-request-row {
  color: #242f42;
  font-size: 14px;
  font-weight: 500;
}

.gh-aoc-docs {
  width: 100%;
  margin: auto;
}

.gh-quote-num-mob {
  display: flex;
  flex-direction: row;
}

.gh-mob-request-row .gh-quote-num-mob .gh-label-quote {
  margin-bottom: 0;
  margin-right: 10px;
  padding: 0 5px;
}

.gh-width-grid-id {
  width: 120px;
  min-width: 120px;
}

.gh-width-grid-status {
  width: 90px;
  min-width: 90px;
}

.gh-width-grid-on {
  width: 95px;
  min-width: 95px;
}

.gh-width-grid-col-progress {
  width: 75px;
}

.gh-width-grid-col-progress .gh-tooltip-icon {
  transform: translateY(-2px);
}

.gh-width-grid-col-progress .uk-icon {
  transform: translateY(-2px);
}

.google-map > div {
  height: 100%;
}

.gh-range-hypen,
.gh-range-hypen-filter {
  position: relative;
}

.gh-range-hypen::after {
  position: absolute;
  content: "-";
  right: -17px;
  top: 12px;
}

.gh-range-hypen-filter::after {
}

.gh-feature-slider-list {
  overflow: inherit;
  padding-bottom: 20px;
}

.gh-feature-slider-list .gh-feature-list-item-container {
  min-height: 390px;
  padding-top: 0;
  max-width: 300px;
}

.gh-feature-slider-list .gh-feature-list-item-container:focus {
  -moz-outline: none;
  -webkit-outline: none;
  outline: none;
}

.gh-feature-slider-list [class*="uk-position-center-left-out"],
.gh-feature-slider-list [class*="uk-position-center-right-out"] {
  color: #1abc9c;
}

.gh-feature-slider-list [class*="uk-position-center-right-out"] {
  right: 0;
  left: auto;
  margin-right: -35px;
  padding: 0;
}

.gh-feature-slider-list [class*="uk-position-center-left-out"] {
  right: auto;
  left: 0;
  margin-left: -45px;
}

.gh-reduce-paragraph-pad p {
  margin: 10px 0;
}

.gh-lunch-section,
.gh-lunch-section .validation-errors {
  background: transparent;
}

.gh-subscribe-section {
  max-width: 350px;
}

.gh-padding-10 {
  padding: 10px;
}

.gh-padding-20 {
  padding: 20px;
}

.gh-bg-white,
.gh-bg-white:focus {
  background-color: #ffffff;
}

/*Homepage ICONS*/

.gh-services-icon {
  border-radius: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  width: 48px;
  height: 48px;
  background-color: transparent !important;
}

.gh-services-icon.broker_icon_1,
.gh-services-icon.operator_icon_1 {
  background-image: url("../../img/home/flow.svg");
}

.gh-services-icon.broker_icon_2,
.gh-services-icon.operator_icon_2 {
  background-image: url("../../img/home/gear.svg");
}

.gh-services-icon.broker_icon_3,
.gh-services-icon.operator_icon_4 {
  background-image: url("../../img/home/medal.svg");
}

.gh-services-icon.broker_icon_4,
.gh-services-icon.operator_icon_5 {
  background-image: url("../../img/home/shield.svg");
}

.gh-services-icon.broker_icon_5 {
  background-image: url("../../img/home/rotate.svg");
}

.gh-services-icon.broker_icon_6,
.gh-services-icon.operator_icon_3 {
  background-image: url("../../img/home/responsive.svg");
}

.gh-services-icon.operator_icon_6 {
  background-image: url("../../img/home/medal.svg");
}

.gh-modal-footer-request {
  margin-top: 15px;
}

.gh-modal-footer-request hr {
  width: 90%;
  margin: 20px auto 10px;
}

.gh-filtered-header {
  color: #10b897 !important;
}

.gh-short-nav {
  background: #ffffff;
  padding: 26px 0;
  margin-bottom: 17px;
}

.gh-back-text {
  color: #242f42 !important;
}

.gh-button-withdraw,
.gh-view-quote-btn {
  font-weight: 500;
}

.gh-button-withdraw:hover {
  text-decoration: none;
}

.gh-button-withdraw:before {
  display: none;
}

.gh-view-quote-btn:before {
  display: none !important;
}

.gh-book-btn {
  color: #0ab897;
  border-color: #0ab897;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-book-btn-disabled,
.gh-book-btn-disabled:hover,
.gh-book-btn-disabled:active,
.gh-book-btn-disabled:focus {
  color: #999 !important;
  border: 1px solid #999 !important;
  cursor: initial !important;
}

.gh-book-btn:hover {
  color: #0ab897;
  border-color: #0ab897;
}

.gh-decline-btn {
  color: #e74c3c;
  background: #f2f4f7;
  border: 1px solid #d5d5d5;
}

.gh-decline-btn:disabled,
.gh-decline-btn:disabled:hover {
  color: #999;
  background: #f2f4f7;
  border: 1px solid #d5d5d5;
}

.gh-decline-btn:hover,
.gh-decline-btn:active,
.gh-decline-btn:focus {
  color: #e74c3c;
  background: #f2f4f7;
  border: 1px solid #d5d5d5;
}

.gh-discussion-btn {
  border: 1px solid #005bff;
  color: #005bff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-discussion-btn.operator {
  display: inline-flex;
}

.gh-discussion-btn svg {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}

.gh-discussion-btn svg path {
  fill: #005bff;
}

.gh-discussion-btn:hover {
  border: 1px solid #004ad4;
  color: #004ad4;
}

.gh-discussion-btn.operator {
  display: inline-flex;
  border: 1px solid #004ad4;
  color: #004ad4;
  align-items: center;
  justify-content: center;
}

.gh-discussion-btn:disabled,
.gh-discussion-btn:disabled:hover {
  border: 1px solid #999;
  color: #999;
}

.gh-discussion-btn:disabled svg path {
  fill: #999;
}

.gh-column-label {
  font-size: 12px;
  color: #6d7481;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 0 10px 0 0;
  text-transform: uppercase;
}

.gh-column-text {
  color: #242f42;
  font-size: 16px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  margin: 0;
  font-weight: normal;
}

.gh-request-status .gh-column-text {
  margin-bottom: 2px;
}

.gh-edit-identifier-btn {
  outline: none;
  border: 0;
  font-size: 16px;
  color: #005bff;
  cursor: pointer;
  font-family: "rubik";
}

.gh-edit-identifier-btn:hover {
  text-decoration: none;
}

.gh-text-green {
  color: #1abc9c;
}

.gh-margin-small-top {
  margin-top: 30px !important;
}

.gh-quote-slider {
  overflow: inherit;
  position: relative;
  z-index: 1;
  height: 100%;
}

.uk-slider-items,
.uk-slider-container {
  height: 100%;
}

.gh-slider-arrows {
  position: absolute;
  z-index: 2;
  background: rgba(36, 47, 66, 0.8);
  /*width: 10px;*/
  /*height: 14px;*/
  padding: 11px 6px 12px 8px;
}

.gh-slider-arrows svg {
  display: none;
}

.gh-slider-dots {
  position: absolute;
  z-index: 2;
  bottom: 74px;
  left: 0;
  width: 100%;
}

.gh-slider-dots {
  bottom: 58px !important;
}

.gh-slider-dots.gh-bottom-small {
  bottom: 48px !important;
}

.gh-quote-slider .uk-dotnav > .uk-active > * {
  background: #ffffff;
}

.gh-quote-slider .uk-dotnav > * {
  padding-left: 7px;
}

.gh-quote-slider .uk-dotnav > * > * {
  width: 7px;
  height: 7px;
  border: 1px solid #ffffff;
}

.gh-slider-arrow-right,
.gh-slider-arrow-left {
  top: 35%;
}

.gh-slider-arrow-left {
  left: 0;
}

.gh-slider-arrow-right {
  right: 0;
}

.gh-quote-slider-item {
  width: 100%;
  height: 100%;
  /*min-height: 283px;*/
  padding-top: 0;
  outline: none;
  position: relative;
}

.gh-quote-slider-item-image {
  width: 100%;
  display: block;
  position: relative;
  z-index: 999;
  height: calc(100% - 36px);
}

.gh-quote-slider-text {
  position: absolute;
  width: calc(100% - 29px);
  bottom: 0;
  left: 0;
  background: #f2f4f7;
  font-size: 12px;
  font-style: italic;
  color: #242f42;
  padding: 8px 13px 10px 16px;
  margin: 0;
  user-select: none;
  z-index: 999;
}

.gh-quote-slider .gh-quote-slider-item-container {
  min-height: 390px;
  padding-top: 0;
  max-width: 300px;
}

.gh-quote-buttons {
  padding: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
}

.gh-quote-buttons .custom-tooltip-icon {
  display: block;
  cursor: not-allowed;
}

.gh-bok-dec-buttons {
  display: flex;
  flex-direction: column;
}

.gh-bok-dec-buttons .gh-main-button-component {
  width: 100%;
}

.gh-bok-dec-buttons .custom-tooltip-wrapper {
  width: 100%;
}

.gh-quote-button {
  margin-bottom: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-full-request-btn {
  font-weight: 500;
}

.gh-margin-ex-small-top {
  margin-top: 16px !important;
}

.gh-first-element-margin {
  margin-top: 16px !important;
}

.gh-padding-quote-content {
  padding: 20px;
}

.gh-quote-pad {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.gh-quote-label {
  color: #6d7481;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.gh-quote-text {
  color: #212121;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  user-select: none;
}

.gh-cursor {
  cursor: pointer;
}

.gh-text-bold {
  font-weight: 700;
}

.gh-quote-pad-marker {
  width: 14px;
  height: 16px;
  margin: 5px 5px 0 0;
}

.gh-margin-top-ex-small {
  margin-top: 5px !important;
}

/*.gh-request-nav {*/
/*    font-family: 'rubik', Arial, Helvetica, sans-serif;*/
/*}*/

.gh-heli-label {
  cursor: pointer;
}

.gh-heli-active {
  font-weight: 700;
}

.gh-broker-nav-request-modal {
  z-index: 2;
}

.gh-broker-nav-request-modal .uk-container {
  padding: 0;
}

.gh-broker-terms {
  position: relative;
  z-index: 1;
}

.gh-quote-detail-modal .uk-modal-close-default {
  top: 25px;
  right: 25px;
  color: #000000;
}

.gh-quote-detail-modal.with-banner {
  padding-top: 60px;
}

.gh-quote-detail-modal.with-banner .uk-modal-close-default {
  top: 64px;
}

.gh-request-details-btn {
  display: flex;
  text-decoration: none;
  color: #242f42;
  font-weight: 500;
}

.gh-request-details-btn:hover {
  color: #242f42;
  text-decoration: none;
}

.gh-short-nav-actions {
  display: flex;
  justify-content: space-between;
}

.gh-edit-button {
  color: #005bff;
  margin-right: 15px;
}

.gh-edit-button:hover {
  text-decoration: none;
  color: #005bff;
}

.gh-text-blue {
  color: #242f42;
}

#gh-quote-detail-accordion {
  margin-top: 25px;
}

#gh-quote-detail-accordion .gh-accordion-section {
  border-top: 1px solid #d6dbe6;
  margin-top: 0;
}

#gh-quote-detail-accordion .gh-accordion-section:last-child {
  border-bottom: 1px solid #d6dbe6;
}

#gh-quote-detail-accordion .uk-accordion-content {
  padding: 0 0 15px 0;
}

#gh-quote-detail-accordion .uk-accordion-title {
  background: #ffffff;
  color: #242f42;
  padding: 15px 0;
  font-weight: 500;
}

#gh-quote-detail-accordion .uk-accordion-title:before {
  content: "";
  background-image: url("../../../assets/img/accordion-arrow.svg");
}

#gh-quote-detail-accordion .uk-open > .uk-accordion-title:before {
  content: "";
  background-image: url("../../../assets/img/accordion-arrow.svg");
  transform: rotate(180deg);
}

/*.gh-vetting {*/
/*  padding: 20px 15px;*/
/*  border: solid 1px #e3e7ed;*/
/*  background-color: #f2f4f7;*/
/*}*/

/*.gh-vetting .gh-box-label {*/
/*	font-weight: 500;*/
/*	opacity: 0.9;*/
/*}*/

/*.gh-box-title {*/
/*  font-weight: 500;*/
/*  font-size: 14px;*/
/*  color: #242f42;*/
/*  text-transform: uppercase;*/
/*  margin: 0 0 10px 0;*/
/*  cursor: pointer;*/
/*}*/

/*.gh-vetting-more-link {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*}*/

/*.gh-vetting-more-link h4 {*/
/*    font-weight: 500 !important;*/
/*    font-size: 14px;*/
/*    color: #242f42;*/
/*    text-transform: uppercase;*/
/*    margin: 0 0 3px 0;*/
/*}*/
/*.gh-vetting-more-link:hover,*/
/*.gh-vetting-more-link:focus {*/
/*  text-decoration: none;*/
/*}*/

/*.gh-vetting .gh-divider {*/
/*  margin: 17px 0;*/
/*}*/

.gh-box-logo {
  width: 90px;
  margin: 0 0 10px 0;
}

.gh-box-logo-img {
  width: 100%;
}

.gh-box-label {
  color: #212121;
  font-weight: normal;
  font-size: 16px;
}

.gh-box-text {
  color: #6d7480;
  font-size: 16px;
  font-weight: 500;
}

.gh-box-icon {
  width: 21px;
  height: 21px;
}

.gh-operator-comments {
  padding: 22px;
  background-color: #fffce8;
}

.gh-box-p {
  font-size: 14px;
  color: #242f42;
  font-weight: 400;
  margin: 0;
}

.gh-full-quote-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  height: 54px;
}

.gh-quote-button-icon {
  width: 20px;
  position: absolute;
  left: 20px;
  top: 15px;
}

.gh-full-quote .gh-slider-arrows {
  min-width: inherit;
  line-height: 0;
  padding: 11px 6px 12px 8px;
}

.gh-full-quote .gh-slider-dots a {
  min-width: 0;
  padding: 0;
}

.gh-broker-pad-map .gh-pad-title {
  color: #005bff;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.gh-broker-pad-map .gh-quote-pad-marker {
  color: #005bff;
  margin: 0 10px 0 0;
}

.gh-pad-modal {
  padding: 0;
}

.gh-pad-modal h3 {
  padding: 30px 30px 10px 30px;
  margin-bottom: 0;
}

.gh-pad-title {
  padding: 0 30px 24px 30px;
}

.gh-pad-map {
  width: 100%;
}

.gh-pad-modal .uk-close,
/*.gh-vetting-modal .uk-close,*/
.gh-preview-modal .uk-close {
  color: #000000;
  top: 20px;
  right: 20px;
}

.gh-preview-file {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-preview-file-pdf {
  min-height: 600px;
  width: 100%;
}

/*.gh-vetting-modal h3 {*/
/*  text-transform: uppercase;*/
/*  font-size: 24px;*/
/*  color: #242f42;*/
/*  margin-bottom: 40px;*/
/*}*/

.gh-summary-accordion #gh-quote-detail-accordion {
  margin-top: 0;
}

.gh-summary-accordion #gh-quote-detail-accordion .gh-accordion-section:last-child {
  border-bottom: 0;
}

.gh-summary-accordion #gh-quote-detail-accordion .gh-accordion-section:first-child {
  border-top: 0;
}

.gh-full-quote {
  position: relative;
}

#demo {
  background: #ffffff;
  padding: 30px;
  width: 1200px;
}

.gh-demo-pdf-block {
  display: flex;
  width: 100%;
  justify-content: center;
}

.gh-pdf-leg-title {
  width: 100%;
  background: #ffffff;
  color: #242f42;
  padding: 15px 0;
  font-weight: 500;
  position: relative;
  border-top: 1px solid #d6dbe6;
}

.gh-pdf-leg-title:before {
  content: "";
  display: flex;
  position: absolute;
  right: 0;
  top: 10px;
  width: 18px;
  height: 10px;
  background-image: url("../../../assets/img/accordion-arrow.svg");
  transform: rotate(180deg);
}

.gh-heli-info {
  padding-top: 10px;
}

.gh-height-max {
  height: 100% !important;
}
.gh-bottom-small {
  bottom: 48px;
}

.gh-without-bg {
  background: none;
}

.gh-alert-close {
  top: 22px !important;
  right: 5px !important;
}

.gh-filter-tabs {
  margin: 0;
  padding: 0 0 8px 0;
  display: flex;
  border-bottom: 2px solid #d9d9dc;
}

.gh-filter-tabs li {
  list-style-type: none;
  margin-right: 25px;
}

.gh-filter-tabs li a {
  text-decoration: none;
  color: #6d7481;
}

.gh-filter-tabs-loading {
    cursor: wait;
}

.gh-active {
  color: #10b897 !important;
}
.gh-replaced-field {
  padding: 10px;
  border: 2px solid #6d7481;
  border-radius: 4px;
}
.gh-outline-line {
  border: 4px solid #c1e4dd;
  border-radius: 4px;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  margin-top: 20px !important;
  transform: translateX(40px);
  padding: 0;
  width: calc(100% - 40px);
}

.gh-outline-line p {
  margin: 0;
}

.gh-outline-line > div {
  margin: 0 !important;
}

.gh-outline-line.disabled {
  display: none;
}

#preloader {
  z-index: 99999;
}

.withdraw-request-modal_btn.disabled {
  opacity: 0.8;
  pointer-events: none;
}

.wrapper-modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.gh-white-field,
.gh-white-field:active,
.gh-white-field:focus,
.gh-white-field:visited {
  background-color: #fff;
  border: 2px solid #6d7481;
}

.gh-quote-slider-text {
  box-sizing: initial !important;
}

.gh-new-request-nav {
  padding: 10px 0;
  background: #fff;
}
.gh-new-request-nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gh-new-request-nav-inner button {
  padding: 0 !important;
  text-align: right;
}

.gh-new-request-nav-inner button:hover:before {
  display: none;
}

.gh-white-field,
.gh-white-field:active,
.gh-white-field:focus,
.gh-white-field:visited {
  background-color: #fff;
  border: 2px solid #6d7481;
}

.gh-white-bg,
.gh-white-bg:active,
.gh-white-bg:focus,
.gh-white-bg:visited {
  background-color: #fff;
}

.gh-padding-medium-top {
  padding-top: 30px;
}

.gh-general-info {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
}

.gh-section {
  border-radius: 4px;
  background: #e6f2f0;
  margin: 0;
  padding: 20px;
}

.gh-second-section {
  margin-left: 0;
}

.gh-dashboard-nav .gh-user-nav .gh-user-initials {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(109, 116, 129, 0.25);
  color: #16bc9c;
  padding: 0px;
  border-radius: 50%;
  line-height: 15px;
  font-size: 14px;
  font-weight: 500;
}

.gh-radio-label {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  color: #242f42;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.gh-radio-label.active {
  font-weight: 500;
}

.gh-radio-label .uk-radio,
.gh-radio-label .uk-radio:checked {
  width: 22px;
  min-width: 22px;
  height: 22px;
  border: 2px solid #9da3ae;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-radio-label .uk-radio:checked:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background: #10b897 !important;
  border-radius: 50%;
}
.new-request .gh-accordion .gh-accordion-section.uk-open .uk-accordion-title,
.new-request .gh-accordion .gh-accordion-section.uk-primary .uk-accordion-title,
.new-request .gh-accordion .gh-accordion-section .uk-accordion-title,
.new-request .gh-accordion-like-title {
  background: #fff;
}

.new-request .gh-accordion .gh-accordion-section .uk-accordion-title,
.new-request .gh-accordion-like-title,
.new-request .uk-open > .uk-accordion-title:before,
.new-request .uk-accordion-title::before {
  color: #6d7481;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  user-select: none;
}

.new-request .uk-open > .uk-accordion-title,
.new-request .uk-open > .uk-accordion-title:before,
.new-request .uk-accordion-title::before {
  color: #242f42 !important;
  user-select: none;
}

.gh-request-section {
  margin-top: 20px;
  padding: 30px 40px;
  background: #fff;
  border-radius: 4px;
}
.new-request .uk-accordion-title,
.gh-request-section-title {
  font-size: 18px !important;
}
.gh-request-section-title {
  font-weight: 500;
  color: #242f42;
  margin: 0;
  line-height: 1.4;
  font-family: "rubik", Arial, Helvetica, sans-serif;
}

.gh-request-section .uk-select {
  background-color: #e7eaef;
}
.gh-checkbox-label {
  font-size: 14px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  color: #242f42;
  display: flex;
  align-items: center;
  user-select: none;
}

.gh-checkbox-label:not(.gh-checkbox-label-disabled) {
  cursor: pointer;
}

.gh-seen-message {
  font-family: "rubik", sans-serif !important;
  font-size: 12px !important;
}
.gh-request-divider {
  border-top: 1px solid #d5d5d5;
  margin: 20px 0;
}

.gh-request-component-title {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #242f42;
  text-transform: uppercase;
}
.new-request .uk-form-label {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #242f42;
  display: block;
  margin: 5px 0 10px 0;
  text-transform: uppercase;
}

.gh-select-field,
.gh-select-field:focus {
  border: 2px solid #6d7481;
  border-radius: 4px;
  background: #fff;
  color: #242f42;
}

.gh-quote-slider-text {
  box-sizing: initial !important;
}

.gh-request-label {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #242f42;
  display: block;
  text-transform: uppercase;
  line-height: 6px;
}

.gh-flight-duration {
  display: flex;
}
.gh-estimated-time {
  font-size: 14px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #242f42;
}

.gh-estimate-warning {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #242f42;
  line-height: normal;
}

.gh-request-info-section {
  border-right: 1px solid #d5d5d5;
  padding: 0 30px;
  height: 100%;
}

.gh-inline-numbers .gh-number-field {
  margin-bottom: 30px;
}

.gh-inline-numbers .uk-form-label {
  font-size: 13px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #6d7481;
}

.gh-remove-border {
  border: 0;
}

.gh-number-btn {
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 2px solid #6d7481;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gh-counter-icon {
  color: #10b897;
  font-size: 18px;
  font-weight: 400;
}

.gh-inline-counter {
  width: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  color: #6d7481;
}

.wrapper-chat-input .uk-input {
  border-radius: 3px;
  height: 50px;
  padding-right: 50px;
}

.wrapper-chat-input .uk-textarea {
  padding-right: 40px;
  max-height: 200px;
}

.wrapper-chat-input .uk-input:focus {
  border-color: #6d7481;
}

.wrapper-chat-input .attach-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  min-width: 50px;
  max-width: 50px;
}

.wrapper-chat-input .attach-btn:disabled {
  opacity: 0.5;
}

.wrapper-chat-input .attach-btn .attach-img {
  padding: 13px;
}

.gh-badge-counter {
  width: 33px;
  height: 26px;
  border-radius: 3px;
  color: #242f42;
  background: #bdc3cf;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-request-radios .uk-radio {
  margin-top: 4px;
}

.gh-request-radios .gh-radio-label {
  font-size: 14px;
  display: flex;
}

.gh-request-info-section .gh-checkbox-label {
  justify-content: flex-start;
}

.gh-map-header {
  display: flex;
  align-items: flex-end;
}

.gh-request-info-section .uk-textarea {
  max-height: 240px;
  min-height: 240px;
  resize: none;
}

.gh-request-area {
  width: 100%;
}

.gh-request-area-box {
  background: #e6f2f0;
  padding: 20px;
  border-radius: 8px;
}
.gh-request-area-box .uk-textarea {
  width: 100%;
  resize: none;
  min-height: 108px;
}

.gh-request-area-box .uk-textarea::placeholder {
  font-size: 16px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
}

.gh-request-add-btn {
  display: block;
  border: 1px solid #9da3ae;
  background: #fff;
  border-radius: 3px;
  padding: 16px 40px;
  color: #242f42;
  font-size: 14px;
  font-weight: 500;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  transition: 0.5s;
}

.gh-request-add-btn:hover {
  text-decoration: none;
  color: #0b7762;
}

.new-request .gh-calendar-input:focus {
  border: 2px solid #6d7481;
}

.new-request .gh-datepicker-field .gh-calendar-input {
  padding: 0 10px 0 15px;
  color: #6d7481 !important;
  font-size: 16px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
}
.new-request .gh-datepicker-field {
  position: relative;
}

.new-request .gh-datepicker-field .gh-enquire-calendar-icon {
  right: 17px;
  top: 13px;
}

.new-request .gh-datepicker-field .gh-utc {
  left: 65px;
  top: 13px;
}

.new-request .rdtPicker {
  border: 0;
}

.new-request .gh-datepicker-field .rdtOpen .rdtPicker {
  width: 100%;
  margin-top: -5px;
  padding: 0;
  border-bottom: 2px solid #6d7481;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: -2px 0 0 inset #6d7481, 2px 0 0 inset #6d7481;
}

.new-request .gh-datepicker-field .rdtOpen .rdtPicker .wrapper {
  display: flex;
  padding: 0 2px;
}

.gh-picker-box {
  background: #fff;
  display: flex;
}

.gh-picker-box-content .gh-radio-label {
  margin: 0 40px 20px 0;
}

.gh-picker-box-content .gh-radio-label .uk-radio {
  margin-top: 1px;
}

.gh-picker-box-content {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.gh-icon-label,
.gh-calendar-label {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 13px;
  right: 50px;
  z-index: 2;
  color: #242f42;
  display: block;
}

.new-request .rdtOpen ~ .gh-calendar-label {
  display: none;
}

.gh-autocomplete-footer {
  width: 100%;
}
.gh-autocomplete-footer .gh-divider {
  margin: 0 0 15px 0;
}
.gh-autocomplete-btn-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}
.gh-autocomplete-btn {
  font-size: 14px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  color: #242f42;
  font-weight: 500;
  width: 80px;
  background: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
}

.gh-short-list-item {
  padding: 14px !important;
}
.gh-short-list-item.active {
  background-color: #dfe2e8;
}
.gh-location-list-item {
  display: flex;
  padding: 16px 20px !important;
  background: #fff;
}
.gh-location-list-item.active {
  background-color: #dfe2e8;
}

.gh-location-row {
  width: 100%;
  display: flex;
}

.gh-sl-title,
.gh-sl-secondary {
  font-size: 14px;
  font-family: "rubik", Arial, Helvetica, sans-serif;
}
.gh-sl-title {
  color: #242f42;
  min-width: 150px;
}

.gh-sl-secondary {
  color: #6d7481;
}

.gh-request-info {
  padding: 20px;
  border-radius: 4px;
  background: #fff;
}

.gh-request-info .gh-divider {
  margin: 16px 0;
}

.gh-page-title,
.gh-info-text,
.gh-field-label {
  color: #242f42;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  margin: 0 !important;
}
.gh-page-title {
  font-weight: normal;
}
.gh-info-text {
  font-weight: normal;
  white-space: pre-wrap;
}
.gh-field-label {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px !important;
}
.gh-simple-section {
  background: #fff;
  margin-top: 20px;
  border-radius: 4px;
}

.gh-simple-section_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  margin: 0 -10px;
}

.gh-simple-section_grid * {
  box-sizing: border-box;
}

.gh-simple-section_grid .gh-gi-card {
  overflow: hidden;
  padding: 5px;
}

.gh-simple-section_grid .gh-gi-card .gh-section {
  height: 100%;
}

.gh-long-dropdown-list {
  max-height: initial;
  overflow-y: auto;
}

.gh-locations-list-section h5 {
  margin: 0;
  padding: 12px 18px;
  background: #f2f4f7;
  font-size: 12px;
  color: #242f42;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.gh-review-leg .gh-field-label {
  margin-bottom: 4px !important;
}

.gh-location-fields {
  /*display: flex;*/
  margin-bottom: 15px;
}

.gh-location-fields h5,
.gh-location-fields span,
.gh-additional-info h6,
.gh-datetime-fields h5,
.gh-datetime-fields span,
.gh-simple-text,
.gh-review-grid-section h6 {
  margin: 0 !important;
  font-family: "rubik", Arial, Helvetica, sans-serif;
}

.gh-location-fields h5 {
  font-size: 18px;
  color: #242f42;
  padding-right: 15px;
  font-weight: normal;
}

.gh-location-fields span {
  font-size: 14px;
  color: #6d7481;
}

.gh-location-fields .gh-field-label {
  margin-bottom: 0 !important;
}

.gh-additional-info {
  padding: 15px 12px;
  border-radius: 4px;
  background: #f2f4f7;
}

.gh-additional-info h6 {
  font-size: 11px;
  color: #6d7481;
  font-weight: 500;
  margin-bottom: 4px !important;
  text-transform: uppercase;
}

.gh-additional-info span {
  font-size: 16px;
  color: #242f42;
}

.gh-datetime-review .gh-field-label {
  margin-bottom: 4px !important;
}

.gh-datetime-fields {
  display: flex;
  align-items: center;
}

.gh-datetime-fields h5 {
  color: #242f42;
  font-weight: normal;
  padding-right: 15px;
}

.gh-review-grid-section {
  height: 100%;
}

.gh-review-grid-section .gh-field-label {
  margin-bottom: 20px !important;
  font-weight: 500;
}

.gh-review-grid-section .row {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-bottom: 23px;
}

.gh-review-grid-section .row h6,
.gh-review-grid-section .row span {
  line-height: 20px;
}

.gh-review-grid-section .row .count {
  margin-right: 8px;
  height: 26px;
  border-radius: 3px;
  color: #242f42;
  background: #bdc3cf;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gh-review-grid-section h6 {
  font-size: 16px;
  color: #242f42;
  font-weight: normal;
}
.gh-review-grid-section .row span {
  display: inline-block;
  min-width: 20px;
  text-align: center;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  color: #242f42;
  font-weight: normal;
}

.gh-review-grid-section .gh-divider {
  margin: 18px 0;
}

.gh-review-grid-section .gh-text-bold {
  font-weight: 500;
}

.gh-review-grid-section .gh-simple-text {
  margin-top: 20px !important;
}

.gh-simple-text {
  display: block;
  font-size: 14px;
  color: #6d7481;
  font-weight: 300;
}
.gh-simple-text-request-form {
  display: block;
  font-size: 14px;
  color: #6d7481;
}

.review-preference .gh-section,
.review-notes .gh-section {
  background: #f2f4f7;
}

.review-icon {
  margin-right: 10px;
}
.gh-location-fields img {
  width: 20px;
}
.gh-take-off {
  color: #242f42;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  margin: 0 !important;
  font-size: 14px;
  text-transform: uppercase;
}

.gh-request-gen-btns {
  font-weight: 500;
  padding: 0;
  min-width: 142px;
}
.gh-edit-request-btn,
.gh-edit-request-btn:hover,
.gh-edit-request-btn:active {
  background: #6d7481;
}

.uk-form-controls a:hover {
  text-decoration: none;
}

.gh-flex-box-reverse,
.gh-flex-box {
  display: flex;
}

.gh-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.1;
  z-index: 999;
}
.gh-overlay.visible {
  display: block;
}

.gh-broker-nav-request-modal .request-review .gh-new-request-nav,
.gh-broker-nav-request-modal .request-review .gh-page-title,
.gh-broker-nav-request-modal .request-review .gh-request-review-actions {
  display: none;
}
.gh-broker-nav-request-modal .request-review .uk-section {
  padding-top: 0;
}
.gh-broker-nav-request-modal .request-review .gh-simple-section {
  padding: 0;
}

.gh-broker-nav-request-modal h3 {
  margin-bottom: 0;
}
.gh-broker-nav-request-modal .request-review .gh-field-label {
  font-size: 12px;
}

.gh-review-checkbox {
  font-weight: 300;
  color: #6d7481;
}
.gh-review-checkbox input {
  width: 12px;
  height: 12px;
  padding: 8px !important;
}
.gh-info-additional {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  width: 100%;
}

.uk-tooltip-top-left::after {
  display: none;
}

.gh-dashboard-nav .gh-user-nav .gh-user-initials {
  height: auto;
  line-height: 15px;
}

.gh-dashboard-nav .uk-navbar-dropdown {
  right: 25px !important;
  left: unset !important;
}

.gh-broker-nav-request-modal .request-review .gh-gi-card {
  width: 100%;
}

.gh-additional-btn {
  background: transparent;
  outline: none;
  border: 0;
  padding: 0;
  margin-left: 10px;
}

.gh-hover-el {
  background: #dfe2e8;
}

.gh-private-text {
  z-index: 10;
}

/*.uk-tooltip::after {*/
/*  bottom: 100%;*/
/*  right: 4%;*/
/*  border: solid transparent;*/
/*  content: '';*/
/*  height: 0;*/
/*  width: 0;*/
/*  position: absolute;*/
/*  pointer-events: none;*/
/*  border-color: rgba(255, 255, 255, 0);*/
/*  border-bottom-color: #ffffff;*/
/*  border-width: 6px;*/
/*  margin-left: -6px;*/
/*}*/

.gh-message-body.second-role-body .gh-bottom-line {
  flex-direction: row-reverse;
}

.gh-bottom-line {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -19px;
  color: #6d7481;
}

.gh-seen-message {
  font-family: "rubik", sans-serif !important;
  font-size: 12px !important;
  display: flex;
  margin-right: 2px;
  font-weight: 400;
}

.wrapper-chat-input {
  position: relative;
}

.wrapper-chat-input .uk-input {
  border-radius: 3px;
  height: 50px;
  padding-right: 50px;
}

.wrapper-chat-input .uk-input:focus {
  border-color: #6d7481;
}

.wrapper-chat-input .attach-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  min-width: 50px;
  max-width: 50px;
}

.wrapper-chat-input .attach-btn:disabled {
  opacity: 0.5;
}

.wrapper-chat-input .attach-btn .attach-img {
  padding: 13px;
}

.gh-message-header-posted {
  font-family: "rubik", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
}

.wrapper-media-message {
  margin-bottom: -17px;
}

.gh-message-body.second-role-body .wrapper-media-message .wrapper-bg {
  border-radius: 8px 8px 0 8px;
}

.wrapper-media-message .wrapper-bg {
  width: 220px;
  height: 100px;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 1px 2px 0 rgba(209, 209, 209, 0.5);
  border: solid 1px #e1e1e1;
  cursor: pointer;
  border-radius: 8px 8px 8px 0;
}

.gh-message-body.second-role-body {
  display: flex;
  justify-content: flex-end;
}

.gh-message-body .gh-bottom-line-media {
  display: flex;
  justify-content: space-between;
}

.gh-message-body.second-role-body .gh-bottom-line-media {
  flex-direction: row-reverse;
}

.gh-message-body .uk-badge {
  font-size: 12px;
  border-radius: 5px;
  margin-right: 5px;
  text-transform: uppercase;
}

.gh-message-body.second-role-body .uk-badge {
  background-color: #1abc9c;
  text-transform: uppercase;
}

.wrapper-preview-chat {
  flex-direction: column;
  padding: 15px 15px 10px 10px;
}

.wrapper-download-btn {
  display: flex;
  align-items: center;
  margin: 10px 0 -24px;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.wrapper-download-btn:hover {
  opacity: 1;
}

.wrapper-download-btn .loader {
  cursor: initial;
  border: none;
}

.wrapper-prev-file {
  border: 2px solid #6d7481;
  border-radius: 4px;
  padding: 10px;
}

.wrapper-prev-file .prev-file {
  width: 240px;
  height: 140px;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 1px 2px 0 rgba(209, 209, 209, 0.5);
  border: solid 1px #e1e1e1;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
}

.wrapper-prev-file .prev-file.--doc {
  display: flex;
  width: fit-content;
  height: auto;
  padding: 10px 50px 10px 10px;
}

.wrapper-prev-file .prev-file.--doc .prev-file-icon {
  margin-right: 15px;
}

.wrapper-prev-file .prev-file .prev-file-close {
  display: inline-block;
  max-width: 22px;
  max-height: 22px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 3px;
  margin-right: 5px;
}

.wrapper-prev-file .prev-file .prev-file-close button {
  line-height: 0;
  min-width: 100% !important;
  background-color: #dfe2e8;
  padding: 5px;
  border-radius: 50px;
  opacity: 1;
}

.wrapper-prev-file .prev-file .prev-file-close button:hover {
  opacity: 0.7;
}

.gh-dashboard-nav .gh-user-nav .gh-user-initials {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(109, 116, 129, 0.25);
  color: #16bc9c;
  padding: 0;
  border-radius: 50%;
  line-height: 15px;
  font-size: 14px;
  font-weight: 500;
}

.gh-dashboard-nav .gh-user-nav-mobile {
  padding-left: 24px;
}

@media (max-width: 1200px) {
  .gh-dashboard-nav .gh-user-nav-mobile .gh-user-initials {
    line-height: 14px;
  }
}
.flag-request {
  min-width: 33px;
}
.flag-request:disabled {
  opacity: 0.8;
  pointer-events: none;
}
.flag-request svg {
  margin-bottom: 0;
}
.flag-request svg path {
  transition: 0.15s ease-in;
}
.flag-request[data-flagged="true"] path {
  stroke: #ffab00;
  fill: #ffab00;
}
.flag-request:hover path {
  stroke: #7d7f81;
}
.flag-request_wrapper {
  position: absolute;
  top: 0;
  right: 25px;
}
.flag-request_wrapper .gh-table-actions {
  border-top: 0 !important;
}
button.gm-ui-hover-effect {
  display: none !important;
}
.markerLabel {
  padding: 3px;
  background: #000;
  color: #fff !important;
  margin-top: 20px !important;
  font-size: 12px;
  border: 2px solid white;
  border-radius: 8px;
  margin-right: 20px;
}
.arrowInfoWindow {
  border: 2px solid white;
  border-radius: 8px;
  padding: 5px;
  background: #242f42;
  color: #fff;
  font-weight: 600;
}

.legInfoWindow {
  max-width: 320px;
  user-select: none;
}

.legInfoWindow .legInfoWindowActionLegs {
  display: flex;
}

.legInfoWindow .legInfoWindowActionLegs a {
  font-size: 14px;
  font-weight: 500;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  color: #0ab897;
}

.legInfoWindow .legInfoWindowActionLegs a:hover {
  color: #0b7762;
}

.legInfoWindow .imageBox {
  width: 320px;
  height: 160px;
  overflow: hidden;
  max-width: 320px;
}

.legInfoWindow .content {
  padding: 15px;
}

.legInfoWindow .content h4 {
  font-size: 16px;
  font-weight: 500;
  font-family: "rubik", Arial, Helvetica, sans-serif;
  margin: 0;
}

.legInfoWindow .content a {
  text-decoration: none;
  outline: none;
}

.legInfoWindow .content .item-icon {
  display: flex;
  align-content: center;
}

.legInfoWindow .content .item-icon img {
  max-width: 14px;
}

.legInfoWindow .content .item-icon .text {
  padding-left: 8px;
}

.legInfoWindow .content .top-head {
  margin-bottom: 15px;
  display: flex;
  line-height: 1;
}

.legInfoWindow .content .bottom-head {
  display: flex;
  margin-top: 24px;
  margin-bottom: 0;
}

.legInfoWindow .content .bottom-head img {
  margin-left: 12px;
}

.legInfoWindow .content .secondary {
  color: #10b897;
}
/*.gm-style-iw-t::after {*/
/*  display: none !important;*/
/*}*/

@media (max-width: 500px) {
  .navbar-logo-item img {
    width: 130px;
  }
}

.wrapper-modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.gh-select-field,
.gh-select-field:focus {
  border: 2px solid #6d7481;
  border-radius: 4px;
  background: #fff;
  color: #242f42;
}

.gh-quote-slider-text {
  box-sizing: initial !important;
}

.uk-offcanvas-close {
  background: transparent;
  border: 0;
}

.gh-offcanvas-bar-logged {
  background: #242f42;
  transform: translateY(86px);
  width: 270px;
  padding: 20px;
}

.gh-offcanvas-bar-logged ul:first-child {
  margin-top: 40px;
}

.gh-offcanvas-bar {
  background: #242f42;
  transform: translateY(86px);
  width: 270px;
  padding: 20px;
}
.gh-offcanvas-bar ul li {
  text-align: left;
  padding: 20px 0 0 0;
}

.gh-offcanvas-bar ul li:first-child {
  padding: 0 0 15px 0 !important;
}

.gh-offcanvas-bar ul .list-link {
  padding: 15px 0;
  border-bottom: 1px solid #6d7481;
}

.gh-offcanvas-bar ul li a {
  color: #ffffff !important;
  font-size: 15px;
  font-family: Rubik, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.gh-dashboard-logout-header-nav .gh-offcanvas-bar ul li a {
    text-transform: none;
}

.gh-dashboard-logout-header-nav .mobile-subnav .subnavbtn {
    text-transform: none;
}

.gh-offcanvas-bar ul li .uk-button-default {
  border: 2px solid #ffffff !important;
}

.gh-offcanvas-bar ul li .uk-button {
  height: 53px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-mobile-book-btn {
  background: #10b997 !important;
}

.gh-bg-transparent {
  background: transparent !important;
}

.gh-button-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}

.gh-invisible {
  visibility: hidden;
}

.gh-enquire .uk-input::placeholder {
  opacity: 0.3;
}
