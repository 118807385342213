.gh-wallet-component-wrapper {
  background: #fff;
  border-radius: 4px;
}

.gh-wallet-component-bottom-section {
  margin-top: -10px;
}

.gh-wallet-component-divider {
  margin: 0 0 20px;
}

@media only screen and (max-width: 576px) {
  .gh-wallet-component-wrapper {
    background: initial;
  }

  .gh-wallet-component-bottom-section {
    margin-top: 0;
    padding: 0;
  }

  .gh-wallet-component-divider {
    margin-top: 20px;
  }
}
