.gh-quote-details-manage-buttons-wrapper {
  margin-bottom: 14px;
}

.gh-quote-details-manage-buttons-left-wrapper {
  display: flex;
}

.gh-quote-details-manage-buttons-left-wrapper .gh-quote-details-manage-buttons:first-child {
  margin-right: 40px;
  text-align: start;
}

.gh-quote-amend-modal-wrapper .gh-quote-amend-modal-btn:first-child {
  margin-right: 0.6rem;
}

.gh-quote-amend-modal-wrapper .gh-main-button-component-success.gh-main-button-component-contained {
  color: #fff;
}

.gh-quote-amend-modal-wrapper .gh-quote-amend-modal-btn {
  padding: 11px 0;
  min-width: 145px;
  font-weight: 400;
}

@media only screen and (max-width: 425px) {
  .gh-quote-details-manage-buttons-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .gh-quote-amend-modal-wrapper .gh-quote-amend-modal-btn {
    min-width: 130px;
  }

  .gh-quote-details-manage-buttons-left-wrapper {
    width: 100%;
    justify-content: space-between;
  }

  .gh-quote-details-manage-buttons-left-wrapper .gh-quote-details-manage-buttons:first-child {
    margin-right: 5px;
  }

  .gh-quote-details-manage-buttons-left-wrapper .gh-quote-details-manage-buttons {
    text-align: start;
  }
}
