.gh-helicopter-suggestions .embla {
  padding: 0;
  max-width: initial;
}

.gh-helicopter-suggestions .embla__slide {
  min-width: 400px;
  max-width: 400px;
}

.gh-helicopter-suggestions .embla__slide__inner {
  /*height: 411px;*/
  height: initial;
  display: flex;
}

.gh-helicopter-suggestions .gh-heli-card.gh-padding-small {
  padding: 0;
  border: 1px solid #d6d6d5;
}

.gh-helicopter-suggestions .embla {
  background: transparent;
}

.gh-helicopter-suggestions .embla__button,
.gh-helicopter-suggestions .embla__dots {
  display: none;
}

.gh-helicopter-suggestions-next-btn {
  transform: rotate(180deg);
}

.gh-heli-suggestions-slider-btn {
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0 5px;
}

.gh-helicopter-suggestions .title-item {
  text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
  .gh-helicopter-suggestions .embla__slide {
    min-width: 90%;
  }
}
