.gh-review-quote .custom-tooltip-wrapper {
  display: none !important;
}

.gh-review-quote .gh-quote-price-col.radio .gh-view-price {
  height: initial;
}

.gh-review-quote .gh-quote-price-row.radio {
  padding: 7px 20px;
}

.gh-quote-price-row.radio:first-child {
  padding-top: 20px;
}

.gh-quote-price-row.radio:last-child {
  padding-bottom: 20px;
}

.gh-review-quote .gh-quote-price-col:not(.gh-quote-price-col.radio) .gh-quote-price-row {
  padding-bottom: 0px;
}

.gh-review-quote
  .gh-quote-price-col:not(.gh-quote-price-col.radio)
  .gh-quote-price-row:not(.total) {
  padding: 5px 0;
}

.gh-review-quote
  .gh-quote-price-col:not(.gh-quote-price-col.radio)
  .gh-quote-price-row:not(.total):nth-child(3) {
  padding-top: 10px;
}

.gh-review-quote
  .gh-quote-price-col:not(.gh-quote-price-col.radio)
  .gh-quote-price-row:not(.total)
  .gh-view-price {
  height: initial;
}
.gh-review-quote
  .gh-quote-price-col:not(.gh-quote-price-col.radio)
  .gh-quote-price-row:not(.total)
  .gh-quote-price-title {
  margin-top: 0;
}

.gh-quote-view-expiration-date {
  display: flex;
}

/***************************************  Media  ****************************************************/

@media (max-width: 767px) {
  .gh-quote-view-expiration-date {
    flex-direction: column;
  }


}