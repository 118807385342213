.gh-broker-client-reference-autocomplete .autocomplete-options {
    max-height: 450px;
}

.gh-broker-client-reference-autocomplete .autocomplete-suggestion:last-child {
    border-bottom: 1px solid #6d7481;
}

.gh-broker-client-reference-add-new-btn {
    position: sticky;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: #fff;
    width: 100%;
    height: 45px;
    justify-content: flex-start;
    padding: 0 10px;
    font-weight: 400;
    border-top: 1px solid #6d7481;
    border-radius: initial;
}

.gh-broker-client-reference-add-new-btn:hover {
    opacity: 1;
    background-color: #f2f2f2;
}

.gh-external-link-svg {
    display: inline-block;
    transform: translateY(-2px);
    margin-left: 5px;
}

.gh-broker-client-reference-autocomplete-wrapper {
    position: relative;
}

.gh-broker-client-reference-autocomplete-error {
    position: absolute !important;
}
