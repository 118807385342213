.gh-quote-card_operator-company {
  font-family: "rubik";
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
  text-align: right;
  color: #fff;
  background-color: #242f42;
  padding: 0 50px;
  border-radius: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gh-quote-card_operator-company.absolute {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
