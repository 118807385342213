.gh-info-container-warning-box {
  display: flex;
  padding: 15px;
  border-radius: 4px;
  background: #f2f4f7;
  align-items: center;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #242f42;
}

.gh-info-container-warning-box--with-title {
  padding: 10px;
}

.gh-info-container-icon-wrapper {
  margin-right: 10px;
  min-width: 21px;
  min-height: 18px;
}

p.gh-info-container-title {
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
  color: #6d7481;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.gh-info-container-text {
  font-size: 14px;
  line-height: 17px;
}
