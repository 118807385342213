.gh-wrapper-progress-grafic-btn {
  display: flex;
  flex-direction: column;
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 3px;
}

.gh-wrapper-progress-grafic-btn.--active {
  border: 1px solid #ffab00;
}

.gh-wrapper-progress-grafic-btn:hover {
  opacity: 0.9;
}

.gh-wrapper-progress-grafic-btn .gh-leg-link-customs-text {
  left: 0;
  position: initial;
  white-space: nowrap;
}


/*------------- animation for btn ----------------*/
@keyframes shadow-pulse-btn {
  0% {
    box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(26, 188, 156, 0.2);
  }
}

@keyframes shadow-pulse-exit-btn {
  100% {
    box-shadow: 0 0 0 10px rgba(26, 188, 156, 0.2);
  }
  0% {
    box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5);
  }
}

.chart-btn-pulse {
}

.chart-btn-pulse-enter {
  box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5);

}

.chart-btn-pulse-enter-active {
  animation-name: shadow-pulse-btn;
  animation-duration: 2s;
}

.chart-btn-pulse-exit {
  box-shadow: 0 0 0 20px rgba(26, 188, 156, 0.2);
}

.chart-btn-pulse-exit-active {
  animation-name: shadow-pulse-exit-btn;
  animation-duration: 2s;
}
