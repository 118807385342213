.gh-event-detail.gh-custom-event .gh-button-link.uk-button.uk-button-default {
    background-color: #10b897;
    color: #FFFFFF;
    margin: 0 auto;
    padding: 16px 30px;
    line-height: 16px;
}

.gh-event-detail.gh-custom-event .gh-custom-event-header {
    display: flex;
}

.gh-event-detail.gh-custom-event .gh-custom-event-header-info {
    flex: 1;
}

.gh-event-detail.gh-custom-event .gh-custom-event-header-button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.gh-enquire {
    padding: 10px;
}




/*===========Optimizing for 13, 14, 15 inch screen height===========*/
@media (min-width: 1279px) and (max-height: 1080px) {

    .gh-enquiry-form-sticky {
        position: sticky;
        top: 8px;
        bottom: 8px;
        max-height: calc(100vh - 16px);
    }

    .gh-enquiry-form-sticky.options {
        overflow-y: scroll;
        position: sticky;
        right: 0;
    }

    /*step 1*/
    .gh-enquire-title {
        margin-top: 5px;
    }

    .gh-step-num-box {
        height: initial;
    }

    .gh-enquire-content,
    .enquire-form {
        padding-top: 0;
    }

    .enquire-form .gh-padding-small {
        padding-bottom: 5px;
    }

    .enquire-form .enquire-form-content {
        margin-top: 10px;
    }

    .enquire-form .gh-height-reduce.uk-margin-bottom {
        margin-bottom: 8px !important;
    }

    .enquire-form .gh-height-reduce-label {
        margin-top: 0 !important;
        margin-bottom: 5px !important;
    }

    .enquire-form .gh-number-label {
        margin-top: 0 !important;
    }

    .enquire-form .enquire-form-content .gh-counter {
        height: 40px;
        width: 40px;
    }

    /*.enquire-form .gh-enquire-calendar {*/
    /*    margin-bottom: 10px;*/
    /*    margin-top: 10px;*/
    /*}*/

    .gh-enquire .uk-form-label {
        margin-top: 0;
        margin-bottom: 3px;
    }

    .enquire-form .uk-width-1-1.uk-margin-top {
        margin-top: 10px;
    }

    .gh-enquire-actions {
        padding-top: 10px;
        padding-bottom: 0;
    }

    .gh-steps:before {
        top: 22px;
        margin: 0;
    }

    /*step 2*/
    .gh-enquiry-form-sticky .helicopters-list-content {
        max-height: initial !important;
    }

    .gh-enquire-title.gh-padding-small {
        padding-top: 8px !important;
    }

    .gh-steps.gh-padding-small {
        padding-bottom: 0 !important;
    }

    .gh-enquire-actions .gh-main-button-component {
        min-width: 150px;
    }

    .gh-enquire-actions.--heli-options {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 6px;
        z-index: 100;

    }

    /*step 3*/
    .enquire-additional-content {
        margin-top: 10px;
    }

    .enquire-additional-content .uk-margin-top {
        margin-top: 5px !important;
    }

    .enquire-additional .uk-textarea {
        height: 115px;
    }

    .gh-enquire-privacy-policy {
        line-height: 15px;
        margin-top: 10px !important;
    }

}

@media (max-width: 768px) {
    .gh-event-detail.gh-custom-event .gh-custom-event-header {
        flex-direction: column;
    }

    .gh-event-detail.gh-custom-event .gh-custom-event-header-button {
        margin-top: 10px;
    }
}