.uk-back-link {
  color: rgb(36, 47, 66);
}

.uk-green-color {
  color: #1abc9c !important;
}

.uk-back-link:hover {
  color: #666;
}

.gh-message-body .gh-seen-message {
  font-size: 11px !important;
  font-weight: 300 !important;
  opacity: 0.7;
}

.gh-message-body .gh-message-header-posted {
  font-size: 11px !important;
  font-weight: 300 !important;
  opacity: 0.7;
}

.wrapper-media-message .wrapper-bg {
  width: 280px !important;
  height: 125px !important;
}

#discussion-booking-modal .uk-modal-body {
  padding: 15px;
}

.wrapper-modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Checkboxes */
.gh-checked-icon {
  position: relative;
  padding-left: 20px;
}

.gh-checked-icon::before {
  content: "";
  position: absolute;
  top: 2px;
  left: -3px;
  width: 17px;
  height: 15.94px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 2L6.0625 10.9375L2 6.875' stroke='%2310B897' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-size: 17px 15.94px;
  background-position: 50% 50%;
}

.gh-checkbox-label,
.gh-radio-label,
.gh-inline-numbers .uk-form-label {
  color: #6d7480;
}

.uk-checkbox:not(:checked):focus {
  border-color: #242f42;
}

.gh-padding-bottom--15 {
  padding-bottom: 15px !important;
}

.gh-input-errors_static li {
  position: unset !important;
}

#home-register-modal {
  background-color: rgba(0, 0, 0, 0.35);
}

.btn-style {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.btn-style-disabled {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: not-allowed;
}

.gh-sort-btn-loading {
  cursor: default;
}

#home-register-modal .modal-banner img {
  max-width: 100%;
}
.gh-request-data-fields .gh-inline-numbers .uk-form-label {
  text-transform: unset;
}
#home-register-modal {
  background-color: rgba(0, 0, 0, 0.35);
}

#home-register-modal .uk-modal-dialog {
  max-width: 640px !important;
}

#home-register-modal .uk-button.uk-button-primary {
  border: 2px solid #1abc9c;
}

#home-register-modal .uk-margin-medium-top,
#home-register-modal .uk-grid,
#home-register-modal .uk-grid-margin {
  margin-top: 5px !important;
}

.stripe-accordion .uk-button-primary {
  background-color: #6976e3 !important;
}

.stripe-accordion .uk-button {
  width: 100%;
  max-width: 442px !important;
}

.stripe-accordion .gh-terms-btns-bank .uk-button {
  width: unset;
}

.stripe-accordion .gh-button-terms-bank {
  width: 100% !important;
}

.stripe-accordion .gh-terms-btns-bank {
  margin: 0 auto;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 20px !important;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  #home-register-modal .uk-margin-medium-top,
  #home-register-modal .uk-grid,
  #home-register-modal .uk-grid-margin {
    margin-top: 5px !important;
  }

  #home-register-modal .buttons {
    margin-top: 24px !important;
  }

  @media (max-width: 600px) {
    .stripe-accordion .gh-terms-btns-bank .uk-button,
    .stripe-accordion .uk-button {
      width: 100%;
      padding: 2px 10px;
    }

    .stripe-accordion .gh-terms-btns-bank {
      flex-direction: column;
      gap: 15px !important;
    }
  }

  #home-register-modal .modal-banner {
    background-color: #242f42;
    padding: 6px 0;
  }
}

.uk-back-link {
  color: rgb(36, 47, 66);
}

.uk-back-link:hover {
  color: #666;
}

#amend-quote-confirmation .gh-decline-btn {
  width: 100%;
  min-width: unset;
}
.stripe-accordion .uk-width-expand.uk-first-column,
.stripe-accordion .gh-alert-without-bg {
  text-align: left !important;
  font-weight: 500;
  color: #4c5059;
}

.stripe-accordion .gh-accordion-section.uk-open {
  position: relative;
  padding-bottom: 44px;
}

.stripe-accordion .just-accordion_footer {
  background-color: #243041;
  padding: 13.5px 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  color: #ffffff;
  font-size: 16px;
}

.stripe-accordion .just-accordion_footer span:first-of-type {
  line-height: 1;
}

.stripe-accordion .just-accordion_footer img {
  width: 60px;
  transform: translateY(-2px);
}

.stripe-accordion .just-accordion_footer .custom-tooltip-icon {
  margin-right: 20px;
}

.custom-tooltip-icon {
  display: flex;
  justify-content: center;
}

.custom-tooltip-content {
  padding: 8px 10px !important;
}

.stripe-accordion .just-accordion_footer .icon {
  width: 18px;
  transform: translateY(-1px);
}

.stripe-accordion .just-accordion_form .validation-wrapper {
  height: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.stripe-accordion .just-accordion_form .input-wrapper {
  flex: 1;
  max-width: 400px;
}

.uk-accordion-status-persist::before {
  display: none !important;
}
.stripe-accordion .just-accordion_form label {
  white-space: nowrap;
  text-transform: unset;
}

.stripe-accordion .just-accordion_form input {
  max-width: 400px;
}

.stripe-accordion .gh-accordion-title {
  font-size: 20px !important;
  padding: 20px 30px !important;
  font-weight: 400 !important;
}

@media (max-width: 720px) {
  .stripe-accordion .gh-accordion-title {
    font-size: 17px !important;
  }
}

@media (max-width: 650px) {
  .stripe-accordion .just-accordion_form .validation-wrapper {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  .stripe-accordion .just-accordion_form .input-wrapper {
    width: 100%;
  }

  .stripe-accordion .just-accordion_form input {
    max-width: unset;
  }
}

.gh-request-data-fields .gh-inline-numbers .uk-form-label {
  text-transform: unset;
}
.gh-broker-nav-request-modal .uk-button {
  font-weight: 500 !important;
}

.legs-modal_component {
  width: 600px !important;
  padding: 20px 20px 6px;
}

.legs-modal_component .dir-item {
  display: flex;
}

.legs-modal_component .dir-name {
  margin-left: 10px;
  color: #000;
  display: block;
}

.legs-modal_component .dir-label {
  min-width: 40px;
  display: block;
}

.legs-modal_component .legs-modal_request {
  border-top: 2px solid #d9d9dc;
  padding: 8px 0px;
}

.legs-modal_component header h3 {
  color: #10b897;
  margin-bottom: 6px !important;
  font-size: 22px;
}

@media (max-width: 540px) {
  .legs-modal_component .dir-container {
    margin-left: 0px !important;
    font-size: 15px;
  }
  .legs-modal_component h5 {
    font-size: 15px;
  }
  .legs-modal_component .dir-name {
    margin-left: 5px;
  }
  .legs-modal_component header h3 {
    font-size: 20px;
  }
  .legs-modal_component .uk-margin-small-bottom {
    margin-bottom: 6px !important;
  }
  .legs-modal_component .legs-modal_request {
    flex-direction: column;
  }
  .legs-modal_component .dir-container .uk-margin-small-bottom {
    margin-bottom: 0 !important;
  }
}

.gh-broker-nav-request-modal .uk-button {
  font-weight: 500 !important;
}

#full-request-modal .uk-modal-dialog {
  max-width: 900px !important;
  width: calc(100% - 10px - 10px) !important;
}

#full-request-modal .d-flex_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#full-request-modal .d-flex_inner {
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  #full-request-modal .d-flex_container {
    flex-direction: column;
  }

  #full-request-modal .d-flex_container .uk-margin-top:not(:first-child) {
    margin-top: 10px !important;
  }

  #full-request-modal .d-flex_container .gh-main-button-component {
    margin: 0 0 10px !important;
    width: 100%;
  }

  #full-request-modal .d-flex_inner {
    width: 100%;
    flex-direction: column;
    align-items: unset;
  }

  #full-request-modal .d-flex_inner .uk-margin-small-right {
    margin-right: 0 !important;
  }
}
.new-request_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 1280px) {
  .new-request_flex-item {
    flex: 1;
    width: 50%;
  }
}

@media (max-width: 760px) {
  .new-request_flex {
    flex-direction: column;
  }

  .new-request_flex-item {
    flex: 1;
    width: 100%;
  }
}

.booking-layout .gh-discussion-btn.operator {
  justify-content: center;
}

@media (max-width: 768px) {
  .booking-layout .gh-discussion-btn_envelope {
    margin-top: 0;
  }
}

@media (max-width: 640px) {
  #home-register-modal .modal-banner {
    background-color: #242f42;
    padding: 6px 0;
    max-height: 33px;
  }
}

@media (max-width: 540px) {
  .legs-modal_component .dir-container {
    margin-left: 0px !important;
    font-size: 15px;
  }
  .legs-modal_component h5 {
    font-size: 15px;
  }
  .legs-modal_component .dir-name {
    margin-left: 5px;
  }
  .legs-modal_component header h3 {
    font-size: 20px;
  }
  .legs-modal_component .uk-margin-small-bottom {
    margin-bottom: 6px !important;
  }
  .legs-modal_component .legs-modal_request {
    flex-direction: column;
  }
  .legs-modal_component .dir-container .uk-margin-small-bottom {
    margin-bottom: 0 !important;
  }
}

.inline-edit_wrapper .inline-edit_input,
.inline-edit_wrapper .inline-edit_actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.inline-edit_wrapper .uk-input {
  height: 38px;
}

.inline-edit_wrapper input:focus {
  border-color: #1abc9c;
}

.inline-edit_wrapper .inline-edit_action {
  width: 38px;
  height: 38px;
  min-width: unset;
  padding: 0px;
  border: 2px solid #6d7481;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease-in;
  cursor: pointer;
}

.inline-edit_wrapper .inline-edit_action.success:active {
  border-color: #1abc9c;
}

.inline-edit_wrapper .inline-edit_action.success:active svg path {
  fill: #1abc9c;
}

.inline-edit_wrapper .inline-edit_action.error:active {
  border-color: rgba(231, 76, 60, 1);
}

.inline-edit_wrapper .inline-edit_action.error:active svg path {
  fill: rgba(231, 76, 60, 1);
}

.inline-edit_wrapper .inline-edit_action svg {
  width: 18px;
  height: 18px;
  transition: 0.1s ease-in;
}

.inline-edit_wrapper .inline-edit_action svg path {
  fill: rgb(48, 48, 48);
}

.inline-edit_wrapper .inline-edit_content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.inline-edit_wrapper .inline-edit_content.true {
  cursor: pointer;
}

.inline-edit_wrapper .inline-edit_content .inline-edit_action {
  width: unset;
  height: unset;
  border: none;
  padding: 0px;
  background: transparent;
  min-width: unset;
}

.inline-edit_wrapper .inline-edit_content .inline-edit_action svg {
  width: 14px;
  height: 14px;
}

.inline-edit_wrapper .inline-edit_input {
  position: relative;
}

.inline-edit_wrapper .inline-edit_input .validation-errors {
  position: absolute !important;
  left: 0;
  top: 100%;
  width: 100%;
}

@media (max-width: 600px) {
  .inline-edit_wrapper .inline-edit_action {
    width: 30px;
    height: 30px;
  }
  .inline-edit_wrapper .inline-edit_action svg {
    width: 15px;
    height: 15px;
  }

  .inline-edit_wrapper .uk-input {
    height: 30px;
  }
}

#full-quote-modal .pl-15 {
  padding-left: 15px !important;
}
.uk-accordion-status-persist::before {
  display: none !important;
}
.new-request .gh-enquire-calendar-icon,
.gh-calendar-label.disabled {
  pointer-events: none;
}

@media (min-width: 1200px) {
  #broker-withdraw-request .uk-modal-dialog {
    width: unset;
  }
}

#broker-withdraw-request .uk-modal-dialog {
  padding: 10px;
}

#broker-withdraw-request .uk-modal-close-default {
  top: 15px;
  right: 15px;
}

#broker-withdraw-request .uk-modal-dialog section {
  padding: 20px;
  background-color: #f2f4f7;
}

#broker-withdraw-request header h3 {
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 24px !important;
}

#broker-withdraw-request .uk-button {
  padding: 0 10px !important;
  margin: 0 !important;
  font-weight: 500;
}

#broker-withdraw-request .wrapper-modal-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

#full-request-modal .uk-modal-dialog {
  max-width: 1200px !important;
  width: 100%;
}

@media (min-width: 1200px) {
  #broker-withdraw-request .uk-modal-dialog {
    width: unset;
  }
}

#full-request-modal .gh-info-additional {
  overflow: unset;
  white-space: unset;
}

@media (max-width: 768px) {
  #broker-withdraw-request .react-modal-content button,
  #broker-withdraw-request .react-modal-content a {
    line-height: 50px;
  }

  #full-request-modal {
    padding: 0;
  }

  #full-request-modal .uk-modal-body {
    padding: 24px 15px;
  }

  #broker-withdraw-request .wrapper-modal-buttons {
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  #broker-withdraw-request .wrapper-modal-buttons {
    flex-direction: column;
  }

  #broker-withdraw-request .react-modal-content button {
    width: 100%;
    max-width: 224px;
    line-height: 46px;
  }
}

.gh-showmore-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  max-width: calc(100vw - 140px);
}

.gh-showmore-text_btn {
  transform: translateY(2px);
  min-width: unset !important;
  padding: 0 !important;
  line-height: 1;
}

.gh-showmore-text_btn img {
  min-width: 24px;
  height: 24px;
}

.pipelines-table .uk-button {
  min-width: 100px;
  padding: 2px 15px;
}

.line-clamp {
  color: unset;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pipline-view-modal .uk-button {
  line-height: 44px;
  padding: 2px 15px;
}

.pipeline-block {
  margin-bottom: 10px;
}

.pipeline-block .title {
  margin: 0;
}

.pipeline-block .descrp {
  margin: 0;
}

.pipeline-block .uk-select {
  max-width: 200px;
  height: 40px !important;
  line-height: 40px;
  margin-top: 5px;
}

.pipeline-block .uk-textarea {
  margin-top: 5px;
}

.pipeline-block .action-title .title {
  line-height: 23px;
}

.pipeline-block .action-title .uk-button {
  margin-left: 5px;
  line-height: 1.4;
  padding: 2px 10px;
  min-width: unset;
}

.gh-pipeline-status {
  color: rgba(255, 171, 0, 1);
}

.status-flight-line {
  display: grid;
}

.status-flight-line:after {
  content: "";
  border: 1px solid #d6d6d5;
  width: 12px;
  transform: rotate(90deg);
  margin-top: 14px;
  margin-left: 2px;
}

.status-flight-line:before {
  content: "";
  border: 1px solid #d6d6d5;
  width: 12px;
  transform: rotate(90deg);
  margin-bottom: 14px;
  margin-left: 2px;
}

.quote-summary_operator-company {
  margin-top: -5px;
  margin-bottom: 5px;
  column-gap: 10px;
}

.quote-summary_operator-company .label {
  font-size: 17px;
}

.quote-summary_operator-company .value {
  font-size: 17px;
  color: #6d7481;
}

.gh-quote-detail-with-overlay {
  padding-top: 50px;
}

@media (max-width: 410px) {
  .booking-layout-title_wrapper .uk-width-1-2 {
    width: 100%;
  }

  .booking-layout-title_wrapper {
    flex-direction: column;
  }

  .booking-layout-title_wrapper .uk-flex-right {
    display: block;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.gh-home-static-content,
.gh-home-static-content > * {
  color: #fff;
  font-size: 16px;
  line-height: normal;
}

.gh-review-luggage {
  margin-top: 23px;
}

.gh-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.gh-confirm-request-modal-text {
  display: block;
  margin-bottom: 25px;
}

.gh-review-request-modal-buttons {
  display: flex;
  justify-content: center;
}

.gh-review-request-modal-buttons .gh-main-button-component {
  margin: 10px;
}

.gh-company-table-align-last-cell {
  width: 65px;
  text-align: center;
}

.available-countries-btn {
  padding: 0;
  min-width: unset;
  min-height: unset;
  height: unset;
  line-height: 6px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  color: #1abc9c;
}

.available-countries-btn .gh-link-icon {
  margin-left: 8px;
}

.gh-leg-link-customs {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rubik, san-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  width: 26px;
  height: 26px;
  background-color: #10b997;
  border-radius: 3px;
  position: relative;
}

.gh-wrapper-progress-grafic-icon .gh-leg-link-customs-text {
  position: initial;
}

.gh-leg-link-customs-text {
  position: absolute;
  color: #10B997;
  bottom: 0;
  margin-bottom: -20px;
  font-size: 12px;
  font-weight: 400 !important;
}

.gh-leg-link-customs:hover {
  color: #ffffff;
  opacity: 0.9;
}

.gh-wrapper-progress {
  display: flex;
  align-items: center;
  min-height: 42px;
}

.gh-wrapper-progress-grafic-icon {
  display: flex;
  align-items: center;
  width: 26px;
  min-width: 26px;
}

.gh-progress-info-modal {
  max-width: 640px !important;
  padding: 20px !important;
  transition: .4s max-width ease-out;
}

.gh-progress-info-modal.small-modal {
  max-width: 520px !important;
}

.gh-progress-info-modal header {
  border-bottom: 1px solid #d6d6d5;
}

.gh-progress-info-modal header h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px !important;
}

.helicopter-event-tooltip {
  font-size: 14px !important;
  text-transform: initial !important;
  font-weight: normal !important;
  min-width: 50px;
  max-width: 250px;
  pointer-events: none;
}

.bp3-popover2-transition-container {
  z-index: 9999 !important;
}

@media only screen and (min-width: 640px) {
  .gh-empty-leg-filter-field .autocomplete-dropdown-container,
  .gh-filter-tabs + .uk-card-default .autocomplete-dropdown-container,
  .gh-location-autocomplete-marketplace-filters .autocomplete-dropdown-container {
    width: 500px;
  }
}

@media only screen and (min-width: 1024px) {
  .gh-empty-leg-filter-field .autocomplete-dropdown-container,
  .gh-filter-tabs + .uk-card-default .autocomplete-dropdown-container,
  .gh-location-autocomplete-marketplace-filters .autocomplete-dropdown-container {
    width: 720px;
  }
}

.gh-payment-form .field label span {
  font-family: Rubik serif;
  font-size: 14px;
  color: #242f42;
  font-weight: 500;
}

.backBtn {
  background: transparent;
  cursor: pointer;
}

.gh-payment-form h3,
.backBtn {
  font-family: Rubik, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #242f42;
  border: 0;
}

.gh-payment-form .field {
  display: block;
}

.gh-payment-form .field span {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #242f42;
}

.gh-payment-form .field .input,
.gh-payment-form .field .focused,
.gh-payment-form .field .empty {
  background: #e6f2f0;
  border: 1px solid #d5d5d5;
  height: 50px;
  display: flex !important;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
  font-weight: 400;
}

.gh-section-title-large {
  font-family: Rubik, sans-serif;
  font-weight: normal;
  color: #242f42;
  font-size: 24px;
  margin-bottom: 15px;
}

.gh-payment-form-btns button,
.gh-payment-form-btns button:hover {
  border: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}

.gh-payment-form-btns .uk-button-primary {
  background: transparent;
  color: #1abc9c;
}
@media (max-width: 567px) {
  .gh-section-title-large {
    font-size: 18px;
    margin-top: 20px;
  }
  .gh-payment-form-btns {
    display: flex;
    justify-content: space-between;
  }
}

.invalid {
  border: 1px solid #e25950 !important;
}

.invalid .form-item-error {
  color: #e25950 !important;
  font-size: 12px !important;
}

.invalid::placeholder {
  color: #e25950 !important;
}

.gh-section-title-medium {
  font-family: Rubik, sans-serif;
  font-weight: normal;
  color: #242f42;
  font-size: 18px;
}

.gh-row-info-title {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #6d7481;
  margin-bottom: 5px;
}

.gh-row-info-text,
.gh-payment-leg-row .gh-site-title {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #242f42;
}

.gh-main-page-video {
  margin-bottom: 90px;
  display: flex;
  justify-content: center;
}

.gh-main-page-video .uk-section {
  padding: 0 35px;
}

.gh-marketplace-wrapper-tooltip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 26px;
  text-align: center;
}

.gh-marketplace-wrapper-tooltip .custom-tooltip-content {
  width: fit-content !important;
}

.gh-marketplace-wrapper-envelope-tooltip .custom-tooltip-content {
  right: 43px !important;
}

.gh-after-submit {
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gh-after-submit .image {
  width: 55px;
}

.gh-after-submit .title {
  font-size: 24px;
  margin: 15px 0 10px 0;
  text-align: center;
}

.gh-after-submit .message {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #242f42;
}

.gh-after-submit .message p {
  margin: 5px 0;
}

.uk-navbar li {
  font-size: 16px;
}
.gh-enquiry-form-swap-wrapper {
  position: relative;
  height: 10px;
}
.gh-enquiry-form-swap-wrapper button.gh-enquiry-form-swap-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  min-width: initial;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 3;
}
.gh-enquire-swap-locations-icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gh-animation-flip-exit-active {
  animation: flip 0.3s ease-out;
  transform-origin: center center;
}
@keyframes flip {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

.gh-payment-table-btn {
  padding: 0 8px;
  margin: 0;
  min-width: fit-content;
  line-height: 23px;
  font-size: 14px;
}

.gh-yellow-alert {
  width: 100%;
  padding: 0;
  background: rgba(255, 171, 0, 1);
  color: #ffffff;
  margin-bottom: 0;
}

.gh-yellow-alert .uk-alert-close {
  top: 15px;
}

.gh-yellow-alert .uk-alert-close svg line {
  stroke-width: 1.5;
  fill: #ffffff;
}

.gh-yellow-alert .gh-alert-message {
  padding: 10px 40px;
  text-align: center;
  font-size: 18px;
}

@media (max-width: 560px) {
  .gh-yellow-alert .gh-alert-message {
    font-size: 15px;
  }
}
.gh-text-danger {
  color: #e74c3c;
}

.gh-text-warning {
  color: #ffab00;
}

.gh-text-success {
  color: #10b897;
}

.gh-text-gray {
  color: #6d7481;
}

.gh-text-black {
  color: #212121;
}

.gh-pulse-animation {
  animation: pulse-animation 2s 3 ease-in-out;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(26, 188, 156, 0.2);
  }
}

.gh-accordion[data-uk-accordion="collapsible: false"] .uk-accordion-title:before {
  visibility: hidden;
}

.gh-cards-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(36 47 66 / 60%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  user-select: none;
}

.gh-cards-overlay .inner-message {
  color: #ffffff;
  font-size: 22px;
  padding: 0 10%;
  text-align: center;
}

.gh-modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.gh-modal-buttons .gh-main-button-component {
  margin: 0 10px;
}

.gh-nav-center {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gh-side-menu-box {
  z-index: 9998;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.gh-side-menu-inner {
  transition: transform 0.3s;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 320px;
  background: #242f42;
  z-index: 99999;
  padding: 0 24px;
}

.gh-payment-legs .uk-accordion {
  margin-top: 0;
}

.gh-payment-legs .gh-accordion-wrapper-item {
  margin-top: 0;
  margin-bottom: 20px;
}

.gh-payment-legs .gh-accordion-wrapper-item:last-child {
  margin-bottom: 0;
}

.gh-disabled {
  pointer-events: none;
  user-select: none;
  color: gray !important;
}

.gh-visible {
  display: block !important;
}

.gh-hidden {
  display: none !important;
}

.gh-shortcut-box {
  margin-top: 4px;
}

.gh-shortcut-box kbd {
  font-size: 12px;
  background: transparent;
  color: #242f42;
  opacity: 0.7;
}

.chat-errors li {
  position: initial !important;
}

.gh-hidden-input,
.gh-hidden-input:focus,
.gh-hidden-input:active {
  border: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.gh-main-center-menu {
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  z-index: 990;
}

.pricing-tooltip .custom-tooltip-icon {
  width: 16px;
}

.pricing-tooltip .custom-tooltip-content {
  min-width: 180px;
}

.gh-button-counter {
  top: 18px;
  right: 12px;
  font-size: 10px;
  padding: 0 4px;
  background-color: #e74c3c;
  border-radius: 2px;
}

.gh-max-width-full {
  max-width: 100% !important;
}

.gh-bigger-icon .custom-tooltip-icon {
  width: 20px;
}

div.gh-wallet-price-row {
  display: flex;
  font-size: 14px;
  margin-bottom: 13px;
}

div.gh-wallet-price-row:last-child {
  margin-bottom: 30px;
}

.gh-wallet-price-key {
  min-width: 160px;
  text-transform: uppercase;
}

.gh-wallet-price-value {
  font-weight: 600;
  color: #242f42;
}

.gh-wrapper-badge {
  position: absolute;
  top: 0px;
  right: 0;
  width: 69px;
  z-index: 0;
}

.gh-wrapper-badge .badge {
  position: absolute;
  top: 0;
  right: 0;
}

.gh-wallet-price-value.deposit-row {
  display: flex;
  align-items: center;
}

.gh-wallet-price-value.deposit-row .gh-wrapper-custom-checkbox {
  margin-left: 15px;
  font-weight: 400;
}

.gh-button-counter {
  top: 18px;
  right: 12px;
  font-size: 10px;
  padding: 0 4px;
  background-color: #e74c3c;
  border-radius: 2px;
}

.gh-quote-wrapper {
  border-left: 1px solid #242E41;
  border-right: 1px solid #242E41;
  border-bottom: 1px solid #242E41;
  position: relative;
}

.gh-quote-border {
  position: relative;
}

.gh-quote-border:before {
  content: "";
  display: block;
  /*width: 100%;*/
  height: 100%;
  position: absolute;
  top: -120px;
  left: -1px;
  border-left: 1px solid #242E41;
  z-index: -1;
}

.gh-quote-border:after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: -120px;
  right: -1px;
  border-right: 1px solid #242E41;
  z-index: -1;
}

.gh-area-disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}

.max-weight {
  margin-right: 0 !important;
}

.max-weight {
  flex-direction: row-reverse;
}
.max-weight label {
  text-align: right;
  margin: 0 5px 0 0 !important;
}

.gh-leg-max-weight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
.gh-symbol-label {
  font-weight: 500;
  font-size: 14px;
  color: #242f42;
}

.gh-max-weight {
  font-size: 14px;
  font-weight: 500;
  color: #242f42;
  padding: 0 5px 0 10px;
}

.quote-request-detail-wrapper {
  background: #ffffff;
}

.gh-elr-row,
.quote-request-detail-wrapper {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.gh-color-primary {
  color: #242f42;
}

.gh-label,
.quote-request-detail-wrapper label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #6d7481;
}

.gh-restrict-info-modal {
  width: fit-content !important;
}

div.hs_submit.hs-submit > div.actions {
  min-height: 105px;
}

.request-form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 620px) {
  .request-form-title {
    flex-direction: column;
    align-items: flex-start;
  }

  .request-form-title .available-countries-btn {
    margin-top: 8px !important;
  }
}

.gh-assign-request-wrapper {
  width: 100%;
}

.gh-dashboard-item {
  margin-top: 40px !important;
}

.gh-wrapper-input-password {
  position: relative;
}

.gh-wrapper-input-password  .gh-icon-show-password {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  top: 0;
  right: 0;
  margin-right: 5px;
  margin-top: 8px;
  cursor: pointer;
}

.gh-wrapper-input-password .gh-icon-show-password img {
  width: 17px;
}

.gh-change-password-form .validation-errors li {
  position: relative;
}

.gh-recover-pass .gh-icon-show-password {
  margin-top: 31px;
}

.gh-recover-pass .validation-errors li {
  position: relative;
}

.--hide {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.hide {
  display: none !important;
}

.gh-signup-subtitle {
  margin: 10px 0 -22px;
  font-weight: 400;
  font-size: 14px;
}

.gh-quote-card .gh-quote-expired-wrapper {
  border-right: 1px solid #242E41;
  border-left: 1px solid #242E41;
}

.gh-preview-docs-modal .hide {
  display: none;
}

.gh-preview-docs-modal img {
  width: 100%;
  height: 100%;
}
.gh-request-tabs {
  padding: 0;
  margin-top: 40px;
}

.gh-request-tabs .gh-tabs-new-design-tab {
  background-color: #FFFFFF;
  border: none;
  min-width: 215px;
  padding: 0 14px;
}

.gh-request-tabs .gh-tabs-new-design-tab.active {
  background-color: #FFFFFF;
}

.gh-request-tabs .gh-tabs-new-design-tab.active:after {
  background-color: #fff;
  border-bottom: 1px solid #D2D2D2;
  height: 2px;
}

.gh-marketplace-envelope {
  display: inline-block;
  width: 20px;
  margin-right: 49px;
}

.gh-quote-list-envelope {
  position: relative;
  width: 20px;
  padding: 5px;
  margin: 0 20px;
}

.gh-expired-reasons-modal {
  max-width: 650px !important;
}

.gh-mark-as-expired-default {
  position: relative;
  width: 100%;
}

.gh-mark-as-expired .gh-leg-link-customs-text {
  left: 0;
  width: 100%;
}

.gh-width-grid-col-copy {
  width: 57px;
}


.dashboard-create-empty-leg {
  max-width: 590px !important;
  padding: 40px 30px;
}

#dashboard-create-empty-leg-id .gh-main-button-component {
  padding: 16px 30px !important;
}

.gh-dashboard-create-empty-leg-row {
  display: flex;
  margin: 0 -5px;
}

.gh-dashboard-create-empty-leg-row .form-control {
  flex: 1;
  padding: 0 5px;
}
.available-countries-btn {
  display: flex;
  align-items: center;
}

.gh-see-coverage-map .gh-main-button-component-outlined {
  padding: 10px 20px;
}

.gh-see-coverage-map .gh-link-icon {
  margin-top: 4px;
}


.gh-marketplace-table-action {
  display: flex;
  align-items: center;
}

.gh-quote-list-envelope .gh-notifications-label.rightTop {
  top: 3px;
  right: 1px;
}

.gh-table-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
  background: rgba(255,255,255,0.75);
  z-index: 99;
}

.gh-info-block-box {
  align-items: center;
}

.gh-info-block-box p {
  display: flex;
  align-items: baseline;
}

.main-page-btn-wrapper {
  display: flex;
  justify-content: center;
  margin: 47px 0 0;
}

.gh-event-detail li::marker {
  color: #10B997 !important;
  font-size: 14px !important;
}

.gh-event-detail p {
  font-size: 14px !important;
}

.gh-event-detail h2 {
  color: #10B897;
  font-size: 16px !important;
}

.gh-event-detail h3 {
  color: #10B897;
  font-size: 14px !important;
}

.gh-marketplace-posted-on {
  display: flex;
  flex-direction: column;
}

.gh-marketplace-relevant-operators {
  color: #00b8d9;
  line-height: 16px;
}


#enquire-form h3 {
  color: var(--gh-font-black);
}

.status-pre_quoted {
  color: #212121;
}

.custom-modal-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 20px;
}

/*new header menu list*/

.header-desktop-menu {
  font-family: Rubik, san-serif, serif;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 20px;
  max-width: 630px;
  margin: 0 0 0 auto;
  width: 100%;
}

.header-desktop-menu.--right-side {
  max-width: inherit;
  width: fit-content;
}

.header-desktop-menu .subnav-content li {
  margin: 7px 0;
}

.header-desktop-menu li {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    position: relative;
}

li.subnav.header-desktop-menu-item {
  height: 100%;
}

.header-desktop-menu li:last-child {
  padding-right: 0;
}

.navbar {
  overflow: hidden;
  background-color: #333;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  text-decoration: none;
}

.subnav {
  position: relative;
  cursor: pointer;
}

.subnav .subnavbtn {
  font-family: Rubik, san-serif, serif;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  padding: 0;
}

.subnav .subnavbtn.gh-text-green {
    color: #1aba9a;
}

.subnav .subnavbtn:hover {
  color: #1aba9a;
}

.subnav-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 80px;
  margin-left: 7px;
  background-color: #242E41;
  width: fit-content;
  min-width: 10rem;
  padding: 15px 25px;
  z-index: 1;
  border-top: 6px solid #1abc9c;
  transition: visibility 0.2s, opacity 0.2s linear;
}

.mobile-subnav .subnav-content {
    border: none;
}

.subnav-content a {
  display: block;
  color: white;
  text-decoration: none;
  transition: color 0.2s;
  white-space: nowrap;
  text-transform: inherit;
  font-size: 15px;
}


.subnav-content a:hover {
  color: #1aba9a;
}

.subnav:hover .subnav-content {
  visibility: visible;
  opacity: 1;
}

.subnav > .subnavbtn {
  border-bottom: 1px solid transparent;
  transition: all 0.15s;
}

.subnav:hover .subnavbtn {
  color: #1aba9a;
  border-bottom: 1px solid #1aba9a;
}

/*======Mobile=====*/

.gh-side-menu {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  margin-top: 35px;
}

.gh-side-menu .subnavbtn {
  padding: 0;
}

.gh-offcanvas-bar ul.gh-side-menu li {
  padding: 7px 0 !important;
}

.mobile-subnav .subnavbtn {
  font-family: Rubik, san-serif, serif;
  font-weight: 400;
  font-size: 15px;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  text-transform: uppercase;
  margin: 0;
}

.mobile-subnav .subnav-content a {
  text-transform: inherit !important;
}

.mobile-subnav.--open .subnavbtn {
  color: #1abc9c;
}

.gh-side-menu .mobile-subnav.--open .subnav-content{
  display: block;
  position: relative;
  margin: 0;
  min-width: auto;
  padding: 10px 25px 5px;
}

.gh-side-menu .subnav-content a, .gh-side-menu .subnav-content .link {
  font-family: Rubik, san-serif, serif;
  display: inline-block;
  font-size: 15px;
  color: #FFFFFF;
}

.gh-side-menu .header-desktop-menu-item {
  padding: 0;
}

.header-desktop-menu-item > a {
  font-size: 15px;
}

.gh-side-menu-item {
  border-bottom: 1px solid #6d7481;
  padding: 15px 0;
}

.gh-side-menu-item:first-child {
  padding-top: 0;
}

.gh-side-menu-item-link {
  display: block;
  color: var(--bs-white);
  transition: all 0.25s;
  padding: 5px 0;
}

.gh-side-menu-item-link:hover {
  color: var(--bs-white);
  opacity: 0.9;
}

.gh-side-menu-buttons {
  display: flex;
  flex-direction: column;
}

.gh-side-menu-buttons .gh-btn {
  width: 100%;
  margin-top: 20px;
}

.gh-side-menu-buttons .gh-btn.outline.green {
  text-transform: uppercase;
}

.gh-right-menu .header-desktop-menu {

}

.gh-right-menu {
  padding-right: 15px;
}

.mobile-subnav .subnavbtn.mobile-subnavbtn{
  color: #1abc9c;
}

.gh-right-menu .subnavbtn {
  color: #1abc9c;
}

.gh-right-menu .gh-login-page .subnav-content {
  left: auto;
  right: 0;
}

.gh-right-menu .header-desktop-menu li {
  white-space: nowrap;
}

.header-desktop-menu-item .gh-btn.outline.green {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: 2px solid #1abc9c;
  color: #1abc9c !important;
  width: 100%;
  padding: 13px 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.gh-event-helicopter-carousel .embla__slide__inner {
  height: 180px;
}

.gh-event-helicopter-desc {
  font-size: 12px;
  margin-top: 2px;
  font-style: italic;
}


.header-desktop-menu li:hover .header-desktop-submenu {
  visibility: visible;
  opacity: 1;
}

.header-desktop-menu-item:hover > .link {
  border-bottom: 1px solid #1abc9c;
}

.header-desktop-submenu {
  visibility: hidden;
  opacity: 0;
  display: flex;
  border-top: 6px solid #1abc9c;
  background-color: #242E41;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 750px;
  height: 510px;
  transition: visibility 0.2s, opacity 0.2s linear;
}

.header-desktop-submenu__col {
  box-sizing: border-box;
}

.header-desktop-submenu__col.left-side {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}

.header-desktop-submenu__col.right-side {
  width: 100%;
}

ul.header-desktop-submenu__col_list {
  padding: 0;
  overflow-y: scroll;
  margin: 0;
  text-align: center;
  width: 100%;
  min-height: 220px;
  flex: 1;
}

li.header-desktop-submenu__col_item {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px auto;
  cursor: pointer;
  width: fit-content;
  height: fit-content !important;
  border-bottom: 1px solid transparent;
  color: #FFFFFF;
  font-size: 15px;
}

li.header-desktop-submenu__col_item.--active {
  border-bottom: 1px solid #1abc9c;
}

.header-desktop-submenu__col.left-side {
  display: flex;
  flex-direction: column;
}

ul.header-desktop-submenu__col_list {
  padding: 10px 20px;
  overflow-y: scroll;
  margin: 0;
  text-align: center;
  width: 100%;
  min-height: 220px;
  flex: 1;
  box-sizing: border-box;
}

li.header-desktop-submenu__col_item {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 14px 0 6px;
  cursor: pointer;
  width: 100%;
  height: fit-content !important;
  border-bottom: 1px solid rgba(26, 188, 156, 0.3);
  transition: all 0.15s;
  box-sizing: border-box;
}

li.header-desktop-submenu__col_item .gh-arrow-open-menu {
  transform: rotate(180deg);
  max-width: 22px;
  opacity: 0;
  transition: opacity 0.15s;
}

li.header-desktop-submenu__col_item.--redirect .gh-arrow-open-menu {
  transform: inherit;
  opacity: 1;
  width: 17px;
}

li.header-desktop-submenu__col_item.--redirect .link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

li.header-desktop-submenu__col_item.--active .gh-arrow-open-menu {
  opacity: 1;
}

li.header-desktop-submenu__col_item.--active {
  border-bottom: 1px solid rgba(26, 188, 156, 1);
}

.header-desktop-submenu__col_bottom-section {
  width: 100%;
  background-color: #1abc9c;
  text-align: center;
}

.header-desktop-submenu__col_bottom-section p {
  color: #242E41;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  text-align: center;
}

.header-desktop-submenu__button {
  margin: 0 auto 10px;
  border: none;
  border-radius: 5px;
  padding: 3px 15px;
  font-size: 15px;
}

.header-desktop-submenu__col.right-side {
  background-color: #f1f4f7;
  padding: 15px 30px;
  max-height: 100%;
  overflow-y: scroll;
}

.header-desktop-submenu__group-list {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.header-desktop-submenu__group-item {
  font-family: Rubik, sans-serif;
  color: #242E41;
  font-size: 15px;
  padding: 1px 0;
  font-weight: 300;
  border-bottom: 1px solid transparent;
  display: inline-block;
  width: fit-content;
  transition: all 0.2s;
  cursor: default;
}

.header-desktop-submenu__group-item a:hover {
  color: #1abc9c;
}

.header-desktop-submenu__group-item a {
  color: #242E41;
  transition: color 0.15s;
}

.header-desktop-submenu__group-item.--title {
  font-size: 17px;
  font-weight: 400;
  cursor: default;
}

.header-desktop-submenu__group-item.--group-title {
  font-size: 15px;
  font-weight: 400;
  cursor: default;
}

.header-desktop-submenu__group-item.--title:hover {
  border-bottom: 1px solid transparent;
}

.header-desktop-submenu__group-item.--title:first-child {
  margin-top: 0;
}

.header-desktop-submenu__group-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.header-desktop-submenu__group-wrapper:last-child {
  margin-bottom: 0;
}


/*custom scroll bar*/
.header-desktop-submenu ::-webkit-scrollbar {
  width: 7px;
}
/* Handle */
.header-desktop-submenu ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
/* Handle on hover */
.header-desktop-submenu ::-webkit-scrollbar-thumb:hover {
  background: #555;
}