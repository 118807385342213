.gh-heli-card-image {
  padding: 20px 20px 0px;
  background-color: #f2f4f7;
}

.gh-heli-card-image .gh-heli-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--gh-font-black);
  padding-bottom: 7px;
}

.gh-enquire-content .gh-heli-card-image .gh-heli-card-title {
    font-size: 18px !important;
}

.gh-enquire-content .gh-event-helicopter-desc {
    color: #10b897;
    font-weight: 500;
    font-size: 15px;
    font-style: inherit;
    line-height: 18px;
}

.gh-heli-card-content .gh-heli-card-subtitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.gh-heli-card-image .gh-heli-card-title .custom-tooltip-wrapper {
  margin-left: 5px;
}

.gh-heli-card .heli-item-label {
  color: #6d7481;
  font-weight: 400;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gh-heli-card .heli-item-text {
  color: #242e41;
  font-weight: 500;
  font-size: 15px;
}

.heli-item-range {
  margin-top: 3px !important;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
}

.heli-item-range_line {
  width: 100%;
  height: 10px;
  background-color: #d5d5d5;
  border-radius: 30px;
  margin-top: 34px;
  position: relative;
}

.gh-heli-card .heli-item-range_item .heli-item-label,
.gh-heli-card .heli-item-range_item .heli-item-text {
  color: #242e41;
  line-height: 1;
  font-size: 14px;
}

.heli-item-range_item {
  position: absolute;
  top: 0px;
  display: inline-flex;
  flex-direction: column;
}

.heli-item-range_item.from {
  left: 15px;
}

.heli-item-range_item.to {
  right: 0;
  text-align: right;
}

.heli-item-range_item.average {
  text-align: center;
}

.heli-item-range_marker {
  position: absolute;
  top: calc(100% - 10px);
  height: 10px;
  width: 54px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(16, 184, 151, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.heli-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.gh-heli-card-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.heli-info-item {
  min-width: 150px;
}

.heli-item-label-tooltip {
  margin-left: 5px !important;
  padding-left: 0 !important;
  transform: translateY(-2px);
  position: relative;
  z-index: 99;
}
