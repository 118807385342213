@import "./line.css";

.signup-progress {
  max-width: 1440px;
  margin: 30px auto 20px;
  padding: 0 20px;
}

.signup-progress-container {
  display: grid;
  grid-template-columns: repeat(var(--step-count), minmax(100px, 1fr));
  grid-template-rows: 1fr;
}

.signup-progress-title {
  color: #242f42;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 400;
}

.signup-progress-step_caption {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  margin: 0 0 12px;
  cursor: pointer;
}

.signup-progress-step_caption .name {
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  color: #6d7481;
}

.signup-progress-step .status img {
  min-width: 16px;
}

.signup-progress-step.stripe .name img {
  width: 50px;
  min-width: 42px;
}
.signup-progress-step.stripe .status {
  margin-right: 5px;
}

.signup-progress-step_caption.completed .name {
  color: #242f42;
}

.signup-progress-step_caption .status {
  line-height: 1;
  margin-right: 10px;
}

.signup-progress-container.lines-container.broker {
  border-left: unset;
  border-color: #cfd4de;
}

@media only screen and (max-width: 882px) {
  .signup-progress {
    overflow-x: overlay;
  }
  .signup-progress-container.lines-container {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: #cfd4de;
    width: 100%;
  }
  .signup-progress-step .status img {
    min-width: 18px;
    width: 18px;
  }

  .signup-progress-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
  }

  .signup-progress-step_caption {
    min-width: 58px;
    overflow: hidden;
  }
  .signup-progress-step_caption.is-active {
    min-width: var(--min-width);
  }
  .signup-progress-step_caption .name {
    overflow: unset;
  }

  .signup-progress-step_caption .status {
    margin: 0 0 0 2px;
  }
  .signup-progress-step_caption.is-active .status,
  .signup-progress-step.stripe.is-active {
    margin: 0 2px 0 0;
  }
  .signup-progress-step.stripe .status {
    margin-left: 0;
    margin-right: 2px;
  }
  .signup-progress {
    padding: 0 10px;
  }
  .signup-progress-step_caption:not(.is-active) .name {
    order: -1;
  }
  .signup-progress-step_caption .order-mobile-circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: #fff;
    background-color: #10b897;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Notification */

.uk-notification .uk-notification-message {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.uk-notification-message-teamMemberWarning {
  background-color: #faa05a;
  border: 1px solid #faa05a;
}

.uk-notification-message-teamMemberSuccess {
  font-size: 15px;
  background-color: #10b897;
  color: #fff;
  border-radius: 4px;
  border: 1px solid #10b897;
}

.uk-notification-message {
  font-size: 15px;
  border-radius: 4px;
  color: #fff;
}

.uk-notification-message-success {
  background-color: #10b897;
  border: 1px solid #10b897;
}

.uk-notification-message-warning {
  background-color: #faa05a;
  border: 1px solid #faa05a;
}

.uk-notification-message-primary {
  background-color: #1e87f0;
  border: 1px solid #1e87f0;
}

.uk-notification-message-danger  {
  background-color: #fff;
  color: #e25950;
  border: 1px solid #fff;
}