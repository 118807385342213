.backlink {
  color: #242f42;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
  height: 100%;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s ease-in;
  cursor: pointer;
  font-family: "rubik";
}

.backlink:hover,
.backlink:focus,
.backlink:active {
  border: none;
  outline: none;
  background: transparent;
}

.backlink:hover {
  color: #242f42b0;
}
