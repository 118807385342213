.gh-request-details-buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: 20px 0 0;
  padding-bottom: 90px;
}

.gh-request-details-buttons .uk-modal {
  margin: 0;
}

.gh-request-details-buttons.--sticky {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding: 15px 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 23px -3px rgb(0 0 0 / 50%);
  -webkit-box-shadow: 0 0 7px -3px rgb(0 0 0 / 50%);
}

.gh-request-details-buttons .gh-main-button-component{
  white-space: nowrap;
}

.gh-request-details-buttons > * {
  margin-left: 20px;
}

.gh-decline-modal-content .gh-wrapper-radio-btn {
  flex-direction: column;
}

.gh-decline-modal-content .gh-wrapper-radio-btn .gh-radio-btn {
  margin-bottom: 15px;
  height: initial;
}

.request-detail-flag {
  background: #fff !important;
  min-height: 50px;
  width: 60px !important;
  min-width: 60px !important;
  margin-right: 15px !important;
}

.new-request .gh-request-gen-btns {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

/*Media */

@media only screen and (max-width: 980px){
  .gh-request-details-buttons.--sticky{
    justify-content: space-evenly;
  }

  .gh-request-details-buttons .gh-request-more-info-button {
    min-width: min-content;
  }

  .gh-request-details-buttons .gh-main-button-component {
    min-width: 110px;
    padding: 16px 10px;
  }

  .gh-request-details-buttons > * {
    margin-left: 5px;
  }
}

@media (max-width: 768px) {
  .gh-request-details-buttons .gh-request-gen-btns {
    min-width: 155px;
    height: 50px;
  }
  .gh-request-details-buttons {
    flex-direction: column-reverse;
  }

  .gh-request-details-right-buttons {
    flex-direction: column-reverse;
  }

  .gh-request-details-buttons .gh-main-button-component {
    width: 100%;
    margin: 0 0 15px;
  }

  .new-request .gh-request-gen-btns {
    display: block;
  }
  .gh-pipeline-save-exit{
    margin-top: 15px;
    margin-left: 0!important;
  }

  .new-request-next-button-wrapper button{
    min-width: 188px;
  }

  .gh-request-details-buttons.--sticky {
    padding: 10px;
  }

  .gh-request-details-buttons.--sticky .gh-main-button-component {
    padding: 12px 28px;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .gh-request-details-buttons.--sticky .request-details-later-action .gh-main-button-component {
    padding: 9px 28px;
  }
}
