/*===========Css reset===========*/
.gh-quote-details-wrapper p {
  margin: 0;
}

@media print {
  .gh-quote-details-body #hubspot-messages-iframe-container {
    display: none !important;
  }

  .gh-quote-details-body #backToRequest {
    display: none !important;
  }

  .gh-quote-details-body #nav {
    display: none !important;
  }

  .gh-quote-details-body .gh-quote-details-buttons {
    display: none !important;
  }
  .gh-quote-details-body .gh-quote-card_operator-company {
    box-shadow: inset 0 0 0 1000px #242f42 !important;
    color: #ffffff !important;
  }
  .gh-quote-details-wrapper .accordion-icon{
    visibility: hidden;
  }
  iframe[data-appzi-dom]{
    visibility: hidden;
  }
}

/*===========Carousel===========*/

.gh-quote-details-wrapper {
  font-family: Rubik, sans-serif;
}

/*===========Typography===========*/

/*===========Text===========*/
.gh-quote-details-text-gray {
  color: #6d7481;
}

.gh-quote-details-text-normal {
  font-weight: 400;
  font-size: 16px;
  color: #242f42;
  font-family: inherit;
}

.gh-quote-stripe-logo {
  display: inline-block;
  max-width: 45px;
  margin-right: 3px;
  width: 100%;
}

.gh-quote-details-text-large {
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  color: #242f42;
}

.gh-quote-details-price-total {
  background: #e6f2f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 15px 16px;
  /*max-width: 160px;*/
}

.gh-quote-details-text-with-tooltip {
  display: flex;
  align-items: center;
}

/*===========Titles===========*/
.gh-quote-details-title {
  font-weight: 400;
  font-size: 14px;
  margin: 17px 0;
  font-family: inherit;
}

.gh-quote-details-title-bold {
  font-weight: 500;
  font-family: inherit;
}

.gh-quote-title-big {
  font-size: 16px;
}

.gh-quote-details-first-title {
  margin-top: 0;
}

.gh-details-flight-row .gh-quote-details-flight-row-title.gh-quote-details-flight-row-item {
  max-width: 200px;
  margin-right: 10px;
}

.gh-quote-details-payload-title {
  max-width: 104px;
  margin-right: 20px;
  display: flex;
  flex: 1;
}

.gh-quote-details-total-price-label {
  display: flex;
  align-items: center;
}

.gh-quote-details-quote-price-title {
  margin-top: 5px;
}

/*===========Paragraph===========*/

.gh-quote-details-quote-price-details-row.animated {
  visibility: visible;
  transition: max-height 0.25s, visibility 0.25s;
  max-height: 100%;
}

.gh-quote-details-quote-price-details-row.animated.hidden {
  max-height: 10px;
  visibility: hidden;
  overflow: hidden;
  transition: visibility 0.14s, max-height 0.2s;
}

.gh-quote-details-quote-price-details-row div:first-of-type {
  max-width: 100px;
}

.gh-quote-details-details-row .gh-quote-details-value {
  /*display: flex;*/
  /*margin-top: 7px;*/
  padding: 0;
  margin: 7px 0;
  flex: 1;
}

/*.gh-quote-details-details-row:not(:last-child) .gh-quote-details-value {*/
/*  margin-bottom: 7px;*/
/*}*/

.gh-quote-details-description-small-gray {
  font-size: 14px;
  color: #6d7481;
  font-style: italic;
  margin: 17px 0;
}

.gh-quote-details-description-normal {
  margin: 17px 0;
}

div.gh-description-small-gray {
  margin: 5px 0;
  font-size: 14px;
  color: #6d7481;
  font-style: italic;
}

div.gh-quote-details-aircraft-description {
  margin-top: 14px;
}

/*===========Wrappers===========*/

.gh-quote-details-wrapper {
  display: flex;
  flex-direction: column;
}

.gh-quote-section-wrapper {
  display: flex;
  margin: 5px -8px 0;
}

.gh-quote-card-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}

.gh-quote-card-wrapper-right {
  flex: 8;
}

.gh-quote-card-wrapper-left {
  flex: 6;
  display: flex;
  flex-direction: column;
}

.gh-quote-details-payload-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.gh-quote-details-payload-listing-items-wrapper > * {
  margin: 2.5px 10px;
}

.gh-quote-details-payload-listing-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -2.5px -10px;
}

.gh-quote-details-terms-and-conditions-column .gh-terms-conditions-col-wrapper {
  display: flex;
  flex-direction: column;
}

.gh-quote-details-terms-and-conditions-wrapper {
  margin-bottom: 20px;
}

/*===========Containers===========*/
.gh-quote-detail-additional-fees {
  margin-left: 100px;
}

.gh-quote-details-details-row,
.gh-quote-detail-total-price-wrapper {
  display: flex;
}

.gh-quote-details-aircraft-listing-row {
  display: flex;
}

.gh-quote-details-aircraft-listing-container {
  display: flex;
  flex-direction: column;
}

.gh-quote-details-aircraft-listing-buttons-container > *:first-child {
  margin-right: 3px;
}

.gh-quote-details-aircraft-listing-buttons-container {
  display: flex;
  align-items: center;
}

.uk-accordion-content .gh-details-flight-row:not(:last-of-type) {
  margin-bottom: 15px;
}

.gh-quote-details-aircraft-span-aircraft-name {
  margin-right: 15px;
}

.gh-quote-details-aircraft-listing-wrapper {
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  text-align: left;
  /*flex: 1;*/
  justify-content: space-between;
}

.gh-quote-details-button-text-wrapper > *:first-child {
  margin-right: 15px;
}

.gh-quote-details-button-text-wrapper {
  display: flex;
  align-items: center;
  width: 107px;
}

.gh-quote-details-aircraft-span-aircraft-name {
  /*min-width: 170px;*/
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gh-quote-details-aircraft-span-with-flag {
  display: flex;
  align-items: center;
}

.gh-quote-details-payload-row {
  display: flex;
  justify-content: space-between;
}

.gh-quote-details-payload-row:not(:last-of-type) {
  margin-bottom: 5px;
}

/*===========Items===========*/
.gh-quote-details-flight-row-item {
  display: flex;
  flex: 1;
}

.gh-quote-details-flag {
  margin-left: 10px;
}

.gh-quote-details-aircraft-andSpan .gh-quote-details-flag {
  margin-right: 10px;
}

.gh-quote-details-aircraft-andSpan {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  max-width: 75px;
  width: 70px;
  flex-wrap: wrap;
}

.gh-quote-details-aircraft-and {
  width: 28px;
  margin-left: auto;
}

.gh-quote-details-aircraft-span-pilots {
  width: 57px;
}

.gh-quote-details-tooltip-margin {
  display: inline-block;
  margin-left: 10px;
}

.gh-quote-details-wrapper .gh-tooltip-box {
  box-sizing: content-box;
}

.gh-quote-details-tooltip-flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background-color: #f2f4f7;
  border-radius: 50%;
}

/*===========Accordion===========*/
.gh-quote-details-wrapper .gh-simple-section:first-child {
  margin-top: 0;
}

/*===========Sections===========*/
.gh-quote-details-section-card:not(.gh-quote-details-section-card--accordion-wrapper) {
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 15px;
  background: #ffffff;
}

.gh-quote-card-wrapper-left .gh-quote-details-section-card,
.gh-quote-card-wrapper-left .gh-terms-conditions-wrapper {
  border: 2px solid #242f42;
}

.gh-quote-card-wrapper-right .uk-open.gh-accordion-wrapper-item,
.gh-quote-card-wrapper-right .gh-accordion-wrapper-item,
.gh-quote-card-wrapper-right .gh-quote-price {
  border: 2px solid #d8d8d8;
}

.gh-quote-details-column {
  display: flex;
  flex-direction: column;
}

.gh-quote-details-flight-site-component {
  margin-bottom: 8px;
}

.gh-quote-details-info-container {
  margin-right: auto;
}

.gh-quote-details-warning-message {
  flex: 1;
}

.gh-quote-details-wrapper .gh-operator-comments {
  background: #fffce8;
  border: 1px solid #e5d2b9;
  border-radius: 4px;
}

.gh-flight-details {
  margin-bottom: 15px;
}

/*===========Right card sections===========*/

/*===========Img===========*/
.gh-quote-details-company-logo-wrapper {
  width: 90px;
  height: 25px;
}

.gh-quote-details-company-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*===========Tooltip===========*/
#tooltipPriceWillVarygh-quote-details-1 {
  margin-left: 12px;
}

#onlyForInformationPurposesgh-quote-details-2 {
  margin-left: 6px;
  display: block;
}

/*===========Buttons===========*/
.gh-select-european-fee-button {
  margin-right: 14px;
}

.gh-select-fee-button:not(:last-child) {
  margin-bottom: 10px;
}

.gh-select-fee-button {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #ffffff;
  border: none;
  cursor: pointer;
  padding: 0;
}

.gh-fee-selected-btn span {
  font-weight: 500;
}

.gh-select-fee-button-not-available {
  border-color: #d6d6d5;
}

.gh-select-fee-button-green {
  border-color: #10b997;
}

.gh-quote-details-select-aircraft-btn {
  border-radius: 4px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px 10px;
}

.gh-quote-details-select-aircraft-btn-active {
  background: #e6f2f0;
  font-weight: 500;
}

/*===========Vetting More Restyling===========*/
.gh-quote-details-vetting-more {
}

/*===========Reusable components customization===========*/
.gh-quote-details-wrapper .gh-operator-comments {
  margin-bottom: 20px;
}

.gh-quote-details-wrapper .gh-box-title {
  margin-bottom: 16px;
  font-size: 14px;
}

.gh-quote-details-wrapper .gh-terms-conditions-text-bold {
  font-size: 14px;
}

/*===========Accordion===========*/
.gh-quote-details-wrapper .gh-accordion-wrapper {
  margin-bottom: 0;
}

.gh-quote-details-wrapper .gh-request-section-title {
  font-size: 14px !important;
  text-transform: uppercase;
}

.gh-quote-details-helicopters-first-title {
  text-transform: uppercase;
}

.gh-quote-details-section-card--accordion-wrapper .uk-accordion > :nth-child(n + 2) {
  margin-top: 15px;
}

/*===========vetting process===========*/

.gh-quote-details-wrapper .gh-vetting-process-card .gh-vetting-date {
  min-width: max-content;
}

.gh-quote-details-wrapper .gh-vetting-process-card .gh-vetting-date .gh-box-text {
  font-weight: 400;
}

.gh-vetting-process-card .gh-box-title {
  font-size: 16px;
  margin: 20px 0;
}

.gh-vetting-process-card .gh-box-title-small {
  font-size: 14px;
  color: #242f42;
  line-height: 30px;
  font-weight: 500;
  /*margin-top: 20px;*/
}

.gh-vetting-process-card .gh-box-title:hover {
  cursor: pointer;
  opacity: 0.8;
}

.gh-quote-details-buttons {
  display: flex;
  margin: 0 -7px 75px;
}

.gh-quote-details-buttons-col {
  margin: 0 7px;
  flex: 1;
}

.gh-quote-details-buttons .uk-button.gh-book-btn {
  border: 1px solid #10b897;
  font-weight: 500;
  white-space: nowrap;
}

.gh-quote-details-buttons .gh-quote-download-btn {
  color: #242f42;
}

.gh-quote-details-buttons .uk-button {
  border: 1px solid #d5d5d5;
  font-weight: 400;
}

.gh-quote-details-buttons .gh-quote-download-btn svg path {
  fill: #242f42;
}

.gh-quote-details-tooltip-margin-left {
  width: 20px;
  max-width: 20px !important;
  margin-left: 7px;
  display: inline-block;
}

.gh-details-flight-row > * {
  padding: 0 7px;
}

.gh-details-flight-row {
  display: flex;
  flex: 1;
  margin: 0 -7px;
}

.gh-details-flight-row.location .gh-quote-details-flight-row-title.gh-quote-details-flight-row-item {
  min-width: 200px;
}

.gh-quote-details-additional-fees-row.gh-quote-details-details-row
  .gh-quote-details-title-bold.gh-quote-details-value {
  padding-left: 10px;
}

.gh-view-full-quote-wrapper .gh-quote-expired-wrapper {
  margin-bottom: 5px;
}

/*===========Media Queries===========*/
@media only screen and (max-width: 1024px) {
  .gh-quote-details-aircraft-andSpan {
    flex-direction: column;
  }

  .gh-quote-details-button-text-wrapper > *:first-child {
    margin-right: 10px;
  }

  .gh-quote-details-buttons {
    flex-wrap: wrap;
  }

  .gh-quote-details-buttons-col {
    flex: 1 1 44%;
  }
}


/*===========Company Banner===========*/

.gh-company-banner-with-date {
  display: flex;
  justify-content: space-between;
}

.gh-company-banner-with-date .gh-expiration-time-on-banner span {
  padding-left: 5px;
}


@media only screen and (max-width: 768px) {
  .gh-quote-section-wrapper {
    flex-direction: column-reverse;
  }
  .gh-quote-card_operator-company.gh-company-banner-with-date {
    font-size: 14px;
    padding: 0 15px;
  }

  /*sections*/
  .gh-quote-details-section-card:not(.gh-quote-details-section-card--accordion-wrapper) {
    padding: 15px;
  }

  .gh-quote-card-wrapper-left .gh-quote-details-section-card,
  .gh-quote-card-wrapper-left .gh-terms-conditions-wrapper,
  .gh-quote-card-wrapper-right .uk-open.gh-accordion-wrapper-item,
  .gh-quote-card-wrapper-right .gh-accordion-wrapper-item,
  .gh-quote-card-wrapper-right
    .gh-quote-details-section-card:not(.gh-quote-details-section-card--accordion-wrapper),
  .gh-quote-card-wrapper-right .gh-quote-price {
    border: 2px solid #d5d5d5;
  }

  /*===========Helicopter details===========*/
  .gh-helicopter-details-box .gh-quote-details-details-row .gh-quote-details-text-normal {
    margin-left: 10px;
  }

  /*===========Accordion elements===========*/
  .gh-quote-details-section-card--accordion-wrapper .gh-details-flight-row {
    flex-direction: column;
  }

  .gh-quote-details-terms-and-conditions-wrapper .gh-accordion-wrapper-item {
    border: 2px solid #d5d5d5;
  }

  /*===========Flight details===========*/
  div.gh-quote-details-flight-row-title {
    align-items: center;
    margin-bottom: 5px;
  }

  /*===========Select helicopter buttons===========*/
  .gh-select-fee-button {
    padding: 12px 15px;
    max-width: 415px;
  }

  .gh-quote-details-select-aircraft-btn-active {
    font-weight: 400;
  }

  .gh-quote-details-aircraft-span-pilots {
    /*min-width: max-content;*/
    width: 56px;
  }

  .gh-quote-details-button-text-wrapper {
    width: 101px;
  }

  .gh-quote-details-aircraft-listing-wrapper.gh-quote-details-aircraft-listing-flag {
    /*grid-template-columns: minmax(125px, max-content)minmax(41px, 50px) minmax(58px, 60px);*/
    display: flex;
    flex: 1;
    max-width: max-content;
  }

  .gh-quote-details-aircraft-listing-wrapper.gh-quote-details-aircraft-listing-flag.gh-quote-details-select-aircraft-btn-have-and {
    width: calc(100% - 75px);
    max-width: max-content;
  }

  .gh-quote-details-aircraft-andSpan .gh-quote-details-flag {
    margin: 0 !important;
  }

  .gh-quote-details-aircraft-listing-wrapper .gh-quote-details-flag {
    margin-left: 5px !important;
  }

  .gh-quote-details-aircraft-andSpan {
    margin-left: 3px;
    flex: 1;
  }

  .gh-quote-details-aircraft-and {
    margin-left: 0;
  }

  /*===========Quote price===========*/
  #tooltipPriceWillVarygh-quote-details-1 {
    min-width: 20px;
  }

  .gh-quote-details-total-price-value {
    flex: 0;
  }

  .gh-quote-details-price-total {
    min-width: max-content;
  }

  /*===========addition fees===========*/
  .gh-quote-detail-additional-fees {
    margin-left: 0;
  }

  .gh-quote-details-additional-fees-row div {
    flex: inherit;
  }

  .gh-quote-details-additional-fees-row div:last-of-type {
    margin-left: auto;
  }

  .gh-quote-details-additional-fees-row .gh-quote-details-text-with-tooltip,
  .gh-quote-details-additional-fees-row
    .gh-quote-details-text-with-tooltip
    #onlyForInformationPurposesgh-quote-details-2 {
    display: inline;
  }

  .gh-quote-details-additional-landing-fees {
    text-align: right;
    min-width: max-content;
  }

  .gh-select-fee-button span {
    margin-top: 10px;
  }

  .gh-select-european-fee-button {
    margin-right: 0;
    margin-bottom: 10px;
  }

  /*===========Select aircraft buttons===========*/
  .gh-quote-details-aircraft-listing-buttons-container {
    flex-wrap: wrap;
  }

  .gh-quote-details-button-text-wrapper {
    height: 100%;
    align-items: flex-start;
  }

  .gh-quote-details-aircraft-andSpan {
    width: 70px;
    flex-direction: row;
  }

  .gh-quote-details-aircraft-andSpan-with-flag {
    /*width: 30px;*/
    max-width: max-content;
    flex: 0 1 auto;
  }

  .gh-quote-details-aircraft-andSpan-flag {
    margin-right: auto;
  }

  /*===========vetting process===========*/
  .gh-quote-details-wrapper .gh-vetting-process-card .gh-box-title {
    margin-top: 0;
  }

  .gh-quote-details-wrapper .gh-vetting-process-card .uk-grid {
    flex-wrap: nowrap;
    align-items: center;
  }

  .gh-quote-details-wrapper .gh-vetting-process-card .gh-vetting-date .gh-box-text {
    font-weight: 400;
  }

  .gh-quote-details-wrapper .gh-vetting-process-card .gh-vetting-date {
    min-width: max-content;
  }

  .gh-vetting-process-card .gh-box-title {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .gh-quote-details-buttons {
    flex-wrap: wrap;
  }

  /*===========Payload===========*/
  .gh-quote-details-payload-listing-items-wrapper > * {
    margin: 1px 4px;
  }

  .gh-quote-details-payload-listing-items-wrapper {
    padding: -1px -4px;
  }
}

@media only screen and (max-width: 576px) {
  .gh-quote-card_operator-company.gh-company-banner-with-date {
    flex-direction: column-reverse;
    align-items: flex-start;
    line-height: 35px;
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 420px) {
  .gh-quote-card_operator-company.gh-company-banner-with-date {
    font-size: 11px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 830px) {
  .gh-quote-details-aircraft-span-aircraft-name {
    max-width: 160px;
  }
}

@media only screen and (min-width: 639px) {
  .gh-quote-details-wrapper .gh-simple-section {
    padding: 20px;
  }
}

@media (max-width: 1100px) {
  .gh-details-flight-row.location .gh-quote-details-flight-row-title.gh-quote-details-flight-row-item {
    margin-bottom: 5px;
  }

  .gh-details-flight-row.location {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .gh-quote-details-buttons-col {
    flex: 1 1 100%;
  }

  /*===========Tooltips===========*/
  .gh-quote-details-tooltip-fix-mobile .custom-tooltip-content {
    max-width: 160px;
  }
}
