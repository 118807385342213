.gh-site-title,
.gh-site-name {
  margin: 0;
}

.gh-site-wrapper {
  display: flex;
}

.gh-site-img-wrapper {
  width: 31px;
  min-width: 31px;
  /*height: 39px;*/
  margin-right: 11px;
}

.gh-site-img-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.gh-site-info-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.gh-site-title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 1;
}

.gh-site-info {
  color: #6d7481;
  font-size: 14px;
  line-height: 17px;
}

.gh-site-wrapper .gh-site-title {
  font-weight: 400;
}
