.gh-booking-info-bar {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 17px 20px;
}

.gh-booking-info-bar .gh-upload-container {
  margin-bottom: 10px;
}

.gh-booking-info-bar-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
}

.gh-booking-info-bar-col:last-child {
  margin-right: 0;
}

.gh-booking-info-bar-col.deposit .gh-booking-info-bar-identifier-input {
  width: 100px;
  margin-left: 0;
}

.gh-booking-info-wrapper-input {
  position: relative;
}

.gh-booking-info-wrapper-input .uk-input-percent {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 12px;
  margin-top: 5px;
}

.gh-booking-info-bar-key {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #6d7481;
  margin-right: 17px;
}

.gh-booking-info-bar-value {
  display: flex;
  align-items: center;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #242f42;
}

.gh-booking-info-bar-value.status {
  text-transform: capitalize;
}

.gh-booking-info-bar-edit-btn {
  cursor: pointer;
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #005bff;
  transition: opacity 0.2s;
}

.gh-booking-info-bar-edit-btn:hover {
  opacity: 0.8;
}

.gh-booking-info-bar-identifier-form {
  display: flex;
  position: relative;
}

.gh-booking-info-bar-identifier-form .gh-message-error {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: -17px;
  margin-left: 10px;
}

.gh-booking-info-bar-identifier-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
  transition: color 0.2s;
}

.gh-booking-info-bar-identifier-btn:hover {
  color: #0f6ecd !important;
}

.gh-booking-info-bar-identifier-btn:disabled {
  cursor: initial;
  color: #999999 !important;
}

.gh-booking-info-bar-identifier-btn:disabled:hover {
  color: #999999 !important;
}

.gh-booking-info-bar-identifier-input {
  font-family: Rubik, san-serif, serif !important;
  color: #242f42 !important;
  border: 2px solid #6d7481;
  border-radius: 4px;
  font-size: 14px !important;
  margin-right: 5px;
  margin-left: 10px;
  padding: 0 8px;
  height: 30px;
  box-sizing: border-box;
}

.gh-booking-info-bar-identifier-input:focus {
  outline: none !important;
  border: 2px solid #1e87f0;
}

.gh-booking-info-bar-buttons {
  display: flex;
  flex-wrap: wrap;
}

.gh-booking-info-bar-buttons .gh-main-button-component {
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  margin-right: 30px;
  padding: 11px 15px;
}

.gh-booking-info-bar-buttons .gh-main-button-component:last-child {
  margin-right: 0;
}

.gh-booking-info-bar-buttons .gh-main-button-component {
  margin-top: 18px;
}

.gh-booking-info-bar-buttons .gh-main-button-component span {
  font-weight: 400;
  padding-left: 10px;
}

.gh-booking-info-bar-buttons .gh-main-button-component img {
  max-width: 21px;
}

.gh-booking-info-bar .hide {
  display: none;
}

.gh-booking-info-bar .gh-uz-file-content {
  background-color: #e7eaef !important;
}

.gh-booking-info-bar .gh-uz-file-content .gh-uz-file-format {
  background-color: #fff;
}

.gh-booking-info-bar .uk-upload-btn {
  border-color: #10b897;
  color: #10b897;
}

/*Media */

@media (max-width: 1199px) {
  .gh-booking-info-bar-wrapper {
    flex-wrap: wrap;
  }

  .gh-booking-info-bar-col {
    flex: 50%;
    padding: 0;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .gh-booking-info-bar-col:nth-child(3),
  .gh-booking-info-bar-col:nth-child(4) {
    margin-bottom: 0;
  }

  .gh-booking-info-bar-buttons {
    flex-wrap: wrap;
    margin-top: 25px;
  }

  .gh-booking-info-bar-buttons .gh-main-button-component {
    flex: 100%;
    margin: 0 0 20px;
  }

  gh-booking-info-bar-buttons .gh-main-button-component:last-child {
    margin: 0;
  }

  .gh-booking-info-bar-buttons .gh-main-button-component span {
    display: block;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .gh-booking-info-bar {
    padding: 20px;
  }

  .gh-booking-info-bar-col {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .gh-booking-info-bar-identifier-input {
    margin-left: 0;
    margin-top: 4px;
  }
}
