.skeleton {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: linear-gradient(
            90deg,
            rgba(190, 190, 190, 0.2) 25%,
            rgba(129, 129, 129, 0.24) 37%,
            rgba(190, 190, 190, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
}

.skeleton > * {
    opacity: 0;
    pointer-events: none;
}
