.form-control .gh-select__control {
  min-height: 48px;
  border-color: #6d7481;
  border-width: 2px;
  box-shadow: unset;
}

.form-control .gh-select__control:hover {
  border-color: #6d7481;
}

.form-control .gh-select__option.gh-select__option--is-selected {
  background-color: #1abc9c;
}

.form-control
  .gh-select__option.gh-select__option--is-focused:not(.gh-select__option--is-selected) {
  background-color: rgba(26, 188, 156, 0.1);
  cursor: pointer;
}

.form-control .gh-select__multi-value__remove {
  min-width: 20px;
}

.form-control .gh-select__value-container {
  max-height: 150px;
  overflow-y: auto;
}
