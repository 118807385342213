.gh-departure-wrapper {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.gh-departure-wrapper .rdtPicker {
  top: -15px;
}

.gh-departure-wrapper-item {
  flex: 1 1 50%;
}

.gh-departure-wrapper-item .rdt {
  width: fit-content;
}

.gh-departure-wrapper.read-only .gh-departure-info-line {
  margin-bottom: 0;
}

.gh-departure-info-line {
  display: flex;
  margin-bottom: 15px;
}

.gh-departure-info-line-icon {
  width: 30px;
  margin-right: 10px;
  padding-top: 4px;
}

.gh-departure-info-line-icon img {
  min-width: 30px;
}

.gh-departure-info-title {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #242f42;
}

.gh-departure-info-period {
  display: flex;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-size: 18px;
  color: #242f42;
}

.gh-departure-info-text {
  margin-right: 10px;
}

.gh-departure-info-secondary-text {
  font-size: 14px;
  color: #6d7481;
}

.gh-departure-wrapper-item .rdtPicker {
  border: 2px solid #6d7481;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.133367);
  border-radius: 3px;
}

.gh-departure-wrapper-item .rdtPicker td,
.rdtPicker th {
  text-align: start !important;
  padding-left: 5px;
}

.gh-departure-wrapper-item .rdtCounters {
  text-align: center !important;
}

.gh-time-picker-content {
  display: flex;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #6d7481;
  margin: 15px 0 10px 13px;
}

.gh-time-picker-content img {
  margin-left: 20px;
}

.gh-time-picker-content-error {
  font-size: 12px;
  color: #e74c3c;
  padding: 5px 5px 10px;
}

/*Media */

@media (max-width: 767px) {
  .gh-departure-wrapper {
    flex-direction: column;
  }

  .gh-departure-wrapper__item {
    margin-bottom: 15px;
  }
}
