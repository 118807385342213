.section-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  width: 100%;
  height: 100%;
  color: #242f42;
  background: transparent;
  border: none;
  cursor: pointer;
}
.section-arrow svg {
  transition: transform 0.2s;
  transform-origin: 50% 50%;
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
}
.section-arrow.closed svg {
  transform: rotate(0);
}
