.autcomplete-wrapper,
.autocomplete-input {
  position: relative;
  z-index: 16;
}

.autocomplete-input {
  background-color: #fff;
  border-radius: 4px;
}

.autocomplete-input .uk-input {
  padding-right: 38px;
}

.autocomplete-input-loader {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 12;
}

.autocomplete-options {
  position: absolute;
  left: 0;
  width: calc(100% - 4px);
  background-color: #fff;
  border-radius: 4px;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  z-index: 22;
  border: 2px solid #6d7481;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  max-height: 500px;
  overflow-y: scroll;
}

.autocomplete-options::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.autocomplete-options::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


.autocomplete-options.bottom {
  transform-origin: top;
  top: calc(100% + 8px);
}

.autocomplete-options.top {
  transform-origin: bottom;
  bottom: calc(100% + 8px);
}

.autocomplete-options .autocomplete-suggestion:not(:last-of-type) {
  border-bottom: 1px solid #6d7481;
}

.autocomplete-suggestion-btn {
  border: none;
  background-color: transparent;
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 16px;
  padding: 0 10px;
  height: 44px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.autocomplete-suggestion-btn .secondary {
  color: #52575f;
  margin-left: 5px;
  font-size: 14px;
}

.autocomplete-suggestion-btn .secondary::after {
  content: ")";
}
.autocomplete-suggestion-btn .secondary::before {
  content: "(";
}

.autocomplete-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.08);
}

.autocomplete-suggestion-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.autocomplete-options.enter-active {
  animation-name: options-animation;
}

.autocomplete-options.exit-active {
  animation-direction: reverse;
  animation-name: options-animation;
}

.autocomplete-suggestion .autocomplete-suggestion-text {
  color: #000000;
}

@keyframes options-animation {
  0% {
    opacity: 0;
    transform: scale3d(1, 0.8, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
