.gh-quote-general-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  flex-basis: 280px;
  padding-top: 20px;
  flex-wrap: wrap;
}

.gh-quote-general-info-wrapper .rdtPicker {
  right: 0;
}

.gh-quote-general-info-title p {
  white-space: nowrap;
}

.gh-quote-general-info-wrapper-rejected.gh-quote-general-info-wrapper {
  justify-content: flex-start;
  max-width: 100%;
}

.gh-quote-general-info-wrapper-rejected .gh-quote-general-info-col {
  flex-wrap: nowrap;
  min-width: auto;
  margin-right: 25px;
}

.gh-quote-general-info-wrapper-rejected
  .gh-quote-general-info-col.reason
  .gh-quote-general-info-value {
  flex: 1;
}

.gh-quote-general-info-wrapper-rejected
  .gh-quote-general-info-col.reason
  .gh-quote-general-info-value
  span {
  display: block;
}

.gh-quote-general-info-wrapper-rejected
  .gh-quote-general-info-col.reason
  .gh-quote-general-info-value
  span.hidden {
  width: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gh-quote-general-info-wrapper-rejected .gh-quote-general-info-col:last-child {
  margin-right: 0;
}

.gh-quote-general-info-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-width: 105px;
  margin-right: 25px;
  margin-bottom: 5px;
}

.gh-quote-general-info-title {
  color: #6d7481;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 20px;
  white-space: nowrap;
}

.gh-quote-general-info-value {
  color: #242f42;
  padding-right: 5px;
  max-width: 100%;
}

.gh-quote-general-info-value.--deposit {
  display: flex;
  flex-wrap: nowrap;
}

.gh-quote-general-info-value.--deposit .--percent {
  margin-right: 10px !important;
}

.gh-quote-general-info-link {
  font-weight: 400;
  padding: 0;
  min-width: initial;
}

.gh-quote-general-info-col .gh-quote-general-info-value span {
  padding-right: 5px;
}

.gh-quote-general-info-wrapper-expiration {
  align-items: center;
}

/*--------------------MEDIA-------------------*/

@media only screen and (max-width: 1024px) {
  .gh-quote-general-info-wrapper-rejected .gh-quote-general-info-col {
    margin-bottom: 15px;
  }

  .gh-quote-general-info-wrapper {
    min-width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .gh-quote-general-info-col {
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin-right: 0;
  }

  .gh-quote-general-info-col-mobile-margin-top {
    margin-top: 20px;
  }

  .gh-quote-general-info-value {
    margin-top: 10px;
  }

  .gh-quote-general-info-wrapper-rejected.gh-quote-general-info-wrapper {
  }

  .gh-quote-general-info-wrapper-rejected .gh-quote-general-info-col {
    margin: 0;
    flex: 50%;
  }

  .gh-quote-general-info-wrapper-rejected .gh-quote-general-info-col.long-text:last-child {
    flex: 100%;
  }

  .gh-quote-general-info-wrapper-rejected .gh-quote-general-info-col .gh-quote-general-info-value {
    margin-top: 5px;
    margin-bottom: 15px;
  }
}



@media only screen and (max-width: 768px) {
  .gh-quote-general-info-col.--expiration-time {
    width: 100%;
  }


}

@media only screen and (max-width: 620px) {
  .gh-quote-general-info-col.--expiration-time {
    margin-right: 0;
    flex-direction: column;
    align-items: start;
  }
}

@media only screen and (max-width: 420px) {
  .gh-quote-general-info-col .gh-quote-general-info-value span {
    margin-left: 0;
  }

  .gh-quote-general-info-value .gh-quote-general-info-wrapper-expiration {
    flex-wrap: wrap;
  }

  .gh-quote-general-info-value .rdt {
    width: 100%;
  }

  .gh-quote-general-info-wrapper .rdtPicker {
    left: 0;
  }

}

