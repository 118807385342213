.gh-color-picker-dropdown-btn {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
}

.gh-color-picker-dropdown-btn.--color {
    display: inline-block;
    height: 38px;
    width: 38px;
    min-width: 38px;
    border: 2px solid #6d7481;
    border-radius: 4px;
}

.gh-color-list {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 4px;
    width: 161px;
    top: 0;
    left: 0;
    margin-top: 54px;
    opacity: 0;
    visibility: hidden;
}

.gh-color-list.--show {
    opacity: 1;
    visibility: visible;
    z-index: 10;
}

.gh-color-item {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    margin: 4px;
    transition: all 0.25s;
}

.gh-color-item .uk-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    color: #FFFFFF;
}

.gh-color-item:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.gh-wrapper-input-color {
    position: relative;
    width: 100%;
}

.gh-input-color-warning {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    margin-right: 15px;
    font-size: 18px;
    color: #e74c3c;
}