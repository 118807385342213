.gh-broker-booking-details-audit-row {
  padding-bottom: 13px;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 19px;
}

.gh-broker-booking-details-audit-row:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.gh-broker-booking-details-audit-top-line {
  display: flex;
}

.gh-broker-booking-details-audit-user-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #10b897;
  margin-right: 10px;
}

.gh-broker-booking-details-audit-date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #6d7481;
}

.gh-broker-booking-details-audit-comment {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #242f42;
}

/****************  Media  ******************/

@media (max-width: 768px) {
  .gh-broker-booking-details-audit-top-line {
    flex-direction: column;
  }
}
