.gh-suggest-message {
    display: inline-block;
    font-size: 12px;
    color: #333;
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 4px;
    padding: 4px 10px;
    cursor: pointer;
    margin: 7px 0 7px;
    opacity: 0.9;
    transition: opacity 0.22s;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.gh-suggest-message:hover {
    opacity: 1;
}
