.gh-custom-progress-bar {
  background-color: #d8d8d8;
  border-radius: 15px;
  position: relative;
  height: 7px;
  max-width: 100%;
  width: 100%;
  border: 1px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
}

.gh-custom-progress-bar-done {
  background-color: #10b997;
  border-radius: 15px;
  display: flex;
  height: 100%;
  width: 0;
  opacity: 0;
}

.gh-custom-progress-bar-done.animated {
  transition: 1s;
}
