.gh-animation-opacity-active-enter {
  -webkit-animation: opacity 0.2s forwards;
  -o-animation: opacity 0.2s forwards;
  animation: opacity 0.2s forwards;
}

.gh-animation-opacity-active-exit {
  -webkit-animation: opacity 0.2s reverse;
  -o-animation: opacity 0.2s reverse;
  animation: opacity 0.2s reverse;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
