.gh-alternative-btn {
  display: inline-block;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  color: #242f42;
  background: #e6f2f0;
  border: 1px solid #10b897;
  border-radius: 4px;
  padding: 6px 11px;
  cursor: pointer;
  height: fit-content;
}
