.request-edit-rights {
  width: 100%;
}

.request-edit-rights .form-control {
  width: 100%;
}

.gh-request-status {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
  align-items: center;
  gap: 15px;
}

.request-edit-rights .gh-column-label {
  font-size: 12px;
  color: #6d7481;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 0 10px 0 0;
  text-transform: uppercase;
}

#full-request-modal .request-edit-rights {
  max-width: 500px;
}

.gh-see-reason-btn {
  display: inline-block;
  width: 21px;
  margin-left: 5px;
  opacity: 0.7;
}

.gh-see-reason-btn img {
  padding-bottom: 2px;
}