.gh-accordion-wrapper .uk-accordion-title::before {
  display: none;
}

.gh-accordion-wrapper .uk-accordion-title {
  position: relative;
}

.gh-accordion-wrapper .uk-accordion-title .accordion-icon {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5px;
  transform: rotate(180deg);
  transition: transform 0.15s;
}

.gh-accordion-wrapper .gh-accordion-wrapper-item {
  border: 2px solid #6d7481;
}

.gh-accordion-wrapper .uk-open.gh-accordion-wrapper-item {
  border: 2px solid #fff;
  border-radius: 4px;
}

.gh-accordion-wrapper .uk-open .uk-accordion-title .accordion-icon {
  transform: rotate(0deg);
}
