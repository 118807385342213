.booking-confirmed-chat {
  height: 28px;
  min-width: 120px;
  max-width: 120px;
  font-size: 14px;
  margin-bottom: 0;
  /* margin-top: 25.67px; */
  padding: 0 8px !important;
  font-weight: normal;
}

.booking-confirmed-chat .gh-discussion-btn_envelope {
  display: none;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.booking-confirmed-chat .gh-discussion-btn_envelope img {
  display: none !important;
}

.booking-confirmed-chat .gh-discussion-btn_envelope .messages-count {
  position: unset;
  top: unset;
  left: unset;
  right: unset;
  bottom: unset;
}

.booking-confirmed-chat.new-msgs .gh-discussion-btn_envelope {
  display: flex;
}

.discuss-modal-modal {
  padding: 20px 10px 10px;
}

.discuss-modal-modal h3 {
  margin-bottom: 10px;
}

.gh-live-chat-feedback-form .gh-feedback-form-textarea{
  margin-bottom: 1.2rem;
}
