.flight-inline-view {
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #d6d6d5;
}

.flight-inline-view .flight-direction {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 16px;
}

.flight-inline-view .flight-subtitle {
  font-size: 14px;
  margin-bottom: 2px;
  line-height: 20px;
}

.flight-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.flight-details > span {
  display: block;
  line-height: 20px;
}

.flight-details-content {
  color: #242f42;
}

.flight-details-content.pax span:not(:last-of-type)::after {
  content: ", ";
}

.flight-caption .flight-location:not(:last-of-type) {
  margin-bottom: 10px;
}

.flight-meta .flight-details:not(:last-of-type) {
  margin-bottom: 15px;
}

.flight-site-title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
  font-weight: 400;
  margin: 0;
}
