.gh-broker-booking-details {
  padding-bottom: 60px;
}

.gh-booking-title-line {
  margin: 20px 0 35px;
}

.gh-broker-booking-details .gh-booking-info-bar {
  margin-bottom: 20px;
}

.gh-broker-booking-details-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
}

/*.gh-broker-booking-details-col {*/
/*    flex: 50%*/
/*}*/

.gh-broker-booking-details-col:nth-child(1) {
  flex: 45%;
}
.gh-broker-booking-details-col:nth-child(2) {
  flex: 55%;
}

.gh-broker-booking-details-col-content {
  padding: 0 10px;
}

.gh-broker-booking-details .gh-quote-price {
  border: 1px solid #d8d8d8;
}

.gh-booking-details-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #242f42;
}

.gh-broker-booking-details .gh-request-section-title {
  font-size: 14px !important;
  text-transform: uppercase;
}

.gh-broker-booking-details .gh-accordion-wrapper .uk-open.gh-accordion-wrapper-item {
  border: 1px solid #d8d8d8;
}

.gh-broker-booking-details .gh-accordion-wrapper .gh-accordion-wrapper-item {
  border: 1px solid #d8d8d8;
}

.gh-broker-booking-details .gh-simple-section {
  padding: 20px;
}

.gh-broker-booking-details p {
  margin: 0;
}

.gh-broker-booking-details .gh-simple-section:first-child {
  margin-top: 0;
}

.gh-broker-booking-details p.gh-quote-details-flight-row-title {
  max-width: 200px;
}

/****************  Media  ******************/

@media (max-width: 768px) {
  .gh-booking-title-line {
    margin: 11px 0 25px;
  }

  .gh-broker-booking-details-wrapper {
    justify-content: flex-start;
    flex-direction: column-reverse;
  }
}

@media print {
  .gh-view-full-booking-details-body .gh-dashboard-nav {
    display: none !important;
  }

  .gh-view-full-booking-details-body .gh-alert-message {
    display: none !important;
  }

  .gh-view-full-booking-details-body .gh-booking-info-bar-buttons {
    display: none !important;
  }

  .gh-view-full-booking-details-body .gh-company-information-wrapper-chat {
    display: none !important;
  }

  .gh-view-full-booking-details-body .gh-booking-details-buttons {
    display: none !important;
  }

  .gh-view-full-booking-details-body .gh-short-nav {
    display: none !important;
  }

  .gh-view-full-booking-details-body .gh-yellow-alert uk-alert {
    display: none !important;
  }

  .gh-view-full-booking-details-body .gh-booking-private-notes {
    display: none !important;
  }

  .gh-view-full-booking-details-body .uk-alert-close.uk-icon.uk-close {
    display: none !important;
  }

  .gh-view-full-booking-details-body #getheli-widget-base-element {
    display: none !important;
  }

}
