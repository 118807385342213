.gh-separator-line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d5d5d5;
  margin: 20px 0;
}

.gh-quote-map-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px 10px;
}
