.gh-input-number {
  position: relative;
}

.gh-input-number__prefix {
  position: absolute;
  top: calc(50% + 2px);
  right: 10px;
  transform: translateY(-50%);
  color: #242f42;
  text-transform: uppercase;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
}

.gh-input-number input.with-prefix {
  padding-right: 34px;
}
