.gh-quote-price .title-item {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #242f42;
  margin-bottom: 25px;
}

.gh-quote-price-wrapper {
  display: flex;
  width: 100%;
}

.gh-quote-price-col {
  flex: 1;
}

.gh-quote-price-col:first-child {
  padding-right: 18px;
}

.gh-quote-price-col:last-child {
  padding-left: 18px;
}

.gh-quote-price-col:first-child {
  border-right: 1px dashed #d5d5d5;
}

.gh-quote-price-row.radio {
  background: #f2f4f7;
  padding: 15px 20px;
}


.gh-quote-price-row.gh-deposit-row label {
  min-width: 0;
  margin-right: 8px;
  font-size: 17px;
  font-weight: 700;
}

.gh-quote-price-row.gh-deposit-row {
  padding: 5px 20px;
}

.gh-quote-price-row.gh-deposit-row .gh-price-input-wrapper {
  width: fit-content;
  margin-right: 20px;
  padding-left: 0;
}

.gh-quote-price-row.gh-deposit-row .gh-deposit-amount {
  font-weight: 500;
  font-size: 14px;
}


.gh-quote-price-row {
  display: flex;
  padding-bottom: 10px;
}

.gh-quote-price-row :last-child {
  margin-bottom: 0;
}

.gh-quote-price-title {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  color: #242f42;
  margin-right: 30px;
  min-width: 62px;
  margin-top: 13px;
}

.gh-quote-price-title.total {
  font-weight: 500;
  font-size: 16px;
}

.gh-quote-price-row .gh-alternative-btn {
  margin-right: 20px;
}

.gh-quote-price-row.radio .gh-quote-price-title {
  display: flex;
  align-items: center;
  flex: 1 1 50%;
  margin-top: 0;
}

.gh-quote-price-row.radio .price-input-wrapper {
  padding-left: 0;
}

.gh-quote-price-row.total {
  align-items: start;
}

.gh-quote-price-row.total .gh-message-error {
  position: absolute;
}

.gh-quote-price-row.vat .gh-message-error {
  position: absolute;
}

.gh-quote-price-row.landing-fees .gh-message-error {
  position: absolute;
}

.gh-view-price {
  font-family: Rubik, sans-serif;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  color: #242f42;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 10px;
  border-radius: 3px;
  width: 164px;
  height: 48px;
  margin-right: 12px;
}

.gh-view-price-small {
  height: 24px;
}

.gh-view-price.total {
  background-color: #e7eaef;
  font-size: 16px;
}

.gh-view-price-icon {
  display: inline-block;
  background: #ffffff;
  border: 2px solid #9da3ae;
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 50%;
  box-sizing: border-box;
}

.gh-view-price-icon:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #10b897;
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 5px;
  margin-left: 5px;
}

.gh-view-price .gh-text-price {
  font-family: Rubik, sans-serif;
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #242f42;
}

/*Media */

@media (max-width: 1300px) {
  .gh-quote-price-row.radio {
    flex-direction: column;
  }

  .gh-quote-price-row.radio .gh-quote-price-title {
    margin: 5px 0;
  }
}

@media (max-width: 767px) {
  .gh-quote-price-wrapper {
    flex-direction: column;
  }

  .gh-quote-price-title {
    margin-right: 0;
  }

  .gh-price-input-wrapper {
    width: 140px;
    margin-right: 5px;
  }

  .gh-price-input-wrapper label,
  .gh-price-input-wrapper.total label {
    margin-right: 5px;
  }

  .gh-quote-price-col:first-child {
    border: none;
  }

  .gh-quote-price-col.radio {
    margin-top: 10px;
    padding: 0;
  }
}

.gh-quote-price-row-wrapper-total {
  display: flex;
}

.gh-quote-price-actions {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 10px;
}

.gh-quote-price-actions .gh-btn-reset {
  margin-right: 10px;
}

.quote-price__repositioning {
  box-sizing: border-box;
  color: #242f42;
  margin-top: 10px;
}

.quote-price__repositioning * {
  box-sizing: border-box;
}

.quote-price__repositioning,
.quote-price__repositioning-percentage {
  display: flex;
  align-items: center;
}

.quote-price__repositioning {
  flex-wrap: wrap;
}

.quote-price__repositioning-label {
  font-size: 14px;
}

.percentage__progress-label {
  font-weight: 500;
  font-size: 14px;
  margin: 0 15px 0 27px;
}

.percentage__progress {
  width: 142px;
  height: 7px;
  position: relative;
  background-color: #d8dce2;
}

.percentage__progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #6d7481;
}


/*=========== 991 <=> 1400 ===========*/

@media only screen and (min-width: 991px) and (max-width: 1400px) {
  .gh-quote-price-title-addition-landing-fees {
    min-width: max-content;
    margin-right: 15px;
  }
}




/*=========== max: 1200 ===========*/

@media only screen and (max-width: 1200px) {
  .gh-quote-price-row-wrapper-total {
    flex-direction: column;
  }
}

/*=========== max: 576 ===========*/

@media only screen and (max-width: 576px) {
  .gh-quote-price-row.total{
    flex-wrap: wrap;
  }

  .gh-quote-price-actions {
    width: 100%;
    justify-content: flex-start;
    padding-top: 7px;
  }

}
