.gh-user-initials {
  position: relative;
  width: 36px !important;
  height: 36px !important;
}

.gh-user-initials-progress {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-91deg);
}

.gh-user-initials-progress circle {
  transition: var(--transition);
}

.gh-user-initials.uncompleted {
  color: #ffab00 !important;
}

.gh-user-initials .gh-user-initials-progress circle {
  stroke: #10b897;
}

.gh-user-initials.uncompleted .gh-user-initials-progress circle {
  stroke: #ffab00;
}

.gh-user-initials .gh-user-initials-info {
  background-color: #10b897;
}

.gh-user-initials-info svg {
  width: 13px;
  height: 13px;
}

.gh-user-initials.uncompleted .gh-user-initials-info {
  background-color: #ffab00;
}

.gh-user-initials-info {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  color: #fff;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gh-user-initials {
  cursor: pointer;
}

.user-initials-warn {
  padding: 30px 20px;
  border: 1px solid #ff7a00;
  background-color: #fff8eb;
  color: #ff7a00;
  font-size: 14px;
  min-width: 280px;
  border-radius: 4px;
  top: calc(100% + 24px);
  z-index: 1021;
}

.user-initials-warn a {
  color: #242f42;
}

.user-initials-warn::after {
  border-bottom-color: #fff8eb;
  right: 14px !important;
}
