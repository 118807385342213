.gh-passengers-info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.gh-wrapper-passengers-info .gh-wrapper-passengers-info-item {
  margin-bottom: 20px;
}

.gh-wrapper-passengers-info .gh-wrapper-passengers-info-item:last-child {
  margin-bottom: 2px;
}

.gh-passengers-info-row.total-row.editing {
  padding: 20px 0;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}

.gh-passengers-info-row.total-row .gh-passengers-info-row-value {
  justify-content: center;
}

.gh-passengers-info-row.total-row.editing .gh-passengers-info-row-value {
  justify-content: flex-end;
}

.gh-passengers-info-row.total-row .gh-passengers-info-row-title {
  font-weight: 500;
}

.gh-passengers-info-row.total-row .gh-passengers-info-row-value .value {
  background-color: #bdc3cf;
  width: 33px;
  height: 26px;
  border-radius: 3px;
  color: #242f42;
}

.gh-passengers-info-row-title {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #242f42;
  text-transform: capitalize;
}

.gh-passengers-info-row-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 98px;
}

.btn-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  min-width: 27px;
  height: 27px;
  border: 2px solid #6d7481;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  font-size: 24px;
  color: #10b897;
  padding-bottom: 3px;
}

.btn-counter::selection {
  background-color: transparent;
  color: #10b897;
}

.gh-passengers-info-row-value .value {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: Rubik, sans-serif;
  font-size: 18px;
  color: #6d7481;
}

.gh-passengers-info-row-other {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #9a9ca1;
}

.gh-passengers-info-row-other .other-textarea {
  min-height: 215px;
  resize: none;
  margin-bottom: 15px;
}

.gh-passengers-comment {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
}

.gh-passengers-comment img {
  margin-top: 5px;
}
.gh-passengers-comment-text {
  margin-left: 10px;
}

.gh-passengers-info-row.total-row.editing.gh-quote-form-passengers-error-total {
  margin-bottom: 0px;
  padding-bottom: 10px;
  border-bottom: none;
}

.gh-quote-form-passengers-error li:first-child {
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 5px;
  width: 100%;
}

/*Media */

@media (max-width: 767px) {
  .gh-passengers-info-row {
    margin-bottom: 10px;
  }

  .gh-passengers-info-row.total-row.editing {
    border: none;
  }
  .gh-quote-form-passengers-error li:first-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}
