/* @font-face {
    font-family: 'rubik';

    src:
            url('../../fonts/rubik/rubik-blackitalic-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-blackitalic-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-blackitalic-webfont.eot'), local('Verdana');
    font-weight: 800;
    font-style: italic;
    font-display:'swap';
}
@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-black-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-black-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-black-webfont.eot'), local('Verdana');
    font-weight: 800;
    font-style: normal;
    font-display:'swap';
}
@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-bolditalic-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-bolditalic-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-bolditalic-webfont.eot'), local('Verdana');
    font-weight: 700;
    font-style: italic;
    font-display:'swap';
}

@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-bold-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-bold-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-bold-webfont.eot'), local('Verdana');
    font-weight: 700;
    font-style: normal;
    font-display:'swap';
}

@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-mediumitalic-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-mediumitalic-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-mediumitalic-webfont.eot'), local('Verdana');
    font-weight: 500;
    font-style: italic;
    font-display:'swap';
}

@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-medium-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-medium-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-medium-webfont.eot'), local('Verdana');
    font-weight: 500;
    font-style: normal;
    font-display:'swap';
}

@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-italic-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-italic-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-italic-webfont.eot'), local('Verdana');
    font-weight: 400;
    font-style: italic;
    font-display:'swap';
}

@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-regular-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-regular-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-regular-webfont.eot'), local('Verdana');
    font-weight: 400;
    font-style: normal;
    font-display:'swap';
}

@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-lightitalic-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-lightitalic-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-lightitalic-webfont.eot'), local('Verdana');
    font-weight: 200;
    font-style: italic;
    font-display:'swap';

}

@font-face {
    font-family: 'rubik';
    src:
            url('../../fonts/rubik/rubik-light-webfont.woff2') format('woff2'),
            url('../../fonts/rubik/rubik-light-webfont.woff') format('woff'),
            url('../../fonts/rubik/rubik-light-webfont.eot'), local('Verdana');
    font-weight: 200;
    font-style: normal;
    font-display:'swap'; */
html {
  height: 100%;
}
body {
  min-height: 100%;
}
.bold,
b,
strong {
  font-weight: 500;
}
.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "rubik";
  font-weight: 500;
  color: #242f42;
}
a,
a:hover {
  color: #1abc9c;
}
.uk-container {
  max-width: 1440px;
}
.uk-container-affiliate {
  max-width: 840px;
}
h1,
.main-heading {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 36px;
  line-height: 48px;
  font-weight: 500;
}
h2,
.subpage-heading {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
}
h3,
.section-heading {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
}
h4,
.subsection-heading {
  font-family: "rubik", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}
.uk-grid-divider:empty {
  border-top: 1px solid #ddd;
  margin-left: 0;
}
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  font-family: "rubik", Arial, Helvetica, sans-serif;
}
.uk-table.uk-table-primary {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.uk-table.uk-table-primary tbody tr td {
  background: #fff;
}
.uk-table.uk-table-primary tbody tr td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.uk-table.uk-table-primary tbody tr td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.uk-link-primary {
  color: #242f42;
}
.uk-link-primary:hover {
  color: #6d7481;
}
.uk-link-primary:active {
  color: #242f42;
}
.uk-link-primary:disabled {
  color: #dfe2e8;
}
.uk-link-secondary {
  color: #1abc9c;
}
.uk-link-secondary:hover {
  color: #1abc9c;
  text-decoration: underline;
}
.uk-link-secondary:active {
  color: #1abc9c;
}
.uk-link-secondary:disabled {
  color: rgba(26, 188, 156, 0.25);
}
.uk-link-secondary svg polyline,
.uk-link-secondary svg line {
  stroke: #1abc9c;
}
.uk-input,
.uk-textarea {
  border: 2px solid #dfe2e8;
  border-radius: 4px;
  font-weight: 400;
}
.uk-input {
  height: 48px;
}
.uk-form-label {
  text-transform: uppercase;
  color: #242f42;
}
.uk-tab > *:not(:first-child) {
  padding-left: 40px;
}
.uk-tab > * > a {
  padding: 5px 0;
  font-size: 16px;
}
.uk-tab > .uk-active > a {
  color: #1abc9c;
  border-color: #1abc9c;
}
.uk-tab::before {
  border-bottom: 2px solid #dfe2e8;
}
.uk-tab > * > a {
  border-bottom: 2px solid transparent;
  color: #242f42;
}
.uk-tab.uk-tab-bottom > .uk-active > a {
  color: #1abc9c;
  border-color: #1abc9c;
}
.uk-tab.uk-tab-bottom::before {
  border-bottom: 0;
  border-top: 2px solid #dfe2e8;
}
.uk-tab.uk-tab-bottom > * > a {
  border-bottom: 0;
  border-top: 2px solid transparent;
}
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  padding: 0 25px;
}
.uk-tab > * > a {
  text-transform: capitalize;
}
.uk-checkbox {
  border: 2px solid #242f42;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  fill: #1abc9c;
  color: #1abc9c;
}
.uk-checkbox:focus {
  background-color: transparent !important;
  border: 2px solid #1abc9c;
}
.uk-checkbox:checked {
  border: 2px solid #1abc9c;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  fill: #1abc9c;
  color: #1abc9c;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%231ABC9C%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #6d7481;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #6d7481;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #1abc9c;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
}
[type="radio"]:checked:disabled + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #6d7481;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
[type="radio"]:checked + label {
  font-weight: 500;
}
.uk-navbar-right .uk-button-default {
  background-color: transparent;
}
.uk-navbar-right .uk-button-default:hover {
  background-color: #fff;
  color: #242f42;
}
.uk-nav-default {
  font-size: 16px;
}
table th {
  font-weight: 500 !important;
  color: #6d7481 !important;
}
table td span,
.gh-text-tertiary {
  color: #00b8d9;
}
.gh-text-secondary {
  color: #242f42;
}
.gh-text-primary {
  color: #1abc9c;
}
.gh-text-default {
  color: #dfe2e8;
}
.gh-text-big {
  font-size: 20px;
}
.gh-text-bigger {
  font-size: 24px;
}
.uk-tooltip {
  max-width: 200px;
  padding: 0 6px;
  background: #ffab00;
  border-radius: 4px;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
}
.uk-button {
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  padding: 0 42px;
  text-transform: none;
  border-radius: 4px;
  min-width: 170px;
}
.uk-button-default {
  background-color: #fff;
  color: #242f42;
  border: 2px solid #dfe2e8;
}
.uk-button-default:hover {
  background-color: #fff;
  border: 2px solid #f0f0f0;
}
.uk-button-default:active {
  background-color: #fff;
  border: 1px solid #6d7481;
}
.uk-button-default:focus {
  background-color: #fff;
  border: 2px solid #00b8d9;
}
.uk-button-primary {
  background-color: #1abc9c;
  color: #fff;
  border: 0;
}
.uk-button-primary:hover {
  background-color: #1abc9c;
}
.uk-button-primary:disabled,
.uk-button-primary:disabled:hover {
  background-color: rgba(26, 188, 156, 0.25);
}
.uk-button-danger {
  background-color: rgba(231, 76, 60, 1);
  border: 0;
}
.uk-button-danger:hover {
  background-color: rgba(231, 76, 60, 0.85);
}
.uk-button-danger:disabled,
.uk-button-danger:disabled:hover {
  background-color: rgba(231, 76, 60, 0.15);
}
.uk-logo img {
  width: 150px;
}
.gh-nav {
  position: absolute;
  z-index: 5;
  width: 100%;
}
.gh-nav a {
  color: #fff;
}
.gh-nav .gh-list-item {
  padding-left: 50px;
}
.gh-nav .gh-list-item a {
  color: #fff;
}
.gh-nav .gh-list-item .uk-button {
  min-width: auto;
}
.gh-nav .gh-list-item:last-child {
  padding-left: 10px;
}
.gh-nav .gh-button-default {
  border: 2px solid #fff;
  color: #fff !important;
  background: transparent;
}
.gh-nav .gh-button-primary {
  border: 2px solid #1abc9c;
  color: #1abc9c !important;
  background: transparent;
}
.gh-main-logos .gh-logo-bbc img {
  max-width: 96px;
}
.gh-main-logos .gh-logo-esquire img {
  max-width: 124px;
}
.gh-main-logos .gh-logo-bloomberg img {
  max-width: 112px;
}
.gh-main-logos .gh-logo-wired img {
  max-width: 134px;
}
.gh-main-logos .gh-logo-fortune img {
  max-width: 58px;
}
.gh-main-logos .gh-logo-inc img {
  max-width: 62px;
}
.gh-slider-main-features .uk-dotnav-vertical {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.gh-slider-main-features .gh-slider-description {
  width: 90%;
}
.gh-broker-banner h1 {
  font-weight: 400;
  display: block;
  color: #fff;
}
.gh-broker-banner a {
  display: block;
}
.gh-services-icon {
  width: 92px;
  height: 92px;
  background-color: #f0f0f0;
  border-radius: 50px;
  padding-left: 0 !important;
}
.gh-services-icon span {
  margin: 50% auto 0 50%;
  transform: translateY(-50%) translateX(-50%);
}
.gh-slider-testimonials .uk-slideshow-items img {
  filter: contrast(50%) brightness(50%);
}
.gh-slider-testimonials .uk-dotnav {
  bottom: 0;
  left: 0;
  right: 0;
}
.gh-grid-newsletter p {
  color: #fff;
}
.gh-form-newsletter button,
.gh-form-newsletter label {
  color: #fff;
}
.gh-form-newsletter button,
.gh-form-newsletter button:hover,
.gh-form-newsletter button:focus {
  border: 0;
  color: #000;
}
.gh-form-newsletter button {
  margin-left: 10px;
}
.gh-form-newsletter .uk-input {
  border: 2px solid #fff;
  color: #fff;
}
.gh-form-newsletter .uk-input::placeholder {
  color: #fff;
}
.gh-form-newsletter .uk-input:focus {
  background: transparent;
}
.gh-form-newsletter .uk-form-icon path,
.gh-form-newsletter .uk-form-icon polyline {
  color: #fff;
}
.gh-main-footer a {
  color: #000;
}
.gh-main-footer .gh-copyright {
  font-size: 14px;
}
.gh-main-footer .gh-subnav a {
  text-transform: capitalize;
}
.gh-dashboard {
  background: #f0f0f0;
}
.gh-dashboard-nav a {
  color: #fff;
}
.gh-dashboard-nav .uk-navbar-container {
  background: #242f42;
  border-bottom: 6px solid #1abc9c;
  min-height: 80px;
}
.gh-dashboard-nav .uk-navbar-nav > li > a {
  color: #fff;
}
.gh-dashboard-nav .uk-navbar-nav > li > a.uk-active {
  color: #1abc9c;
}
.gh-dashboard-nav .uk-navbar-center > ul > li > a {
  text-transform: capitalize;
  font-size: 16px;
}
.gh-dashboard-nav .uk-navbar-right > ul > li > a {
  text-transform: capitalize;
}
.gh-dashboard-nav .gh-navbar-right > ul > li {
  border-left: 1px solid #6d7481;
}
.gh-dashboard-nav .gh-navbar-right .gh-req-quotes {
  color: #ffab00;
}
.gh-dashboard-nav .gh-navbar-right .gh-notifications-icon path {
  color: #fff;
}
.gh-dashboard-nav .gh-navbar-right .gh-notifications-label {
  top: 18px;
  right: 12px;
  font-size: 10px;
  padding: 0 4px;
  background-color: #e74c3c;
  border: 2px solid #242f42;
  border-radius: 4px;
}
.gh-dashboard-nav .gh-user-nav .gh-user-initials {
  background-color: rgba(109, 116, 129, 0.25);
  color: #00b8d9;
  padding: 10px;
  border-radius: 50px;
  line-height: 16px;
  height: 16px;
  font-weight: 500;
}
.gh-dashboard-nav .gh-user-nav .gh-user-name {
  text-transform: capitalize;
  color: #fff;
}
.gh-dashboard-nav .gh-user-nav .gh-dropdown-arrow polyline {
  color: #fff;
}
.gh-filters-tab {
  margin-left: auto;
}
.gh-section-statistics .uk-card {
  box-shadow: 0 0 0 transparent;
  border-radius: 4px;
}
.gh-section-statistics .gh-count {
  font-size: 60px;
  font-weight: 400;
  line-height: 60px;
}
.gh-section-statistics .gh-count.gh-count-primary {
  color: #00b8d9;
}
.gh-section-statistics .gh-count.gh-count-danger {
  color: #e74c3c;
}
.gh-section-statistics .gh-count.gh-count-warning {
  color: #ffab00;
}
.gh-section-statistics .gh-count.gh-count-default {
  color: #242f42;
}
.gh-section-statistics .gh-statistics-head {
  font-weight: 200;
  text-transform: uppercase;
  font-size: 15px;
}
.gh-tab-gradient > li > a {
  height: 58px;
  line-height: 58px;
  font-size: 18px;
}
.gh-tab-gradient > li:not(:first-child) {
  background: linear-gradient(to bottom, #dfe2e8 0, #d2d6db 100%);
  border-top: 0;
}
.gh-tab-gradient > li > a {
  background: linear-gradient(to bottom, #dfe2e8 0, #d2d6db 100%);
  border-top: 2px solid #dfe2e8 !important;
}
.gh-tab-gradient > li.uk-active > a {
  background: linear-gradient(to bottom, #ebeced 0, #fff 100%);
  border-top: 2px solid #1abc9c !important;
}
.gh-button-terms .gh-button-terms-text {
  color: #1abc9c;
}
.gh-add-helicopter-row .uk-icon-button,
.gh-button-terms .uk-icon-button,
.gh-add-invitation-input .uk-icon-button {
  color: #fff;
  background: #1abc9c;
}
.gh-delete-helicopter-row .uk-icon-button {
  background: transparent;
}
.gh-added-fleet td,
.gh-select-fleet td {
  vertical-align: middle;
}
.uk-select {
  border: 0;
  border-radius: 4px;
  line-height: 48px;
  height: 48px !important;
  background-color: #dfe2e8;
  background-position: right 10px center !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20ratio%3D%221%22%3E%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-width%3D%221.03%22%20points%3D%2216%207%2010%2013%204%207%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E") !important;
}
.uk-select:focus {
  background-color: #f0f0f0;
}
.gh-verification-doc-upload {
  background: #fff;
  border: 0;
}
.gh-verification-doc {
  border-bottom: 2px solid #dfe2e8;
}
.gh-status-account-summary li {
  border-bottom: 2px solid #dfe2e8;
  height: 50px;
  line-height: 50px;
}
.gh-button-group {
  border: 2px solid #dfe2e8;
  padding: 3px;
  border-radius: 4px;
  background: #fff;
}
.gh-button-group .uk-button-default {
  border: 0;
  background: transparent;
}
.gh-accordion .gh-accordion-section .uk-accordion-title,
.gh-accordion-like-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background: #6d7481;
  border-radius: 4px;
}
.gh-accordion-like-title {
  background: #242f42;
}
.gh-accordion .gh-accordion-section.uk-primary .uk-accordion-title {
  background: #00b8d9;
}
.gh-accordion .gh-accordion-section.uk-open .uk-accordion-title {
  background: #242f42;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.gh-accordion .uk-accordion-content,
.uk-accordion-like-content {
  background: #fff;
}
.gh-accordion .uk-primary .uk-accordion-title::after {
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20ratio%3D%221%22%3E%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-width%3D%221.5%22%20points%3D%224%2C10%208%2C15%2017%2C4%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E);
}
.gh-accordion .uk-open .uk-accordion-title::after {
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20ratio%3D%221%22%3E%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-width%3D%221.53%22%20points%3D%2216%207%2010%2013%204%207%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E);
}
.gh-accordion .uk-accordion-title,
.gh-accordion .uk-accordion-content,
.gh-accordion-like-title,
.uk-accordion-like-content {
  padding: 30px 40px 30px 40px;
}
.gh-accordion .gh-flip-image {
  transform: scaleX(-1);
}
.gh-accordion .gh-leg-title {
  background: #f0f0f0;
  color: #242f42;
  font-weight: 500;
  padding: 10px 14px 10px 14px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.gh-accordion .gh-leg-content {
  border: 2px solid #f0f0f0;
  padding: 14px 14px 4px 14px;
}
.gh-aircrafts-tags .uk-label {
  background: #00b8d9;
  text-transform: capitalize;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
.gh-aircrafts-tags .uk-label a[uk-icon]:hover {
  color: #fff;
}
.gh-req-quotes-details .gh-helicopter {
  top: 48px;
  left: 254px;
  background: #fff;
  display: block;
  width: 50px;
  height: 50px;
  z-index: 1;
  padding: 10px;
}
.gh-req-quotes-details .gh-helicopter-line-divider {
  width: 10px;
  height: 0;
  border-bottom: 4px solid #fff;
}
.gh-req-quotes-details .gh-helicopter-line-divider-1 {
  top: 106px;
  left: 150px;
  transform: rotate(63deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-2 {
  top: 99px;
  left: 165px;
  transform: rotate(64deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-3 {
  top: 93px;
  left: 179px;
  transform: rotate(67deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-4 {
  top: 88px;
  left: 194px;
  transform: rotate(73deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-5 {
  top: 84px;
  left: 210px;
  transform: rotate(76deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-6 {
  top: 80px;
  left: 227px;
  transform: rotate(78deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-7 {
  top: 78px;
  left: 242px;
  transform: rotate(82deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-8 {
  top: 78px;
  left: 306px;
  transform: rotate(102deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-9 {
  top: 80px;
  left: 321px;
  transform: rotate(104deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-10 {
  top: 84px;
  left: 338px;
  transform: rotate(104deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-11 {
  top: 88px;
  left: 354px;
  transform: rotate(106deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-12 {
  top: 93px;
  left: 369px;
  transform: rotate(108deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-13 {
  top: 99px;
  left: 383px;
  transform: rotate(110deg);
}
.gh-req-quotes-details .gh-helicopter-line-divider-14 {
  top: 106px;
  left: 398px;
  transform: rotate(112deg);
}
.gh-req-quotes-details .gh-helicopter-line-start {
  width: 6px;
  height: 1px;
  border: 1px solid #00b8d9;
  border-radius: 4px;
  top: 111px;
  left: 141px;
  transform: rotate(63deg);
  background-color: #00b8d9;
}
.gh-req-quotes-details .gh-helicopter-line-end {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  top: 103px;
  left: 407px;
  transform: rotate(44deg);
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20ratio%3D%221%22%3E%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%231ABC9C%22%20stroke-width%3D%222%22%20points%3D%227%204%2013%2010%207%2016%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E);
}
.gh-req-quotes-details .gh-helicopter-line-start-back {
  width: 6px;
  height: 1px;
  border: 1px solid #00b8d9;
  border-radius: 4px;
  top: 110px;
  left: 412px;
  transform: rotate(113deg);
  background-color: #00b8d9;
}
.gh-req-quotes-details .gh-helicopter-line-end-back {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  top: 103px;
  left: 135px;
  transform: rotate(133deg);
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20ratio%3D%221%22%3E%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%231ABC9C%22%20stroke-width%3D%222%22%20points%3D%227%204%2013%2010%207%2016%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E);
}
.gh-req-quotes-details .gh-path {
  top: 32px;
  left: 136px;
}
.gh-req-quotes-details .gh-flip-image {
  transform: scaleX(-1);
}
.gh-req-quotes-details .gh-leg-info span {
  color: #242f42;
}
.irs {
  height: 40px;
}
.irs-with-grid {
  height: 60px;
}
.irs-line {
  height: 3px;
  top: 25px;
}
.irs-line-left {
  height: 1px;
  border-radius: 1px;
  border: 1px solid #6d7481;
  background: #6d7481;
}
.irs-line-mid {
  height: 3px;
  background: #6d7481;
}
.irs-line-right {
  height: 1px;
  border-radius: 1px;
  border: 1px solid #6d7481;
  background: #6d7481;
}
.irs-bar {
  height: 5px;
  top: 24px;
  background-color: #1abc9c;
}
.irs-bar-edge {
  top: 25px;
  height: 6px;
  width: 7px;
  background-position: 0 -90px;
}
.irs-shadow {
  height: 1px;
  top: 34px;
  background: #000;
  opacity: 0.75;
}
.irs-slider {
  width: 16px;
  height: 16px;
  top: 15px;
  background-color: #1abc9c;
  border-radius: 50px;
  border: 3px solid #fff;
  box-shadow: 0 1px 6px #a8a8a8;
}
.irs-slider.state_hover,
.irs-slider:hover {
  background-position: 0 -150px;
}
.irs-min,
.irs-max {
  color: silver;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: rgba(0, 0, 0, 0.1);
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.irs-from,
.irs-to,
.irs-single {
  color: #242f42;
  font-size: 11px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: rgba(255, 255, 255, 0.8);
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.irs-grid-pol {
  background: #777;
}
.irs-grid-text {
  color: #242f42;
}
.gh-spinner [uk-icon] {
  color: #6d7481;
}
.gh-account-nav > li.uk-active > a {
  color: #1abc9c;
  font-weight: 500;
}
.gh-account-nav > li.uk-active > a:after {
  content: "";
  position: absolute;
  height: 27px;
  width: 27px;
  background-repeat: no-repeat;
  right: 0;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2227%22%20height%3D%2227%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20ratio%3D%222%22%3E%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%231ABC9C%22%20stroke-width%3D%221.5%22%20points%3D%2210%205%2015%209.5%2010%2014%22%3E%3C%2Fpolyline%3E%20%3Cline%20fill%3D%22none%22%20stroke%3D%22%231ABC9C%22%20stroke-width%3D%221.5%22%20x1%3D%224%22%20y1%3D%229.5%22%20x2%3D%2215%22%20y2%3D%229.5%22%3E%3C%2Fline%3E%3C%2Fsvg%3E);
}
.gh-tab-group:before {
  border: 0;
}
.gh-tab-group li:not(:first-child) {
  padding-left: 0;
}
.gh-notification-unread {
  border: 1px solid #00b8d9;
  box-shadow: 0 3px 3px #dfe2e8;
  color: #242f42;
}
