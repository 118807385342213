.gh-review-leg-wrapper {
  display: flex;
  width: 100%;
  max-width: 480px;
}

.gh-leg-location-marker {
  display: inline-block;
  width: 35px;
  height: 44px;
}

.gh-leg-field-label {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.gh-review-leg-location-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px 10px;
}

.gh-review-leg-location-detail .gh-leg-title-location {
  font-weight: 400;
  margin: 0;
  font-size: 18px;
}
.gh-review-leg-location-detail .gh-leg-sub-title-location {
  line-height: 1.6;
  font-size: 14px;
}

.gh-review-leg-wrapper .gh-leg-take-off {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #242f42;
  margin: 0 !important;
}

.gh-additional-leg-info {
  max-width: 440px;
  margin-left: 40px;
  padding: 12px 15px;
  background: #f2f4f7;
  border-radius: 4px;
}

.gh-additional-leg-info-title {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  color: #6d7481;
}

.gh-additional-leg-info-text {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #242f42;
}

.gh-wrapper-suggest-btn {
  margin: 15px 0 0 40px;
}

/*Media */
@media (max-width: 767px) {
  .gh-review-leg-wrapper {
    max-width: 100%;
  }

  .gh-review-leg-location {
    width: 100%;
  }

  .gh-additional-leg-info {
    max-width: 100%;
    margin: 0;
  }
}

@media (max-width: 639px) {
  .gh-leg-location-marker {
    display: inline-block;
    width: 25px;
    height: 34px;
  }

  .gh-leg-field-label {
    font-size: 13px;
  }

  .gh-review-leg-wrapper .gh-leg-take-off {
    font-size: 12px;
  }

  .gh-review-leg-location-detail .gh-leg-title-location {
    font-weight: 400;
  }
}
