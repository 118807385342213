.gh-legs-location-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gh-legs-location-wrapper-item {
  flex: 1 1 50%;
  position: relative;
}

.gh-legs-autocomplete-wrapper .leg-form-label {
  margin-top: 0;
  margin-bottom: 15px;
}
.gh-legs-autocomplete-wrapper {
  padding-left: 45px;
  min-height: 148px;
}

.gh-legs-autocomplete-wrapper-first {
  margin-right: 20px;
}

.gh-legs-edit-img {
  position: absolute;
  top: 0;
  left: 0;
}

.coverage-warning {
  color: orange;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  font-size: 13px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*Media */

@media (max-width: 767px) {
  .gh-legs-location-wrapper {
    flex-direction: column;
  }

  .gh-legs-location-wrapper-item {
    margin-bottom: 20px;
  }

  .gh-legs-autocomplete-wrapper {
    padding-left: 35px;
    min-height: auto;
  }
  .gh-legs-edit-img {
    position: absolute;
    top: 10px;
    left: 0;
  }
  .gh-legs-autocomplete-wrapper .leg-form-label {
    font-size: 13px;
  }
  .gh-legs-autocomplete-wrapper-first {
    margin-right: 0;
  }
}
