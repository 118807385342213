.gh-terms-conditions-wrapper {
    padding: 25px;
    border: 2px solid #6d7481;
    border-radius: 4px;
    font-size: 14px;
}

.gh-terms-conditions-title {
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #242f42;
}

.gh-terms-conditions-col-wrapper {
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    display: grid;
    grid-template-columns: minmax(240px, 2fr) minmax(120px, 1fr);
}

.gh-terms-conditions-col {
    margin-top: 0;
}

.gh-terms-conditions-subtitle {
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #242f42;
}

.gh-terms-conditions-border {
    border: 1px solid #d5d5d5;
    margin: 10px 0;
}

.gh-terms-conditions-text-bold {
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #242f42;
    margin-right: 5px;
}

.gh-terms-conditions-text-bold-medium-size {
    font-size: 14px;
}

.gh-terms-conditions-text-content {
    font-family: Rubik, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 5px;
    display: flex;
}

.gh-terms-conditions-note {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
}

.gh-terms-conditions-note-push-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.gh-terms-conditions-note:before {
    content: '*';
    font-size: 12px;
    margin-right: 5px;
    display: inline-block;
    transform: translateY(2px);
}

.gh-terms-conditions-text-view {
    font-size: 16px;
    font-weight: 500;
    color: #242f42 !important;
    padding-top: 20px;
    width: fit-content;
}

.gh-terms-conditions-text-view:hover {
    cursor: pointer;
    opacity: 0.8;
}

/*Modify Layout*/
.gh-terms-conditions-title-size {
    min-width: 220px;
    margin-right: 10px;
}

.gh-terms-conditions-first-col {
    margin-right: 20px;
    padding-bottom: 17px;
    position: relative;
}

.gh-terms-conditions-second-col {
    margin-left: 20px;
}

.gh-terms-conditions-wrapper-no-col-margin .gh-terms-conditions-first-col,
.gh-terms-conditions-wrapper-no-col-margin .gh-terms-conditions-second-col {
    margin-right: 0;
    margin-left: 0;
}

.gh-terms-conditions-wrapper-no-col-margin .gh-terms-conditions-first-col {
    padding-bottom: 25px;
}

.gh-terms-conditions-wrapper-desk-small .gh-terms-conditions-text-content:last-child {
    margin-bottom: 20px;
}

.gh-terms-conditions-wrapper-desk-small .gh-terms-conditions-title-size {
    max-width: 125px;
    min-width: 125px;
}

/*===========Media Queries===========*/
@media (max-width: 768px) {
    .gh-terms-conditions-first-col,
    .gh-terms-conditions-second-col {
        margin-right: 0;
        margin-left: 0;
    }

    .gh-terms-conditions-col-wrapper {
        display: flex;
        flex-direction: column;
    }

    .gh-terms-conditions-first-col {
        margin-right: 20px;
        padding-bottom: 30px;
    }

    .gh-terms-conditions-first-col {
        margin-bottom: 15px;
    }
}

@media (max-width: 500px) {
    .gh-terms-conditions-text-content {
        line-height: 1.4;
    }

    .gh-terms-conditions-col {
        margin-top: 20px;
    }
}

@media (max-width: 425px) {
    .gh-terms-conditions-text-content {
        flex-direction: column;
    }

    .gh-terms-conditions-title-size {
        margin-right: 0;
        min-width: initial;
    }

    .gh-terms-conditions-wrapper-desk-small .gh-terms-conditions-title-size {
        max-width: initial;
        min-width: initial;
    }
}
