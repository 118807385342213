.gh-can-not-estimate-price-wrapper {
  padding: 15px;
  background-color: #f2f4f7;
  border: 1px solid #e3e7ed;
  display: flex;
  align-items: center;
}
.gh-can-not-estimate-price-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 14px;
}

.gh-can-not-estimate-price-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: #242f42;
}
