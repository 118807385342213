.gh-progress-info-loader {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.gh-progress-info-wrapper {
  padding-top: 10px;
}

.gh-progress-info-actions.reasons {
  margin-top: 5px;
}

.gh-progress-info-actions-row {
  font-size: 14px;
  display: flex;
  margin-bottom: 5px;
}

.gh-progress-info-actions-row:last-child {
  margin-bottom: 0;
}

.gh-progress-info-actions-row-name {
  display: flex;
  width: 180px;
  min-width: 180px;
  padding-right: 20px;
  color: #212121;
}

.gh-progress-info-actions-row-value {
  display: flex;
  color: #6d7480;
  width: 100%;
}

.gh-progress-info-actions-row-value.progress {
  margin: 8px 0;
  max-width: 300px;
}

.gh-progress-info-actions-row-reasons {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: #242e41;
  padding-left: 18px;
  position: relative;
}

.gh-progress-info-actions-row-reasons::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #10b997;
  position: absolute;
  left: 0;
  top: 5px;
}

.gh-progress-info-actions-row-reasons-percent {
  font-weight: 500;
  padding-right: 10px;
  padding-top: 1px;
  line-height: 18px;
}

.gh-progress-info-actions-row-reasons-value {
  font-weight: 400;
  line-height: 17px;
}

.renotify-btn {
  padding: 0 !important;
}

.gh-view-request-btn {
  color: #10B997;
}

/*-------Media-------*/

@media (max-width: 576px) {
  .gh-progress-info-actions.progress .gh-progress-info-actions-row-name {
    min-width: 160px;
    width: 160px;
  }
  .gh-progress-info-actions.reasons .gh-progress-info-actions-row-name {
    display: none;
  }

  .gh-progress-info-actions-row-name {
    min-width: 140px;
    width: 140px;
  }

  .renotify-btn {
    padding: 0 !important;
  }

  .gh-progress-info-actions-row {
    flex-direction: column;
  }

  .gh-progress-info-actions .gh-progress-info-actions-row:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
