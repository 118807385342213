.gh-quote-reason-wrapper {
    padding: 0 20px 10px 20px;
}

.gh-quote-reason-wrapper .gh-divider {
    width: 100%;
}

.gh-quote-reason {
    display: flex;
    align-items: baseline;
    padding-top: 10px;
}