.row {
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px 10px;
}

.field {
  position: relative;
  width: 100%;
  margin: 10px 0;
}

.input {
  width: 100%;
  color: #32325d;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik, sans-serif;
  border-radius: 4px;
  height: 50px;
  padding: 0;
}
.input .__PrivateStripeElement {
  width: 90%;
  padding: 0;
}

.input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

input.input,
button.stripe {
  -webkit-animation: 1ms void-animation-out;
}

button.stripe {
  display: block;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #24b47e;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.error .message,
.error span {
  display: block;
  color: #e25950;
}

.success .icon .border {
  stroke: #abe9d2;
}

.success .icon .checkmark {
  stroke: #24b47e;
}

.success .title {
  color: #32325d;
  font-size: 16px !important;
}

.success .message {
  color: #8898aa;
  font-size: 13px !important;
}

.success .reset path {
  fill: #24b47e;
}
