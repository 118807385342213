.form-control.invalid {
  border: none !important;
}

.gh-form-textarea {
  resize: none;
  overflow: hidden;
  min-height: 60px;
  max-height: 420px;
}
