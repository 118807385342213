.accordion__title.clickable {
  cursor: pointer;
}
.accordion__content {
  overflow: hidden;
  /* transition: max-height 0.1s; */
  max-height: 0;
}
.accordion__content.open {
  max-height: calc(var(--height) * 1px);
}
