.gh-hook-time-picker-wrapper {
    position: relative;
}

.gh-hook-time-picker-wrapper .rdtPicker {
    width: calc(100% - 10px);
}

.gh-hook-clock-icon {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 15px;
    transform: translateY(-50%);
}

.gh-hook-utc {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-left: 65px;
    margin-top: 1px;
}