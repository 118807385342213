.wallet-row-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
}

.wallet-row-subtitle {
  margin-bottom: 10px;
  line-height: 1.3;
  color: #000;
  margin-top: 0;
  font-size: 17px;
}

.waller-row-warn {
  color: rgb(222, 37, 37);
  line-height: 1.2;
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 0;
  font-size: 15px;
}

.default-cards-list {
  position: relative;
}

.wallet-row-default.disabled {
  opacity: 0.7;
  background-color: #f2f4f7;
  pointer-events: none;
  user-select: none;
}

.gh-card-disabled-warn {
  color: rgb(222, 37, 37);
  line-height: 1.2;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 15px;
}
