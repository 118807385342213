.gh-wrapper-radio-btn {
  display: flex;
}

/* The container */
.gh-radio-btn {
  min-width: 45px;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 24px;
  color: #6d7481;
}

.gh-radio-btn-disabled {
    filter: grayscale(.9);
}

.gh-radio-btn-disabled .gh-radio-btn {
    cursor: not-allowed;
}


/* Hide the browser's default radio button */
.gh-radio-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #6d7481;
  box-sizing: border-box;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.gh-radio-btn input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.gh-radio-btn .checkmark:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #10b897;
  box-sizing: border-box;
}

.gh-radio-btn.selected {
  font-weight: 500;
}
