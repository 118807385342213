.embla {
  position: relative;
  background-color: #f7f7f7;
  /*padding: 20px;*/
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla__slide {
  position: relative;
  min-width: 100%;
  padding-left: 10px;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 269px;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.embla__button {
  outline: 0;
  cursor: pointer;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 20px !important;
  min-width: 20px !important;
  height: 35px !important;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  background: #242f42;
  opacity: 0.8;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0;
}

.embla__button__svg {
  /*width: 6px;*/
  /*height: 12px;*/
}
.embla__button__svg-left {
  transform: rotate(180deg);
}
.embla__button--prev {
  left: 0px;
}

.embla__button--next {
  right: 0px;
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 24px;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  padding: 0 !important;
  outline: 0;
  border: 0;
  width: 7px;
  min-width: 7px !important;
  height: 7px;
  margin-right: 4.5px;
  margin-left: 4.5px;
  display: flex;
  align-items: center;
}

/*.embla__dot:after {*/
/*    background-color: #efefef;*/
/*    width: 100%;*/
/*    height: 4px;*/
/*    border-radius: 2px;*/
/*    content: "";*/
/*}*/

.embla__dot.is-selected:after {
  width: 5px;
  height: 5px;
  background-color: transparent;
  border: 2px solid #fff;
  opacity: 1;
}

/*===========Media Queries===========*/
/*@media only screen and (max-width: 768px) {*/

/*    .embla__slide__img {*/
/*        object-fit: contain;*/
/*    }*/
/*}*/
