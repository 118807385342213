.gh-estimate-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + 20px);
  margin: 0 -10px;
}

.gh-estimate-row-item {
  font-family: Rubik, sans-serif;
  display: flex;
  margin: 0 10px;
  flex: 1;
}

.gh-estimate-row-item:nth-child(2) {
  justify-content: center;
}

.gh-estimate-row-item:nth-child(3) {
  justify-content: flex-end;
}

.gh-estimate-row-item-key {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #242f42;
  padding-right: 20px;
}

.gh-estimate-row-item-value {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  color: #242f42;
}

.gh-estimate-check-item.checked .gh-estimate-check-item-text {
  font-weight: 500;
}

.gh-estimate-check-item-text {
  font-size: 14px;
  color: #242f42;
  margin-left: 13px;
}

/*Media */

@media (max-width: 920px) {
  .gh-estimate-row {
    flex-direction: column;
    width: 100%;
    margin: 0;
    align-items: flex-start;
  }

  .gh-estimate-row-item {
    margin: 0 0 20px;
    align-items: flex-start;
  }

  .gh-estimate-row-item:last-child {
    margin: 0;
  }

  .gh-estimate-check-item {
    flex-direction: column;
  }

}

@media (max-width: 576px) {
  .gh-estimate-row-item {
    flex-direction: column;
  }

  .estimate-row-item-key {
    margin-bottom: 10px;
  }
}
